define("momentumhub/helpers/custom-widgets/rating", ["exports", "survey-knockout", "survey-core"], function (_exports, Survey, SurveyCore) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Rating = {
    name: "ratinggauge",
    title: "Rating Gauge",
    iconName: "",
    widgetIsLoaded: function () {
      return true;
    },
    isFit: function (question) {
      return question.getType() === 'ratinggauge';
    },
    activatedByChanged: function (activatedBy) {
      Survey.JsonObject.metaData.addClass("ratinggauge", [], null, "text");
      Survey.JsonObject.metaData.addProperties("ratinggauge", [{
        name: "buttonText",
        default: "Click Me"
      }]);
      Survey.JsonObject.metaData.addProperty("ratinggauge", {
        name: "ratingRange:string",
        default: 11,
        category: "general"
      });
      Survey.JsonObject.metaData.addProperty('ratinggauge', {
        name: 'gaugeType:string',
        default: 'gauge',
        category: 'general',
        choices: ['gauge', 'happiness meter']
      });
      Survey.JsonObject.metaData.addProperty("page", {
        name: "ratingTange:string",
        default: 11
      });
      SurveyCore.JsonObject.metaData.addClass("ratinggauge", [], null, "text");
      SurveyCore.JsonObject.metaData.addProperties("ratinggauge", [{
        name: "buttonText",
        default: "Click Me"
      }]);
      SurveyCore.JsonObject.metaData.addProperty("ratinggauge", {
        name: "ratingRange:string",
        default: 11,
        category: "general"
      });
      SurveyCore.JsonObject.metaData.addProperty('ratinggauge', {
        name: 'gaugeType:string',
        default: 'gauge',
        category: 'general',
        choices: ['gauge', 'happiness meter']
      });
      SurveyCore.JsonObject.metaData.addProperty("page", {
        name: "ratingTange:string",
        default: 11
      });
    },
    isDefaultRender: false,
    htmlTemplate: `<div class="widget-wrapper cw-gauge--wrapper" id="cw-gauge--wrapper">
                    <div class="cw-gauge--reading-container">
                    </div>
                    <div class="cw-gauge--needle"></div>
                  </div>`,
    afterRender: function (question, el) {
      const createCustomSurvey = () => {
        const ratingRange = question.ratingRange;
        let startValue, endValue;
        if (isNaN(ratingRange)) {
          const arr = ratingRange.split('-');
          startValue = arr[1];
          endValue = arr[0];
        } else {
          startValue = ratingRange - 1;
          endValue = 0;
        }
        let finalAngle = 120;
        let _html = "";
        const childTemplate = (rating, theta, i) => `<div class="cw-reading--${rating}"
                                                      data-rating="${rating}"
                                                      data-theta="${theta}"
                                                      style="transform:rotate(${theta}Deg)"
                                                  >
                                                    <div style="transform:rotate(${theta * -1}Deg)">${i}</div>
                                                  </div>`;
        if (question.gaugeType == 'happiness meter') {
          $(el).addClass('happiness-meter');
        }
        let count = startValue - endValue + 1;
        const angleDivider = count;
        for (let i = startValue; i >= endValue; i--) {
          _html += childTemplate(count, finalAngle, i);
          finalAngle -= 270 / angleDivider;
          count--;
        }
        const readingContainer = el.querySelector('.cw-gauge--reading-container');
        readingContainer.innerHTML = _html;
        const readingArr = el.querySelectorAll('.cw-gauge--reading-container > div');
        const needle = el.querySelector('.cw-gauge--needle');
        readingArr.forEach(reading => {
          reading.addEventListener('click', e => {
            const selected = e.target.parentElement;
            document.querySelectorAll('.cw-gauge--reading-container .selected').forEach(a => a.classList.remove('selected'));
            const rating = selected.getAttribute('data-rating');
            const theta = selected.getAttribute('data-theta');
            selected.classList.add('selected');
            needle.style.transform = "rotate(" + (Number(theta) + 95) + "deg)";
            question.value = rating;
          });
        });
      };
      if (question.value) {
        setTimeout(function () {
          var domElement = $(el);
          var dataRating = question.value;
          var selectedElement = domElement.find('div[data-rating="' + dataRating + '"]');
          selectedElement.addClass('selected');
          const theta = selectedElement.attr('data-theta');
          domElement.find('.cw-gauge--needle').css({
            'transform': "rotate(" + (Number(theta) + 95) + "deg)"
          });
          // domElement.find('.cw-gauge--reading-container > div').addClass('disable-clicks');
        }, 500);
      }
      question.registerFunctionOnPropertyValueChanged("ratingRange", createCustomSurvey);
      createCustomSurvey();
    },
    willUnmount: function (question, el) {},
    pdfQuestionType: "text"
  };
  var _default = Rating;
  _exports.default = _default;
});