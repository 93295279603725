define("momentumhub/models/participantmodule", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberData.default.Model.extend({
    participant: _emberData.default.belongsTo('user'),
    module: _emberData.default.belongsTo('module'),
    study: _emberData.default.belongsTo('study'),
    node_id: _emberData.default.attr('number'),
    module_type: _emberData.default.attr(),
    completed: _emberData.default.attr('boolean'),
    available: _emberData.default.attr('boolean'),
    is_resource: _emberData.default.attr('boolean'),
    created_at: _emberData.default.attr('date')
  });
  _exports.default = _default;
});