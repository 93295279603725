define("momentumhub/templates/components/blockly-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "NHi4BZh3",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"div\"],[14,0,\"blockly-component\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"hover_bkgr_fricc-study-blockly\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"login-component-box\"],[12],[2,\"\\n            \"],[10,\"div\"],[14,0,\"inner-box\"],[12],[2,\"\\n                \"],[11,\"div\"],[24,0,\"close close2\"],[24,\"title\",\"Reset to default\"],[4,[38,0],[[32,0],\"resetToDefault\"],null],[12],[2,\"\\n                    \"],[1,[30,[36,1],[\"undo\"],[[\"class\"],[\"saveicons\"]]]],[13],[2,\"\\n                \"],[11,\"div\"],[24,0,\"close close1\"],[24,\"title\",\"Close\"],[4,[38,0],[[32,0],\"closeWithoutSaving\"],null],[12],[2,\"\\n                    \"],[1,[30,[36,1],[\"times\"],[[\"class\"],[\"saveicons\"]]]],[13],[2,\"\\n                \"],[11,\"div\"],[24,0,\"close\"],[24,\"title\",\"Save\"],[4,[38,0],[[32,0],\"close\"],null],[12],[1,[30,[36,1],[\"save\"],[[\"class\"],[\"saveicons\"]]]],[13],[2,\"\\n                \"],[10,\"h1\"],[14,0,\"head\"],[12],[2,\"Add Logic\"],[13],[2,\"\\n                \"],[10,\"div\"],[14,0,\"inner-component\"],[12],[2,\"\\n                    \"],[10,\"div\"],[14,0,\"toolbox\"],[14,1,\"toolbox\"],[12],[2,\"\\n\\n                    \"],[13],[2,\"\\n                    \"],[10,\"div\"],[14,0,\"blocklyDiv\"],[14,1,\"blocklyDiv\"],[12],[2,\"\\n\\n                    \"],[13],[2,\"\\n                \"],[13],[2,\"\\n\\n\\n            \"],[13],[2,\"\\n        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\\n\"],[13],[2,\"\\n\\n\\n\\n\\n\\n\"],[18,1,null]],\"hasEval\":false,\"upvars\":[\"action\",\"fa-icon\"]}",
    "moduleName": "momentumhub/templates/components/blockly-component.hbs"
  });
  _exports.default = _default;
});