define("momentumhub/models/event", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberData.default.Model.extend({
    name: _emberData.default.attr(),
    identifier: _emberData.default.attr(),
    action: _emberData.default.attr(),
    receiver: _emberData.default.attr(),
    sender: _emberData.default.attr(),
    message: _emberData.default.attr(),
    rule: _emberData.default.belongsTo('rule'),
    is_node_event: _emberData.default.attr("boolean"),
    delivery_time: _emberData.default.attr(),
    delivery_timestamp: _emberData.default.attr(),
    delivery_type: _emberData.default.attr(),
    delivery_time_after_creation: _emberData.default.attr(),
    event_fire_frequency: _emberData.default.attr(),
    days_between_fire_frequency: _emberData.default.attr(),
    delivery_time_after_creation: _emberData.default.attr(),
    reward_operation: _emberData.default.attr(),
    reward_change: _emberData.default.attr(),
    reward_variable: _emberData.default.attr(),
    module_id: _emberData.default.attr(),
    module_availability: _emberData.default.attr("boolean"),
    is_resource: _emberData.default.attr("boolean"),
    delay_in_hours: _emberData.default.attr(),
    survey_id: _emberData.default.attr(),
    report_type: _emberData.default.attr(),
    notification_settings: _emberData.default.attr()
  });
  _exports.default = _default;
});