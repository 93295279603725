define("momentumhub/controllers/assesment-results", ["exports", "momentumhub/config/environment", "chart.js/auto"], function (_exports, _environment, _auto) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    //variable declaration
    isTeenager: false,
    init() {
      this._super(...arguments);
      var participantAge = localStorage.getItem('participantAge');
      if (participantAge <= 12) {
        this.set('isTeenager', false);
      } else {
        this.set('isTeenager', true);
      }
    },
    actions: {
      downloadReport: function (report) {
        var reportUrl = '/reports/user' + report.user + '/' + report.report_type_display.toLowerCase() + '/' + report.id + '/' + 'Article.pdf';
        window.open(reportUrl);
      },
      viewReport: function (report) {
        var reportUrl = '/reports/user' + report.user + '/' + report.report_type_display.toLowerCase() + '/' + report.id + '/' + 'index.html';
        window.open(reportUrl);
      }
    }
  });
  _exports.default = _default;
});