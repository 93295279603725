define("momentumhub/templates/climbing-plans/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "AXwJG4qp",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,0],[\"Edit\"],null]],[2,\"\\n\\n\"],[1,[30,[36,2],null,[[\"model\"],[[35,1]]]]],[2,\"\\n\\n\"],[1,[30,[36,4],[[30,[36,3],null,null]],null]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"page-title\",\"model\",\"mount-momentum-form\",\"-outlet\",\"component\"]}",
    "moduleName": "momentumhub/templates/climbing-plans/edit.hbs"
  });
  _exports.default = _default;
});