define("momentumhub/controllers/onboarding-confirmation", ["exports", "momentumhub/controllers/registration", "momentumhub/config/environment", "momentumhub/constants/consent-questions"], function (_exports, _registration, _environment, _consentQuestions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _registration.default.extend({
    showConsentForm: false,
    showOnboardingForm: true,
    provideConsent: '',
    age: null,
    userType: null,
    selectedCheckboxes: 0,
    gender: [{
      "name": "Male",
      "value": "M",
      "group": 'M'
    }, {
      "name": "Female",
      "value": "F",
      "group": 'F'
    }, {
      "name": "Non-binary",
      "value": "non_binary",
      "group": 'O'
    }, {
      "name": "Trans male",
      "value": "trans_male",
      "group": "O"
    }, {
      "name": "Trans female",
      "value": "trans_female",
      "group": "O"
    }, {
      "name": "I don't identify with any of these",
      "value": "dont_identify",
      "group": "O"
    }, {
      "name": "I prefer not to say",
      "value": "prefer_not_to_say",
      "group": "O"
    }],
    questionsForUserType: Ember.computed('userType', function () {
      let questions = _consentQuestions.default;
      if (questions.default) {
        questions = questions.default;
      }
      return questions[this.get('userType')];
    }),
    isConsentButtonDisabled: Ember.computed('selectedCheckboxes', function () {
      return this.get('selectedCheckboxes') != this.get('questionsForUserType').length;
    }),
    init() {
      this._super(...arguments);
      let age = localStorage.getItem('onboardingAge');
      if (age) {
        age = parseFloat(age);
        this.set('age', age);
        let userType = 'youth';
        if (age <= 12) {
          userType = 'child';
        } else if (age <= 15) {
          userType = 'teen';
        }
        this.set('userType', userType);
      }
    },
    actions: {
      setConsent: function (value) {
        this.set('provideConsent', value.currentTarget.value);
      },
      setCheckbox: function (event) {
        const isChecked = event.target.checked;
        if (isChecked) {
          this.set('selectedCheckboxes', this.get('selectedCheckboxes') + 1);
        } else {
          this.set('selectedCheckboxes', this.get('selectedCheckboxes') - 1);
        }
      },
      confirmConsent: function () {
        var consent = this.get('provideConsent');
        if (consent === '') {
          return;
        } else if (consent === 'true') {
          this.set('showOnboardingForm', true);
          this.set('showConsentForm', false);
        } else {
          window.location.href = _environment.default.APP.ONBOARDING_EXIT_NOCONSENT_PAGE;
        }
      },
      setGender: function (value) {
        this.set('usergender', value);
      },
      registerUser: function () {
        const that = this;
        const first_name = this.get('first_name');
        const last_name = this.get('last_name');
        const user_email = this.get('user_email');
        const age = this.get('age');
        const password = this.get('password');
        const confirm_password = this.get('confirm_password');
        const mobile = this.get('mobile');
        var usergender = this.get('usergender');
        var gendergroup = usergender;
        if (usergender != 'M' && usergender != 'F') {
          gendergroup = 'O';
        }
        var isMobileValid = mobile && mobile.length == 10 && mobile.slice(0, 2) == '04';
        if (first_name) {
          // if (last_name) {
          if (user_email) {
            if (this.validateEmail(user_email)) {
              if (age) {
                if (password) {
                  if (this.validatePassword(password)) {
                    if (confirm_password) {
                      if (usergender) {
                        if (password == confirm_password) {
                          if (isMobileValid) {
                            const usertype = this.usertype;
                            var data_to_api = {
                              "email": user_email,
                              "first_name": first_name,
                              // "last_name": last_name,
                              "age": age,
                              "user_type": "PT",
                              "password": password,
                              "is_active": true,
                              "gender": gendergroup,
                              "gender_detail": usergender,
                              'mobile': '+61' + mobile
                            };
                            $.ajax({
                              url: _environment.default.APP.API_HOST + '/users',
                              type: 'POST',
                              data: data_to_api,
                              success: function (res) {
                                const userId = res.user.id;
                                $('.hover_bkgr_fricccc_spinner1').show();
                                that.authenticate(user_email, password, userId, age);
                                that.set('first_name', "");
                                that.set('last_name', "");
                                that.set('user_email', "");
                                that.set('password', "");
                                that.set('confirm_password', "");
                                that.set('gender', null);
                                that.set('age', null);
                              },
                              error: function (err, xH) {
                                var str = err.responseText;
                                str = str.split(':["').pop();
                                str = str.split('"]')[0];
                                that.newAlert(str, "Registration", "Failure");
                              }
                            });
                          } else {
                            this.newAlert("Mobile number should be 10 digits and should start with 04", "Mobile Number", "Failure");
                          }
                        } else {
                          this.newAlert("Password and Confirm Password doesn't match.", "Registration", "Warning");
                        }
                      } else {
                        this.showError("genderselect");
                      }
                    } else {
                      this.showError("confirm_password");
                    }
                  }
                } else {
                  this.showError("password");
                }
              } else {
                this.showError("age");
              }
            }
          } else {
            this.showError("user_email");
          }
          // } else { this.showError("last_name") }
        } else {
          this.showError('first_name');
        }
      }
    }
  });
  _exports.default = _default;
});