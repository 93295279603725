define("momentumhub/templates/components/footer-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "jhLtAaHb",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"footer\"],[14,0,\"footer-component\"],[12],[2,\"\\n    \"],[10,\"a\"],[12],[2,\" Copyright USQ 2024 — v1.6.0\"],[13],[2,\"\\n    \"],[10,\"a\"],[12],[2,\"Terms & Conditions\"],[13],[2,\"\\n    \"],[10,\"a\"],[12],[2,\"Privacy Policy\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[18,1,null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "momentumhub/templates/components/footer-component.hbs"
  });
  _exports.default = _default;
});