define("momentumhub/controllers/create-survey", ["exports", "momentumhub/config/environment", "jquery", "survey-pdf", "survey-creator"], function (_exports, _environment, _jquery, SurveyPDF, _surveyCreator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    survey_js_url: _environment.default.APP.SURVEY_JS,
    surveyCreator: "",
    surveyId: "",
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    isEditable: false,
    survey: [],
    toast: Ember.inject.service(),
    stringValidation: true,
    isAnotherResearcherWorking: false,
    isLocked: false,
    isSurveyEditing: true,
    timer: "",
    seconds: 60,
    minutes: 8,
    activityTimer: "",
    delayTimer: "",
    isEditing: true,
    lock_assigned_to: "",
    exit: false,
    surveyChanged: false,
    lockAssignedTo: "",
    actions: {
      backToSurveys: function () {
        this.set('surveyChanged', false);
        this.set('minutes', 8);
        this.set('activityTimer', "");
        clearInterval(this.timer);
        clearInterval(this.seconds);
        this.set('timer', "");
        this.set('seconds', 60);
        this.set('isSurveyEditing', true);
        this.transitionToRoute('surveys');
      },
      closeBox: function () {
        this.set('isAnotherResearcherWorking', false);
      },
      saveAndrouteBack: function () {
        this.routeBackToSurveys();
      },
      closeSaveAlert: function () {
        (0, _jquery.default)('.hover_bkgr_fricc-study-module1').hide();
      },
      // Asks and start continue working with survey
      continueWorking: function () {
        this.set('minutes', 8);
        this.set('activityTimer', "");
        clearInterval(this.delayTimer);
        clearInterval(this.timer);
        // clearInterval(this.activityTimer);
        this.set('timer', "");
        this.set('seconds', 60);
        this.set('isSurveyEditing', true);
        this.startsurveyActivityCounter();
      },
      surveyUpdated: function () {
        // This function updates backend that user is still working on survey
        this.sendActivityToBackend();
        this.set('surveyChanged', true);
      },
      savesurvey: function () {
        var surveyCreator = this.surveyCreator;
        var surveyText = surveyCreator.text;
        var newSurveyText = this.getParticipantJson(JSON.parse(surveyCreator.text));
        var surveyId = this.surveyId;
        var self = this;
        var accessToken = this.get('session.data.authenticated.access_token');
        const r = confirm("Are you sure you want to save this survey?");
        if (r) {
          if (surveyId) {
            var date_updated = new Date();
            _jquery.default.ajax({
              url: _environment.default.APP.API_HOST + '/surveys/' + surveyId,
              type: 'PATCH',
              headers: {
                "Authorization": "Bearer " + accessToken,
                "Content-Type": "application/json"
              },
              data: JSON.stringify({
                "survey": {
                  "survey_body": JSON.parse(surveyText),
                  "survey_body_viewable": newSurveyText,
                  "date_updated": date_updated,
                  locked: false
                }
              }),
              success: function (res) {
                var surveyId = res.survey.id;
                var localStorageName = "survey-" + surveyId;
                localStorage.removeItem(localStorageName);
                self.newAlert("Survey is successfully saved.", "Survey", "Success");
                self.set('surveyChanged', false);
                // self.transitionToRoute('surveys');
              },

              error: function (err, xH) {
                self.set('surveyChanged', false);
                var errorMessage = err.responseText;
                alert(errorMessage);
              }
            });
          } else {
            alert("Survey id is needed to save the Survey.");
          }
        }
      },
      exportPdf: function () {
        const r = confirm("Do you want to export this survey as a PDF?");
        var that = this;
        if (r) {
          var creator = this.surveyCreator;
          var newSurveyText = this.getParticipantJson(that.surveyCreator.JSON);
          setTimeout(function () {
            const options = {
              commercial: true,
              fontSize: creator.survey.pdfFontSize,
              fontName: creator.survey.pdfFontName,
              margins: {
                left: creator.survey.pdfMarginLeft,
                right: creator.survey.pdfMarginRight,
                top: creator.survey.pdfMarginTop,
                bot: creator.survey.pdfMarginBottom
              },
              htmlRenderAs: creator.survey.pdfHtmlRenderAs,
              matrixRenderAs: creator.survey.pdfMatrixRenderAs,
              compress: creator.survey.pdfCompress
            };
            if (creator.survey.pdfOrientation !== "auto") {
              options.orientation = creator.survey.pdfOrientation;
            }
            if (creator.survey.pdfFormatType === "custom") {
              options.format = [creator.survey.pdfFormatWidth, creator.survey.pdfFormatHeight];
            } else if (creator.survey.pdfFormatType !== "default") {
              options.format = creator.survey.pdfFormatType;
            }
            var surveyPDF = new SurveyPDF.SurveyPDF(newSurveyText, options);

            //uncomment next code to add html and markdown text support
            /*var converter = new showdown.Converter();
            surveyPDF.onTextMarkdown.add(function(survey, options) {
                var str = converter.makeHtml(options.text);
                str = str.substring(3);
                str = str.substring(0, str.length - 4);
                options.html = str;
            });*/

            surveyPDF.onRenderHeader.add(function (_, canvas) {
              canvas.drawText({
                text: "",
                fontSize: 10
              });
            });
            surveyPDF.save(that.surveyCreator.JSON.title);
          }, 100);
        }
      },
      backBtn(surveyId) {
        var localStorageName = "survey-" + surveyId;
        localStorage.removeItem(localStorageName);
        window.history.back();
      },
      showEditTitle: function () {
        var isEditable = this.isEditable;
        var survey = this.survey;
        var accessToken = this.get('session.data.authenticated.access_token');
        var that = this;
        var surveyId = survey.get('id');
        if (isEditable) {
          var survey_name = this.get('survey_name');
          if (survey_name) {
            var dateUpdated = Date();
            const apidata = {
              "name": survey_name,
              "date_updated": dateUpdated
            };
            _jquery.default.ajax({
              url: _environment.default.APP.API_HOST + '/surveys/' + surveyId,
              type: 'PATCH',
              headers: {
                "Authorization": "Bearer " + accessToken
              },
              data: apidata,
              success: function (res) {
                console.log(res);
                that.send('refreshCurrentRoute');
                that.set('isEditable', false);
              },
              error: function (err, xH) {
                var errorMessage = err.responseText;
                alert(errorMessage);
              }
            });
          } else {}
        } else {
          this.set('survey_name', survey.get('name'));
          this.set('isEditable', true);
        }
      },
      closeEditTitle: function () {
        this.set('isEditable', false);
      }
    },
    getParticipantJson: function (json) {
      var that = this;
      json.pages.forEach(function (page, pageIndex) {
        //Each Page
        page.elements.forEach(function (question, questionIndex) {
          //1. Radio Group or Dropdown or Checkbox or Ranking
          if (question.type == "radiogroup" || question.type == "dropdown" || question.type == "checkbox" || question.type == "ranking") {
            question.title = that.stripeString(question.title, "question", question.type);
            question.choices.forEach(function (choice) {
              if (choice.text) {
                choice.text = that.stripeString(choice.text, "item", question.type);
              }
            });
          }

          // 2. Text  or Comment or Rating
          if (question.type == "text" || question.type == "comment" || question.type == "rating") {
            question.title = that.stripeString(question.title, "question", question.type);
            if (question.rateValues) {
              question.rateValues.forEach(function (item) {
                if (item.text) {
                  item.text = that.stripeString(item.text, "item", question.type);
                }
              });
            }
          }

          //Boolean
          if (question.type == "boolean") {
            question.title = that.stripeString(question.title, "question", question.type);
            question.labelTrue = that.stripeString(question.labelTrue, "question", question.type);
            question.labelFalse = that.stripeString(question.labelFalse, "question", question.type);
          }

          // //3. Multipletext
          if (question.type == "multipletext") {
            question.title = that.stripeString(question.title, "question", question.type);
            question.items.forEach(function (item) {
              if (item.title) {
                item.title = that.stripeString(item.title, "item", question.type);
              }
            });
          }

          // 4. Matrix
          if (question.type == "matrix") {
            question.title = that.stripeString(question.title, "question", question.type);
            question.columns.forEach(function (item) {
              if (item.text) {
                item.text = that.stripeString(item.text, "item", question.type);
              }
            });
            question.rows.forEach(function (item) {
              if (item.text) {
                item.text = that.stripeString(item.text, "item", question.type);
              }
            });
          }

          //6. ImagePicker
          if (question.type == "imagepicker") {
            question.title = that.stripeString(question.title, "question", question.type);
            question.choices.forEach(function (item) {
              if (item.text) {
                item.text = that.stripeString(item.text, "item", question.type);
              }
            });
          }

          //matrixdropdown
          if (question.type == "matrixdropdown") {
            question.title = that.stripeString(question.title, "question", question.type);
            question.columns.forEach(function (item) {
              if (item.title) {
                item.title = that.stripeString(item.title, "item", question.type);
              }
            });
            question.rows.forEach(function (item) {
              if (item.text) {
                item.text = that.stripeString(item.text, "item", question.type);
              }
            });
            question.choices.forEach(function (item) {
              if (item.text) {
                item.text = that.stripeString(item.text, "item", question.type);
              }
            });
          }

          // For Parent deatils
          if (question.type == "text" || question.type == "boolean") {
            question.title = that.StripeDollar(question.title);
          }

          //expression
          if (question.type == "expression") {
            if (question.title) {
              question.title = that.stripeString(question.title);
            }
            question.name = that.stripeString(question.name);
          }

          //ratinggauge
          if (question.type == "ratinggauge") {
            if (question.title) {
              question.title = that.stripeString(question.title);
            }
            question.name = that.stripeString(question.name);
          }
        });
      });
      return json;
    },
    stripeString: function (text, type, questionType) {
      if (text) {
        if (text.includes("~")) {
          return text.split('~')[0];
        } else {
          if (type == "item") {
            this.set('stringValidation', false);
          }
          return text;
        }
      } else {
        if (type == "item") {
          this.set('stringValidation', false);
        }
        return "";
      }
    },
    // Add parent details strings to match
    StripeDollar: function (text) {
      if (text.includes("$parent_first_name")) {
        return text.split('$parent_first_name')[0];
      }
      if (text.includes("$parent_last_name")) {
        return text.split('$parent_last_name')[0];
      }
      if (text.includes("$parent_email")) {
        return text.split('$parent_email')[0];
      }
      if (text.includes("$parent_password")) {
        return text.split('$parent_password')[0];
      }
      if (text.includes("$parent_same_email")) {
        return text.split('$parent_same_email')[0];
      }

      //Parent General Info
      if (text.includes("$parent_general_info_first_name")) {
        return text.split('$parent_general_info_first_name')[0];
      }
      if (text.includes("$parent_general_info_last_name")) {
        return text.split('$parent_general_info_last_name')[0];
      }
      if (text.includes("$parent_general_info_email")) {
        return text.split('$parent_general_info_email')[0];
      }
      return text;
    },
    /*  Alert to show success/ failure or warning   */
    newAlert: function (message, title, type) {
      if (type === "Success") {
        this.toast.success(message, title, {
          "closeButton": false,
          "debug": false,
          "newestOnTop": false,
          "progressBar": false,
          "positionClass": "toast-top-right",
          "preventDuplicates": false,
          "onclick": null,
          "showDuration": "300",
          "hideDuration": "1000",
          "timeOut": "5000",
          "extendedTimeOut": "1000",
          "showEasing": "swing",
          "hideEasing": "linear",
          "showMethod": "fadeIn",
          "hideMethod": "fadeOut"
        });
      } else if (type === "Failure") {
        this.toast.error(message, title, {
          "closeButton": false,
          "debug": false,
          "newestOnTop": false,
          "progressBar": false,
          "positionClass": "toast-top-right",
          "preventDuplicates": false,
          "onclick": null,
          "showDuration": "300",
          "hideDuration": "1000",
          "timeOut": "5000",
          "extendedTimeOut": "1000",
          "showEasing": "swing",
          "hideEasing": "linear",
          "showMethod": "fadeIn",
          "hideMethod": "fadeOut"
        });
      } else {
        this.toast.warning(message, title, {
          "closeButton": false,
          "debug": false,
          "newestOnTop": false,
          "progressBar": false,
          "positionClass": "toast-top-right",
          "preventDuplicates": false,
          "onclick": null,
          "showDuration": "300",
          "hideDuration": "1000",
          "timeOut": "5000",
          "extendedTimeOut": "1000",
          "showEasing": "swing",
          "hideEasing": "linear",
          "showMethod": "fadeIn",
          "hideMethod": "fadeOut"
        });
      }
    },
    /**************************** Survey Activity ********************/

    surveyEditing: function () {
      const isEditing = this.isEditing;
      console.log(isEditing);
    },
    startsurveyActivityCounter: function () {
      var that = this;
      var currentDate = new Date();
      console.log("Timmer start at " + currentDate);
      that.delayTimer = setTimeout(function () {
        that.set('isSurveyEditing', false);
        if (!that.exit) {
          that.startSixtySecondsCounter();
        }
        var currentDate1 = new Date();
        console.log("Timmer stopped at " + currentDate1);
      }, 480000);
      // 480000
      // if (!this.activityTimer) {
      //     this.activityTimer = window.setInterval(function () {
      //         // that.surveyActivityCounter();
      //         if (that.minutes > 1) { that.minutes--; console.log(that.minutes) }
      //         else {
      //             if (!that.exit) {
      //                 clearInterval(that.activityTimer);
      //                 that.set('isSurveyEditing', false);
      //                 that.startSixtySecondsCounter();
      //             }
      //         }
      //     }, 60000);
      // }
    },

    surveyActivityCounter: function () {
      if (this.minutes > 1) {
        this.minutes--;
        console.log(this.minutes);
      } else {
        if (!this.exit) {
          clearInterval(this.activityTimer);
          this.set('isSurveyEditing', false);
          this.startSixtySecondsCounter();
        }
      }
    },
    /*********************** Only for Alert closing purpose **************/
    startSixtySecondsCounter: function () {
      var that = this;
      if (!this.timer) {
        this.timer = window.setInterval(function () {
          // that.sixtySecondsCounter();
          if (that.seconds < 60) {
            document.getElementById("timer").innerHTML = that.seconds;
          }
          if (that.seconds > 0) {
            that.seconds--;
          } else {
            clearInterval(that.timer);
            that.routeBackToSurveys();
          }
        }, 1000);
      }
    },
    sixtySecondsCounter: function () {
      if (this.seconds < 60) {
        if (document.getElementById("timer")) document.getElementById("timer").innerHTML = this.seconds;
      }
      if (this.seconds > 0) {
        this.seconds--;
      } else {
        clearInterval(this.timer);
        this.routeBackToSurveys();
      }
    },
    routeBackToSurveys: function () {
      this.set('minutes', 8);
      this.set('activityTimer', "");
      clearInterval(this.timer);
      clearInterval(this.seconds);
      this.set('timer', "");
      this.set('seconds', 60);
      this.set('isSurveyEditing', true);
      this.set('surveyChanged', false);
      this.saveSurveyBeforredirecting();
      this.transitionToRoute('surveys');
    },
    resetTimmers: function () {
      this.set('activityTimer', null);
      this.set('timer', null);
      clearInterval(this.delayTimer);
      clearInterval(this.timer);
      // clearInterval(this.seconds);
      // clearInterval(this.activityTimer);
      this.set('minutes', 0);
      this.set('exit', true);
      console.log('exit');
    },
    ifNotSaved: function (transition) {
      if (this.surveyChanged) {
        transition.abort();
        (0, _jquery.default)('.hover_bkgr_fricc-study-module1').show();
      }

      //Open Lock without saving
      else {
        const lockAssignedTo = this.lockAssignedTo;
        const userOnline = this.session.get('data.userId');
        if (userOnline == lockAssignedTo) {
          this.unlockSurveyWithoutSaving();
        }
      }
    },
    unlockSurveyWithoutSaving: function () {
      var surveyId = this.surveyId;
      var accessToken = this.get('session.data.authenticated.access_token');
      var date_updated = new Date();
      _jquery.default.ajax({
        url: _environment.default.APP.API_HOST + '/surveys/' + surveyId,
        type: 'PATCH',
        headers: {
          "Authorization": "Bearer " + accessToken,
          "Content-Type": "application/json"
        },
        data: JSON.stringify({
          "survey": {
            "date_updated": date_updated,
            locked: false
          }
        }),
        success: function (res) {
          var surveyId = res.survey.id;
          var localStorageName = "survey-" + surveyId;
          localStorage.removeItem(localStorageName);
        },
        error: function (err, xH) {}
      });
    },
    saveSurveyBeforredirecting: function () {
      var surveyId = this.surveyId;
      var self = this;
      var accessToken = this.get('session.data.authenticated.access_token');
      var date_updated = new Date();
      var surveyText = this.surveyCreator.text;
      var newSurveyText = this.getParticipantJson(JSON.parse(surveyText));
      _jquery.default.ajax({
        url: _environment.default.APP.API_HOST + '/surveys/' + surveyId,
        type: 'PATCH',
        headers: {
          "Authorization": "Bearer " + accessToken,
          "Content-Type": "application/json"
        },
        data: JSON.stringify({
          "survey": {
            "survey_body": JSON.parse(surveyText),
            "survey_body_viewable": newSurveyText,
            "date_updated": date_updated,
            locked: false
          }
        }),
        success: function (res) {
          var surveyId = res.survey.id;
          var localStorageName = "survey-" + surveyId;
          localStorage.removeItem(localStorageName);
          self.newAlert("Survey is successfully saved.", "Survey", "Success");
        },
        error: function (err, xH) {
          var errorMessage = err.responseText;
          alert(errorMessage);
        }
      });
    },
    sendActivityToBackend: function () {
      var surveyId = this.surveyId;
      var self = this;
      var accessToken = this.get('session.data.authenticated.access_token');
      var userOnline = this.session.get('data.userId');
      var that = this;
      that.set('isAnotherResearcherWorking', false);
      that.set('isLocked', false);
      that.set('lock_assigned_to', "");
      _jquery.default.ajax({
        url: _environment.default.APP.API_HOST + '/surveys/' + surveyId,
        type: 'PATCH',
        headers: {
          "Authorization": "Bearer " + accessToken,
          "Content-Type": "application/json"
        },
        data: JSON.stringify({
          "survey": {
            "locked": true,
            "lock_assigned_to": userOnline
          }
        }),
        success: function (res) {
          // Start counter again
          self.send('continueWorking');
        },
        error: function (err, xH) {
          var str = err.responseText;
          str = str.split(':["').pop();
          str = str.split('"]')[0];
          that.set('isAnotherResearcherWorking', true);
          that.set('isLocked', true);
          that.set('lock_assigned_to', str);
        }
      });
    }
  });
  _exports.default = _default;
});