define("momentumhub/controllers/user-profile/rewards", ["exports", "momentumhub/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    session: Ember.inject.service(),
    // participant age declare
    isTeenager: false,
    isChild: false,
    // get details of rewards vars (TR_ vars)
    rewardVars: Ember.computed('model.rewards', function () {
      return this.get('model.rewards').firstObject.reward_vars;
    }),
    init: function () {
      this._super(...arguments);
      var participantAge = localStorage.getItem('participantAge');
      if (participantAge <= 12) {
        this.set('isTeenager', false);
        this.set('isChild', true);
      } else {
        this.set('isTeenager', true);
        this.set('isChild', false);
      }
    },
    rewardsArr: Ember.computed('model.rewards', function () {
      const rewards = this.get('model.rewards');
      let count = 0;
      if (rewards && rewards.firstObject) {
        const reward_vars = rewards.firstObject.reward_vars || {};
        count = reward_vars.TR_Reward1 || 0;
        count /= 2;
        count = Math.floor(count);
        count += 1;
      }
      return Array(count).keys();
    })
  });
  _exports.default = _default;
});