define("momentumhub/routes/feelings-tracker", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "momentumhub/config/environment", "survey-knockout", "momentumhub/helpers/custom-widgets"], function (_exports, _authenticatedRouteMixin, _environment, Survey, _customWidgets) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    session: Ember.inject.service(),
    activate: function () {
      this._super();
      window.scrollTo(0, 0);
    },
    model(param) {
      // if (param.id) {
      return Ember.RSVP.hash({
        survey: this.store.findRecord('survey', _environment.default.APP.DIARY_FEELINGSTRACKER_FORMID)
      });
      // }
    },

    actions: {
      refreshCurrentRoute() {
        this.refresh();
      }
    },
    setupController(controller, model) {
      this._super(controller, model);
    }
  });
  _exports.default = _default;
});