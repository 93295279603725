define("momentumhub/components/modules/module-tags", ["exports", "momentumhub/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    router: Ember.inject.service(),
    tags: [],
    moduleId: "",
    moduleName: "",
    moduleDescription: "",
    moduleThumbnail: "",
    isDeleteTag: true,
    eachTagDelete: false,
    allowPrint: false,
    hideSaveButtons: false,
    type: "",
    actions: {
      setPrintOption: function (event) {
        this.set('allowPrint', event.target.checked);
      },
      setHideSaveButtons: function (event) {
        this.set('hideSaveButtons', event.target.checked);
      },
      /********* Close assets component *********/
      close: function () {
        $('.hover_bkgr_fricc-module-tags').hide();
        this.set('tags', []);
        this.set('moduleId', "");
        this.set('isDeleteTag', true);
        this.set('eachTagDelete', false);
      },
      /* Preview the media */
      previewMedia: function () {
        var uploadmediainput = document.getElementById('modulethumbnail1');
        var tmppath = URL.createObjectURL(uploadmediainput.files[0]);
        this.set('moduleThumbnail', tmppath);
      },
      /********* Create Tags *********/
      createTag: function () {
        var that = this;
        var tag = this.get('tag');
        if (tag) {
          if (this.tags.includes(tag)) {
            $('.note-tag').animate({
              opacity: '1'
            }, 300);
            setTimeout(function () {
              $('.note-tag').animate({
                opacity: '0'
              }, 300);
            }, 3000);
          } else {
            if (tag.includes(",")) {
              var tagsArray = tag.split(',');
              tagsArray.forEach(element => {
                if (that.tags.includes(element)) {
                  $('.note-tag').animate({
                    opacity: '1'
                  }, 300);
                  setTimeout(function () {
                    $('.note-tag').animate({
                      opacity: '0'
                    }, 300);
                  }, 3000);
                } else {
                  that.tags.pushObject(element);
                  that.set('tag', '');
                }
              });
            } else {
              this.tags.pushObject(tag);
              this.set('tag', '');
            }
          }
        }
      },
      /********* Delete Tags *********/
      deleteTag: function (index) {
        const r = confirm("Are you sure you want to delete this tag");
        if (r) {
          this.tags.removeAt(index);
          if (this.tags.length === 0) {
            this.set('isDeleteTag', true);
            this.set('eachTagDelete', false);
          }
        }
      },
      /********* Activate Delete Tag buttons *********/
      activateDeleteTag: function () {
        if (this.isDeleteTag) {
          this.set('isDeleteTag', false);
          this.set('eachTagDelete', true);
        } else {
          this.set('isDeleteTag', true);
          this.set('eachTagDelete', false);
        }
      },
      /********* Update Tags *********/
      updateTags: function () {
        var self = this;
        var accessToken = this.get('session.data.authenticated.access_token');
        var moduleId = this.get('moduleId');
        var tags = this.get('tags');
        var dateUpdated = new Date();
        var type = this.type;
        var moduleName = this.get('moduleName');
        var moduleDescription = this.get('moduleDescription');
        var mediaFile = document.getElementById('modulethumbnail1');
        var hideSaveButtons = this.get('hideSaveButtons');
        /* Update Tags on Module */
        if (moduleName) {
          if (type == "Module") {
            var formdata = new FormData();
            formdata.append("name", moduleName);
            formdata.append("tags", JSON.stringify(tags));
            formdata.append("date_updated", dateUpdated);
            formdata.append("mod_description", moduleDescription);
            formdata.append('hide_save_buttons', hideSaveButtons);
            if (mediaFile.files[0]) {
              formdata.append("thumbnail_image", mediaFile.files[0], mediaFile.files[0].name);
            }
            $.ajax({
              url: _environment.default.APP.API_HOST + '/modules/' + moduleId,
              type: 'PATCH',
              headers: {
                "Authorization": "Bearer " + accessToken
                // "Content-Type": "application/json"
              },

              processData: false,
              contentType: false,
              data: formdata,
              success: function (res) {
                self.sendAction('refreshCurrentRoute');
                $('.hover_bkgr_fricc-module-tags').hide();
              },
              error: function (err, xH) {
                var errorMessage = err.responseText;
                if (errorMessage.includes("Module with this name already exists.")) {
                  $('.modulenameexist').show();
                  setTimeout(function () {
                    $('.modulenameexist').hide();
                  }, 3000);
                }
              }
            });
          } else {
            var userOnline = this.session.get('data.userId');
            // 1. Acquire lock
            $.ajax({
              url: _environment.default.APP.API_HOST + '/surveys/' + moduleId,
              type: 'PATCH',
              headers: {
                "Authorization": "Bearer " + accessToken,
                "Content-Type": "application/json"
              },
              data: JSON.stringify({
                "survey": {
                  "locked": true,
                  "lock_assigned_to": userOnline,
                  "allow_print": self.get('allowPrint')
                }
              }),
              success: function (res) {
                savData();
              },
              error: function (err, xH) {
                var errorMessage = err.responseText;
                alert(errorMessage);
              }
            });

            // 2. Save data
            function savData() {
              $.ajax({
                url: _environment.default.APP.API_HOST + '/surveys/' + moduleId,
                type: 'PATCH',
                headers: {
                  "Authorization": "Bearer " + accessToken,
                  "Content-Type": "application/json"
                },
                data: JSON.stringify({
                  "survey": {
                    "tags": tags,
                    "date_updated": dateUpdated,
                    "name": moduleName,
                    "description": moduleDescription
                  }
                }),
                success: function (res) {
                  // 3. Unassign lock
                  $.ajax({
                    url: _environment.default.APP.API_HOST + '/surveys/' + moduleId,
                    type: 'PATCH',
                    headers: {
                      "Authorization": "Bearer " + accessToken,
                      "Content-Type": "application/json"
                    },
                    data: JSON.stringify({
                      "survey": {
                        "locked": false
                      }
                    }),
                    success: function (res) {
                      self.sendAction('refreshCurrentRoute');
                      $('.hover_bkgr_fricc-module-tags').hide();
                    },
                    error: function (err, xH) {
                      var errorMessage = err.responseText;
                      alert(errorMessage);
                    }
                  });
                },
                error: function (err, xH) {
                  var errorMessage = err.responseText;
                  if (errorMessage.includes("Survey with this name already exists.")) {
                    $('.modulenameexist').show();
                    setTimeout(function () {
                      $('.modulenameexist').hide();
                    }, 3000);
                  } else {
                    alert(errorMessage);
                  }
                }
              });
            }
          }
        } else {
          $("#modulesurveyname123").addClass("error");
          setTimeout(function () {
            $("#modulesurveyname123").removeClass("error");
          }, 2000);
        }
      }
    }
  });
  _exports.default = _default;
});