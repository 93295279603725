define("momentumhub/components/loading-spinner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{!-- Spinner Component --}}
  <div class="spinner-component">
      <div class="hover_bkgr_fricccc_spinner">
          <div class="spinner">
              <div class="dot1"></div>
              <div class="dot2"></div>
          </div>
      </div>
  </div> 
  */
  {
    "id": "EYE/pMGC",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"spinner-component\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"hover_bkgr_fricccc_spinner\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"spinner\"],[12],[2,\"\\n            \"],[10,\"div\"],[14,0,\"dot1\"],[12],[13],[2,\"\\n            \"],[10,\"div\"],[14,0,\"dot2\"],[12],[13],[2,\"\\n        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"],[13],[2,\" \"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "momentumhub/components/loading-spinner.hbs"
  });
  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
  _exports.default = _default;
});