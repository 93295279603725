define("momentumhub/helpers/custom-widgets/random-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const RandomSelect = {
    name: 'randomselect',
    title: 'Random Select',
    iconName: '',
    widgetIsLoaded: function () {
      return true;
    },
    isFit: function (question) {
      return question.getType() === 'randomselect';
    },
    activatedByChanged: function (activatedBy) {
      Survey.JsonObject.metaData.addClass("randomselect", [], null, "text");
      Survey.JsonObject.metaData.addProperty("randomselect", {
        name: "choices:itemvalues",
        category: "choices"
      });
      Survey.JsonObject.metaData.addProperty("page", {
        name: "choices:itemvalues"
      });
    },
    isDefaultRender: false,
    htmlTemplate: `<div class="random-select"></div>`,
    afterRender: function (question, el) {
      const choices = question.choices;
      if (choices.length) {
        const randomChoice = Math.floor(Math.random() * choices.length);
        const selectedChoice = choices[randomChoice].getData();
        question.setNewValue(selectedChoice.value);
      }
      if (!el.parentElement.parentElement.classList.contains('question_actions')) {
        el.closest('.sv-row').style = 'display: none';
      }
    }
  };
  var _default = RandomSelect;
  _exports.default = _default;
});