define("momentumhub/helpers/custom-widgets/quiz", ["exports", "survey-knockout", "survey-core"], function (_exports, Survey, SurveyCore) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Quiz = {
    name: "quiz",
    title: "Quiz",
    iconName: "",
    widgetIsLoaded: function () {
      return true;
    },
    isFit: function (question) {
      return question.getType() === 'quiz';
    },
    activatedByChanged: function (activatedBy) {
      Survey.JsonObject.metaData.addClass("quiz", [], null, "text");
      Survey.JsonObject.metaData.addProperty("quiz", {
        name: "choices:itemvalues",
        category: "choices"
      });
      Survey.JsonObject.metaData.addProperty('quiz', {
        name: 'showRightOrWrongBox:boolean',
        category: 'Right or Wrong Box'
      });
      Survey.JsonObject.metaData.addProperty("quiz", {
        name: "correctAnswer:string",
        category: "Correct Answer"
      });
      Survey.JsonObject.metaData.addProperty("page", {
        name: "choices:itemvalues"
      });
      Survey.JsonObject.metaData.addProperty("page", {
        name: "correctAnswer:string"
      });
      SurveyCore.JsonObject.metaData.addClass("quiz", [], null, "text");
      SurveyCore.JsonObject.metaData.addProperty("quiz", {
        name: "choices:itemvalues",
        category: "choices"
      });
      SurveyCore.JsonObject.metaData.addProperty('quiz', {
        name: 'showRightOrWrongBox:boolean',
        category: 'Right or Wrong Box'
      });
      SurveyCore.JsonObject.metaData.addProperty("quiz", {
        name: "correctAnswer:string",
        category: "Correct Answer"
      });
      SurveyCore.JsonObject.metaData.addProperty("page", {
        name: "choices:itemvalues"
      });
      SurveyCore.JsonObject.metaData.addProperty("page", {
        name: "correctAnswer:string"
      });
    },
    isDefaultRender: false,
    htmlTemplate: `<div class="quiz-question"><div class="quiz-choices"></div><div class="correct-answer answer-response">&#10004; Correct Answer</div><div class="wrong-answer answer-response">
&#10060; Wrong Answer</div></div>`,
    afterRender: function (question, el) {
      const createCustomSurvey = () => {
        const wrapperElement = el.closest('.sv-row');
        if (wrapperElement) wrapperElement.classList.add('quiz-wrapper');
        const choices = question.choices;
        const showRightOrWrongBox = question.showRightOrWrongBox;
        const querySelector = el.querySelector('.quiz-choices');
        var choicesHtml = '';
        choices.map(choice => {
          const data = choice.getData();
          const isImage = data.text && data.text.match(/^http[^\?]*.(jpg|jpeg|gif|png|tiff|bmp|svg)(\?(.*))?$/gmi) != null;
          var divContent = data.text;
          var customClass = '';
          if (isImage) {
            divContent = '<img src="' + data.text + '" data-value="' + data.value + '">';
            customClass = 'image-choice';
          }
          choicesHtml += '<div class="quiz-choice ' + customClass + '" data-value="' + data.value + '">' + divContent + '</div>';
        });
        querySelector.innerHTML = choicesHtml;
        const choiceElements = el.querySelectorAll('.quiz-choice');
        choiceElements.forEach(choice => {
          choice.addEventListener('click', e => {
            const selected = e.target;
            const parentElement = selected.closest('.quiz-choices');
            parentElement.classList.add('answer-selected');
            for (const element of parentElement.getElementsByClassName('quiz-choice')) {
              element.classList.remove('selected-answer');
            }
            selected.closest('.quiz-choice').classList.add('selected-answer');
            const value = selected.getAttribute('data-value');
            if (showRightOrWrongBox) {
              wrapperElement.classList.remove('correct-response');
              wrapperElement.classList.remove('wrong-response');
              if (value === question.correctAnswer) {
                wrapperElement.classList.add('correct-response');
              } else {
                wrapperElement.classList.add('wrong-response');
              }
            }
            question.setNewValue(value);
          });
        });
      };
      question.onItemValuePropertyChanged.add(createCustomSurvey);
      question.registerFunctionOnPropertyValueChanged("choices", createCustomSurvey);
      createCustomSurvey();
    },
    willUnmount: function (question, el) {},
    pdfQuestionType: "text"
  };
  var _default = Quiz;
  _exports.default = _default;
});