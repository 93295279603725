define("momentumhub/templates/loading", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "RwTUsPO9",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,0],[\"Loading\"],null]],[2,\"\\n\"],[10,\"div\"],[14,0,\"loading_c\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"spinner1\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"rect1\"],[12],[13],[2,\"\\n        \"],[10,\"div\"],[14,0,\"rect2\"],[12],[13],[2,\"\\n        \"],[10,\"div\"],[14,0,\"rect3\"],[12],[13],[2,\"\\n        \"],[10,\"div\"],[14,0,\"rect4\"],[12],[13],[2,\"\\n        \"],[10,\"div\"],[14,0,\"rect5\"],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[1,[30,[36,2],[[30,[36,1],null,null]],null]]],\"hasEval\":false,\"upvars\":[\"page-title\",\"-outlet\",\"component\"]}",
    "moduleName": "momentumhub/templates/loading.hbs"
  });
  _exports.default = _default;
});