define("momentumhub/routes/module-preview", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "momentumhub/config/environment"], function (_exports, _authenticatedRouteMixin, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    session: Ember.inject.service(),
    ajax: Ember.inject.service(),
    queryParams: {
      showNext: {
        refreshModel: true
      },
      nodeId: {
        refreshModel: true
      }
    },
    activate: function () {
      this._super();
      window.scrollTo(0, 0);
    },
    model(param) {
      if (param.id) {
        var that = this;
        that.controllerFor('module-preview').set('isLastPage', false);
        this.store.query('module', {
          identifier: param.id
        }).then(function (modules) {
          var module = modules.get('firstObject');
          var isPage = module.get('current_page');
          var hedgeDocUrl = null;
          if (isPage) {
            hedgeDocUrl = _environment.default.APP.HEDGE_DOC + "/s/" + param.id;
            if (!module.get('hide_save_buttons')) that.controllerFor('module-preview').set('isLastPage', true);
          } else {
            hedgeDocUrl = _environment.default.APP.HEDGE_DOC + "/p/" + param.id;
          }
          that.controllerFor("module-preview").set('hedgeDocUrl', hedgeDocUrl);
          that.controllerFor("module-preview").set('module', module);
        });
      }
      return Ember.RSVP.hash({
        paramID: param?.id,
        showNext: param?.showNext,
        nodeId: param?.nodeId
      });
    },
    actions: {
      refreshCurrentRoute() {
        this.refresh();
      }
    },
    setupController(controller, model) {
      this._super(controller, model);
      this.controllerFor("module-preview").set('exitModule', false);
      this.controllerFor("module-preview").set('hasNext', false);
      if (model.showNext !== 'true' && model.showNext != true) {
        return;
      }
      var userId = this.session.get('data.userId');
      const that = this;
      const accessToken = this.get('session.data.authenticated.access_token');
      const viewingId = this.session.get('data.viewingId');
      if (viewingId) {
        userId = viewingId;
      }
      $.ajax({
        url: _environment.default.APP.API_HOST + '/studyusers?user=' + userId,
        type: 'GET',
        headers: {
          "Authorization": "Bearer " + accessToken
        },
        success: function (res) {
          const studyUsermodel = res.studyuser;
          if (studyUsermodel.length > 0) {
            const studyUser = studyUsermodel[0];
            const studyPartJson = studyUser.user_study_part_json;
            for (const key in studyPartJson) {
              const partJson = studyPartJson[key];
              let continuetoNextNode = partJson.filter(e => e.id == model.nodeId);
              if (continuetoNextNode.length == 0) {
                continuetoNextNode = partJson.filter(e => e.typeid == model.paramID && e.continueNode == true && (e.status === 'COM' || e.processed === 1));
              }
              if (continuetoNextNode.length > 0) {
                that.controllerFor("module-preview").set('studyPartJson', partJson);
                const currentNodeObject = continuetoNextNode[0];
                that.controllerFor("module-preview").set('current_node', currentNodeObject.id);
                that.controllerFor("module-preview").set('hasNext', currentNodeObject.continueNode);
                that.controllerFor("module-preview").set('next_node', currentNodeObject.next);
              }
            }
          }
        }
      });
    }
  });
  _exports.default = _default;
});