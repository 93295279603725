define("momentumhub/controllers/studyview", ["exports", "survey-knockout", "survey-pdf", "momentumhub/config/environment", "momentumhub/utils"], function (_exports, Survey, SurveyPDF, _environment, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    session: Ember.inject.service(),
    toast: Ember.inject.service(),
    showModule: true,
    hedgeDocUrl: "",
    currentIndex: 0,
    typenName: "",
    studyPartJson: [],
    wholeStudyJson: [],
    current_node: "",
    studyUserId: "",
    studyUser: null,
    current_phase: "",
    currentStudy: "",
    nodeType: "",
    nextExecutionTime: null,
    current_module_page: "",
    userEvents: [],
    shownEvents: [],
    teenagePrimaryColor: ["#4281A4", "#7C606B", "#C1666B", "#48A9A6", "1A3A61", "#D4B483"],
    kidsPrimaryColor: ["#28B4CC", "#19C89C", "#FACE03", "#003E8F", "EF5406", "#822EB2"],
    is_study_completed: false,
    currentUser: "",
    currentReadableNodeId: "",
    responseEndRule: "",
    currentModuleId: "",
    isTester: false,
    required_module_id: null,
    isOnboardingStudy: false,
    part_timestamp_json: [],
    currentPhaseidentifier: "",
    hideSaveButtons: false,
    init() {
      this._super(...arguments);
    },
    actions: {
      nextButton: async function () {
        if (this.required_module_id) {
          $('.bottom-nextstudy').hide();
          this.changeRequiredModuleStatusInStudyView();
        } else {
          // If end rule present then show alert first
          var callSaveNewNode = true;
          if (this.responseEndRule) {
            callSaveNewNode = await this.getAlerts(this.responseEndRule, true);
          }
          //Else show next node
          if (callSaveNewNode) {
            $('.bottom-nextstudy').hide();
            // this.postProgress(false);
            this.saveNewNode(true);
            this.saveModuleForLaterUse();
          }
        }
      },
      endOfModule: function () {
        /* if (this.required_module_id) {
            if (!this.hideSaveButtons)
                $('.bottom-nextstudy').show();
        } else {
            this.postProgress();
            this.saveModuleForLaterUse();
        } */
        if (!this.hideSaveButtons) $('.bottom-nextstudy').show();
      },
      nextSlide: function () {
        var currentSlide = document.getElementById("nextSlide").dataset.nextslide;
        this.updateSlideNumber(currentSlide);
      },
      // Only for embeded hedgedoc surveys
      nextSurvey: function () {
        const surveyId = localStorage.getItem("hedgedocsurveyresult");
        let result = localStorage.getItem("hedgedocsurveyresult1");
        localStorage.setItem("hedgedocsurveyresult", "");
        localStorage.setItem("hedgedocsurveyresult1", "");
        const that = this;
        this.store.findRecord('survey', surveyId).then(function (survey) {
          // const json = survey.get('survey_body_viewable');
          var survey_body = survey.get('survey_body');
          var surveyObj = new Survey.Model(survey.get('survey_body_viewable'));
          result = JSON.parse(result);
          for (const [key, value] of Object.entries(result)) {
            surveyObj.setValue(key, value);
          }
          that.saveSurveyResult(surveyObj, survey_body, surveyId, "InModule", survey_body);
        });
      },
      closeAlerts: function () {
        var that = this;
        const accessToken = this.get('session.data.authenticated.access_token');
        this.userEvents.forEach(function (events, index) {
          setTimeout(() => {
            const event = events.get('id');
            var data_to_api = {
              "marked_as_read": true
            };
            $.ajax({
              url: _environment.default.APP.API_HOST + '/userevents/' + event,
              type: 'PATCH',
              data: data_to_api,
              headers: {
                "Authorization": "Bearer " + accessToken
              },
              success: function (res) {
                $('#add-to-list').hide();
                const myNode = document.getElementById('list');
                myNode.innerHTML = '';
                $('.hover_bkgr_fricc-study-alert').hide();
                if (that.responseEndRule) {
                  $('.bottom-nextstudy').hide();
                  that.saveNewNode();
                  that.set('responseEndRule', "");
                }
              },
              error: function (err, xH) {
                var str = err.responseText;
                str = str.split(':["').pop();
                str = str.split('"]')[0];
                console.log(str);
              }
            });
          }, index * 1000);
        });
      },
      /************** Exit study on completion ************* */
      exitStudy: async function () {
        this.getStudyProgress();
        this.saveNewNode();
      },
      // Exit study on completion
      studyCompleted: function () {
        const that = this;
        const accessToken = this.get('session.data.authenticated.access_token');
        const studyUserId = this.studyUserId;
        const currentTimeStamp = this.getTimeStamp();
        const current_phase = this.current_phase;
        var part_timestamp_json = this.part_timestamp_json;
        var currentPhaseidentifier = this.currentPhaseidentifier;
        const uid_end_timestamp = currentPhaseidentifier + "_end_timestamp";
        if (part_timestamp_json[currentPhaseidentifier]) {
          part_timestamp_json[currentPhaseidentifier][uid_end_timestamp] = currentTimeStamp;
        }
        var data_to_api = {
          "completed": true,
          "current_study": false,
          "study_end_time": currentTimeStamp,
          "part_timestamp_json": JSON.stringify(part_timestamp_json)
        };
        $.ajax({
          url: _environment.default.APP.API_HOST + '/studyusers/' + studyUserId,
          type: 'PATCH',
          data: data_to_api,
          headers: {
            "Authorization": "Bearer " + accessToken
          },
          success: function (res) {
            $('.bottom-nextstudy-1').hide();
            that.transitionToRoute('dashboard');
          },
          error: function (err, xH) {}
        });
      }
    },
    getModuleOrSurvey: function () {
      const current_node = this.current_node;
      const studyPartJson = this.studyPartJson;
      if (this.get('studyUser.background_error')) {
        this.newAlert(this.get('studyUser.background_error'), 'Study Progress error', 'Failure');
      }
      if (studyPartJson) {
        const currentNodeObjectArray = studyPartJson.filter(function (e) {
          return e.id == current_node;
        });
        const currentNodeObject = currentNodeObjectArray[0];
        const nodeType = currentNodeObject.type;
        const nodeTypeId = currentNodeObject.typeid;
        // If Module
        if (nodeType == "module") {
          this.set('showModule', true);
          this.set('currentModuleId', nodeTypeId);
          this.getModules(nodeTypeId);
        }

        // If Survey
        if (nodeType == "survey") {
          this.set('showModule', false);
          this.getsurvey(nodeTypeId);
        }
        this.set('nodeType', nodeType);
      }
    },
    // ********* Get Surveys *********//
    getsurvey: function (id) {
      var that = this;
      $('.bottom-nextstudy').hide();
      this.store.query('survey', {
        identifier: id
      }).then(function (surveys) {
        const response = surveys.get('firstObject');
        var json = response.get('survey_body_viewable');
        var survey_body = response.get('survey_body');
        var survey_id = response.get('id');
        Survey.StylesManager.applyTheme("modern");
        window.survey = new Survey.Model(json);
        if (response.get('allow_print')) {
          (0, _utils.setupSurveyPrint)(window.survey, json);
        }
        survey.onComplete.add(function (result) {
          console.log(result);
          that.saveSurveyResult(result, survey_body, survey_id, "InStudy", survey_body);
        });
        survey.render("surveyElement");
        // survey.completedHtml = "<p class='survey_response_copy'>Thank you for the response.</p>"
      });
    },

    // get modules
    getModules: function (id) {
      const current_module_page = this.current_module_page;
      const that = this;
      this.store.query('module', {
        identifier: id
      }).then(function (modules) {
        const response = modules.get('firstObject');
        const current_page = response.get('current_page');
        const hideSaveButtons = response.get('hide_save_buttons');
        that.set('hideSaveButtons', hideSaveButtons);
        if (current_page) {
          var hedgeDocUrl = _environment.default.APP.HEDGE_DOC + "/s/" + id;
          that.set('hedgeDocUrl', hedgeDocUrl);
          //Show next button for single page module
          if (!hideSaveButtons) $('.bottom-nextstudy').show();
        } else {
          if (current_module_page) {
            var hedgeDocUrl = _environment.default.APP.HEDGE_DOC + "/p/" + id + "#/" + current_module_page;
          } else {
            var hedgeDocUrl = _environment.default.APP.HEDGE_DOC + "/p/" + id;
          }
          that.set('hedgeDocUrl', hedgeDocUrl);
        }
      });
    },
    //************************************ Save module/ survey progress  *****************************/

    // Save Survey results
    saveSurveyResult: function (survey, json, surveyId, type, survey_body_json) {
      var result = survey.data;
      var that = this;
      var isForParentDetails = this.checkParentDetilsSurvey(survey_body_json);
      var isForParentGeneralDetails = this.checkParentGeneralDetilsSurvey(survey_body_json);
      var finalResultArray = [];
      var displayValues = [];
      for (var key in survey.data) {
        var question = survey.getQuestionByValueName(key);
        var mappings = {};
        if (!question) continue;
        if (question.classMetaData.name == 'matrix') question.rows.map(function (row, index) {
          mappings[row.id] = row.title;
        });
        var questionResult = {
          name: key,
          value: survey.data[key],
          title: question.title,
          displayValue: question.displayValue,
          questionLabel: mappings
        };
        displayValues.push(questionResult);
      }
      json.pages.forEach(function (page) {
        var finalResult = [];
        if (isForParentDetails || isForParentGeneralDetails) {
          for (const [key, value] of Object.entries(result)) {
            const a = page.elements.filter(function (e) {
              return e.name == key;
            });
            if (a[0]) {
              const b = a[0];
              //7. Simple Text
              if (b.type == "text") {
                var title = b.title;
                var answer = value;
                if (title.indexOf('~') > -1) {
                  title = title.split('~')[1];
                  answer = title + ':' + answer;
                }
                const obj = {
                  "question": key,
                  "answer": answer
                };
                finalResultArray.pushObject(obj);
              }

              //8. boolean type
              if (b.type == "boolean") {
                if (value == true) {
                  var text = b.labelTrue;
                  text = that.checkString(text);
                  const obj = {
                    "question": key,
                    "answer": text
                  };
                  finalResultArray.pushObject(obj);
                } else {
                  var text = b.labelFalse;
                  text = that.checkString(text);
                  const obj = {
                    "question": key,
                    "answer": text
                  };
                  finalResultArray.pushObject(obj);
                }
              }
            }
          }
        } else {
          for (const [key, value] of Object.entries(result)) {
            const a = page.elements.filter(function (e) {
              return e.name == key;
            });
            if (a[0]) {
              const b = a[0];

              // 1.Checkbox
              if (b.type == "checkbox") {
                var choicesArray = [];
                value.forEach(function (val) {
                  const c = b.choices.filter(function (e) {
                    return e.value == val;
                  });
                  if (c[0]) {
                    var text = c[0].text;
                    text = that.checkString(text);
                    choicesArray.pushObject(text);
                  }
                });
                const obj = {
                  "question": key,
                  "answer": choicesArray
                };
                finalResultArray.pushObject(obj);
              }

              // 2.radiogroup
              if (b.type == "radiogroup") {
                const c = b.choices.filter(function (e) {
                  return e.value == value;
                });
                if (c[0]) {
                  var text = c[0].text;
                  text = that.checkString(text);
                  const obj = {
                    "question": key,
                    "answer": text
                  };
                  finalResultArray.pushObject(obj);
                }
              }

              //3. Dropdown
              if (b.type == "dropdown") {
                const c = b.choices.filter(function (e) {
                  return e.value == value;
                });
                if (c[0]) {
                  var text = c[0].text;
                  text = that.checkString(text);
                  const obj = {
                    "question": key,
                    "answer": text
                  };
                  finalResultArray.pushObject(obj);
                }
              }

              //4. Boolean
              if (b.type == "boolean") {
                if (value == true) {
                  var text = b.labelTrue;
                  text = that.checkString(text);
                  const obj = {
                    "question": key,
                    "answer": text
                  };
                  finalResultArray.pushObject(obj);
                } else {
                  var text = b.labelFalse;
                  text = that.checkString(text);
                  const obj = {
                    "question": key,
                    "answer": text
                  };
                  finalResultArray.pushObject(obj);
                }
              }

              //5. Ratings
              if (b.type == "rating") {
                var text = value;
                if (b.rateValues) {
                  const c = b.rateValues.filter(function (e) {
                    return e.value == value;
                  });
                  if (c[0]) {
                    text = c[0].text;
                    text = that.checkString(text);
                  }
                }
                const obj = {
                  "question": key,
                  "answer": text
                };
                finalResultArray.pushObject(obj);
              }

              //6. Ranking
              if (b.type == "ranking") {
                const obj = {
                  "question": key,
                  "answer": value
                };
                finalResultArray.pushObject(obj);
              }

              //7. Simple Text
              if (b.type == "text") {
                var title = b.title;
                var answer = value;
                if (title && title.indexOf('~') > -1) {
                  title = title.split('~')[1];
                  answer = title + ':' + answer;
                }
                const obj = {
                  "question": key,
                  "answer": answer
                };
                finalResultArray.pushObject(obj);
              }

              //8. matrix
              if (b.type == "matrix") {
                var matrixArray = [];
                for (const [k, v] of Object.entries(value)) {
                  var columnText = "";
                  var rowText = "";
                  var cv = b.columns.filter(function (e) {
                    return e.value == v;
                  });
                  if (cv[0]) {
                    columnText = cv[0].text;
                  }
                  var rv = b.rows.filter(function (e) {
                    return e.value == k;
                  });
                  if (rv[0]) {
                    rowText = rv[0].text;
                  }
                  if (columnText.includes("~")) {
                    var fields = columnText.split('~ val:');
                    columnText = fields[1];
                  }
                  if (rowText.includes("~")) {
                    var fields = rowText.split('~ val:');
                    rowText = fields[1];
                  }
                  if (!isNaN(parseInt(columnText))) {
                    matrixArray.pushObject(rowText + ":" + columnText);
                  }
                }
                const obj = {
                  "question": key,
                  "answer": matrixArray
                };
                finalResultArray.pushObject(obj);
              }

              //9. ImagePicker
              if (b.type == "imagepicker") {
                const c = b.choices.filter(function (e) {
                  return e.value == value;
                });
                if (c[0]) {
                  var text = c[0].text;
                  text = that.checkString(text);
                  const obj = {
                    "question": key,
                    "answer": text
                  };
                  finalResultArray.pushObject(obj);
                }
              }

              //10. matrixdropdown
              if (b.type == "matrixdropdown") {
                var matrixArray = [];
                for (const [k, v] of Object.entries(value)) {
                  var rowIndex = k[4];
                  var rowText = b.rows[parseInt(rowIndex) - 1].text;
                  var rowColumn = "";
                  if (rowText.includes("~")) {
                    var fields = rowText.split('~ val:');
                    rowText = fields[1];
                  }
                  //Columns
                  for (const [k1, v1] of Object.entries(v)) {
                    var columnIndex = k1[7];
                    var columnText = b.columns[parseInt(columnIndex) - 1].title;
                    if (columnText.includes("~")) {
                      var fields = columnText.split('~ val:');
                      columnText = fields[1];
                      rowColumn = rowText + "_" + columnText;
                      matrixArray.pushObject(rowColumn + ":" + v1);
                    } else {
                      if (b.choices[v1 - 1]) {
                        const choice = b.choices[v1 - 1].text;
                        if (choice.includes("~")) {
                          var fields = choice.split('~ val:');
                          rowColumn = rowText + "_" + columnText;
                          matrixArray.pushObject(rowColumn + ":" + fields[1]);
                        }
                      }
                    }
                  }
                }
                const obj = {
                  "question": key,
                  "answer": matrixArray
                };
                finalResultArray.pushObject(obj);
              }
              if (b.type == "expression") {
                var title = b.title || b.name;
                title = title.split('~')[1];
                const answer = title + ":" + value;
                const obj = {
                  "question": key,
                  "answer": answer
                };
                finalResultArray.pushObject(obj);
              }
              if (b.type == "ratinggauge") {
                var title = b.title;
                title = title.split('~')[1];
                const answer = title + ":" + value;
                const obj = {
                  "question": key,
                  "answer": answer
                };
                finalResultArray.pushObject(obj);
              }
            }
          }
        }
      });
      if (isForParentGeneralDetails) {
        that.createParentGeneralUser(finalResultArray);
      } else if (isForParentDetails) {
        that.createParentUser(finalResultArray);
      } else {
        displayValues.reverse();
        finalResultArray.reverse();
        that.saveSurveyActivity(finalResultArray, surveyId, type, result, displayValues);
      }
    },
    checkParentDetilsSurvey: function (json) {
      var isParentSurvey = false;
      json.pages.forEach(function (page, pageIndex) {
        page.elements.forEach(function (question, questionIndex) {
          if (question.type == "text") {
            if (question.title) {
              if (question.title.includes("$parent_first_name") || question.title.includes("$parent_last_name") || question.title.includes("$parent_email") || question.title.includes("$parent_password")) {
                isParentSurvey = true;
              }
            }
          }
        });
      });
      return isParentSurvey;
    },
    checkParentGeneralDetilsSurvey: function (json) {
      var isParentSurvey = false;
      json.pages.forEach(function (page, pageIndex) {
        page.elements.forEach(function (question, questionIndex) {
          if (question.type == "text") {
            if (question.title) {
              if (question.title.includes("$parent_general_info_first_name") || question.title.includes("$parent_general_info_last_name") || question.title.includes("$parent_general_info_email")) {
                isParentSurvey = true;
              }
            }
          }
        });
      });
      return isParentSurvey;
    },
    checkString(text) {
      if (text) {
        if (text.includes("~")) {
          var fields = text.split('~');
          return fields[1];
        } else {
          return text;
        }
      } else {
        return "";
      }
    },
    getGuardianEmail: function (email) {
      var email_start = email.substr(0, email.indexOf('@'));
      var email_end = email.split('@')[1];
      return email_start + "+guardian@" + email_end;
    },
    //**************** Create Parent User on Onbording process */
    createParentUser: function (finalResult) {
      const that = this;
      const first_name = finalResult[0].answer;
      const last_name = finalResult[1].answer;
      const useSameEmail = finalResult[2].answer;
      var user_email = "";
      var password = "";
      var set_a_random_pass = false;
      const accessToken = this.get('session.data.authenticated.access_token');
      if (useSameEmail == " true") {
        const participant_email = that.session.get('data.user_email');
        user_email = that.getGuardianEmail(participant_email);
        set_a_random_pass = true;
      } else {
        user_email = finalResult[3].answer;
        password = finalResult[4].answer;
      }
      const queryUrl = _environment.default.APP.API_HOST + '/check-if-user-exists/?email=' + user_email;
      $.ajax({
        url: queryUrl,
        type: 'GET',
        headers: {
          "Authorization": "Bearer " + accessToken
        },
        success: function (res) {
          var user = res.checkIfUserExists;
          if (user && user.id) {
            that.assignParentId(user.id);
            return;
          }
          var data_to_api = {
            "email": user_email,
            "first_name": first_name,
            "last_name": last_name,
            "user_type": "PR",
            "password": password,
            "is_active": true,
            "set_a_random_pass": set_a_random_pass
          };
          $.ajax({
            url: _environment.default.APP.API_HOST + '/users',
            type: 'POST',
            data: data_to_api,
            headers: {
              "Authorization": "Bearer " + accessToken
            },
            success: function (res) {
              that.assignParentId(res.user.id);
            },
            error: function (err, xH) {
              var str = err.responseText;
              str = str.split(':["').pop();
              str = str.split('"]')[0];
              console.log(str);
            }
          });
        }
      });
    },
    // Need to send welcome email along with password
    createParentGeneralUser: function (finalResult) {
      const that = this;
      const first_name = finalResult[0].answer;
      const last_name = finalResult[1].answer;
      const participant_email = that.session.get('data.user_email');
      const user_email = that.getGuardianEmail(participant_email);
      const accessToken = this.get('session.data.authenticated.access_token');
      const queryUrl = _environment.default.APP.API_HOST + '/check-if-user-exists/?email=' + user_email;
      $.ajax({
        url: queryUrl,
        type: 'GET',
        headers: {
          "Authorization": "Bearer " + accessToken
        },
        success: function (res) {
          var user = res.checkIfUserExists;
          if (user && user.id) {
            that.assignParentId(user.id);
            return;
          }
          var data_to_api = {
            "email": user_email,
            "first_name": first_name,
            "last_name": last_name,
            "user_type": "PR",
            "password": "",
            "is_active": true,
            "set_a_random_pass": true
          };
          $.ajax({
            url: _environment.default.APP.API_HOST + '/users',
            type: 'POST',
            data: data_to_api,
            headers: {
              "Authorization": "Bearer " + accessToken
            },
            success: function (res) {
              that.assignParentId(res.user.id);
            },
            error: function (err, xH) {
              var str = err.responseText;
              str = str.split(':["').pop();
              str = str.split('"]')[0];
              console.log(str);
            }
          });
        }
      });
    },
    //*************** Link parent id with participant *********** */
    assignParentId: function (parentId) {
      const that = this;
      const accessToken = this.get('session.data.authenticated.access_token');
      var participentId = this.currentUser;
      var data_to_api = {
        "parent": parentId,
        "participant": participentId
      };
      $.ajax({
        url: _environment.default.APP.API_HOST + '/parentparticipants',
        type: 'POST',
        data: data_to_api,
        headers: {
          "Authorization": "Bearer " + accessToken
        },
        success: function (res) {
          that.postProgress();
        },
        error: function (err, xH) {
          var str = err.responseText;
          str = str.split(':["').pop();
          str = str.split('"]')[0];
          console.log(str);
        }
      });
    },
    saveSurveyActivity: function (result_array, survey, type, result_json, displayValues) {
      const that = this;
      const accessToken = this.get('session.data.authenticated.access_token');
      const study = this.currentStudy;
      const user = this.currentUser;
      const current_phase = this.current_phase;
      const current_node = this.current_node;
      var currentReadableNodeId = this.currentReadableNodeId;
      var c = null;
      if (currentReadableNodeId.includes('_nomod')) {
        c = currentReadableNodeId.split('_nomod_sur');
      } else {
        c = currentReadableNodeId.split('_mod');
      }
      if (type == "InModule") {
        const currentModuleId = this.currentModuleId;
        currentReadableNodeId = c[0] + "_mod" + currentModuleId + "_sur" + survey;
      } else {
        const currentModuleId = this.currentModuleId;
        var c = currentReadableNodeId.split('_nomod_sur');
        currentReadableNodeId = c[0] + "_nomod_sur" + survey;
      }
      var data_to_api = {
        "study": study,
        "user": user,
        "part": current_phase,
        "node": current_node,
        "survey": survey,
        "result_array": JSON.stringify(result_array),
        "node_ref": currentReadableNodeId,
        "trackable_name": currentReadableNodeId,
        "result_json": JSON.stringify(result_json),
        "display_values": JSON.stringify(displayValues)
      };
      console.log(data_to_api);
      $.ajax({
        url: _environment.default.APP.API_HOST + '/activities',
        type: 'POST',
        data: data_to_api,
        headers: {
          "Authorization": "Bearer " + accessToken
        },
        success: function (res) {
          if (type == "InStudy") {
            that.postProgress();
          }
        },
        error: function (err, xH) {
          var str = err.responseText;
          alert(str);
        }
      });
    },
    // Function to update progress in the json
    updateprogress: function () {
      var wholeStudyJson = this.wholeStudyJson;
      const current_phase = this.current_phase;
      const partJson = wholeStudyJson[current_phase];
      const current_node = this.current_node;
      var currentIndex = partJson.findIndex(x => x.id == current_node);
      wholeStudyJson[current_phase][currentIndex].status = "COM";
      return wholeStudyJson;
    },
    // Post Progress to the backend
    postProgress: function () {
      let shouldCheckResponse = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
      const that = this;
      const accessToken = this.get('session.data.authenticated.access_token');
      const studyUserId = this.studyUserId;
      const userStudyPart = this.updateprogress();
      var data_to_api = {
        "user_study_part_json": JSON.stringify(userStudyPart),
        "current_module_page": "",
        "current_study": true
      };
      return $.ajax({
        url: _environment.default.APP.API_HOST + '/studyusers/' + studyUserId,
        type: 'PATCH',
        data: data_to_api,
        headers: {
          "Authorization": "Bearer " + accessToken
        },
        success: function (res) {
          if (shouldCheckResponse) {
            var studyuser = res.studyuser;
            that.checkResponse(studyuser);
          }
        },
        error: function (err, xH) {
          var str = err.responseText;
          str = str.split(':["').pop();
          str = str.split('"]')[0];
          console.log(str);
        }
      });
    },
    /**********************************Jump to next node on success *********************/

    checkResponse: function (response) {
      const wholeStudyJson = response.user_study_part_json;
      const current_phase = this.current_phase;
      const current_node = this.current_node;
      const studyPartJson = wholeStudyJson[current_phase];
      const partJsonArray = studyPartJson.filter(e => e.id == current_node);
      this.set('responseEndRule', "");
      var checkIfContinue = this.checkIfContinue(current_node, studyPartJson);
      if (partJsonArray[0]) {
        const part = partJsonArray[0];
        const status = part.status;
        const end_rule = part.end_rule;
        //1. If status = complete then all good
        if (status == "COM") {
          if (end_rule) {
            this.set('responseEndRule', end_rule);
            if (!this.hideSaveButtons) $('.bottom-nextstudy').show();
          } else {
            if (checkIfContinue && !this.hideSaveButtons) {
              $('.bottom-nextstudy').show();
            } else {
              this.saveNewNode();
            }
          }
        }

        //2. If status = fail then show error and ask participant to try after some time
        if (status == "FAIL") {
          if (end_rule) {
            this.set('responseEndRule', end_rule);
            if (!this.hideSaveButtons) $('.bottom-nextstudy').show();
          } else {
            if (checkIfContinue && !this.hideSaveButtons) {
              $('.bottom-nextstudy').show();
            } else {
              this.saveNewNode();
            }
          }
        }

        // this.saveNewNode();
        // if (end_rule) { this.getAlerts(end_rule); }
      }

      this.set('studyPartJson', studyPartJson);
      this.set('wholeStudyJson', wholeStudyJson);
      if (response.background_error && response.background_error.length > 0) {
        this.newAlert(response.background_error, 'Study Progress error', 'Failure');
      }
    },
    /*********************************** Save response in the backend and move to next node ********************* */

    //check current position of a node
    //check next node (linear or branch)
    getNextNode: function (currentNode, part) {
      //1. Get current node's object
      var currentNodeObjectArray = part.filter(e => e.id == currentNode);
      if (currentNodeObjectArray.length > 0) {
        const currentNodeObject = currentNodeObjectArray[0];
        var isnextBranch = 0;
        var next = currentNodeObject.next;
        const nextNode = part.filter(e => e.id == next);
        if (nextNode.length > 0 && nextNode[0].type == 'branch') {
          isnextBranch = 1;
          next = currentNode;
        }
        const totalBranches = currentNodeObject.totalBranches;

        //2. If there is a next node
        if (totalBranches > 0) {
          // 2.1. if next node is a linear node then return next node id
          if (isnextBranch == 0) {
            return next;
          }

          //2.2. Else next node is a branch then find a node with status NTT
          else {
            var nextNodeObjectArray = part.filter(e => e.status === "NTT");
            if (nextNodeObjectArray.length > 0) {
              var nextNodeObject = nextNodeObjectArray[0];
              var nextId = nextNodeObject.id;
              return nextId;
            }
          }
        }
        //3. No next node
        else {
          return "PART COMPLETE";
        }
      }
    },
    // Checks if participant will continue or leave the study view
    checkIfContinue: function (currentNode, part) {
      var currentNodeObjectArray = part.filter(e => e.id == currentNode);
      if (currentNodeObjectArray.length > 0) {
        const currentNodeObject = currentNodeObjectArray[0];
        const continueNode = currentNodeObject.continueNode;
        return continueNode;
      }
    },
    // If there's next part then return next part id else return same part id
    getNextPart: function () {
      var currentIndex = null;
      const wholeStudyJson = this.wholeStudyJson;
      const current_phase = this.current_phase;
      Object.keys(wholeStudyJson).forEach(function (key, index) {
        if (current_phase == Object.keys(wholeStudyJson)[index]) {
          currentIndex = index;
        }
      });
      if (Object.keys(wholeStudyJson)[currentIndex + 1]) {
        return Object.keys(wholeStudyJson)[currentIndex + 1];
      } else {
        return Object.keys(wholeStudyJson)[currentIndex];
      }
    },
    // get next node start rule
    getStartRule: function (nodeId) {
      const wholeStudyJson = this.wholeStudyJson;
      const current_phase = this.current_phase;
      const next_node = nodeId;
      const studyPartJson = wholeStudyJson[current_phase];
      const partJsonArray = studyPartJson.filter(e => e.id == next_node);
      if (partJsonArray[0]) {
        const part = partJsonArray[0];
        const start_rule = part.start_rule;
        return start_rule;
      }
    },
    showBackgroundError: function (studyUser) {
      if (studyUser.background_error) {
        this.newAlert(studyUser.background_error, 'Study Progress error', 'Failure');
      }
    },
    // Save new node in backend
    saveNewNode: async function () {
      let postProgress = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      const that = this;
      const accessToken = this.get('session.data.authenticated.access_token');
      const studyUserId = this.studyUserId;
      const current_node = this.current_node;
      const studyPartJson = this.studyPartJson;
      const current_phase = this.current_phase;
      const nextNode = this.getNextNode(current_node, studyPartJson);
      const next_phase = this.getNextPart();
      const next_start_rule = this.getStartRule(nextNode);
      const study_user_json = this.wholeStudyJson;
      const canContinue = this.getAlerts(next_start_rule);
      var currentModulePage = null;
      var checkIfContinue = this.checkIfContinue(current_node, studyPartJson);
      if (!canContinue) {
        return;
      }
      var currentNodeObject = studyPartJson.find(d => d.id == current_node);
      var nextExecutionTime = null;
      if (currentNodeObject && currentNodeObject.delay_in_days) {
        var delay = parseFloat(currentNodeObject.delay_in_days);
        var currentTime = new Date();
        currentTime.addHours(24 * delay);
        nextExecutionTime = currentTime;
      }
      if (current_node != nextNode) {
        currentModulePage = "";
      }
      if (nextNode == "PART COMPLETE") {
        if (next_phase != current_phase) {
          var part_timestamp_json = this.part_timestamp_json;
          const currentTimeStamp = this.getTimeStamp();
          const currentPhaseidentifier = this.currentPhaseidentifier;
          const uid_end_timestamp = currentPhaseidentifier + "_end_timestamp";
          if (part_timestamp_json[currentPhaseidentifier]) {
            part_timestamp_json[currentPhaseidentifier][uid_end_timestamp] = currentTimeStamp;
          }
          var data_to_api = {
            "current_node": "",
            "current_phase": next_phase,
            "current_module_page": "",
            "part_timestamp_json": JSON.stringify(part_timestamp_json)
          };
          if (postProgress) {
            var userStudyPart = this.updateprogress();
            data_to_api["user_study_part_json"] = JSON.stringify(userStudyPart);
          }
          if (nextExecutionTime) {
            data_to_api['next_node_execution_time'] = nextExecutionTime.toISOString();
          }
          $.ajax({
            url: _environment.default.APP.API_HOST + '/studyusers/' + studyUserId,
            type: 'PATCH',
            data: data_to_api,
            headers: {
              "Authorization": "Bearer " + accessToken
            },
            success: function (res) {
              const tt = res.studyuser;
              const suj = tt.user_study_part_json;
              that.showBackgroundError(tt);
              that.saveNodeStartTime(suj, current_phase, current_node, studyUserId, checkIfContinue);
              that.getAlerts(next_start_rule);
              // that.send('refreshCurrentRoute');
            },

            error: function (err, xH) {
              var str = err.responseText;
              str = str.split(':["').pop();
              str = str.split('"]')[0];
              alert(str);
            }
          });
        } else {
          if (postProgress) {
            var userStudyPart = this.updateprogress();
            var data_to_api = {
              "user_study_part_json": JSON.stringify(userStudyPart)
            };
            $.ajax({
              url: _environment.default.APP.API_HOST + '/studyusers/' + studyUserId,
              type: 'PATCH',
              data: data_to_api,
              headers: {
                "Authorization": "Bearer " + accessToken
              },
              success: function (res) {
                that.getAlerts(next_start_rule);
                that.showBackgroundError(res.studyuser);
              },
              error: function (err, xH) {
                var str = err.responseText;
                str = str.split(':["').pop();
                str = str.split('"]')[0];
                alert(str);
              }
            });
          }
          if (!that.hideSaveButtons) $('.bottom-nextstudy-1').show();
          // study complete
          console.log("Study complete");
          if (that.isOnboardingStudy) {
            that.updateUserStatus();
          }
        }
      } else {
        var data_to_api = {
          "current_node": nextNode,
          "current_phase": current_phase
        };
        if (currentModulePage != null) {
          data_to_api['current_module_page'] = currentModulePage;
        }
        if (postProgress) {
          var userStudyPart = this.updateprogress();
          data_to_api["user_study_part_json"] = JSON.stringify(userStudyPart);
        }
        if (nextExecutionTime) {
          data_to_api['next_node_execution_time'] = nextExecutionTime.toISOString();
        }
        $.ajax({
          url: _environment.default.APP.API_HOST + '/studyusers/' + studyUserId,
          type: 'PATCH',
          data: data_to_api,
          headers: {
            "Authorization": "Bearer " + accessToken
          },
          success: function (res) {
            const tt = res.studyuser;
            const suj = tt.user_study_part_json;
            that.store.unloadAll('studyuser');
            that.showBackgroundError(res.studyuser);
            that.saveNodeStartTime(suj, current_phase, current_node, studyUserId, checkIfContinue);
            that.getAlerts();
            if (checkIfContinue) {
              // Save branch as complete
              that.updateBranchAsComplete(nextNode, studyPartJson);
              that.send('refreshCurrentRoute');
            } else {
              // If user is a tester then show alert
              if (that.isTester) {
                const alertMessage = "The participant would be redirected to the dashboard and offer the ability to Continue study.";
                that.newAlert(alertMessage, "Continue", "Warning");

                // Save branch as complete
                that.updateBranchAsComplete(nextNode, studyPartJson);
                that.checkRequiredModule(0, null);
              }
              // Else on participant route to dashboad
              else {
                that.set('is_study_completed', true);
                $('.bottom-nextstudy').show();
              }
              // Save branch as complete
              that.updateBranchAsComplete(nextNode, studyPartJson);
            }
          },
          error: function (err, xH) {
            var str = err.responseText;
            str = str.split(':["').pop();
            str = str.split('"]')[0];
            alert(str);
          }
        });
      }
    },
    /********** Updates backend on each slide change *********** */
    updateSlideNumber: function (current_module_page) {
      const that = this;
      const accessToken = this.get('session.data.authenticated.access_token');
      const studyUserId = this.studyUserId;
      var data_to_api = {
        "current_module_page": current_module_page
      };
      $.ajax({
        url: _environment.default.APP.API_HOST + '/studyusers/' + studyUserId,
        type: 'PATCH',
        data: data_to_api,
        headers: {
          "Authorization": "Bearer " + accessToken
        },
        success: function (res) {},
        error: function (err, xH) {}
      });
    },
    /********************* Update Study Completion *************** */
    updateStudyCompletion: function () {
      const that = this;
      const accessToken = this.get('session.data.authenticated.access_token');
      const studyUserId = this.studyUserId;
      const study = this.currentStudy;
      const currentTimeStamp = this.getTimeStamp();
      var data_to_api = {
        "study": currentStudy,
        "completed": true,
        "current_study": false,
        "study_end_time": currentTimeStamp
      };
      $.ajax({
        url: _environment.default.APP.API_HOST + '/studyusers/' + studyUserId,
        type: 'PATCH',
        data: data_to_api,
        headers: {
          "Authorization": "Bearer " + accessToken
        },
        success: function (res) {
          // that.send('refreshCurrentRoute');
          that.set('is_study_completed', true);
          $('.bottom-nextstudy').show();
          // that.getUserNextStudy();
        },

        error: function (err, xH) {}
      });
    },
    /******************************** get progress **************************** */
    getStudyProgress: function () {
      const study_user_json = this.wholeStudyJson;
      const that = this;
      const accessToken = this.get('session.data.authenticated.access_token');
      var data_to_api = {
        "study_user_json": JSON.stringify(study_user_json)
      };
      $.ajax({
        url: _environment.default.APP.API_HOST_INITIAL + '/user/progress',
        type: 'POST',
        data: data_to_api,
        headers: {
          "Authorization": "Bearer " + accessToken
        },
        success: function (res) {
          const progress = res.getProgressView.progress;
          if (progress) {
            that.checkRequiredModule(progress, null);
          } else {
            that.checkRequiredModule(0, null);
          }
        },
        error: function (err, xH) {
          var str = err.responseText;
          str = str.split(':["').pop();
          str = str.split('"]')[0];
          that.checkRequiredModule(0, str);
          console.log(str);
        }
      });
    },
    // Check required Module
    checkRequiredModule: function (progress, error) {
      const that = this;
      const accessToken = this.get('session.data.authenticated.access_token');
      const study = this.currentStudy;
      const user = this.currentUser;
      $.ajax({
        url: _environment.default.APP.API_HOST + '/participantmodules?id=&participant=' + user + '&module=&module_type=RM&completed=false&study=' + study + '&available=true',
        type: 'GET',
        headers: {
          "Authorization": "Bearer " + accessToken
        },
        success: function (res) {
          const participantmodules = res.participantmodule;
          if (participantmodules[0]) {
            const module_id = participantmodules[0].module.identifier;
            const participantmodulesId = participantmodules[0].id;
            that.updateStudyProgress(progress, error, module_id, participantmodulesId);
          } else {
            that.updateStudyProgress(progress, error, null, null);
          }
        },
        error: function (err, xH) {
          var str = err.responseText;
          str = str.split(':["').pop();
          str = str.split('"]')[0];
          console.log(str);
        }
      });
    },
    // Save study Progress
    updateStudyProgress: function (progress, error, module_id, participantmoduleId) {
      const that = this;
      const accessToken = this.get('session.data.authenticated.access_token');
      const studyUserId = this.studyUserId;
      var data_to_api = {
        "current_progress": progress,
        "current_progress_error": error,
        "required_module_id": module_id
      };
      $.ajax({
        url: _environment.default.APP.API_HOST + '/studyusers/' + studyUserId,
        type: 'PATCH',
        data: data_to_api,
        headers: {
          "Authorization": "Bearer " + accessToken
        },
        success: function (res) {
          if (that.isTester) {
            that.send('refreshCurrentRoute');
          } else {
            $('.bottom-nextstudy').hide();
            that.transitionToRoute('dashboard');
          }
          if (participantmoduleId) {
            that.changeRequiredModuleStatus(participantmoduleId);
          }
        },
        error: function (err, xH) {}
      });
    },
    changeRequiredModuleStatus: function (id) {
      const that = this;
      const accessToken = this.get('session.data.authenticated.access_token');
      // var data_to_api = {
      //     "completed": true
      // };
      $.ajax({
        url: _environment.default.APP.API_HOST + '/participantmodules/' + id,
        type: 'DELETE',
        // data: data_to_api,
        headers: {
          "Authorization": "Bearer " + accessToken
        },
        success: function (res) {
          if (that.isTester) {
            var alertMessage = "This required Module will be shown when participant come back to continue.";
            that.newAlert(alertMessage, "Continue", "Success");
            that.send('refreshCurrentRoute');
          }
        },
        error: function (err, xH) {}
      });
    },
    changeRequiredModuleStatusInStudyView: function () {
      const that = this;
      const accessToken = this.get('session.data.authenticated.access_token');
      const studyUserId = this.studyUserId;
      var data_to_api = {
        "required_module_id": null
      };
      $.ajax({
        url: _environment.default.APP.API_HOST + '/studyusers/' + studyUserId,
        type: 'PATCH',
        data: data_to_api,
        headers: {
          "Authorization": "Bearer " + accessToken
        },
        success: function (res) {
          that.send('refreshCurrentRoute');
        },
        error: function (err, xH) {}
      });
    },
    /********** get alerts *********** */
    getAlerts: async function (ruleId, saveNewNodePostProgress) {
      var that = this;
      this.set('userEvents', []);
      const user = this.currentUser;
      var userevents = await this.store.query('userevent', {
        user: user,
        marked_as_read: false,
        event__action: "AL"
      });
      if (userevents.length > 0) {
        $('.hover_bkgr_fricc-study-alert').show();
        that.showAlerts(userevents);
        that.set('userEvents', userevents);
        return false;
      } else {
        return true;
      }
    },
    /**************** Show alerts **************** */
    showAlerts: function (userEvents) {
      //Set colors for each alert
      var participantAge = localStorage.getItem("participantAge");
      var colors = [];
      if (participantAge <= 12) {
        colors = this.kidsPrimaryColor;
      } else {
        colors = this.teenagePrimaryColor;
      }

      // append list
      var that = this;
      // const myNode = document.getElementById('list');
      // myNode.innerHTML = '';
      userEvents.forEach(function (events, index) {
        setTimeout(() => {
          const event = events.get('event');
          const eventId = event.get('id');
          that.store.findRecord('event', eventId).then(function (eventRecord) {
            const id = eventRecord.get('id');
            if (that.shownEvents.includes(id)) {
              return;
            }
            that.shownEvents.push(id);
            const message = eventRecord.get("message");
            if (message) {
              var list = document.getElementById('list');
              var newLI = document.createElement('li');
              if (index < 6) {
                newLI.style.backgroundColor = colors[index];
              }
              newLI.innerHTML = message;
              list.appendChild(newLI);
              setTimeout(function () {
                newLI.className = newLI.className + " show";
              }, 100);
              if (userEvents.length == index + 1) {
                $('#add-to-list').show();
              }
            }
          });
        }, index * 1500);
      });
    },
    /********************* Update Branch as complete *********** */
    updateBranchAsComplete: function (nextId, part) {
      const parentBranch = part.filter(e => e.childNodes.includes(nextId));
      if (parentBranch[0]) {
        const type = parentBranch[0].type;
        var wholeStudyJson = this.wholeStudyJson;
        const current_phase = this.current_phase;
        const partJson = wholeStudyJson[current_phase];
        const branchId = parentBranch[0].id;
        if (type == "branch") {
          const currentIndex = partJson.findIndex(x => x.id == branchId);
          wholeStudyJson[current_phase][currentIndex].status = "COM";
          const accessToken = this.get('session.data.authenticated.access_token');
          const studyUserId = this.studyUserId;
          const userStudyPart = wholeStudyJson;
          var data_to_api = {
            "user_study_part_json": JSON.stringify(userStudyPart),
            "current_module_page": ""
          };
          $.ajax({
            url: _environment.default.APP.API_HOST + '/studyusers/' + studyUserId,
            type: 'PATCH',
            data: data_to_api,
            headers: {
              "Authorization": "Bearer " + accessToken
            },
            success: function (res) {
              console.log("Status saved");
            },
            error: function (err, xH) {
              var str = err.responseText;
              str = str.split(':["').pop();
              str = str.split('"]')[0];
              console.log(str);
            }
          });
        }
      }
    },
    // Save module to show them in participant sessions
    saveModuleForLaterUse: function () {
      var that = this;
      const accessToken = this.get('session.data.authenticated.access_token');
      const participentId = this.currentUser;
      const study = this.currentStudy;
      const currentNode = this.current_node;
      $.ajax({
        url: _environment.default.APP.API_HOST + '/modules?identifier=' + this.currentModuleId,
        type: 'GET',
        headers: {
          "Authorization": "Bearer " + accessToken
        },
        success: function (res) {
          const modules = res.module;
          if (modules[0]) {
            const moduleId = modules[0].id;
            var data_to_api = {
              "study": study,
              "participant": participentId,
              "module": moduleId,
              "completed": true,
              "node_id": currentNode
            };
            $.ajax({
              url: _environment.default.APP.API_HOST + '/participantmodules',
              type: 'POST',
              data: data_to_api,
              headers: {
                "Authorization": "Bearer " + accessToken
              },
              success: function (res) {},
              error: function (err, xH) {
                var str = err.responseText;
                str = str.split(':["').pop();
                str = str.split('"]')[0];
                console.log(str);
              }
            });
          }
        },
        error: function (err, xH) {
          const errorMessage = err.responseText;
          console.log(errorMessage);
        }
      });
    },
    updateUserStatus: function () {
      const accessToken = this.get('session.data.authenticated.access_token');
      const user_id = this.session.get('data.userId');
      console.log("User onboarding status updated");
      var data_to_api = {
        "has_completed_onboarding": true
      };
      $.ajax({
        url: _environment.default.APP.API_HOST + '/users/' + user_id,
        type: 'PATCH',
        data: data_to_api,
        headers: {
          "Authorization": "Bearer " + accessToken
        },
        success: function (res) {},
        error: function (err, xH) {}
      });
    },
    saveNodeStartTime: function (studyPartJson, current_phase, currentNode, studyUserId, checkIfContinue) {
      const that = this;
      const accessToken = this.get('session.data.authenticated.access_token');
      const newStudyPartJson = this.setTimeStamp(studyPartJson, current_phase, currentNode, "end");
      var data_to_api = {
        "user_study_part_json": JSON.stringify(newStudyPartJson)
      };
      $.ajax({
        url: _environment.default.APP.API_HOST + '/studyusers/' + studyUserId,
        type: 'PATCH',
        data: data_to_api,
        headers: {
          "Authorization": "Bearer " + accessToken
        },
        success: function (res) {
          if (checkIfContinue) {
            that.send('refreshCurrentRoute');
          } else {
            if (that.isTester) {} else {
              that.set('is_study_completed', true);
              $('.bottom-nextstudy').show();
            }
          }
        },
        error: function (err, xH) {
          var str = err.responseText;
          str = str.split(':["').pop();
          str = str.split('"]')[0];
          alert(str);
        }
      });
    },
    setTimeStamp: function (studyPartJson, current_phase, currentNode, type) {
      if (studyPartJson[current_phase]) {
        var partJson = studyPartJson[current_phase];
        var currentNodeObjectArray = partJson.filter(e => e.id == currentNode);
        if (currentNodeObjectArray.length > 0) {
          const currentTimeStamp = this.getTimeStamp();
          const currentNodeObject = currentNodeObjectArray[0];
          if (type == "start") {
            currentNodeObject.start_timestamp = currentTimeStamp;
          } else {
            currentNodeObject.end_timestamp = currentTimeStamp;
          }
          return studyPartJson;
        }
      }
    },
    getTimeStamp: function () {
      var currentDate = new Date();
      var date = currentDate.getDate();
      if (date < 10) {
        date = "0" + date;
      }
      ;
      var month = currentDate.getMonth() + 1;
      if (month < 10) {
        month = "0" + month;
      }
      ;
      const year = currentDate.getFullYear();
      var hour = currentDate.getHours();
      if (hour < 10) {
        hour = "0" + hour;
      }
      ;
      var minutes = currentDate.getMinutes();
      if (minutes < 10) {
        minutes = "0" + minutes;
      }
      ;
      var seconds = currentDate.getSeconds();
      if (seconds < 10) {
        seconds = "0" + seconds;
      }
      ;
      return year + "-" + month + "-" + date + "T" + hour + ":" + minutes + ":" + seconds + ".224671+10:00";
    },
    /*  Alert to show success/ failure or warning   */
    newAlert: function (message, title, type) {
      if (type === "Success") {
        this.toast.success(message, title, {
          "closeButton": false,
          "debug": false,
          "newestOnTop": false,
          "progressBar": false,
          "positionClass": "toast-top-right",
          "preventDuplicates": false,
          "onclick": null,
          "showDuration": "300",
          "hideDuration": "1000",
          "timeOut": "5000",
          "extendedTimeOut": "1000",
          "showEasing": "swing",
          "hideEasing": "linear",
          "showMethod": "fadeIn",
          "hideMethod": "fadeOut"
        });
      } else if (type === "Failure") {
        this.toast.error(message, title, {
          "closeButton": false,
          "debug": false,
          "newestOnTop": false,
          "progressBar": false,
          "positionClass": "toast-top-right",
          "preventDuplicates": false,
          "onclick": null,
          "showDuration": "300",
          "hideDuration": "1000",
          "timeOut": "5000",
          "extendedTimeOut": "1000",
          "showEasing": "swing",
          "hideEasing": "linear",
          "showMethod": "fadeIn",
          "hideMethod": "fadeOut"
        });
      } else {
        this.toast.warning(message, title, {
          "closeButton": false,
          "debug": false,
          "newestOnTop": false,
          "progressBar": false,
          "positionClass": "toast-top-right",
          "preventDuplicates": false,
          "onclick": null,
          "showDuration": "300",
          "hideDuration": "1000",
          "timeOut": "5000",
          "extendedTimeOut": "1000",
          "showEasing": "swing",
          "hideEasing": "linear",
          "showMethod": "fadeIn",
          "hideMethod": "fadeOut"
        });
      }
    }
  });
  _exports.default = _default;
});