define("momentumhub/controllers/application", ["exports", "momentumhub/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    //variable declaration
    open_menu: true,
    router: Ember.inject.service(),
    session: Ember.inject.service(),
    isParticipant: false,
    isTeenager: false,
    totalUnread: 0,
    session: Ember.inject.service(),
    delayTimer: "",
    isLogout: false,
    delayTimer: "",
    createSurveyController: Ember.inject.controller('create-survey'),
    helpButtonModule: Ember.computed('isTeenager', function () {
      if (this.get('isTeenager')) {
        return _environment.default.APP.LINK_HELP_TEEN;
      } else {
        return _environment.default.APP.LINK_HELP_CHILD;
      }
    }),
    init() {
      this._super(...arguments);
      this.inactivityTime();
      var participantAge = localStorage.getItem('participantAge');
      if (participantAge <= 12) {
        this.set('isTeenager', false);
      } else {
        this.set('isTeenager', true);
      }
    },
    actions: {
      refreshRoute: function () {
        this.send('updateTheme');
      },
      // Open & close mobile menu
      open_close_menu: function () {
        var open_menu = this.get('open_menu');
        if (open_menu) {
          $('.left-nav').css({
            display: 'flex'
          }, 100);
          var mobileMin = window.matchMedia("(max-width: 364px)");
          if (mobileMin.matches) {
            $('.left-nav-bar').animate({
              width: '280px'
            }, 500);
          } else {
            $('.left-nav-bar').animate({
              width: '300px'
            }, 500);
          }
          this.set('open_menu', false);
        } else {
          this.set('open_menu', true);
          $('.left-nav').hide();
          $('.left-nav-bar').animate({
            width: '0px'
          }, 500);
        }
      },
      showMenu: function () {
        $('.participant-left-navbar').show();
      },
      iframelogout: function () {
        const that = this;
        this.set('isLogout', true);
        setTimeout(function () {
          that.set('isLogout', true);
        }, 5000);
      },
      returnToProfile: function () {
        var userId = this.session.get('data.userId');
        let user = this.store.peekRecord('user', userId);
        this.session.set('data.viewingName', "");
        this.session.set('data.viewingId', "");
        this.session.set('data.viewingAge', "");
        this.session.set('data.viewingDark_theme', user.get('dark_theme'));
        this.session.set('data.viewingShow_feelings_tracker', false);
        this.session.set('data.viewingEmail', "");
        this.session.set('data.viewingUserType', user.get('user_type'));
        this.send('refreshCurrentRoute');
        this.get('router').transitionTo('dashboard');
      }
    },
    // logout user if inactivity
    inactivityTime: function () {
      const that = this;
      var timerId = 0;
      loginDateTime();
      document.onmousemove = resetTimer;
      document.onkeydown = resetTimer;
      function resetTimer() {
        clearTimeout(timerId);
        timerId = setTimeout(function () {
          const currentDate = Date();
          const loginDateTime = localStorage.getItem("ldt");
          const fourhours = _environment.default.APP.LOGOUT_TIME;
          const difference = Date.parse(currentDate) - Date.parse(loginDateTime);
          //Check activity between 3:30 to 4 hour window, if yes than add 1 hour to the login time
          if (difference < fourhours && difference > 12600000) {
            var addOneHour = new Date(loginDateTime).addHours(1);
            localStorage.setItem('ldt', addOneHour);
            console.log('here');
          }
        }, 5000);
      }
      Date.prototype.addHours = function (h) {
        this.setTime(this.getTime() + h * 60 * 60 * 1000);
        return this;
      };
      function startTimmer() {
        that.delayTimer = setTimeout(function () {
          loginDateTime();
        }, 60000);
      }
      function loginDateTime() {
        const currentDate = Date();
        const loginDateTime = localStorage.getItem("ldt");
        const fourhours = _environment.default.APP.LOGOUT_TIME;
        if (loginDateTime) {
          const difference = Date.parse(currentDate) - Date.parse(loginDateTime);
          if (difference > fourhours) {
            const currentUrl = window.location.href;
            if (currentUrl.includes('create-survey')) {
              that.createSurveyController.unlockSurveyWithoutSaving();
              setTimeout(function () {
                console.log('out');
                that.get('session').invalidate();
                that.session.set('data.viewingName', "");
                that.session.set('data.viewingId', "");
                that.session.set('data.viewingAge', "");
                that.session.set('data.viewingDark_theme', "");
                that.session.set('data.user_email', "");
                localStorage.removeItem('ldt');
                window.location.href = _environment.default.APP.API_HOST_INITIAL + '/account/logout/';
              }, 10000);
            } else {
              console.log('out');
              that.get('session').invalidate();
              that.session.set('data.viewingName', "");
              that.session.set('data.viewingId', "");
              that.session.set('data.viewingAge', "");
              that.session.set('data.viewingDark_theme', "");
              that.session.set('data.user_email', "");
              localStorage.removeItem('ldt');
              window.location.href = _environment.default.APP.API_HOST_INITIAL + '/account/logout/';
            }
          } else {
            clearTimeout(that.delayTimer);
            startTimmer();
          }
        }
      }
    }
  }); // Wed Nov 10 2021 07:18:19 GMT+1100 (Australian Eastern Daylight Time)
  _exports.default = _default;
});