define("momentumhub/models/user-report", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberData.default.Model.extend({
    report_name: _emberData.default.attr(),
    report_date: _emberData.default.attr('date'),
    report_type: _emberData.default.attr(),
    user: _emberData.default.attr(),
    report_type_display: Ember.computed('report_type', function () {
      var mapping = {
        'AS': 'Assessment'
      };
      if (mapping[this.report_type]) {
        return mapping[this.report_type];
      }
      return 'Progress';
    })
  });
  _exports.default = _default;
});