define("momentumhub/controllers/user-profile/index", ["exports", "momentumhub/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    ajax: Ember.inject.service(),
    toast: Ember.inject.service(),
    session: Ember.inject.service(),
    editUserProfile: true,
    editTheme: true,
    isTeenager: false,
    mediaPreview: "",
    userId: "",
    dark_theme: false,
    mobile: "",
    // selected_theme: true,
    selected_theme: false,
    parentparticipants: [],
    ptType: "",
    user_type: "",
    applicationController: Ember.inject.controller('application'),
    addsupportingperson: true,
    supportingPerson: [],
    actions: {
      sameAsEmail: function () {
        const same_as_your_email = $('#same_as_your_email').is(':checked');
        if (same_as_your_email) {
          $('#support_email').hide();
        } else {
          $('#support_email').show();
        }
      },
      closeSupportingPersonForm: function () {
        this.set('addsupportingperson', false);
      },
      addSupportingPerson: function () {
        this.set('addsupportingperson', true);
      },
      editUser: function () {
        if (this.editUserProfile) {
          this.set('editUserProfile', false);
        } else {
          this.set('editUserProfile', true);
        }
      },
      getReport: function () {
        console.log("Clicked");
      },
      editWebsiteTheme: function () {
        if (this.editTheme) {
          this.set('editTheme', false);
          this.set('selected_theme', this.dark_theme);
          var that = this;
          setTimeout(function () {
            if (that.dark_theme) {
              $('#choose-theme-two').css({
                "border": 'solid 2px var(--primary-color-1)'
              });
              $('#choose-theme-one').css({
                "border": 'solid 2px #FAFAFA'
              });
              $('.application').addClass('high-contrast');
            } else {
              $('#choose-theme-one').css({
                "border": 'solid 2px var(--primary-color-1)'
              });
              $('#choose-theme-two').css({
                "border": 'solid 2px #FAFAFA'
              });
              $('.application').removeClass('high-contrast');
            }
          }, 100);
        } else {
          this.set('editTheme', true);
        }
      },
      selectParticipant: function () {
        var e = document.getElementById("loginAs");
        var loginAs = e.options[e.selectedIndex].value;
        if (loginAs == "myAccount") {
          let user = this.store.peekRecord('user', this.userId);
          this.session.set('data.viewingName', "");
          this.session.set('data.viewingId', "");
          this.session.set('data.viewingAge', "");
          this.session.set('data.viewingDark_theme', user.get('dark_theme'));
          this.session.set('data.viewingShow_feelings_tracker', false);
          this.session.set('data.viewingEmail', "");
          this.session.set('data.viewingUserType', "PR");
        } else {
          let user = this.store.peekRecord('user', loginAs);
          const fullName = user.get('first_name') + " " + user.get('last_name');
          this.session.set('data.viewingName', fullName);
          this.session.set('data.viewingId', loginAs);
          this.session.set('data.viewingAge', user.get('age'));
          this.session.set('data.viewingDark_theme', user.get('dark_theme'));
          this.session.set('data.viewingShow_guardian_progress_messages', user.get('show_guardian_progress_messages'));
          this.session.set('data.viewingShow_feelings_tracker', user.get('show_guardian_feelings_tracker'));
          this.session.set('data.viewingEmail', user.get('email'));
          this.session.set('data.viewingUserType', "PT");
        }
        this.applicationController.send('refreshRoute');
      },
      /* Preview the media */
      previewMedia: function () {
        var uploadmediainput = document.getElementById('uploadmediainput');
        var tmppath = URL.createObjectURL(uploadmediainput.files[0]);
        this.set('mediaPreview', tmppath);
      },
      updateUser: function () {
        var that = this;
        var token = that.get('session.data.authenticated.access_token');
        var first_name = this.get('first_name');
        const user_type = this.session.get('data.user_type');
        var last_name = this.get('last_name');
        var dob = this.get('dob');
        var validDob = true;
        if (user_type == "PT") {
          if (dob) {
            validDob = true;
          } else {
            validDob = false;
          }
        } else {
          if (!dob) {
            dob = "";
          }
        }
        var mobile = this.get('mobile');
        var user_id = this.userId;
        var mediaFile = document.getElementById('uploadmediainput');
        var password = this.get('password');
        if (first_name) {
          if (validDob) {
            var formdata = new FormData();
            formdata.append("first_name", first_name);
            formdata.append("age", dob);
            formdata.append("mobile", mobile);
            if (mediaFile.files[0]) {
              $('.hover_bkgr_fricccc_spinner').show();
              formdata.append("avatar", mediaFile.files[0], mediaFile.files[0].name);
            }
            $.ajax({
              url: _environment.default.APP.API_HOST + '/users/' + user_id,
              headers: {
                Authorization: 'Bearer ' + token
              },
              data: formdata,
              processData: false,
              contentType: false,
              type: 'PATCH',
              success: function (res) {
                if (password) {
                  that.send("resetPassword");
                } else {
                  that.set('editUserProfile', true);
                  that.send('refreshCurrentRoute');
                  $('.hover_bkgr_fricccc_spinner').hide();
                }
              },
              error: function (err, xH) {
                alert(err.responseText);
              }
            });
          } else {
            this.showError('dob');
          }
        } else {
          this.showError('first_name');
        }
      },
      togglePermissions: function () {
        const that = this;
        const guardian_progress_messages = $('#guardian_progress_messages').is(':checked');
        const guardian_assessment_reports = $('#guardian_assessment_reports').is(':checked');
        const guardian_progress_reports = $('#guardian_progress_reports').is(':checked');
        const token = this.get('session.data.authenticated.access_token');
        const user_id = this.userId;
        let data_to_api;
        if (this.ptType == "child") {
          data_to_api = {
            "show_guardian_progress_charts": guardian_progress_reports
          };
        }
        if (this.ptType == "teen") {
          data_to_api = {
            "show_guardian_progress_charts": guardian_progress_reports
          };
        }
        if (this.ptType == "youth") {
          data_to_api = {
            "show_guardian_progress_messages": guardian_progress_messages,
            "show_guardian_assessment_reports": guardian_assessment_reports,
            "show_guardian_progress_charts": guardian_progress_reports
          };
        }
        $.ajax({
          url: _environment.default.APP.API_HOST + '/users/' + user_id,
          type: 'PATCH',
          data: data_to_api,
          headers: {
            Authorization: 'Bearer ' + token
          },
          success: function (res) {},
          error: function (err, xH) {
            var str = err.responseText;
            str = str.split(':["').pop();
            str = str.split('"]')[0];
            console.log(str);
          }
        });
      },
      toggleFeelingsTracker: function () {
        const that = this;
        const guardian_feelings_tracker = $('#guardian_feelings_tracker').is(':checked');
        const token = this.get('session.data.authenticated.access_token');
        const user_id = this.userId;
        let data_to_api;
        //Youth will come here , child is just a monkey patch
        if (this.ptType == "youth") {
          data_to_api = {
            "show_guardian_feelings_tracker": guardian_feelings_tracker
          };
        }
        $.ajax({
          url: _environment.default.APP.API_HOST + '/users/' + user_id,
          type: 'PATCH',
          data: data_to_api,
          headers: {
            Authorization: 'Bearer ' + token
          },
          success: function (res) {
            console.log("Successfully written");
          },
          error: function (err, xH) {
            var str = err.responseText;
            str = str.split(':["').pop();
            str = str.split('"]')[0];
            console.log(str);
          }
        });
      },
      //******************** update password  **********************/
      resetPassword: function () {
        var password = this.get('password');
        var confirm_password = this.get('confirm_password');
        var token = this.get('session.data.authenticated.access_token');
        var that = this;
        if (password) {
          if (this.validatePassword(password)) {
            if (password === confirm_password) {
              var reset_email_data_to_api = {
                "new_password1": password,
                "new_password2": confirm_password
              };
              $.ajax({
                url: _environment.default.APP.API_HOST_INITIAL + '/rest-auth/password/change/',
                type: 'POST',
                data: reset_email_data_to_api,
                headers: {
                  Authorization: 'Bearer ' + token
                },
                success: function (res) {
                  that.set('password', "");
                  that.set('confirm_password', "");
                  that.set('editUserProfile', true);
                  that.send('refreshCurrentRoute');
                  $('.hover_bkgr_fricccc_spinner').hide();
                },
                error: function (err, xH) {
                  var str = err.responseText;
                  str = str.split(':["').pop();
                  str = str.split('"]')[0];
                  that.newAlert(str, "Password", "Failure");
                }
              });
            } else {
              that.newAlert("Password and confirm password doesn't match.", "Password", "Warning");
            }
          }
        }
      },
      updateTheme: function () {
        var that = this;
        var token = that.get('session.data.authenticated.access_token');
        var selected_theme = this.selected_theme;
        var user_id = this.userId;
        var formdata = new FormData();
        formdata.append("dark_theme", selected_theme);
        $.ajax({
          url: _environment.default.APP.API_HOST + '/users/' + user_id,
          headers: {
            Authorization: 'Bearer ' + token
          },
          data: formdata,
          processData: false,
          contentType: false,
          type: 'PATCH',
          success: function (res) {
            that.set('editTheme', true);
            that.send('refreshCurrentRoute');
          },
          error: function (err, xH) {
            alert(err.responseText);
          }
        });
      },
      changeTheme: function (option) {
        var selectedTheme = window.localStorage.getItem('selected_theme');
        if (option == 1) {
          this.set('selected_theme', true);
          window.localStorage.setItem('selected_theme', true);
          window.localStorage.setItem('dark_theme', true);
          window.localStorage.setItem('high-contrast', false);
          $('.participant-application-view').addClass('dark');
          $('.participant-application-view').removeClass('contrast');
          if (this.isTeenager) {
            this.set('selected_theme', true);
            $('.participant-application-view').addClass('teenager');
          }
        } else {
          this.set('selected_theme', false);
          $('.participant-application-view').addClass('contrast');
          $('.participant-application-view').removeClass('dark');
          window.localStorage.setItem('selected_theme', true);
          window.localStorage.setItem('dark_theme', false);
          window.localStorage.setItem('high-contrast', true);
        }
        this.send('updateTheme');
      },
      addSupportPerson: function () {
        const that = this;
        const accessToken = this.get('session.data.authenticated.access_token');
        const support_first_name = this.get('support_first_name');
        const support_last_name = this.get('support_last_name');
        var support_email = this.get('support_email');
        var participant_email = this.session.get('data.user_email');
        var validEmail = true;
        const same_as_your_email = $('#same_as_your_email').is(':checked');
        if (same_as_your_email) {
          support_email = that.getGuardianEmail(participant_email);
        } else {
          if (support_email) {
            validEmail = true;
          } else {
            validEmail = false;
          }
        }
        if (support_first_name) {
          if (support_last_name) {
            if (validEmail) {
              $.ajax({
                url: _environment.default.APP.API_HOST + '/users?email=' + support_email,
                type: 'GET',
                headers: {
                  "Authorization": "Bearer " + accessToken
                },
                success: function (res) {
                  var users = res.user;
                  if (users[0]) {
                    const user_id = users[0].id;
                    that.send('assignGuardian', user_id);
                  } else {
                    var data_to_api = {
                      "email": support_email,
                      "first_name": support_first_name,
                      "last_name": support_last_name,
                      "age": "",
                      "user_type": "PR",
                      "set_a_random_pass": true,
                      "is_active": true,
                      "gender": ""
                    };
                    $.ajax({
                      url: _environment.default.APP.API_HOST + '/users',
                      type: 'POST',
                      data: data_to_api,
                      success: function (res) {
                        that.send('assignGuardian', res.user.id);
                        that.set('support_first_name', "");
                        that.set('support_last_name', "");
                        that.set('support_email', "");
                      },
                      error: function (err, xH) {
                        $('.creating_user_text').hide();
                        var str = err.responseText;
                        str = str.split(':["').pop();
                        str = str.split('"]')[0];
                        that.newAlert(str, "Add User", "Failure");
                      }
                    });
                  }
                },
                error: function (err, xH) {
                  var str = err.responseText;
                  str = str.split(':["').pop();
                  str = str.split('"]')[0];
                  that.newAlert(str, "Add Site", "Failure");
                }
              });
            } else {
              this.showError('support_email');
            }
          } else {
            this.showError('support_last_name');
          }
        } else {
          this.showError('support_first_name');
        }
      },
      assignGuardian: function (guardian_id) {
        const that = this;
        const accessToken = this.get('session.data.authenticated.access_token');
        const participant_id = this.session.get('data.userId');
        var data_to_api = {
          "participant": participant_id,
          "parent": guardian_id
        };
        $.ajax({
          url: _environment.default.APP.API_HOST + '/parentparticipants',
          type: 'POST',
          data: data_to_api,
          headers: {
            "Authorization": "Bearer " + accessToken
          },
          success: function (res) {
            that.getClinicianGuardian();
            that.newAlert("Guardian added successfully.", "Guardian Added", "Success");
          },
          error: function (err, xH) {
            var str = err.responseText;
            str = str.split(':["').pop();
            str = str.split('"]')[0];
            that.newAlert(str, "Add User", "Failure");
          }
        });
      },
      deleteGuardianOrClinician: function (id) {
        var that = this;
        var accessToken = this.get('session.data.authenticated.access_token');
        const r = confirm("Are you sure you want to remove this supporting person?");
        if (r) {
          $.ajax({
            url: _environment.default.APP.API_HOST + '/parentparticipants/' + id,
            type: 'DELETE',
            headers: {
              "Authorization": "Bearer " + accessToken
            },
            success: function (res) {
              that.newAlert("Supporting person removed successfully.", "Supporting person Removed", "Success");
              that.getClinicianGuardian();
            },
            error: function (err, xH) {
              var str = err.responseText;
              str = str.split(':["').pop();
              str = str.split('"]')[0];
              that.newAlert(str, "Add User", "Failure");
            }
          });
        }
      }
    },
    getClinicianGuardian: function () {
      var that = this;
      const participant_id = this.session.get('data.userId');
      that.set('supportingPerson', []);
      that.set('addsupportingperson', true);
      var accessToken = this.get('session.data.authenticated.access_token');

      // Get Guardians
      $.ajax({
        url: _environment.default.APP.API_HOST + '/parentparticipants?id=&parent=&participant=' + participant_id,
        type: 'GET',
        headers: {
          "Authorization": "Bearer " + accessToken
        },
        success: function (res) {
          var parentparticipants = res.parentparticipant;
          if (parentparticipants[0]) {
            parentparticipants.forEach(function (pt) {
              var parentparticipant = pt;
              var parent = parentparticipant.parent;
              that.store.query('user', {
                all_users: true,
                id: parent
              }).then(function (parentGuardian) {
                const pg = parentGuardian.get('firstObject');
                const obj = {
                  "id": parentparticipant.id,
                  "user": pg
                };
                that.supportingPerson.pushObject(obj);
              });
            });
            that.set('addsupportingperson', false);
          }
        },
        error: function (err, xH) {
          var str = err.responseText;
          str = str.split(':["').pop();
          str = str.split('"]')[0];
          that.newAlert(str, "Password", "Failure");
        }
      });
    },
    showError: function (id) {
      $("#" + id).addClass("error");
      setTimeout(function () {
        $("#" + id).removeClass("error");
      }, 2000);
    },
    getGuardianEmail: function (email) {
      var email_start = email.substr(0, email.indexOf('@'));
      var email_end = email.split('@')[1];
      return email_start + "+guardian@" + email_end;
    },
    /*  Alert to show success/ failure or warning   */
    newAlert: function (message, title, type) {
      if (type === "Success") {
        this.toast.success(message, title, {
          "closeButton": false,
          "debug": false,
          "newestOnTop": false,
          "progressBar": false,
          "positionClass": "toast-top-right",
          "preventDuplicates": false,
          "onclick": null,
          "showDuration": "300",
          "hideDuration": "1000",
          "timeOut": "5000",
          "extendedTimeOut": "1000",
          "showEasing": "swing",
          "hideEasing": "linear",
          "showMethod": "fadeIn",
          "hideMethod": "fadeOut"
        });
      } else if (type === "Failure") {
        this.toast.error(message, title, {
          "closeButton": false,
          "debug": false,
          "newestOnTop": false,
          "progressBar": false,
          "positionClass": "toast-top-right",
          "preventDuplicates": false,
          "onclick": null,
          "showDuration": "300",
          "hideDuration": "1000",
          "timeOut": "5000",
          "extendedTimeOut": "1000",
          "showEasing": "swing",
          "hideEasing": "linear",
          "showMethod": "fadeIn",
          "hideMethod": "fadeOut"
        });
      } else {
        this.toast.warning(message, title, {
          "closeButton": false,
          "debug": false,
          "newestOnTop": false,
          "progressBar": false,
          "positionClass": "toast-top-right",
          "preventDuplicates": false,
          "onclick": null,
          "showDuration": "300",
          "hideDuration": "1000",
          "timeOut": "5000",
          "extendedTimeOut": "1000",
          "showEasing": "swing",
          "hideEasing": "linear",
          "showMethod": "fadeIn",
          "hideMethod": "fadeOut"
        });
      }
    },
    validatePassword: function (password) {
      const re = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()+=-\?;,./{}|\":<>\[\]\\\' ~_]).{9,30}/;
      const pr = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{9,30}$/;
      if (re.test(password)) {
        return true;
      }
      this.newAlert("Please use 9 characters, use at least one number, one uppercase letter, one lowercase letter and one special symbol.", "Password", "Warning");
      return false;
    }
  });
  _exports.default = _default;
});