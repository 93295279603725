define("momentumhub/controllers/registration", ["exports", "jquery", "momentumhub/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    session: Ember.inject.service(),
    toast: Ember.inject.service(),
    ajax: Ember.inject.service(),
    usertype: "",
    studyId: "",
    groupId: "",
    gender: [{
      "name": "Male",
      "value": "M"
    }, {
      "name": "Female",
      "value": "F"
    }, {
      "name": "Other",
      "value": "O"
    }],
    //Action
    actions: {
      lowerCaseEmail: function () {
        var email = this.get('user_email');
        const lowercase = s => {
          if (typeof s !== 'string') return '';
          return s.toLowerCase();
        };
        if (email) {
          this.set('user_email', lowercase(email));
        }
      },
      //1. First checks the User
      checkUser: function () {
        var self = this;
        var email = self.get('user_email');
        var data = {
          "email": email
        };
        this.get('ajax').request(_environment.default.APP.API_HOST_INITIAL + '/user/check-user-type', {
          method: 'POST',
          data: data
        }).then(response => {
          var user = response.user.type;
          if (user) {
            if (user === "PT") {
              (0, _jquery.default)('#next_button').hide();
              (0, _jquery.default)('#login_part').show();
            } else {
              self.newAlert("Only participants can access this link.", "Access", "Warning");
            }
          } else {
            (0, _jquery.default)('#check_user').hide();
            (0, _jquery.default)("#registrationBox").show();
          }
        });
      },
      // 2.A If user exist then Login
      participantLogin: function () {
        var self = this;
        this.session.set('data.viewingName', "");
        this.session.set('data.viewingId', "");
        this.session.set('data.viewingAge', "");
        this.session.set('data.viewingDark_theme', "");
        this.session.set('data.user_email', "");
        this.session.set('data.loginHedgdoc', "");
        const indentification = this.get('user_email');
        const password = this.get('login_password');
        if (indentification && password) {
          self.session.set('data.user_email', indentification);
          self.get('session').authenticate('authenticator:oauth2', indentification, password).then(result => {
            const token = self.get('session.data.authenticated.access_token');
            console.log('auth token', token);
            if (token) {
              // Check participant user onboarding status
              _jquery.default.ajax({
                url: _environment.default.APP.API_HOST + '/users?email=' + indentification,
                type: 'GET',
                headers: {
                  "Authorization": "Bearer " + token
                },
                success: function (res) {
                  var users = res.user;
                  if (users[0]) {
                    const has_completed_onboarding = users[0].has_completed_onboarding;
                    const age = users[0].age;
                    const userId = users[0].id;
                    // Completed on boarding
                    if (has_completed_onboarding) {
                      self.session.set('data.loginHedgdoc', "true");
                      self.createGroupInstance(userId, age, has_completed_onboarding);
                    }

                    // Haven't completed onboarding study
                    else {
                      self.createGroupInstance(userId, age, has_completed_onboarding);
                    }
                  }
                },
                error: function (err, xH) {
                  var str = err.responseText;
                  str = str.split(':["').pop();
                  str = str.split('"]')[0];
                }
              });
            }
          }, reason => {
            self.set('errorMessage', reason.error || reason);
            (0, _jquery.default)('.incorrectuspassalert').show();
            (0, _jquery.default)('.hover_bkgr_fricccc_spinner1').hide();
            setTimeout(function () {
              (0, _jquery.default)('.incorrectuspassalert').hide();
            }, 4000);
          });
        } else {
          (0, _jquery.default)('.nouspassalert').show();
          setTimeout(function () {
            (0, _jquery.default)('.nouspassalert').hide();
          }, 4000);
        }
      },
      /*********************************** 1. Register a user *******************/

      registerUser: function () {
        const that = this;
        const first_name = this.get('first_name');
        const last_name = this.get('last_name');
        const user_email = this.get('user_email');
        const dob = document.getElementById('dob').value;
        const password = this.get('password');
        const confirm_password = this.get('confirm_password');
        var e = document.getElementById("genderselect");
        var usergender = e.options[e.selectedIndex].value;
        var gendergroup = e.options[e.selectedIndex].group;
        if (first_name) {
          // if (last_name) {
          if (user_email) {
            if (this.validateEmail(user_email)) {
              if (dob) {
                if (password) {
                  if (this.validatePassword(password)) {
                    if (confirm_password) {
                      if (usergender != "1") {
                        if (password == confirm_password) {
                          const usertype = this.usertype;
                          // var type = "";
                          // if (usertype == "child") {
                          //     type = "PT"
                          // }
                          // else if (usertype == "youth") {
                          //     type = "PT"
                          // }
                          // else if (usertype == "teen") {
                          //     type = "PT"
                          // }
                          // else if (usertype == "parent") {
                          //     type = "PR"
                          // }
                          // else if (usertype == "researcher") {
                          //     type = "RS"
                          // }

                          var data_to_api = {
                            "email": user_email,
                            "first_name": first_name,
                            // "last_name": last_name,
                            "age": dob,
                            "user_type": "PT",
                            "password": password,
                            "is_active": true,
                            "gender": gendergroup,
                            "gender_detail": usergender
                          };
                          _jquery.default.ajax({
                            url: _environment.default.APP.API_HOST + '/users',
                            type: 'POST',
                            data: data_to_api,
                            success: function (res) {
                              const userId = res.user.id;
                              (0, _jquery.default)('.hover_bkgr_fricccc_spinner1').show();
                              that.authenticate(user_email, password, userId, dob);
                              that.set('first_name', "");
                              that.set('last_name', "");
                              that.set('user_email', "");
                              that.set('password', "");
                              that.set('confirm_password', "");
                              (0, _jquery.default)('select option:contains(Select gender)').prop('selected', true);
                              document.getElementById('dob').value = "";
                            },
                            error: function (err, xH) {
                              var str = err.responseText;
                              str = str.split(':["').pop();
                              str = str.split('"]')[0];
                              that.newAlert(str, "Registration", "Failure");
                            }
                          });
                        } else {
                          this.newAlert("Password and Confirm Password doesn't match.", "Registration", "Warning");
                        }
                      } else {
                        this.showError("genderselect");
                      }
                    } else {
                      this.showError("confirm_password");
                    }
                  }
                } else {
                  this.showError("password");
                }
              } else {
                this.showError("dob");
              }
            }
          } else {
            this.showError("user_email");
          }
          // } else { this.showError("last_name") }
        } else {
          this.showError('first_name');
        }
      }
    },
    /******************************* 2. Authenticate User ************************* */
    authenticate: function (indentification, password, userId, age) {
      var self = this;
      this.session.set('data.user_email', "");
      self.session.set('data.user_email', indentification);
      self.get('session').authenticate('authenticator:oauth2', indentification, password).then(result => {
        const token = self.get('session.data.authenticated.access_token');
        if (token) {
          self.session.set('data.userId', userId);
          self.session.set('data.user_type', 'PT');
          self.createGroupInstance(userId, age, false);
        }
      }, reason => {
        self.set('errorMessage', reason.error || reason);
      });
    },
    /***************************************** 2.B Create Group Instance ***************** */
    createGroupInstance: async function (userId, age, has_completed_onboarding) {
      const that = this;
      var user = await this.store.findRecord('user', userId);
      var studies = await this.store.query('study', {
        'identifier': this.get('studyId')
      });
      var siteId = null;
      var availableSites = user.get('get_all_available_sites');
      if (studies && studies.get('firstObject')) {
        var study = studies.get('firstObject');
        siteId = study.get('site.id');
        if (siteId) {
          siteId = parseInt(siteId);
        }
      }
      if (siteId && availableSites && availableSites.length > 0 && availableSites.indexOf(siteId) === -1) {
        that.newAlert('E02 - User cannot enroll in this study as it is from a different site', 'Enrollment error', 'Failure');
        return;
      }
      const accessToken = this.get('session.data.authenticated.access_token');
      that.store.query('participantgroup', {
        identifier: that.groupId
      }).then(function (participantgroupModel) {
        const participantgroup = participantgroupModel.get('firstObject');
        const participantgroupId = participantgroup.get('id');

        // Check is group instance already exist
        _jquery.default.ajax({
          url: _environment.default.APP.API_HOST + '/participantgroupinstances?id=&user=' + userId + '&participant_group=' + participantgroupId,
          type: 'GET',
          headers: {
            "Authorization": "Bearer " + accessToken
          },
          success: function (res) {
            var participantgroupinstances = res.participantgroupinstance;
            if (participantgroupinstances[0]) {
              that.getStudy(userId, age, has_completed_onboarding);
            }

            // Create new Participant instance
            else {
              const data_to_api = {
                "user": userId,
                "participant_group": participantgroupId
              };
              _jquery.default.ajax({
                url: _environment.default.APP.API_HOST + '/participantgroupinstances',
                type: 'POST',
                headers: {
                  "Authorization": "Bearer " + accessToken
                },
                data: data_to_api,
                success: function (res) {
                  that.updateStudyParticipant(participantgroupId, userId, age, has_completed_onboarding);
                },
                error: function (err, xH) {
                  var errorMessage = err.responseText;
                  alert(errorMessage);
                  (0, _jquery.default)('.hover_bkgr_fricccc_spinner1').hide();
                }
              });
            }
          },
          error: function (err, xH) {
            var str = err.responseText;
            str = str.split(':["').pop();
            str = str.split('"]')[0];
          }
        });
      });
    },
    //******************************** 2.C Update study participant ******************* */

    updateStudyParticipant: function (groupId, userId, age, has_completed_onboarding) {
      const that = this;
      const accessToken = this.get('session.data.authenticated.access_token');
      that.store.query('study', {
        identifier: that.studyId
      }).then(function (studyModel) {
        const study = studyModel.get('firstObject');
        const studyId = study.get('id');

        //***************** Get request to check if group is already allocated to site ************ */
        _jquery.default.ajax({
          url: _environment.default.APP.API_HOST + '/create-study-user/',
          type: 'POST',
          headers: {
            "Authorization": "Bearer " + accessToken
          },
          data: {
            study: studyId,
            group: groupId
          },
          success: function (res) {
            that.getStudy(userId, age, has_completed_onboarding);
          },
          error: function (err, xH) {
            var str = err.responseText;
            str = str.split(':["').pop();
            str = str.split('"]')[0];
          }
        });
      });
    },
    // Get global variables saved as per site
    getGlobalVariables: function (userId) {
      const that = this;
      const accessToken = that.get('session.data.authenticated.access_token');
      console.log('accessToken', accessToken);
      var finalGlobalVariables = {};
      that.store.query('study', {
        identifier: that.studyId
      }).then(function (studyModel) {
        const study = studyModel.get('firstObject');
        const site = study.get('site');
        const studyId = study.get('id');
        console.log('studyId', studyId);
        var user_email = that.session.get('data.user_email');
        // const siteId = site.get('id');
        // console.log('siteId', siteId)
        that.store.query('userrewardvar', {
          'user__email': user_email
        }).then(function (userRewardsModel) {
          // Ember.keys(userrewardvar .toJSON()).forEach(function(prop) { console.log(prop + " " + model.get(prop)); } )

          var userRewardsVar = userRewardsModel.get('firstObject').get('reward_vars');
          var user_reward_id = userRewardsModel.get('firstObject').get('id');
          console.log('user_reward_id', user_reward_id);
          _jquery.default.ajax({
            url: _environment.default.APP.API_HOST + '/users?email=' + user_email,
            type: 'GET',
            headers: {
              "Authorization": "Bearer " + accessToken,
              "Content-Type": "application/json"
            },
            success: function (res) {
              console.log('users?email res', res);
              //for update the rewards we need to pass current user object
              var user_obj = res.user[0];
              console.log('user_obj', user_obj);
              // We need to get the studies
              _jquery.default.ajax({
                url: _environment.default.APP.API_HOST + '/studies/' + studyId,
                type: 'GET',
                headers: {
                  "Authorization": "Bearer " + accessToken,
                  "Content-Type": "application/json"
                },
                success: function (res) {
                  console.log('res getGlobalVariables register', res);
                  let defaultVarObj = [];
                  const variables_to_track = study.variables_to_track;
                  variables_to_track.forEach(function (variables) {
                    const split_variable = variables.split(':');
                    defaultVarObj[split_variable[0]] = split_variable[1];
                  });
                  console.log('variables_to_track', variables_to_track);
                  console.log('defaultVarObj', defaultVarObj);
                  if (defaultVarObj['TR_defaul_check'] == '1') {
                    var newRewardVariables = [];
                    var finalGlobalVariables = {};
                    console.log('here');
                    console.log('userRewardsVar', userRewardsVar);
                    if (userRewardsVar && Object.keys(userRewardsVar).length > 0) {
                      //convert both objects in an array of objects to compare
                      let defaultVarObjArr = Object.keys(defaultVarObj).map(function (key) {
                        //check if the value is not a number to sanitize garbage from default variables
                        var val = isNaN(defaultVarObj[key]) ? defaultVarObj[key] : parseInt(defaultVarObj[key]);
                        return {
                          key: key,
                          value: val
                        };
                      });
                      //clean the null values to avoid futher issues
                      Object.keys(userRewardsVar).forEach(key => {
                        if (userRewardsVar[key] === null) {
                          delete userRewardsVar[key];
                        }
                      });
                      let userRewardsModelArr = Object.keys(userRewardsVar).map(function (key) {
                        return {
                          key: key,
                          value: userRewardsVar[key]
                        };
                      });

                      //compare two array of objects and delete the ones with the same key
                      newRewardVariables = userRewardsModelArr.filter(function (obj) {
                        return defaultVarObjArr.findIndex(obj2 => obj2.key === obj.key) === -1;
                      });

                      //combine both array of objects
                      newRewardVariables = newRewardVariables.concat(defaultVarObjArr);

                      //merge all objects in a single object
                      newRewardVariables.forEach(function (obj) {
                        finalGlobalVariables[obj.key] = obj.value;
                      });
                      console.log('defaultVarObjArr', defaultVarObjArr);
                      console.log('userRewardsModelArr', userRewardsModelArr);
                      console.log('newRewardVariables', newRewardVariables);
                      console.log('finalGlobalVariables', finalGlobalVariables);
                    } else {
                      finalGlobalVariables = defaultVarObj;
                    }
                    console.log('finalGlobalVariables', finalGlobalVariables);
                    console.log('newRewardVariables', newRewardVariables);
                    //save final variables
                    //TODO
                    // userRewardsModel.set('reward_vars', finalGlobalVariables);

                    //test get
                    _jquery.default.ajax({
                      url: _environment.default.APP.API_HOST + '/userrewardvars?email=' + user_email,
                      headers: {
                        Authorization: 'Bearer ' + accessToken
                      },
                      type: 'DELETE',
                      success: function (res) {
                        console.log('success userrewardvars delete user', res);
                      },
                      error: function (err, xH) {
                        console.log('err userrewardvars GET 1', err.responseText);
                      }
                    });

                    // post
                    var data_to_api = {
                      "user": 7,
                      "reward_vars": JSON.stringify(finalGlobalVariables)
                    };
                    console.log('data_to_api', data_to_api);
                    _jquery.default.ajax({
                      url: _environment.default.APP.API_HOST + '/userrewardvars',
                      headers: {
                        Authorization: 'Bearer ' + accessToken
                      },
                      type: 'PUT',
                      data: data_to_api,
                      success: function (res) {
                        console.log('success userrewardvars patch user', res);
                      },
                      error: function (err, xH) {
                        console.log('err userrewardvars PUT 1', err.responseText);
                      }
                    });
                    var data_to_api = {
                      "user": 7,
                      "reward_vars": JSON.stringify(finalGlobalVariables)
                    };
                    console.log('data_to_api', data_to_api);
                    _jquery.default.ajax({
                      url: _environment.default.APP.API_HOST + '/userrewardvars',
                      headers: {
                        Authorization: 'Bearer ' + accessToken
                      },
                      type: 'PATCH',
                      data: data_to_api,
                      success: function (res) {
                        console.log('success userrewardvars patch user', res);
                      },
                      error: function (err, xH) {
                        console.log('err userrewardvars PATCH 1', err.responseText);
                      }
                    });
                    var data_to_api = {
                      "reward_vars": JSON.stringify(finalGlobalVariables)
                    };
                    console.log('data_to_api', data_to_api);
                    _jquery.default.ajax({
                      url: _environment.default.APP.API_HOST + '/userrewardvars?email=' + user_obj.email,
                      headers: {
                        Authorization: 'Bearer ' + accessToken
                      },
                      type: 'PATCH',
                      data: data_to_api,
                      success: function (res) {
                        console.log('success userrewardvars patch user', res);
                      },
                      error: function (err, xH) {
                        console.log('err userrewardvars PATCH EMAIL 1', err.responseText);
                      }
                    });
                  }
                },
                error: function (err, xH) {
                  var errorMessage = err.responseText;
                  alert(errorMessage);
                }
              });
            },
            error: function (err, xH) {
              console.log('user err', err);
            }
          });
        });
      });
    },
    /***************************** 3. Get study and arm to create a userstudy ********************* */
    getStudy: function (userId, age, has_completed_onboarding) {
      const that = this;
      const accessToken = this.get('session.data.authenticated.access_token');

      // If onboarding done
      if (has_completed_onboarding) {
        that.store.query('study', {
          identifier: that.studyId
        }).then(function (studyModel) {
          const study = studyModel.get('firstObject');
          const studyId = study.get('id');
          _jquery.default.ajax({
            url: _environment.default.APP.API_HOST + '/studyusers?id=&study=' + studyId + '&user=' + userId,
            type: 'GET',
            headers: {
              "Authorization": "Bearer " + accessToken
            },
            success: function (res) {
              var studyusers = res.studyuser;
              console.log('studyusers', studyusers);
              that.getGlobalVariables(userId);
              // Route to study view
              if (studyusers[0]) {
                const studyuserId = studyusers[0].id;
                that.transitionToRoute('studyview', studyuserId, "na");
                (0, _jquery.default)('.hover_bkgr_fricccc_spinner1').hide();
                // setTimeout(function () {
                //     that.authenticateApi(accessToken);
                // }, 1000);
              } else if (that.session.isAuthenticated) {
                that.handleCreatingStudyUser(study, userId);
              }
            },
            error: function (err, xH) {
              console.log(err);
            }
          });
        });
      }
      //else not done
      else {
        var phaseArray = {};
        //1. Get Study
        that.store.query('study', {
          on_boarding_study: true,
          age: age
        }).then(function (studyModel) {
          const study = studyModel.get('firstObject');
          const studyId = study.get('id');
          const number_of_arms = study.get('number_of_arms');

          // Check is study user already exist

          _jquery.default.ajax({
            url: _environment.default.APP.API_HOST + '/studyusers?id=&study=' + studyId + '&user=' + userId,
            type: 'GET',
            headers: {
              "Authorization": "Bearer " + accessToken
            },
            success: function (res) {
              var studyusers = res.studyuser;

              // Route to study view
              if (studyusers[0]) {
                const studyuserId = studyusers[0].id;
                that.transitionToRoute('studyview', studyuserId, "na");
                (0, _jquery.default)('.hover_bkgr_fricccc_spinner1').hide();
                // setTimeout(function () {
                //     that.authenticateApi(accessToken);
                // }, 1000);
              }

              // Create study user
              else {
                that.handleCreatingStudyUser(study, userId);
              }
            },
            error: function (err, xH) {
              var str = err.responseText;
              str = str.split(':["').pop();
              str = str.split('"]')[0];
            }
          });
        });
      }
    },
    handleCreatingStudyUser(study, userId) {
      let phaseArray = {};
      let studyId = study.get('id');
      const number_of_arms = study.get('number_of_arms');
      const that = this;
      //2. Get initial study part
      const initial_study_part = study.get('initial_study_part');
      if (initial_study_part) {
        that.store.findRecord('part', initial_study_part).then(function (initial_study) {
          const initial_study_json = initial_study.get('part_result_json');
          phaseArray[initial_study_part] = initial_study_json;
          if (number_of_arms == 0) {
            that.createStudyUser(userId, studyId, phaseArray);
          }
        });
      }
      if (number_of_arms != 0) {
        //3. Get Arm
        that.store.query('arm', {
          study: study.get('id')
        }).then(function (arm) {
          //4.  Get Phases
          const firstArm = arm.get('firstObject');
          that.store.query('phase', {
            arm: firstArm.get('id')
          }).then(function (phases) {
            if (phases.length > 0) {
              //5. Get Parts
              const phaseLength = phases.length;
              phases.forEach(function (phase, index) {
                const lastCount = index + 1;
                that.store.query('part', {
                  phase: phase.get('id')
                }).then(function (part) {
                  const firstPart = part.get('firstObject');
                  const partId = firstPart.get('id');
                  const part_result_json = firstPart.get('part_result_json');
                  if (part_result_json) {
                    phaseArray[partId] = part_result_json;
                  }
                  if (phaseLength == lastCount) {
                    //6. Create study user
                    console.log(phaseArray);
                    that.createStudyUser(userId, studyId, phaseArray);
                  }
                });
              });
            } else {
              that.createStudyUser(userId, studyId, phaseArray);
            }
          });
        });
      }
    },
    /**************************** 4. Create study user ************************** */
    createStudyUser: function (userId, studyId, user_study_part_json) {
      const that = this;
      var accessToken = this.get('session.data.authenticated.access_token');
      var data_to_api = {
        "user": userId,
        "study": studyId,
        "user_study_part_json": JSON.stringify(user_study_part_json),
        "is_onboarding": true
      };
      _jquery.default.ajax({
        url: _environment.default.APP.API_HOST + '/create-study-user/',
        type: 'POST',
        headers: {
          "Authorization": "Bearer " + accessToken
        },
        data: data_to_api,
        success: function (res) {
          const studyUserId = res.studyUserCreateView.studyuser.id;
          setTimeout(function () {
            that.transitionToRoute('studyview', studyUserId, "na");
            (0, _jquery.default)('.hover_bkgr_fricccc_spinner1').hide();
          }, 3000);
        },
        error: function (err, xH) {
          var errorMessage = err.responseText;
          alert(errorMessage);
          (0, _jquery.default)('.hover_bkgr_fricccc_spinner1').hide();
        }
      });
    },
    saveusernameInLocalstorage: function (user_email) {
      const loginDate = Date();
      localStorage.setItem("signedInUser", user_email);
      localStorage.setItem("ldt", loginDate);
    },
    showError: function (id) {
      (0, _jquery.default)("#" + id).addClass("error");
      setTimeout(function () {
        (0, _jquery.default)("#" + id).removeClass("error");
      }, 5000);
    },
    validateEmail: function (email) {
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
        return true;
      }
      this.newAlert("You have entered an invalid email address.", "Email", "Warning");
      return false;
    },
    /*  Alert to show success/ failure or warning   */
    newAlert: function (message, title, type) {
      if (type === "Success") {
        this.toast.success(message, title, {
          "closeButton": false,
          "debug": false,
          "newestOnTop": false,
          "progressBar": false,
          "positionClass": "toast-top-right",
          "preventDuplicates": false,
          "onclick": null,
          "showDuration": "300",
          "hideDuration": "1000",
          "timeOut": "5000",
          "extendedTimeOut": "1000",
          "showEasing": "swing",
          "hideEasing": "linear",
          "showMethod": "fadeIn",
          "hideMethod": "fadeOut"
        });
      } else if (type === "Failure") {
        this.toast.error(message, title, {
          "closeButton": false,
          "debug": false,
          "newestOnTop": false,
          "progressBar": false,
          "positionClass": "toast-top-right",
          "preventDuplicates": false,
          "onclick": null,
          "showDuration": "300",
          "hideDuration": "1000",
          "timeOut": "5000",
          "extendedTimeOut": "1000",
          "showEasing": "swing",
          "hideEasing": "linear",
          "showMethod": "fadeIn",
          "hideMethod": "fadeOut"
        });
      } else {
        this.toast.warning(message, title, {
          "closeButton": false,
          "debug": false,
          "newestOnTop": false,
          "progressBar": false,
          "positionClass": "toast-top-right",
          "preventDuplicates": false,
          "onclick": null,
          "showDuration": "300",
          "hideDuration": "1000",
          "timeOut": "5000",
          "extendedTimeOut": "1000",
          "showEasing": "swing",
          "hideEasing": "linear",
          "showMethod": "fadeIn",
          "hideMethod": "fadeOut"
        });
      }
    },
    validatePassword: function (password) {
      const re = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()+=-\?;,./{}|\":<>\[\]\\\' ~_]).{9,30}/;
      const pr = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{9,30}$/;
      if (re.test(password)) {
        return true;
      }
      this.newAlert("Password must be at least: 9 characters long, One upper case letter, One lower case letter, One symbol ( ! @ # $ % ^ & * ( ) + = - ; , . / { } ~ _), One numeric digit.", "Password", "Warning");
      return false;
    },
    authenticateApi(access_token) {
      window.location.href = _environment.default.APP.API_HOST_INITIAL + '/api/login?access_token=' + access_token;
    }
  });
  _exports.default = _default;
});