define("momentumhub/routes/studyview", ["exports", "momentumhub/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    session: Ember.inject.service(),
    ajax: Ember.inject.service(),
    activate: function () {
      this._super();
      window.scrollTo(0, 0);
    },
    model(params) {
      const that = this;
      var currentUser = this.get('session.data.userId');
      if (params.UserId != "na") {
        this.controllerFor("studyview").set("currentUser", params.UserId);
      } else {
        this.controllerFor("studyview").set("currentUser", currentUser);
      }
      return Ember.RSVP.hash({
        studyUserId: params.studyUserId
      });
    },
    actions: {
      refreshCurrentRoute() {
        this.refresh();
      }
    },
    setupController(controller, model) {
      this._super(controller, model);
      this.checkIfTester();
      const that = this;
      const accessToken = this.get('session.data.authenticated.access_token');
      const studyuserId = model.studyUserId;
      $.ajax({
        url: _environment.default.APP.API_HOST + '/studyusers/' + studyuserId,
        type: 'GET',
        headers: {
          "Authorization": "Bearer " + accessToken
        },
        success: function (res) {
          var studyUsermodel = res.studyuser;
          if (studyUsermodel) {
            var studyUser = studyUsermodel;
            that.setFullScreen(studyUser.is_onboarding);
            var studyPartJson = studyUser.user_study_part_json;
            var current_phase = studyUser.current_phase;
            var current_node = studyUser.current_node;
            var studyUserId = studyUser.id;
            var current_module_page = studyUser.current_module_page;
            var studyComplete = studyUser.completed;
            var study_start_time = studyUser.study_start_time;
            var part_timestamp_json = studyUser.part_timestamp_json;
            if (studyComplete && !studyUser.required_module_id) {
              that.controllerFor("studyview").set("is_study_completed", true);
            } else {
              that.controllerFor("studyview").set("is_study_completed", false);
            }
            if (studyUsermodel.next_node_execution_time) {
              var currentTime = new Date();
              var nextExecution = new Date(studyUsermodel.next_node_execution_time);
              if (nextExecution > currentTime) {
                that.transitionTo('modules');
              }
            }
            that.controllerFor("studyview").set('studyUser', studyUser);
            that.controllerFor("studyview").set('studyUserId', studyUserId);
            that.controllerFor("studyview").set('current_module_page', current_module_page);
            that.controllerFor("studyview").set("part_timestamp_json", part_timestamp_json);
            var cn = null;
            var cp = null;
            var cs = studyUser.study;
            if (studyPartJson) {
              if (current_phase) {
                console.log("current_phase:" + current_phase);
                var partJson = studyPartJson[current_phase];
                if (current_node) {
                  that.controllerFor("studyview").set('current_node', current_node);
                  cn = current_node;
                  that.saveNodeStartTime(studyPartJson, current_phase, current_node, studyUserId, study_start_time, part_timestamp_json);
                  if (current_node) {
                    const currentNodeObjectArray = partJson.filter(function (e) {
                      return e.id == current_node;
                    });
                    if (currentNodeObjectArray[0]) {
                      const currentNodeObject = currentNodeObjectArray[0];
                      if (currentNodeObject.nodeId) {
                        var currentReadableNodeId = currentNodeObject.study_name + "_" + currentNodeObject.nodeId;
                        that.controllerFor("studyview").set('currentReadableNodeId', currentReadableNodeId);
                      }
                    }
                  }
                } else {
                  if (partJson) {
                    if (partJson[0]) {
                      var crnt_node = partJson[0].id;
                      that.saveNodeStartTime(studyPartJson, current_phase, crnt_node, studyUserId, study_start_time, part_timestamp_json);
                      that.controllerFor("studyview").set('current_node', crnt_node);
                      if (partJson[0].nodeId) {
                        var currentReadableNodeId = partJson[0].study_name + "_" + partJson[0].nodeId;
                        that.controllerFor("studyview").set('currentReadableNodeId', currentReadableNodeId);
                      }
                      cn = crnt_node;
                    }
                  }
                }
                that.controllerFor("studyview").set('current_phase', current_phase);
                that.getCurrentPhaseidentifier(current_phase);
                that.controllerFor("studyview").set('studyPartJson', partJson);
                cp = current_phase;
              } else {
                var firstPhase = Object.keys(studyPartJson)[0];
                var partJson = studyPartJson[firstPhase];
                if (current_node) {
                  that.saveNodeStartTime(studyPartJson, firstPhase, current_node, studyUserId, study_start_time, part_timestamp_json);
                  that.controllerFor("studyview").set('current_node', current_node);
                  cn = current_node;
                  if (current_node) {
                    const currentNodeObjectArray = partJson.filter(function (e) {
                      return e.id == current_node;
                    });
                    if (currentNodeObjectArray[0]) {
                      const currentNodeObject = currentNodeObjectArray[0];
                      if (currentNodeObject.nodeId) {
                        var currentReadableNodeId = currentNodeObject.study_name + "_" + currentNodeObject.nodeId;
                        that.controllerFor("studyview").set('currentReadableNodeId', currentReadableNodeId);
                      }
                    }
                  }
                } else {
                  if (partJson) {
                    if (partJson[0]) {
                      var crnt_node = partJson[0].id;
                      that.saveNodeStartTime(studyPartJson, firstPhase, crnt_node, studyUserId, study_start_time, part_timestamp_json);
                      if (partJson[0].nodeId) {
                        var currentReadableNodeId = partJson[0].study_name + "_" + partJson[0].nodeId;
                        that.controllerFor("studyview").set('currentReadableNodeId', currentReadableNodeId);
                      }
                      that.controllerFor("studyview").set('current_node', crnt_node);
                      cn = crnt_node;
                    }
                  }
                }
                that.controllerFor("studyview").set('studyPartJson', partJson);
                that.controllerFor("studyview").set('current_phase', firstPhase);
                that.getCurrentPhaseidentifier(firstPhase);
                cp = firstPhase;
              }

              // Initialise the function
              that.controllerFor("studyview").set("wholeStudyJson", studyPartJson);
              that.controllerFor("studyview").set("currentStudy", cs);
              that.checkOnboardingStudy(cs);
              var required_module_id = studyUser.required_module_id;
              //Required Modules
              if (required_module_id) {
                //const hedgeDocUrl = ENV.APP.HEDGE_DOC + "/p/" + required_module_id;
                // that.controllerFor("studyview").set("hedgeDocUrl", hedgeDocUrl);
                that.controllerFor("studyview").getModules(required_module_id);
                that.controllerFor("studyview").set("showModule", true);
              }
              // Or continue with nodes
              else {
                that.controllerFor("studyview").getModuleOrSurvey();
              }
              that.controllerFor("studyview").set("required_module_id", required_module_id);
              that.controllerFor("studyview").getAlerts();
            }
            console.log("start_timestamp");
          }
        },
        error: function (err, xH) {
          return res.err;
        }
      });
    },
    checkOnboardingStudy: function (studyId) {
      const that = this;
      const accessToken = this.get('session.data.authenticated.access_token');
      $.ajax({
        url: _environment.default.APP.API_HOST + '/studies/' + studyId,
        type: 'GET',
        headers: {
          "Authorization": "Bearer " + accessToken
        },
        success: function (res) {
          var study = res.study;
          var on_boarding_study = study.on_boarding_study;
          that.controllerFor("studyview").set("isOnboardingStudy", on_boarding_study);
        },
        error: function (err, xH) {
          return res.err;
        }
      });
    },
    saveNodeStartTime: function (studyPartJson, current_phase, currentNode, studyUserId, study_start_time, part_timestamp_json) {
      const accessToken = this.get('session.data.authenticated.access_token');
      if (studyPartJson[current_phase]) {
        var partJson = studyPartJson[current_phase];
        var currentNodeObjectArray = partJson.filter(e => e.id == currentNode);
        if (currentNodeObjectArray.length > 0) {
          const currentNodeObject = currentNodeObjectArray[0];
          if (!currentNodeObject.start_timestamp) {
            const newStudyPartJson = this.setTimeStamp(studyPartJson, current_phase, currentNode, "start");
            if (!study_start_time) {
              this.postStudySTart(studyPartJson, studyUserId);
              const currentTimeStamp = this.getTimeStamp();
              var data_to_api = {
                "user_study_part_json": JSON.stringify(newStudyPartJson),
                "study_start_time": currentTimeStamp
              };
            } else {
              var data_to_api = {
                "user_study_part_json": JSON.stringify(newStudyPartJson)
              };
            }
            $.ajax({
              url: _environment.default.APP.API_HOST + '/studyusers/' + studyUserId,
              type: 'PATCH',
              data: data_to_api,
              headers: {
                "Authorization": "Bearer " + accessToken
              },
              success: function (res) {},
              error: function (err, xH) {
                var str = err.responseText;
                str = str.split(':["').pop();
                str = str.split('"]')[0];
                alert(str);
              }
            });
          }
        }
      }
      this.savePhaseStartTime(current_phase, studyUserId, part_timestamp_json);
    },
    savePhaseStartTime: function (current_phase, studyUserId, part_timestamp_json) {
      const accessToken = this.get('session.data.authenticated.access_token');
      const currentTimeStamp = this.getTimeStamp();
      const that = this;
      var phaseArray = {};
      $.ajax({
        url: _environment.default.APP.API_HOST + '/parts/' + current_phase,
        type: 'GET',
        headers: {
          "Authorization": "Bearer " + accessToken
        },
        success: function (res) {
          const part = res.part;
          const identifier = part.identifier;
          if (!part_timestamp_json) {
            const uid_start_timestamp = identifier + "_start_timestamp";
            var obj = {};
            obj[uid_start_timestamp] = currentTimeStamp;
            phaseArray[identifier] = obj;
            patchData();
          } else {
            phaseArray = part_timestamp_json;
            if (!phaseArray[identifier]) {
              const uid_start_timestamp = identifier + "_start_timestamp";
              var obj = {};
              obj[uid_start_timestamp] = currentTimeStamp;
              phaseArray[identifier] = obj;
              console.log(phaseArray);
              patchData();
            } else {
              const uid_start_timestamp = identifier + "_start_timestamp";
              if (phaseArray[identifier]) {
                if (!phaseArray[identifier][uid_start_timestamp]) {
                  phaseArray[identifier][uid_start_timestamp] = currentTimeStamp;
                  patchData();
                }
              }
            }
          }
        },
        error: function (err, xH) {}
      });
      function patchData() {
        var data_to_api = {
          "part_timestamp_json": JSON.stringify(phaseArray)
        };
        $.ajax({
          url: _environment.default.APP.API_HOST + '/studyusers/' + studyUserId,
          type: 'PATCH',
          data: data_to_api,
          headers: {
            "Authorization": "Bearer " + accessToken
          },
          success: function (res) {
            const studyUser = res.studyuser;
            const part_timestamp_json = studyUser.part_timestamp_json;
            that.controllerFor("studyview").set("part_timestamp_json", part_timestamp_json);
            console.log("Part start time saved");
          },
          error: function (err, xH) {
            var str = err.responseText;
            str = str.split(':["').pop();
            str = str.split('"]')[0];
            alert(str);
          }
        });
      }
    },
    setTimeStamp: function (studyPartJson, current_phase, currentNode, type) {
      if (studyPartJson[current_phase]) {
        var partJson = studyPartJson[current_phase];
        var currentNodeObjectArray = partJson.filter(e => e.id == currentNode);
        if (currentNodeObjectArray.length > 0) {
          const currentTimeStamp = this.getTimeStamp();
          const currentNodeObject = currentNodeObjectArray[0];
          if (type == "start") {
            currentNodeObject.start_timestamp = currentTimeStamp;
            currentNodeObject.end_timestamp = currentTimeStamp;
          } else {
            currentNodeObject.end_timestamp = currentTimeStamp;
          }
          return studyPartJson;
        }
      }
    },
    getTimeStamp: function () {
      var currentDate = new Date();
      var date = currentDate.getDate();
      if (date < 10) {
        date = "0" + date;
      }
      ;
      var month = currentDate.getMonth() + 1;
      if (month < 10) {
        month = "0" + month;
      }
      ;
      const year = currentDate.getFullYear();
      var hour = currentDate.getHours();
      if (hour < 10) {
        hour = "0" + hour;
      }
      ;
      var minutes = currentDate.getMinutes();
      if (minutes < 10) {
        minutes = "0" + minutes;
      }
      ;
      var seconds = currentDate.getSeconds();
      if (seconds < 10) {
        seconds = "0" + seconds;
      }
      ;
      return year + "-" + month + "-" + date + "T" + hour + ":" + minutes + ":" + seconds + ".224671+10:00";
    },
    getCurrentPhaseidentifier: function (current_phase) {
      const accessToken = this.get('session.data.authenticated.access_token');
      const that = this;
      $.ajax({
        url: _environment.default.APP.API_HOST + '/parts/' + current_phase,
        type: 'GET',
        headers: {
          "Authorization": "Bearer " + accessToken
        },
        success: function (res) {
          const part = res.part;
          const identifier = part.identifier;
          that.controllerFor("studyview").set('currentPhaseidentifier', identifier);
        },
        error: function (err, xH) {
          var str = err.responseText;
          str = str.split(':["').pop();
          str = str.split('"]')[0];
          console.log(str);
        }
      });
    },
    //Post studySTart
    postStudySTart: function (studyPartJson, studyUserId) {
      const that = this;
      var phaseArray = {};
      const currentTimeStamp = this.getTimeStamp();
      const accessToken = this.get('session.data.authenticated.access_token');
      const dictionarylength = Object.keys(studyPartJson).length;
      var count = 0;
      // Create  initial part json holder for rule
      for (const [key, value] of Object.entries(studyPartJson)) {
        // GET Part identifier
        this.store.findRecord('part', key).then(function (part) {
          const identifier = part.get('identifier');
          console.log("t");
          const uid_start_timestamp = identifier + "_start_timestamp";
          const uid_end_timestamp = identifier + "_end_timestamp";
          var obj = {};
          const first_key = Object.keys(studyPartJson)[0];
          if (first_key == key) {
            obj[uid_start_timestamp] = currentTimeStamp;
          } else {
            obj[uid_start_timestamp] = null;
          }
          obj[uid_end_timestamp] = null;
          phaseArray[identifier] = obj;
          count += 1;
          if (count == dictionarylength) {
            postToStudyUser(phaseArray);
          }
        });
      }
      function postToStudyUser(phaseArray) {
        var data_to_api = {
          "part_timestamp_json": JSON.stringify(phaseArray)
        };
        $.ajax({
          url: _environment.default.APP.API_HOST + '/studyusers/' + studyUserId,
          type: 'PATCH',
          data: data_to_api,
          headers: {
            "Authorization": "Bearer " + accessToken
          },
          success: function (res) {
            const studyUser = res.studyuser;
            const part_timestamp_json = studyUser.part_timestamp_json;
            that.controllerFor("studyview").set("part_timestamp_json", part_timestamp_json);
          },
          error: function (err, xH) {
            var str = err.responseText;
            str = str.split(':["').pop();
            str = str.split('"]')[0];
          }
        });
      }
    },
    /********************** Check if user is a tester ****************/
    checkIfTester: function () {
      this.controllerFor("studyview").set("isTester", false);
      const currentUserType = this.get('session.data.user_type');
      if (currentUserType == "PA" || currentUserType == "SA" || currentUserType == "RS") {
        this.controllerFor("studyview").set("isTester", true);
      }
    },
    setFullScreen: function (isOnboarding) {
      if (isOnboarding) {
        $('#study_view').addClass('study_view_full_screen');
      } else {
        $('#study_view').removeClass('study_view_full_screen');
      }
    }
  });
  _exports.default = _default;
});