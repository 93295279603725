define("momentumhub/routes/climbing-plans/create", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class MountMomentumCreateRoute extends Ember.Route {
    model() {
      return this.store.createRecord('mountain', {
        'title': '',
        'steps': []
      });
    }
  }
  _exports.default = MountMomentumCreateRoute;
});