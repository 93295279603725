define("momentumhub/components/create-surveys", ["exports", "jquery", "momentumhub/config/environment", "blockly/msg/en"], function (_exports, _jquery, _environment, _en) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    ajax: Ember.inject.service(),
    routerService: Ember.inject.service('router'),
    session: Ember.inject.service(),
    router: Ember.inject.service(),
    tags: [],
    isDeleteTag: true,
    eachTagDelete: false,
    alreadyExistedSurvey: "",
    allowPrint: false,
    actions: {
      setPrintOption: function (event) {
        console.log(event.target.checked);
        this.set('allowPrint', event.target.checked);
      },
      /********* Close assets component *********/
      close: function () {
        (0, _jquery.default)('.hover_bkgr_fricc-study-survey').hide();
        this.set('tags', []);
        this.set('isDeleteTag', true);
        this.set('eachTagDelete', false);
      },
      checkExistingSurvey: function () {
        const that = this;
        var e = document.getElementById("specialsurveyType");
        var surveyType = e.options[e.selectedIndex].value;
        if (surveyType != "None") {
          this.store.query('survey', {
            special_category: surveyType
          }).then(function (surveys) {
            if (surveys.length > 0) {
              const survey = surveys.get('firstObject');
              const surveyId = survey.get('identifier');
              that.set('alreadyExistedSurvey', surveyId);
              (0, _jquery.default)('.warning').show();
            } else {
              (0, _jquery.default)('.vrtotrack').show();
              (0, _jquery.default)('.warning').hide();
            }
          });
        } else {
          (0, _jquery.default)('.warning').hide();
          (0, _jquery.default)('.vrtotrack').hide();
          that.set('alreadyExistedSurvey', "");
        }
      },
      viewExistingCategorySurvey: function () {
        var alreadyExistedSurvey = this.alreadyExistedSurvey;
        let routerService = this.get('routerService');
        let url = routerService.urlFor('create-survey', alreadyExistedSurvey);
        window.open(url);
      },
      /********* Create Tags *********/
      createTag: function () {
        var that = this;
        var tag = this.get('tag');
        if (tag) {
          if (this.tags.includes(tag)) {
            (0, _jquery.default)('.note-tag').animate({
              opacity: '1'
            }, 300);
            setTimeout(function () {
              (0, _jquery.default)('.note-tag').animate({
                opacity: '0'
              }, 300);
            }, 3000);
          } else {
            if (tag.includes(",")) {
              var tagsArray = tag.split(',');
              tagsArray.forEach(element => {
                if (that.tags.includes(element)) {
                  (0, _jquery.default)('.note-tag').animate({
                    opacity: '1'
                  }, 300);
                  setTimeout(function () {
                    (0, _jquery.default)('.note-tag').animate({
                      opacity: '0'
                    }, 300);
                  }, 3000);
                } else {
                  that.tags.pushObject(element);
                  that.set('tag', '');
                }
              });
            } else {
              this.tags.pushObject(tag);
              this.set('tag', '');
            }
          }
        }
      },
      /********* Delete Tags *********/
      deleteTag: function (index) {
        const r = confirm("Are you sure you want to delete this tag");
        if (r) {
          this.tags.removeAt(index);
          if (this.tags.length === 0) {
            this.set('isDeleteTag', true);
            this.set('eachTagDelete', false);
          }
        }
      },
      /********* Activate Delete Tag buttons *********/
      activateDeleteTag: function () {
        if (this.isDeleteTag) {
          this.set('isDeleteTag', false);
          this.set('eachTagDelete', true);
        } else {
          this.set('isDeleteTag', true);
          this.set('eachTagDelete', false);
        }
      },
      /* Create Survet and send user to the create-survey page */
      createNewSurvey: function () {
        var self = this;
        var accessToken = this.get('session.data.authenticated.access_token');
        var surveyName = this.get('surveyName');
        var description = this.get('description');
        var tags = this.get('tags');
        var dateCreated = new Date();
        var allowPrint = this.get('allowPrint');
        var status = "IP";
        var surveyType = "";
        var valid = true;
        // var e = document.getElementById("specialsurveyType");
        // var specialsurveyvariable = "";
        // if (surveyType == "SA" || surveyType == "SM") {
        //     surveyType = e.options[e.selectedIndex].value;
        //     specialsurveyvariable = this.get('specialsurveyvariable');
        //     if (specialsurveyvariable) { valid = true }
        //     else { valid = false }
        // }
        if (surveyName) {
          if (valid) {
            // if (tags.length != 0) {
            _jquery.default.ajax({
              url: _environment.default.APP.API_HOST + '/surveys',
              type: 'POST',
              headers: {
                "Authorization": "Bearer " + accessToken,
                "Content-Type": "application/json"
              },
              data: JSON.stringify({
                "survey": {
                  "name": surveyName,
                  "status": status,
                  "tags": tags,
                  "date_created": dateCreated,
                  "description": description,
                  "allow_print": allowPrint
                }
              }),
              success: function (res) {
                self.set('surveyName', "");
                self.set("description", "");
                self.set("tags", []);
                self.set('alreadyExistedSurvey', "");
                (0, _jquery.default)('select option:contains(None)').prop('selected', true);
                var surveyIdentifier = res.survey.identifier;
                var localStorageName = "survey-" + surveyIdentifier;
                var defaultJSON = {
                  "title": surveyName,
                  pages: [{
                    name: 'page1'
                  }],
                  "clearInvisibleValues": "none"
                };
                window.localStorage.setItem(localStorageName, JSON.stringify(defaultJSON));
                (0, _jquery.default)('.hover_bkgr_fricc-study-survey').hide();
                self.get('router').transitionTo('create-survey', surveyIdentifier);
              },
              error: function (err, xH) {
                var errorMessage = err.responseText;
                if (errorMessage.includes("Survey with this name already exists.")) {
                  (0, _jquery.default)('.modulenameexist').show();
                  setTimeout(function () {
                    (0, _jquery.default)('.modulenameexist').hide();
                  }, 3000);
                }
              }
            });
            // }
            // else {
            //     $("#moduletaginput1").addClass("error");
            //     setTimeout(function () {
            //         $("#moduletaginput1").removeClass("error");
            //     }, 2000);
            // }
          } else {
            (0, _jquery.default)("#specialsurveyvariable").addClass("error");
            setTimeout(function () {
              (0, _jquery.default)("#specialsurveyvariable").removeClass("error");
            }, 2000);
          }
        } else {
          (0, _jquery.default)("#modulenameinput1").addClass("error");
          setTimeout(function () {
            (0, _jquery.default)("#modulenameinput1").removeClass("error");
          }, 2000);
        }
      }
    }
  });
  _exports.default = _default;
});