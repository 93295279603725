define("momentumhub/controllers/mood-boosters", ["exports", "@fullcalendar/timegrid", "@fullcalendar/interaction", "momentumhub/utils", "momentumhub/config/environment"], function (_exports, _timegrid, _interaction, _utils, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    session: Ember.inject.service(),
    plugins: [_timegrid.default, _interaction.default],
    isShowingEventModal: false,
    eventInFocus: null,
    updateEvents: false,
    showExampleEvents: false,
    examples: null,
    events: Ember.computed('model.events', 'updateEvents', function () {
      var model = this.get('model');
      var eventsList = [];
      if (model) {
        model.events.map(model => {
          eventsList.push(model.generateFullCalendarFormat());
        });
        return eventsList;
      }
    }),
    initializeNewEventData: function () {
      this.set('eventInFocus', {
        id: null,
        title: null,
        day: 1,
        start: null,
        end: null,
        background_colour: '#2BB989'
      });
    },
    init: function () {
      this._super(...arguments);
      this.initializeNewEventData();
      var participantAge = localStorage.getItem('participantAge');
      if (participantAge <= 12) {
        this.set('isTeenager', false);
      } else {
        this.set('isTeenager', true);
      }
      setTimeout(function () {
        var headers = document.getElementsByClassName('fc-day-header');
        for (const item of headers) {
          var text = item.textContent;
          text = text.split(' ')[0];
          item.textContent = text;
        }
      }, 2000);
      this.get('store').findAll('mood-booster-example', {}).then(response => {
        this.set('examples', response);
      });
      //Prevent the modal to show as default
    },

    createDraggable: function () {
      setTimeout(function () {
        if (document.getElementById('draggable-container')) {
          new _interaction.Draggable(document.getElementById('draggable-container'), {
            itemSelector: '.draggable'
          });
        }
      }, 2000);
    },
    generateEventPayload(event) {
      var dateFormat = {
        'hour': '2-digit',
        'minute': '2-digit',
        'hourCycle': 'h23'
      };
      var eventInFocus = {
        'id': event.id,
        'title': event.title,
        'day': event.start.getDay().toString(),
        'start': event.start.toLocaleTimeString('en-US', dateFormat),
        'end': event.end.toLocaleTimeString('en-US', dateFormat),
        'background_colour': event.backgroundColor
      };
      return eventInFocus;
    },
    updateEventDetails: function (event, eventInFocus) {
      var keys = ['title', 'day', 'start', 'end', 'background_colour'];
      keys.map(key => {
        var value = eventInFocus[key];
        if (key == 'day' && value == '0') value = '7';
        event[key] = value;
      });
      return event;
    },
    classNames: ['css-framework-fancy-class'],
    targetAttachment: "none",
    participantId: 0,
    header: {
      isHeader: true
    },
    footer: {
      isFooter: true
    },
    body: {
      isBody: true
    },
    form1: {
      isForm1: true
    },
    form2: {
      isForm2: true
    },
    toggleEventModal: function () {
      this.set('isShowingEventModal', !this.get('isShowingEventModal'));
    },
    actions: {
      toggleEventModal: function () {
        this.toggleEventModal();
      },
      createOrUpdateEvent: function () {
        var eventInFocus = this.get('eventInFocus');
        var keys = ['title', 'day', 'start', 'end'];
        var hasErrors = false;
        keys.map(function (key) {
          if (!eventInFocus[key]) {
            $('#id_' + key).addClass('error');
            hasErrors = true;
            setTimeout(function () {
              $('#id_' + key).removeClass('error');
            }, 2000);
          }
        });
        if (hasErrors) {
          return;
        }
        var payload = eventInFocus;
        if (eventInFocus.id) {
          this.store.findRecord('mood-booster-event', eventInFocus.id).then(event => {
            event = this.updateEventDetails(event, eventInFocus);
            event.save().then(response => {
              /* var existingIndex = this.get('events').findIndex(e => e.id == response.id);
              var events = this.get('model.events');
              events.objectAt(existingIndex).reload(); */
              this.set('updateEvents', !this.get('updateEvents'));
            });
          });
        } else {
          var event = this.store.createRecord('mood-booster-event', payload);
          event.save().then(response => {
            var event = response.generateFullCalendarFormat();
            this.get('events').pushObject(event);
          });
        }
        this.toggleEventModal();
        this.initializeNewEventData();
      },
      setNewEventDay: function (value) {
        this.set('eventInFocus.day', value);
      },
      clicked: function (_ref, calendar) {
        let {
          event,
          jsEvent,
          view
        } = _ref;
        var eventInFocus = this.generateEventPayload(event);
        this.set('eventInFocus', eventInFocus);
        this.toggleEventModal();
      },
      eventReceive: function (info) {
        var eventInFocus = this.generateEventPayload(info.event);
        var event = this.store.createRecord('mood-booster-event', eventInFocus);
        event.save().then(response => {
          var event = response.generateFullCalendarFormat();
          this.get('events').pushObject(event);
        });
      },
      eventDrop: function (info) {
        var eventInFocus = this.generateEventPayload(info.event);
        var event = this.store.peekRecord('mood-booster-event', eventInFocus.id);
        if (eventInFocus.id) {
          event = this.updateEventDetails(event, eventInFocus);
          event.save().then(response => {
            this.set('updateEvents', !this.get('updateEvents'));
          });
        }
      },
      deleteEvent: function () {
        var eventInFocus = this.get('eventInFocus');
        if (eventInFocus.id) {
          var event = this.store.peekRecord('mood-booster-event', eventInFocus.id);
          if (event) {
            event.destroyRecord().then(() => {
              this.toggleEventModal();
              this.set('updateEvents', !this.get('updateEvents'));
            });
          }
          ;
        }
      },
      setBackgroundColour: function (colour) {
        this.set('eventInFocus.background_colour', colour);
      },
      toggleExampleEvents: function () {
        this.set('showExampleEvents', !this.get('showExampleEvents'));
        this.createDraggable();
      },
      showMoodBoosterTipsModule: function () {
        var moduleId = _environment.default.APP.MOOD_BOOSTERS_TIPS_CHILD;
        if (this.get('isTeenager')) {
          moduleId = _environment.default.APP.MOOD_BOOSTERS_TIPS_TEEN;
        }
        this.transitionToRoute('modulePreview', moduleId);
      }
    }
  });
  _exports.default = _default;
});