define("momentumhub/models/user", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberData.default.Model.extend({
    email: _emberData.default.attr(),
    first_name: _emberData.default.attr(),
    last_name: _emberData.default.attr(),
    date_joined: _emberData.default.attr(),
    avatar: _emberData.default.attr(),
    age: _emberData.default.attr(),
    gender: _emberData.default.attr(),
    gender_detail: _emberData.default.attr(),
    validated_on: _emberData.default.attr(),
    is_staff: _emberData.default.attr(),
    is_active: _emberData.default.attr(),
    is_superuser: _emberData.default.attr(),
    user_type: _emberData.default.attr(),
    dark_theme: _emberData.default.attr('boolean'),
    mobile: _emberData.default.attr(),
    update_2fa: _emberData.default.attr('boolean'),
    disable_2fa: _emberData.default.attr('boolean'),
    // get_sites_associated_with:DS.hasMany('mommentumhubsite'),
    selected_theme: _emberData.default.attr(),
    // selected_theme: DS.attr(),
    show_guardian_progress_messages: _emberData.default.attr('boolean'),
    show_guardian_assessment_reports: _emberData.default.attr('boolean'),
    show_guardian_progress_charts: _emberData.default.attr('boolean'),
    show_guardian_in_session_answers: _emberData.default.attr('boolean'),
    show_guardian_diary_entries: _emberData.default.attr('boolean'),
    show_guardian_feelings_tracker: _emberData.default.attr('boolean'),
    get_all_available_sites: _emberData.default.attr(),
    has_completed_onboarding: _emberData.default.attr('boolean'),
    report_password: _emberData.default.attr(),
    // Study user related fields
    enrollment_time: _emberData.default.attr(),
    last_login: _emberData.default.attr(),
    progress: _emberData.default.attr(),
    arm: _emberData.default.attr(),
    phase: _emberData.default.attr(),
    number_of_surveys: _emberData.default.attr(),
    reward_vars: _emberData.default.attr(),
    study_progress: _emberData.default.attr('number'),
    site: _emberData.default.attr('string'),
    can_send_messages: _emberData.default.attr('boolean')
  }); //userrewardvars
  _exports.default = _default;
});