define("momentumhub/components/create-newstudy", ["exports", "jquery", "momentumhub/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    router: Ember.inject.service(),
    isStudy: false,
    creationType: "",
    currentUser: [],
    sites: [],
    // `available` is used to show clinical tool, `required` shows number of open stars in session sidebar, `completed` is for closed stars
    // would probably make sense to move this default array to the .env file
    trackingVariables: ['TR_ClueCalmers_available:1', 'TR_ClueCalmers_required:5', 'TR_ClueCalmers_completed:0', 'TR_ClimbingPlans_available:1', 'TR_ClimbingPlans_required:5', 'TR_ClimbingPlans_completed:0', 'TR_PowerThinking_available:1', 'TR_PowerThinking_required:5', 'TR_PowerThinking_completed:0', 'TR_MoodBoost_available:1', 'TR_MoodBoost_required:5', 'TR_MoodBoost_completed:0', 'TR_ChangeFocus_available:1', 'TR_ChangeFocus_required:5', 'TR_ChangeFocus_completed:0', 'TR_FeelingsTracker_available:1', 'TR_defaul_check:1', 'TR_ChangingBadge_child:0', 'TR_ClimbingPlansBadge_child:0', 'TR_ClueCalmersBadge_child:0', 'TR_HelpfulThoughtsBadge_child:0', 'TR_MoodBoostingBadge_child:0', 'TR_PokingHolesBadge_child:0', 'TR_PowerThoughtsBadge_child:0', 'TR_WorryJarBadge_child:0', 'TR_ChangingBadge_teen:0', 'TR_ClimbingPlansBadge_teen:0', 'TR_ClueCalmersBadge_teen:0', 'TR_HelpfulThoughtsBadge_teen:0', 'TR_MoodBoostingBadge_teen:0', 'TR_PokingHolesBadge_teen:0', 'TR_PowerThoughtsBadge_teen:0', 'TR_WorryTimeBadge_teen:0', 'TR_Reward1:0', 'TR_Reward2:0'],
    didReceiveAttrs() {
      this._super(...arguments);
      const that = this;
      that.set('sites', []);
      var user_email = this.session.get('data.user_email');
      this.store.query('user', {
        email__iexact: user_email
      }).then(function (users) {
        const user = users.get('firstObject');
        const sites = user.get('get_all_available_sites');
        that.set('sites', that.store.query('mommentumhubsite', {
          'id__in': sites.join(',')
        }));
      });
    },
    //Action
    actions: {
      //Close assets component
      close: function () {
        (0, _jquery.default)('.hover_bkgr_fricc-study-comp').hide();
      },
      routeToNewStudy: function () {
        (0, _jquery.default)('.hover_bkgr_fricc-study-comp').hide();
        this.get('router').transitionTo('study-builder');
      },
      openstudy: function () {
        var isStudy = (0, _jquery.default)('#isPageChecked').is(':checked');
        this.set('isStudy', isStudy);
      },
      //Creating a study
      createStudy: function () {
        var that = this;
        var userId = this.currentUser.id;
        var accessToken = this.get('session.data.authenticated.access_token');
        var studyName = this.get('studyName');
        var numberOfArms = this.get('numberOfArms');
        var numberofPhases = this.get('numberofPhases');
        // var randomisationStrategy = this.get('randomisationStrategy');
        var variable = this.get('variables');
        var date = document.getElementById('start_date').value;
        var isStudy = (0, _jquery.default)('#isPageChecked').is(':checked');
        var min_onboardingAge = this.get('min_onboardingAge');
        var max_onboardingAge = this.get('max_onboardingAge');
        var user_type = this.currentUser.user_type;
        var selectsite = true;
        var site = null;
        var e = document.getElementById("selectsite");
        var siteId = e.options[e.selectedIndex].value;
        if (siteId) {
          selectsite = true;
          site = siteId;
        } else {
          selectsite = false;
        }
        var allVariables = [];
        if (variable) {
          variable = variable.replace(/\s/g, '');
          var allVariables = this.splitString(variable);
        }
        //Add default tracking variables
        allVariables.push(...this.trackingVariables);
        if (studyName) {
          if (numberOfArms) {
            if (numberofPhases) {
              // if (variable) {
              if (date) {
                var armPhaseJson = this.createArmsPhaseArray(numberofPhases, numberOfArms);
                // variable = variable.replace(/\s/g, '');
                // var allVariables = this.splitString(variable)
                _jquery.default.ajax({
                  url: _environment.default.APP.API_HOST + '/studies',
                  type: 'POST',
                  headers: {
                    "Authorization": "Bearer " + accessToken,
                    "Content-Type": "application/json"
                  },
                  data: JSON.stringify({
                    "study": {
                      "study_name": studyName,
                      "number_of_arms": numberOfArms,
                      "number_of_phases": numberofPhases,
                      "start_date": date,
                      "variables_to_track": allVariables,
                      "creator": userId,
                      "structure_json": armPhaseJson,
                      "public_to_all_participants": isStudy,
                      "min_age": min_onboardingAge,
                      "max_age": max_onboardingAge,
                      "on_boarding_study": isStudy
                    }
                  }),
                  success: function (res) {
                    var studyId = res.study.id;
                    that.saveSite(studyId, siteId);
                  },
                  error: function (err, xH) {
                    var errorMessage = err.responseText;
                    alert(errorMessage);
                  }
                });
              } else {
                this.showError('start_date');
              }
              // }
              // else { this.showError('variables') }
            } else {
              this.showError('numberofPhases');
            }
          } else {
            this.showError('numberOfArms');
          }
        } else {
          this.showError('study_name');
        }
      }
    },
    saveSite: function (studyId, site) {
      var that = this;
      var accessToken = this.get('session.data.authenticated.access_token');
      _jquery.default.ajax({
        url: _environment.default.APP.API_HOST + '/studies/' + studyId,
        type: 'PATCH',
        headers: {
          "Authorization": "Bearer " + accessToken,
          "Content-Type": "application/json"
        },
        data: JSON.stringify({
          "study": {
            "site": site
          }
        }),
        success: function (res) {
          that.set('studyName', "");
          that.set('numberOfArms', "");
          that.set('numberofPhases', "");
          // that.set('randomisationStrategy', "");
          that.set('variables', "");
          that.set('min_onboardingAge', "");
          that.set('max_onboardingAge', "");
          that.set('isStudy', false);
          document.getElementById('start_date').value = "";
          if (that.creationType == "one") {
            that.get('router').transitionTo('study-builder', studyId);
          }
          if (that.creationType == "two") {
            that.sendAction('refreshCurrentRoute');
            (0, _jquery.default)('.hover_bkgr_fricc-study-comp').hide();
          }
        },
        error: function (err, xH) {
          var errorMessage = err.responseText;
          alert(errorMessage);
        }
      });
    },
    splitString: function (variable, allVariables, type) {
      if (variable.includes(",")) {
        var variablesArray = variable.split(',');
        variablesArray.forEach(element => {
          if (type == "study") {
            if (allVariables.includes(element)) {
              alert("You can't add the same variable twice i.e " + element);
            } else {
              var tem = element.replace(/\s/g, '');
              allVariables.pushObject(tem);
            }
          } else if (type == "tracking") {
            if (allVariables.includes("TR_" + element)) {
              alert("You can't add the same variable twice i.e " + "TR_" + element);
            } else {
              if (element.substring(0, 3) == "tr_" || variable.substring(0, 3) == "TR_") {
                var a = element.substring(3);
                a = a.replace(/\s/g, '');
                a = "TR_" + a;
                allVariables.pushObject(a);
              } else {
                var tem = element.replace(/\s/g, '');
                allVariables.pushObject("TR_" + tem);
              }
            }
          } else {
            if (allVariables.includes("GL_" + element)) {
              alert("You can't add the same variable twice i.e " + "GL_" + element);
            } else {
              if (element.substring(0, 3) == "gl_" || variable.substring(0, 3) == "GL_") {
                var a = element.substring(3);
                a = a.replace(/\s/g, '');
                a = "GL_" + a;
                allVariables.pushObject(a);
              } else {
                var tem = element.replace(/\s/g, '');
                allVariables.pushObject("GL_" + tem);
              }
            }
          }
        });
      } else {
        // console.log('variable not includes ,')
        if (type == "study") {
          if (allVariables.includes(variable)) {
            alert("You can't add the same variable twice i.e " + variable);
          } else {
            allVariables.pushObject(variable);
          }
        } else if (type == "tracking") {
          // console.log('tracking var')
          if (variable.substring(0, 3) == "tr_" || variable.substring(0, 3) == "TR_") {
            var a = variable.substring(3);
            a = "TR_" + a;
            if (allVariables.includes(a)) {
              alert("You can't add the same variable twice i.e " + a);
            } else {
              allVariables.pushObject(a);
            }
          } else {
            if (allVariables.includes("TR_" + variable)) {
              alert("You can't add the same variable twice i.e " + "TR_" + variable);
            } else {
              allVariables.pushObject("TR_" + variable);
            }
          }
        } else {
          if (variable.substring(0, 3) == "gl_" || variable.substring(0, 3) == "GL_") {
            var a = variable.substring(3);
            a = "GL_" + a;
            if (allVariables.includes(a)) {
              alert("You can't add the same variable twice i.e " + a);
            } else {
              allVariables.pushObject(a);
            }
          } else {
            if (allVariables.includes("GL_" + variable)) {
              alert("You can't add the same variable twice i.e " + "GL_" + variable);
            } else {
              allVariables.pushObject("GL_" + variable);
            }
          }
        }
      }
      return allVariables;
    },
    showError: function (id) {
      (0, _jquery.default)("#" + id).addClass("error");
      setTimeout(function () {
        (0, _jquery.default)("#" + id).removeClass("error");
      }, 2000);
    },
    // Create Arms and Phases basic json
    createArmsPhaseArray: function (numberOfPhase, numberOfArms) {
      var arms = [];
      for (let i = 0; i < numberOfArms; i++) {
        var armId = i + 1;
        var phase = [];
        for (let j = 0; j < numberOfPhase; j++) {
          var phaseId = j + 1;
          const phaseObj = {
            "phase": phaseId,
            "id": "",
            "created": false
          };
          phase.pushObject(phaseObj);
        }
        const armObj = {
          "arm": armId,
          "id": "",
          "phase": phase
        };
        arms.pushObject(armObj);
      }
      return arms;
    }
  });
  _exports.default = _default;
});