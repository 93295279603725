define("momentumhub/routes/application", ["exports", "momentumhub/helpers/custom-widgets", "survey-knockout"], function (_exports, _customWidgets, _surveyKnockout) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    session: Ember.inject.service(),
    routerService: Ember.inject.service('router'),
    activate: function () {
      var that = this;
      this._super();
      window.scrollTo(0, 0);
      setTimeout(function () {
        var participantAge = localStorage.getItem("participantAge");
        // console.log('participantAge',participantAge)
        if (participantAge > 12) {
          $('.participant-application-view').addClass('teenager');
          $('.participant-application-view').removeClass('child');
        } else {
          $('.participant-application-view').removeClass('teenager');
          $('.participant-application-view').addClass('child');
        }
        window.Survey = window.Survey || _surveyKnockout.default;
        (0, _customWidgets.default)();
        const userType = that.get('session.data.user_type');
        const viewingId = that.get('session.data.viewingId');
        if (userType === 'CL' && !viewingId) {
          $('.participant-application-view').addClass('clinician');
        }
      }, 500);
    },
    setupController(controller, model) {},
    actions: {
      refreshCurrentRoute() {
        this.refresh();
      },
      updateTheme() {
        this.changeTheme();
      },
      didTransition: function () {
        console.log("didTransition");
        let url = window.location.pathname;
        if (url.indexOf('modulePreview') === -1) {
          localStorage.setItem('previousUrl', url);
        }
      },
      changeTheme: function () {
        this.changeTheme();
      }
    },
    model() {
      this.authenticationCheck();
      if (!this.session.isAuthenticated) return;
      var user_email = this.session.get('data.user_email');
      var viewingId = this.session.get('data.viewingId');
      if (!viewingId || viewingId.length === 0) viewingId = this.session.get('data.userId');
      return Ember.RSVP.hash({
        users: this.store.query('user', {
          email__iexact: user_email
        }),
        rewards: this.store.query('userrewardvar', {
          'user': viewingId
        })
      });
    },
    afterModel(model) {
      this.changeTheme(model);
    },
    setupController(controller, model) {
      this._super(controller, model);
      this.controllerFor("application").set('isLogout', false);
    },
    authenticationCheck: function () {
      console.log('this.session', this.session);
      if (!this.session.isAuthenticated) {
        this.controllerFor("application").set('isParticipant', true);
      } else {
        const currentUrl = window.location.href;
        if (!currentUrl.includes('dashboard')) {
          this.controllerFor("dashboard").getNotifications();
        }
      }
    },
    //Set Theme
    changeTheme: function (model) {
      if (!model) {
        model = this.get('model');
      }
      var participantAge = localStorage.getItem("participantAge");
      var that = this;
      setTimeout(function () {
        var users = model.users;
        var isDarkTheme = false;
        if (users && users.firstObject) {
          var user = users.firstObject;
          isDarkTheme = user.dark_theme;
        }
        if (!isDarkTheme || isDarkTheme === 'false' || isDarkTheme == false) {
          $('.participant-application-view').removeClass('dark');
          $('.participant-application-view').addClass('contrast');
        } else {
          console.log('enter is darktheme');
          $('.participant-application-view').addClass('dark');
          $('.participant-application-view').removeClass('contrast');
        }
        if (participantAge > 12) {
          $('.participant-application-view').addClass('teenager');
        } else {
          $('.participant-application-view').removeClass('teenager');
        }
      }, 100);
      var viewingAge = this.session.get('data.viewingAge');
      var viewingDark_theme = this.session.get('data.viewingDark_theme');
      if (viewingAge) {
        participantAge = viewingAge;
      }
      if (participantAge) {
        var selected_theme = localStorage.getItem("selected_theme");
        if (viewingDark_theme != undefined) {
          if (viewingDark_theme) {
            selected_theme = "true";
          } else {
            selected_theme = "false";
          }
        }
        if (selected_theme == "false") {
          if (participantAge <= 12) {
            //primary colors
            document.documentElement.style.setProperty('--primary-color-1', "#4281A4");
            document.documentElement.style.setProperty('--primary-color-2', "#48A9A6");
            document.documentElement.style.setProperty('--primary-color-3', "#D4B483");
            document.documentElement.style.setProperty('--primary-color-4', "#C1666B");
            //secondary colors
            document.documentElement.style.setProperty('--secondary-color-1', "#1A3A61");
            document.documentElement.style.setProperty('--secondary-color-2', "#E4DFDA");
            document.documentElement.style.setProperty('--secondary-color-3', "#80606E");
            //font colors
            document.documentElement.style.setProperty('--font-color-1', "#1A3A61");
            document.documentElement.style.setProperty('--font-color-2', "#737373");
            document.documentElement.style.setProperty('--font-color-3', "#131929");
            document.documentElement.style.setProperty('--font-color-4', "#FFFFFF");

            //Menu color
            document.documentElement.style.setProperty('--font-inverted-white', "white");
            document.documentElement.style.setProperty('--font-color-5', "#1A3A61");
            document.documentElement.style.setProperty('--menu-color-1', "#003E8F");
            document.documentElement.style.setProperty('--menu-color-2', "#28B4CC");
            document.documentElement.style.setProperty('--menu-color-3', "#EF5406");
            document.documentElement.style.setProperty('--menu-color-4', "#19C89C");
            document.documentElement.style.setProperty('--menu-color-5', "#822EB2");
            document.documentElement.style.setProperty('--menu-color-6', "#FACE03");

            // single-page module font colors
            document.documentElement.style.setProperty('--module-h1-color', "#EF5406");
            document.documentElement.style.setProperty('--module-h2-color', "#822EB2");
            document.documentElement.style.setProperty('--module-h3-color', "#003E8F");
            document.documentElement.style.setProperty('--module-h4-color', "#28B4CC");
            document.documentElement.style.setProperty('--module-h5-color', "#000000");
            document.documentElement.style.setProperty('--module-h6-color', "#FFB744");
          } else if (participantAge > 12) {
            setTimeout(function () {
              $('.participant-application-view').addClass('teenager');
            }, 100);

            //primary colors
            document.documentElement.style.setProperty('--primary-color-1', "#28B4CC");
            document.documentElement.style.setProperty('--primary-color-2', "#19C89C");
            document.documentElement.style.setProperty('--primary-color-3', "#FACE03");
            document.documentElement.style.setProperty('--primary-color-4', "#822EB2");
            //secondary colors
            document.documentElement.style.setProperty('--secondary-color-1', "#EF5406");
            document.documentElement.style.setProperty('--secondary-color-2', "#003E8F");
            document.documentElement.style.setProperty('--secondary-color-3', "#FACE03");
            //font colors
            document.documentElement.style.setProperty('--font-color-1', "#1A3A61");
            document.documentElement.style.setProperty('--font-color-2', "#737373");
            document.documentElement.style.setProperty('--font-color-3', "#131929");
            document.documentElement.style.setProperty('--font-color-4', "#FFFFFF");

            //Menu color
            document.documentElement.style.setProperty('--font-inverted-white', "white");
            document.documentElement.style.setProperty('--font-color-5', "#1A3A61");
            document.documentElement.style.setProperty('--menu-color-1', "#4281A4");
            document.documentElement.style.setProperty('--menu-color-2', "#7C606B");
            document.documentElement.style.setProperty('--menu-color-3', "#C1666B");
            document.documentElement.style.setProperty('--menu-color-4', "#48A9A6");
            document.documentElement.style.setProperty('--menu-color-5', "#1A3A61");
            document.documentElement.style.setProperty('--menu-color-6', "#D4B483");

            // single-page module font colors
            document.documentElement.style.setProperty('--module-h1-color', "#48A9A6");
            document.documentElement.style.setProperty('--module-h2-color', "#C1666B");
            document.documentElement.style.setProperty('--module-h3-color', "#000000");
            document.documentElement.style.setProperty('--module-h4-color', "#7C606B");
            document.documentElement.style.setProperty('--module-h5-color', "#000000");
            document.documentElement.style.setProperty('--module-h6-color', "#1A3A61");
          }
        } else {
          // document.documentElement.style.setProperty('--primary-color-1', "rgba(0, 0, 0, 0.801)");
          // document.documentElement.style.setProperty('--primary-color-2', "rgba(0, 0, 0, 0.801)");
          // document.documentElement.style.setProperty('--primary-color-3', "rgba(0, 0, 0, 0.801)");
          // document.documentElement.style.setProperty('--primary-color-4', "rgba(0, 0, 0, 0.801)");
          // //secondary colors
          // document.documentElement.style.setProperty('--secondary-color-1', "white");
          // document.documentElement.style.setProperty('--secondary-color-2', "white");
          // document.documentElement.style.setProperty('--secondary-color-3', "white");
          // //font colors
          // document.documentElement.style.setProperty('--font-color-1', "rgba(0, 0, 0, 0.801)");
          // document.documentElement.style.setProperty('--font-color-2', "rgba(0, 0, 0, 0.801)");
          // document.documentElement.style.setProperty('--font-color-3', "rgba(0, 0, 0, 0.801)");
          // document.documentElement.style.setProperty('--font-color-4', "rgba(0, 0, 0, 0.801)");

          // //Menu color
          // document.documentElement.style.setProperty('--font-inverted-white', "white");
          // document.documentElement.style.setProperty('--font-color-5', "white");

          // document.documentElement.style.setProperty('--menu-color-1', "rgba(0, 0, 0, 0.801)");
          // document.documentElement.style.setProperty('--menu-color-2', "rgba(0, 0, 0, 0.801)");
          // document.documentElement.style.setProperty('--menu-color-3', "rgba(0, 0, 0, 0.801)");
          // document.documentElement.style.setProperty('--menu-color-4', "rgba(0, 0, 0, 0.801)");
          // document.documentElement.style.setProperty('--menu-color-5', "rgba(0, 0, 0, 0.801)");
          // document.documentElement.style.setProperty('--menu-color-6', "rgba(0, 0, 0, 0.801)");
        }
      }
    }
  });
  _exports.default = _default;
});