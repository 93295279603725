define("momentumhub/instance-initializers/body-class", ["exports", "ember-body-class/instance-initializers/body-class"], function (_exports, _bodyClass) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _bodyClass.default;
    }
  });
  Object.defineProperty(_exports, "initialize", {
    enumerable: true,
    get: function () {
      return _bodyClass.initialize;
    }
  });
});