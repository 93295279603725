define("momentumhub/controllers/study-builder", ["exports", "momentumhub/config/environment", "mathjs"], function (_exports, _environment, _mathjs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    ajax: Ember.inject.service(),
    toast: Ember.inject.service(),
    session: Ember.inject.service(),
    routerService: Ember.inject.service('router'),
    keepVariablesInStudy: false,
    //Module
    modulePage: 1,
    modulesearch: "",
    numberOfRecords: 10,
    modules: [],
    delayTimer: "",
    nextModule: "",
    previousModule: "",
    //Surveys
    surveyPage: 1,
    surveySearch: "",
    surveys: [],
    nextSurvey: "",
    previousSurvey: "",
    //Rules
    rulePage: 1,
    rulePage1: 1,
    ruleSearch: "",
    timebasedruleSearch: "",
    rules: [],
    nextRule: "",
    previousRule: "",
    //Views
    leftSide: true,
    rightSide: true,
    newRule: false,
    ruleId: "",
    rule: [],
    //variables
    variables: ["anxiety", "happiness", "sad"],
    variableSelected: false,
    searchAllRules: false,
    //Phase
    selectedArmIndex: "",
    selectedPhaseIndex: "",
    selectedArmId: "",
    partName: "",
    selectedArmPhase: {},
    //Part
    partId: "",
    //Phase
    phaseId: "",
    previousPhaseId: "",
    treeSelected: true,
    moduleSelected: false,
    surveySelected: false,
    deleteButton: false,
    ruleSelected: true,
    timebasedruleselected: false,
    globalvariableSelected: false,
    studyvariablesSelected: true,
    trackingvariablesSelected: false,
    //Rule tapped on tree
    createRuleForModuleSurvey: "",
    selectedNodeId: "",
    selectedRuleType: "",
    childNodeIds: [],
    resultArray: [],
    study_name: "",
    isTestingStudy: true,
    totalArms: [],
    isTreeEditing: false,
    ruleChoiceSelected: "",
    study: [{
      "arm": 1,
      "phase": [{
        "phase": 1
      }, {
        "phase": 2
      }, {
        "phase": 3
      }]
    }, {
      "arm": 2,
      "phase": [{
        "phase": 1
      }, {
        "phase": 2
      }, {
        "phase": 3
      }]
    }],
    nodes: [],
    randomizationArmsIds: [],
    randomizationArmsAllIds: [],
    eachPhaseArray: {
      "id": 1,
      "name": "Study Name.Arm2.Phase1",
      nodes: [{
        "id": 1,
        "type": "rule",
        "nodeTypeId": 1,
        "numberOfChildNodes": 3,
        "childNodesIds": [11, 12, 13],
        "parentNodeId": null
      }, {
        "id": 12,
        "type": "module",
        "nodeTypeId": 1,
        "numberOfChildNodes": 2,
        "childNodesIds": [111, 112, 113],
        "parentNodeId": 1
      }]
    },
    alphabets: ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z", "AA", "AB", "AC", "AD", "AE", "AF", "AG", "AH", "AI", "AJ", "AK", "AL", "AM", "AN", "AO", "AP", "AQ", "AR", "AS", "AT", "AU", "AV", "AW", "AX", "AY", "AZ", "BA", "BB", "BC", "BD", "BE", "BF", "BG", "BH", "BI", "BJ", "BK", "BL", "BM", "BN", "BO", "BP", "BQ", "BR", "BS", "BT", "BU", "BV", "BW", "BX", "BY", "BZ", "CA", "CB", "CC", "CD", "CE", "CF", "CG", "CH", "CI", "CJ", "CK", "CL", "CM", "CN", "CO", "CP", "CQ", "CR", "CS", "CT", "CU", "CV", "CW", "CX", "CY", "CZ"],
    alphabetChange: false,
    simpleVariables: [],
    // trackingVariables: ['TR_ClueCalmers_available:5', 'TR_ClueCalmers_required:1','TR_ClueCalmers_completed:0',
    //                     'TR_ClimbingPlans_available:5','TR_ClimbingPlans_required:1','TR_ClimbingPlans_completed:0',
    //                     'TR_PowerThinking_available:5','TR_PowerThinking_required:1','TR_PowerThinking_completed:0',
    //                     'TR_MoodBoost_available:5', 'TR_MoodBoost_required:1','TR_MoodBoost_completed:0',
    //                     'TR_ChangeFocus_available:5','TR_ChangeFocus_required:1','TR_ChangeFocus_completed:0', 'TR_defaul_check:1'],
    trackingVariables: [],
    globalVariables: [],
    studyVariables: [],
    timebasedRules: [],
    nextTimeBasedRule: "",
    previousTimeBasedRule: "",
    study_node_ids: [],
    armaArrayId: "",
    studyavailable: true,
    nodeNotSaved: false,
    showalertonleaving: false,
    alertmessagechage: false,
    routingTo: "",
    currentSum: "",
    isDefaultChecked: true,
    init() {
      this._super(...arguments);
      // this.set('isDefaultChecked', true);
      // var defaultTrVars = this.get('trackingVariables');
      // console.log()
    },

    actions: {
      setSearchAllRules: function (e) {
        this.set('searchAllRules', e.target.checked);
        this.send('getRules');
      },
      showEditTitle: function () {
        this.set('isEditable', true);
      },
      closeEditTitle: function () {
        this.set('isEditable', false);
      },
      saveEditTitle: function () {
        var study = this.get('model.study');
        var that = this;
        study.save().then(() => {
          this.set('isEditable', false);
        });
      },
      leaveRoute: function () {
        this.set('showalertonleaving', false);
        this.set('alertmessagechage', false);
        this.set('nodeNotSaved', false);
        this.transitionToRoute(this.routingTo);
      },
      openRandomisation: function () {
        const that = this;
        $('.randomisationStrategyComponent').show();
        that.set('randomizationArmsAllIds', []);
        that.set('blocksize', this.model.study.randomisation_block_size);
        const temp = [];
        setTimeout(function () {
          that.model.study.structure_json.forEach(function (item) {
            const armIdentifier = item.id;
            temp.push(armIdentifier);
            that.store.query('arm', {
              identifier: armIdentifier
            }).then(function (arms) {
              const arm = arms.get('firstObject');
              const armId = arm.get('id');
              that.randomizationArmsAllIds.push(armId);
              const randomisation_probability = arm.get('randomisation_probability');
              var string = randomisation_probability;
              if (randomisation_probability) {
                string = string.substring(string.indexOf('/') + 1);
                document.getElementById(armIdentifier).value = string;
              }
            });
          });
          that.set('randomizationArmsIds', temp);
          const randomisation_stratification_vars = that.model.study.randomisation_stratification_vars;
          if (randomisation_stratification_vars == "NA") {
            $("#blockoptions").prop('selectedIndex', 0);
          }
          if (randomisation_stratification_vars == "AG") {
            $("#blockoptions").prop('selectedIndex', 1);
          }
          if (randomisation_stratification_vars == "AE") {
            $("#blockoptions").prop('selectedIndex', 2);
          }
          if (randomisation_stratification_vars == "GN") {
            $("#blockoptions").prop('selectedIndex', 3);
          }
        }, 100);
      },
      saveRandomisation: function () {
        const isDarft = this.model.study.draft;
        if (isDarft) {
          const randomizationArmsIds = this.randomizationArmsIds;
          var count = 0;
          const that = this;
          var length = 0;
          randomizationArmsIds.forEach(function (item) {
            var input = document.getElementById(item).value;
            if (input) {
              count = count + 1 / input;
              length = length + 1;
            }
          });
          const blockSize = this.get('blocksize');
          if (blockSize < 1) {
            $('.block-size-error').show();
            return;
          } else {
            $('.block-size-error').hide();
          }
          that.set('currentSum', count);
          if (length == randomizationArmsIds.length) {
            $('.errormessage1').hide();
            if (count == 1) {
              $('.errormessage').hide();
              this.patchRandomisationProbability(0);
              this.saveBlockSize();
            } else {
              $('.errormessage').show();
            }
          } else {
            $('.errormessage1').show();
          }
        } else {
          this.newAlert("You can't update/save if study is already published.", "Randomisation", "Warning");
        }
      },
      closerandomisation: function () {
        $('.randomisationStrategyComponent').hide();
      },
      refreshModule: function () {
        this.set('modulePage', 1);
        $('.refreshModulesurvey').addClass('bounce');
        setTimeout(function () {
          $('.refreshModulesurvey').removeClass('bounce');
        }, 1000);
        this.send('getModules');
      },
      refreshSurvey: function () {
        this.set('surveyPage', 1);
        $('.refreshModulesurvey').addClass('bounce');
        setTimeout(function () {
          $('.refreshModulesurvey').removeClass('bounce');
        }, 1000);
        this.send('getSurveys');
      },
      // Updates the node array when component is dragged
      updateNode: function () {
        var that = this;
        this.set('showalertonleaving', true);
        var id = localStorage.getItem("targetId");
        var surveyBox = document.getElementById('add-survey' + id);
        var moduleBox = document.getElementById('add-module' + id);
        var ruleBox = document.getElementById('add-rule' + id);
        var actionComponent = document.getElementById('action_component' + id);
        var branch = document.getElementById('branch' + id);
        var dragRule2 = document.getElementById('dragRule2' + id);
        var dragRule1 = document.getElementById('dragRule1' + id);
        var branchRule = document.getElementById('branchRule' + id);
        var studyRule = document.getElementById('dragText' + id);
        var dragTextModule = document.getElementById('dragTextModule' + id);
        var dragTextSurvey = document.getElementById('dragTextSurvey' + id);
        var study_node_box = document.getElementById('study_node_box' + id);
        var study_name = this.study_name;
        var selectedArmId = this.armaArrayId;
        var deleteNodeButton = document.getElementById('deleteNode' + id);
        var phaseId = this.phaseArrayId;
        if (selectedArmId < 9 && selectedArmId > 0) {
          selectedArmId = "0" + selectedArmId;
        }
        if (phaseId < 9) {
          phaseId = "0" + phaseId;
        }
        if (surveyBox || moduleBox || branch || actionComponent) {
          //************** Latest change ***********//
          const testIndex = that.nodes.filter(e => e.id == id);
          if (testIndex[0]) {
            const element = testIndex[0];
            const index = that.nodes.indexOf(testIndex[0]);
            var nodeAlphabet = "";
            if (testIndex[0].nodeAlphabet) {
              nodeAlphabet = testIndex[0].nodeAlphabet;
            }
            var type = element.type;
            var parentId = element.parentId;
            var node = "";
            var node_id = "Arm" + selectedArmId + "_Phase" + phaseId + "_" + nodeAlphabet + "_NodeID" + id;
            if (type == "survey") {
              node_id = node_id + "_nomod_sur";
              node = surveyBox.outerHTML || new XMLSerializer().serializeToString(surveyBox);
              node += actionComponent.outerHTML || new XMLSerializer().serializeToString(actionComponent);
              node += study_node_box.outerHTML || new XMLSerializer().serializeToString(study_node_box);
              node += deleteNodeButton.outerHTML || new XMLSerializer().serializeToString(deleteNodeButton);
              var div = [[{
                'v': id.toString(),
                'f': node
              }, parentId, '']];
              that.nodes[index].div = div;
            }
            if (type == "module") {
              node_id = node_id + "_mod";
              node = moduleBox.outerHTML || new XMLSerializer().serializeToString(moduleBox);
              node += actionComponent.outerHTML || new XMLSerializer().serializeToString(actionComponent);
              node += study_node_box.outerHTML || new XMLSerializer().serializeToString(study_node_box);
              node += deleteNodeButton.outerHTML || new XMLSerializer().serializeToString(deleteNodeButton);
              var div = [[{
                'v': id.toString(),
                'f': node
              }, parentId, '']];
              that.nodes[index].div = div;
            }
            if (type == "branch") {
              node_id = node_id + "_Branch";
              node = branch.outerHTML || new XMLSerializer().serializeToString(branch);
              node += study_node_box.outerHTML || new XMLSerializer().serializeToString(study_node_box);
              node += deleteNodeButton.outerHTML || new XMLSerializer().serializeToString(deleteNodeButton);
              var div = [[{
                'v': id.toString(),
                'f': node
              }, parentId, '']];
              that.nodes[index].div = div;
            }
            if (type == "button") {
              node = "<p class='pipleline'>|</p>";
              var div = [[{
                'v': id.toString(),
                'f': node
              }, parentId, '']];
              that.nodes[index].div = div;
            }
            if (type == "rule") {
              node_id = node_id + "_rule";
              node = ruleBox.outerHTML || new XMLSerializer().serializeToString(ruleBox);
              node += actionComponent.outerHTML || new XMLSerializer().serializeToString(actionComponent);
              node += study_node_box.outerHTML || new XMLSerializer().serializeToString(study_node_box);
              node += deleteNodeButton.outerHTML || new XMLSerializer().serializeToString(deleteNodeButton);
              var div = [[{
                'v': id.toString(),
                'f': node
              }, parentId, '']];
              that.nodes[index].div = div;
            }
            if (dragRule2) {
              var ruleId = dragRule2.dataset.id;
              if (ruleId) {
                that.nodes[index].rule2 = ruleId;
              }
            }
            if (dragRule1) {
              var ruleId = dragRule1.dataset.id;
              if (ruleId) {
                that.nodes[index].rule1 = ruleId;
              }
            }
            if (dragTextModule) {
              var ruleId = dragTextModule.dataset.id;
              if (ruleId) {
                that.nodes[index].typeid = ruleId;
              }
            }
            if (dragTextSurvey) {
              var ruleId = dragTextSurvey.dataset.id;
              if (ruleId) {
                that.nodes[index].typeid = ruleId;
              }
            }
            if (branchRule) {
              var branchRuleId = branchRule.dataset.id;
              if (branchRuleId) {
                that.nodes[index].typeid = branchRuleId;
                if (element.hasOwnProperty('nodeId')) {
                  node_id = node_id + "_BranchRule" + branchRuleId;
                  that.nodes[index].nodeId = node_id;
                }
              }
            }
            if (studyRule) {
              var ruleId = studyRule.dataset.id;
              if (ruleId) {
                that.nodes[index].typeid = ruleId;
                if (element.hasOwnProperty('nodeId')) {
                  node_id = node_id + "_StudyRule" + ruleId;
                  that.nodes[index].nodeId = node_id;
                }
              }
            }
            var typeid = element.typeid;
            if (element.hasOwnProperty('nodeId')) {
              that.nodes[index].nodeId = node_id + typeid;
            }
          }
        }
        // console.log(that.nodes)
        that.send('drawChart');
        // that.send('autoSave');
      },

      //Choose left component choice
      chooseLeftComponentOption: function (choice) {
        if (choice === "tree") {
          $('.media-choices').css({
            "color": 'black'
          });
          $('.shadow').animate({
            left: '0px'
          }, 300);
          $('.tree').css({
            "color": 'white'
          });
          this.set('treeSelected', true);
          this.set('variableSelected', false);
          this.set('timebasedruleselected', false);
        } else if (choice === "modules") {
          $('.media-choices').css({
            "color": 'black'
          });
          $('.shadow').animate({
            left: '33%'
          }, 300);
          $('.modules').css({
            "color": 'white'
          });
          this.set('treeSelected', false);
          this.set('variableSelected', true);
          this.set('timebasedruleselected', false);
          $('.media-choices-6').css({
            "color": 'black'
          });
          $('.shadow3').animate({
            left: '0px'
          }, 300);
          $('.study').css({
            "color": 'white'
          });
          this.set('studyvariablesSelected', true);
          this.set('globalvariableSelected', false);
          this.set('trackingvariablesSelected', false);
        } else {
          $('.media-choices').css({
            "color": 'black'
          });
          $('.shadow').animate({
            left: '66%'
          }, 300);
          $('.surveys').css({
            "color": 'white'
          });
          this.set('treeSelected', false);
          this.set('variableSelected', false);
          this.set('timebasedruleselected', true);
        }
      },
      choosevariables: function (choice) {
        console.log('choice', choice);
        if (choice === "study") {
          $('.media-choices-6').css({
            "color": 'black'
          });
          $('.shadow3').animate({
            left: '0px'
          }, 300);
          $('.study').css({
            "color": 'white'
          });
          this.set('studyvariablesSelected', true);
          this.set('globalvariableSelected', false);
          this.set('trackingvariablesSelected', false);
        } else if (choice === "global") {
          $('.media-choices-6').css({
            "color": 'black'
          });
          $('.shadow3').animate({
            left: '33.3%'
          }, 300);
          $('.global').css({
            "color": 'white'
          });
          this.set('studyvariablesSelected', false);
          this.set('globalvariableSelected', true);
          this.set('trackingvariablesSelected', false);
        } else if (choice === "tracking") {
          $('.media-choices-6').css({
            "color": 'black'
          });
          $('.shadow3').animate({
            left: '66.6%'
          }, 300);
          $('.tracking').css({
            "color": 'white'
          });
          this.set('studyvariablesSelected', false);
          this.set('globalvariableSelected', false);
          this.set('trackingvariablesSelected', true);
        }
      },
      //Left Side menu
      leftCloseMenu: function () {
        $('.left-choice').hide();
        $('.top-menu2').hide();
        $('.tree-component').hide();
        $('.left-side').animate({
          width: '0px'
        }, 500);
        $('.left-side').css({
          padding: '0px'
        });
        $('.left-tree-asset-button').show();
        this.set('leftSide', false);
        if (this.rightSide) {
          var width = $('.study-analytics-component').width() - 390;
          $('.right-side').animate({
            width: width
          }, 500);
        } else {
          var width = $('.study-analytics-component').width() - 20;
          $('.right-side').animate({
            width: width
          }, 500);
        }
      },
      openLeftMenu: function () {
        // show left side
        $('.left-choice').show();
        $('.top-menu2').show();
        $('.tree-component').show();
        $('.left-side').css({
          padding: '30px'
        }, 400);
        $('.left-side').animate({
          width: '400px'
        }, 500);
        this.set('leftSide', true);
        if (this.rightSide) {
          var width = $('.study-analytics-component').width() - 840;
          $('.right-side').animate({
            width: width
          }, 500);
        } else {
          var width = $('.study-analytics-component').width() - 550;
          $('.right-side').animate({
            width: width
          }, 500);
        }
        $('.left-tree-asset-button').hide();
      },
      //Open Rule menu
      open_close_rules_Menu: function () {
        // Show right side
        $('.right-side1').animate({
          width: '300px'
        }, 500);
        $('.right-side1').css({
          padding: '30px'
        }, 400);
        $('.searchandadd').show();
        $('.modules-comm').show();
        $('.top-menu1').show();
        $('.rule-button').hide();
        $('.right-choice').show();
        this.set('rightSide', true);
        if (this.leftSide) {
          var width = $('.study-analytics-component').width() - 840;
          $('.right-side').animate({
            width: width
          }, 500);
        } else {
          var width = $('.study-analytics-component').width() - 450;
          $('.right-side').animate({
            width: width
          }, 500);
        }
      },
      closeRuleMenu: function () {
        this.set('rightSide', false);
        $('.right-study').css({
          padding: '0px'
        }, 100);
        $('.right-study').animate({
          width: '0px'
        }, 500);
        $('.top-menu1').hide();
        $('.rule-button').show();
        $('.searchandadd').hide();
        $('.modules-comm').hide();
        $('.right-choice').hide();
        if (this.leftSide) {
          var width = $('.study-analytics-component').width() - 490;
          $('.right-side').animate({
            width: width
          }, 500);
        } else {
          var width = $('.study-analytics-component').width() - 20;
          $('.right-side').animate({
            width: width
          }, 500);
        }
      },
      //Update open tree
      updateTree: function () {
        if (this.deleteButton) {
          $('.m2').css({
            "background-color": '#fafafa'
          });
          $('.m2').css({
            "color": '#656599'
          });
          this.set('deleteButton', false);
        } else {
          $('.m2').css({
            "background-color": '#656599'
          });
          $('.m2').css({
            "color": '#fafafa'
          });
          this.set('deleteButton', true);
        }
      },
      deleteChild: function (armindex, phaseindex, text) {
        if (text == "arm") {
          var r = confirm("Are you sure you want to delete this Arm");
          if (r) {
            var temp = this.study;
            temp.removeAt(armindex);
            this.set("study", temp);
          }
        } else {
          var r = confirm("Are you sure you want to delete this Part");
          if (r) {
            var temp = this.study;
            temp[armindex].phase.removeAt(phaseindex);
            this.set("study", temp);
          }
        }
      },
      addChild: function (armindex) {
        var r = confirm("Do you want to add a new part to this arm?");
        if (r) {
          var lastPhase = this.study[armindex].phase[this.study[armindex].phase.length - 1].phase;
          var newPhase = +lastPhase + 1;
          const obj = {
            "phase": newPhase
          };
          this.study[armindex].phase.pushObject(obj);
        }
      },
      addArm: function () {
        var r = confirm("Do you want to add a new Arm?");
        if (r) {
          var previousArm = this.study[this.study.length - 1].arm;
          var newArm = +previousArm + 1;
          const obj = {
            "arm": newArm,
            "phase": [{
              "phase": 1
            }]
          };
          this.study.pushObject(obj);
          var that = this;
          setTimeout(function () {
            that.divPositioning();
          }, 300);
        }
      },
      openEditVariableBox: function (variable, index) {
        $('#variable_edit_box' + index).slideDown(1000);
        setTimeout(function () {
          // This is the logic for the tracking variable
          // TR prefix will be require to determine if is a tracking varaible
          if (variable.includes("TR_")) {
            var split_variable = variable.split(":");
            var value = split_variable[1];
            var name = split_variable[0];
            document.getElementById('variable_input' + index).value = name;
            document.getElementById('variable_value' + index).value = value;
            // document.getElementById('variable_title'  + index).innerHTML = name;
          } else {
            document.getElementById('variable_input' + index).value = variable;
          }
        }, 100);
      },
      closeEditVariableBox: function (index) {
        $('#variable_edit_box' + index).slideUp(1000);
      },
      deleteVariable: function (array, index, type) {
        const that = this;
        const input = document.getElementById('variable_input' + index).value;
        const r = confirm("Are you sure you want to delete this variable ?");
        if (r) {
          var temp = [];
          array.splice(index, 1);
          temp = array;
          if (type == "study") {
            this.globalVariables.forEach(function (element) {
              temp.push(element);
            });
          } else if (type == "tracking") {
            this.trackingVariables.forEach(function (element) {
              temp.push(element);
            });
          } else {
            this.simpleVariables.forEach(function (element) {
              temp.push(element);
            });
          }
          var accessToken = this.get('session.data.authenticated.access_token');
          var studyId = this.model.study.id;
          $.ajax({
            url: _environment.default.APP.API_HOST + '/studies/' + studyId,
            type: 'PATCH',
            headers: {
              "Authorization": "Bearer " + accessToken,
              "Content-Type": "application/json"
            },
            data: JSON.stringify({
              "study": {
                "variables_to_track": temp
              }
            }),
            success: function (res) {
              that.set('simpleVariables', []);
              that.set('globalVariables', []);
              that.store.findRecord('study', studyId);
              const study = res.study;
              const variables_to_track = study.variables_to_track;
              var golbalVariables = [];
              var studyVariables = [];
              var trackingVariables = [];
              variables_to_track.forEach(function (variables) {
                const strFirstThree = variables.substring(0, 3);
                if (strFirstThree == "GL_" || strFirstThree == "gl_") {
                  golbalVariables.push(variables);
                } else if (strFirstThree == "TR_" || strFirstThree == "tr_") {
                  trackingVariables.push(variables);
                } else {
                  studyVariables.push(variables);
                }
              });
              that.set('simpleVariables', studyVariables);
              that.set('globalVariables', golbalVariables);
              that.set('trackingVariables', trackingVariables);
              $('#variable_edit_box' + index).slideUp(1000);
              if (type == "study") {
                that.set('treeSelected', false);
                that.set('variableSelected', true);
                that.set('studyvariablesSelected', true);
                that.set('globalvariableSelected', false);
                that.set('trackingvariablesSelected', false);
              } else if (type == "tracking") {
                that.set('treeSelected', false);
                that.set('variableSelected', true);
                that.set('studyvariablesSelected', false);
                that.set('globalvariableSelected', false);
                that.set('trackingvariablesSelected', true);
              } else {
                that.set('treeSelected', false);
                that.set('variableSelected', true);
                that.set('studyvariablesSelected', false);
                that.set('globalvariableSelected', true);
                that.set('trackingvariablesSelected', false);
              }
            },
            error: function (err, xH) {
              var errorMessage = err.responseText;
              alert(errorMessage);
            }
          });
        }
      },
      editVariable: function (array, index, type) {
        console.log('array', array);
        console.log('index', index);
        console.log('type', type);
        // return false;
        const that = this;
        const input = document.getElementById('variable_input' + index).value;
        const r = confirm("Are you sure you want to edit this variable ?");
        if (r) {
          var temp = [];
          if (type == "tracking") {
            const val = document.getElementById('variable_value' + index).value;
            array[index] = `${input}:${val}`;
          } else {
            array[index] = input;
          }
          temp = array;
          this.globalVariables.forEach(function (element) {
            if (temp.indexOf(element) == -1) temp.push(element);
          });
          this.trackingVariables.forEach(function (element) {
            if (temp.indexOf(element) == -1) temp.push(element);
          });
          this.simpleVariables.forEach(function (element) {
            if (temp.indexOf(element) == -1) temp.push(element);
          });
          temp = temp.sort().filter(function (item, pos, ary) {
            return !pos || item != ary[pos - 1];
          });
          var accessToken = this.get('session.data.authenticated.access_token');
          var studyId = this.model.study.id;
          $.ajax({
            url: _environment.default.APP.API_HOST + '/studies/' + studyId,
            type: 'PATCH',
            headers: {
              "Authorization": "Bearer " + accessToken,
              "Content-Type": "application/json"
            },
            data: JSON.stringify({
              "study": {
                "variables_to_track": temp
              }
            }),
            success: function (res) {
              const study = res.study;
              const variables_to_track = study.variables_to_track;
              var golbalVariables = [];
              var studyVariables = [];
              var trackingVariables = [];
              that.store.findRecord('study', studyId);
              variables_to_track.forEach(function (variables) {
                const strFirstThree = variables.substring(0, 3);
                if (strFirstThree == "GL_" || strFirstThree == "gl_") {
                  golbalVariables.push(variables);
                } else if (strFirstThree == "TR_" || strFirstThree == "tr_") {
                  trackingVariables.push(variables);
                } else {
                  studyVariables.push(variables);
                }
              });
              console.log('variables_to_track', variables_to_track);
              console.log('simpleVariables', studyVariables);
              console.log('globalVariables', golbalVariables);
              console.log('trackingVariables', trackingVariables);
              $('#variable_edit_box' + index).slideUp(1000);
              if (type == "study") {
                that.set('simpleVariables', studyVariables);
                that.set('treeSelected', false);
                that.set('variableSelected', true);
                that.set('studyvariablesSelected', true);
                that.set('globalvariableSelected', false);
                that.set('trackingvariablesSelected', false);
              } else if (type == "tracking") {
                that.set('trackingVariables', trackingVariables);
                that.set('treeSelected', false);
                that.set('variableSelected', true);
                that.set('studyvariablesSelected', false);
                that.set('globalvariableSelected', false);
                that.set('trackingvariablesSelected', true);
              } else {
                that.set('globalVariables', golbalVariables);
                that.set('treeSelected', false);
                that.set('variableSelected', true);
                that.set('studyvariablesSelected', false);
                that.set('globalvariableSelected', true);
                that.set('trackingvariablesSelected', false);
              }
            },
            error: function (err, xH) {
              var errorMessage = err.responseText;
              alert(errorMessage);
            }
          });
        }
      },
      // Right side component
      addAction: function () {
        $('#option-box').show();
      },
      closeOptionMenu: function () {
        $('#option-box').hide();
      },
      openInitialPart: function (initial_study_part) {
        var alertShown = this.showAlertOnChange();
        if (alertShown) {
          return;
        }
        var accessToken = this.get('session.data.authenticated.access_token');
        if (!initial_study_part) {
          var that = this;
          var studyId = this.model.study.id;
          $.ajax({
            url: _environment.default.APP.API_HOST + '/parts',
            type: 'POST',
            headers: {
              "Authorization": "Bearer " + accessToken,
              "Content-Type": "application/json"
            },
            success: function (res) {
              var partId = res.part.id;
              that.set('nodes', []);
              $('.Add-action').show();
              that.send('drawChart');
              that.set('partId', partId);
              $.ajax({
                url: _environment.default.APP.API_HOST + '/studies/' + studyId,
                type: 'PATCH',
                headers: {
                  "Authorization": "Bearer " + accessToken,
                  "Content-Type": "application/json"
                },
                data: JSON.stringify({
                  "study": {
                    "initial_study_part": partId
                  }
                }),
                success: function (res) {
                  that.send('refreshCurrentRoute');
                },
                error: function (err, xH) {
                  var errorMessage = err.responseText;
                  alert(errorMessage);
                }
              });
            },
            error: function (err, xH) {
              var errorMessage = err.responseText;
              alert(errorMessage);
            }
          });
        } else {
          var partId = initial_study_part;
          this.set('partId', partId);
          $('.Add-action').show();
          var that = this;
          $.ajax({
            url: _environment.default.APP.API_HOST + '/parts/' + partId,
            type: 'GET',
            headers: {
              "Authorization": "Bearer " + accessToken
            },
            success: function (res) {
              var part = res.part;
              var part_json = part.part_json;
              if (part_json != null) {
                that.set('nodes', part_json);
              } else {
                that.set('nodes', []);
              }
              that.send('drawChart');
            },
            error: function (err, xH) {
              var errorMessage = err.responseText;
              that.newAlert(errorMessage, "Study", "Failure");
            }
          });
        }
        $('.selectedbox').hide();
        $('#selectedbox123').show();
        var partName = this.model.study.study_name;
        this.set('partName', partName);
        this.set('armaArrayId', 0);
        this.set('phaseArrayId', 1);
      },
      keepVariablesInStudyChange: function (event) {
        console.log(event.target);
        var that = this;
        var studyId = this.model.study.id;
        var value = event.target.checked;
        var accessToken = this.get('session.data.authenticated.access_token');
        that.set('keepVariablesInStudy', value);
        $.ajax({
          url: _environment.default.APP.API_HOST + '/studies/' + studyId,
          method: 'PATCH',
          headers: {
            "Authorization": "Bearer " + accessToken,
            "Content-Type": "application/json"
          },
          data: JSON.stringify({
            "study": {
              "keep_variables_from_previous_study": value
            }
          })
        });
      },
      //Choose arm.phase
      selectArmPhase: function (armIndex, phaseIndex, phase, armId, armArrayId, phaseArrayId) {
        var alertShown = this.showAlertOnChange();
        var previousPhaseId = this.phaseId;
        //1. Get phase result

        if (!alertShown && (phase.id == previousPhaseId || previousPhaseId == "" || this.nodes.length == 0 || this.isTreeEditing == false)) {
          var study_name = this.model.study.study_name;
          var phaseCreated = phase.created;
          this.set('selectedArmId', armId);
          var accessToken = this.get('session.data.authenticated.access_token');
          //If phase is not created then show popup to ask for phase creation
          if (!phaseCreated) {
            this.set('selectedArmIndex', armIndex);
            this.set('selectedPhaseIndex', phaseIndex);
            if (armId) {
              this.createPhase(armId);
            } else {
              this.createArm();
            }
          } else {
            var partId = phase.partId;
            this.set('phaseId', phase.id);
            $('.Add-action').show();
            var that = this;
            $('.hover_bkgr_fricccc_spinner').show();
            $.ajax({
              url: _environment.default.APP.API_HOST + '/parts?identifier=' + partId,
              type: 'GET',
              headers: {
                "Authorization": "Bearer " + accessToken
              },
              success: function (res) {
                var parts = res.part;
                if (parts[0]) {
                  var part = parts[0];
                  var part_json = part.part_json;
                  that.set('partId', part.id);
                  if (part_json != null) {
                    that.set('nodes', part_json);
                  } else {
                    that.set('nodes', []);
                  }
                  that.send('drawChart');
                  setTimeout(function () {
                    $('.hover_bkgr_fricccc_spinner').hide();
                  }, 1000);
                }
              },
              error: function (err, xH) {
                var errorMessage = err.responseText;
                that.newAlert(errorMessage, "Study", "Failure");
                setTimeout(function () {
                  $('.hover_bkgr_fricccc_spinner').hide();
                }, 1000);
              }
            });
            $('.selectedbox').hide();
            $('#selectedbox' + armArrayId + phaseArrayId).show();
          }
          var partName = study_name + " Arm" + armArrayId + ".Phase " + phaseArrayId;
          this.set('armaArrayId', armArrayId);
          this.set('phaseArrayId', phaseArrayId);
          this.set('partName', partName);
        } else {
          this.set('previousPhaseId', phase.id);
          if (!alertShown) {
            this.set('nodeNotSaved', true);
            this.set('showalertonleaving', true);
          }
          const obj = {
            "armIndex": armIndex,
            "phaseIndex": phaseIndex,
            "phase": phase,
            "armId": armId,
            "armArrayId": armArrayId,
            "phaseArrayId": phaseArrayId
          };
          this.set('selectedArmPhase', obj);
        }
      },
      closepops: function () {
        this.set('nodeNotSaved', false);
        this.set('showalertonleaving', false);
        this.set('selectedArmIndex', "");
        this.set('selectedPhaseIndex', "");
        this.set('selectedArmId', "");
        this.set('showTreeChangedAlert', false);
      },
      clearTree: function () {
        const r = confirm("Are you sure you want to clear the tree?");
        if (r) {
          this.set('nodes', []);
          this.set('resultArray', []);
          $('.Add-action').show();
          this.send('drawChart');
        }
      },
      createPhase: function () {
        var selectedArmId = this.selectedArmId;
        if (selectedArmId) {
          this.createPhase(selectedArmId);
        } else {
          this.createArm();
        }
      },
      addNode: function (type, nodes, newId, id, index, totalBranches) {
        this.set('isTreeEditing', true);
        let node;
        var idString = "";
        var d = index;
        if (id != -1) {
          idString = id.toString();
          // index = index + 1
        }

        if (this.nodes.length == 1 && this.nodes[0].type === 'addNode') {
          this.set('nodes', []);
        }
        var newIdString = newId.toString();
        if (type != 'addNode') this.set('showalertonleaving', true);
        // For 1 node i.e module, survey , rules
        if (nodes == 1) {
          switch (type) {
            case "survey":
              node = '<div class="add-survey-div add-survey-div11" id="add-survey' + newId + '"><div class="add-survey-head add-survey-head1" ondrop="allowDropModule(event,' + newId + ',1)" ondragover="dropModule(event,' + newId + ',1)" id="dragRule1' + newId + '">Rule</div><div class="addsurvey-drag"><p class="drag-text" ondrop="allowDropModule(event,' + newId + ',2)" ondragover="dropModule(event,' + newId + ',2)" id="dragTextSurvey' + newId + '"><small style="font-weight:normal">Drag Survey Here</small></p></div><div class="add-survey-head add-survey-head1" ondrop="allowDropModule(event,' + newId + ',3)" ondragover="dropModule(event,' + newId + ',3)" id="dragRule2' + newId + '">Rule</div><div class="continuenode"><p>Continue</p><input type="checkbox" id="continuewNode' + newId + '"></div><div class="delaynode"><p>Delay:</p><input type="number" id="delay-days-' + newId + '"></div></div>';
              node += '<div class="action-component action-component1" id="action_component' + newId + '"><div class="addbutton-1" id="AddButton' + newId + '"><p>+</p></div>';
              node += '<div class="choose-option choose-option1 bounce" id="option-box' + newId + '"><div class="close-option close-option-1" id="closeoption' + newId + '">x</div><p class="option-text">Choose Option</p><div class="option-button" id="AddSurvey' + newId + '">Add Survey</div><div class="option-button" id="AddModule' + newId + '">Add Module</div><div class="option-button" id="AddBranch' + newId + '">Add Branch</div><div class="option-button" id="AddRule' + newId + '">Attach Rule</div><div class="option-button" id="AttachNode' + newId + '">Attach Node</div></div></div>';
              node += '<div class="study_node_id" id="study_node_box' + newId + '"> <p class="ptext" id="study_node_id' + newId + '"></p></div>';
              node += '<button id="deleteNode' + newId + '" class="nodedeletebutton displaynone"><img src="../assets/trash-primary.png"></button>';
              break;
            case "module":
              node = '<div class="add-survey-div add-survey-div12" id="add-module' + newId + '"><div class="add-survey-head add-survey-head1" ondrop="allowDropModule(event,' + newId + ',1)" ondragover="dropModule(event,' + newId + ',1)" id="dragRule1' + newId + '">Rule</div><div class="addsurvey-drag"><p class="drag-text" ondrop="allowDropModule(event,' + newId + ',4)" ondragover="dropModule(event,' + newId + ',4)" id="dragTextModule' + newId + '"><small style="font-weight:normal">Drag Module Here</small></p></div><div class="add-survey-head add-survey-head1" ondrop="allowDropModule(event,' + newId + ',3)" ondragover="dropModule(event,' + newId + ',3)" id="dragRule2' + newId + '">Rule</div><div class="continuenode"><p>Continue</p><input type="checkbox" id="continuewNode' + newId + '"></div><div class="delaynode"><p>Delay:</p><input type="number" id="delay-days-' + newId + '"></div></div>';
              node += '<div class="action-component action-component1" id="action_component' + newId + '"><div class="addbutton-1" id="AddButton' + newId + '"><p>+</p></div>';
              node += '<div class="choose-option choose-option1 bounce" id="option-box' + newId + '"><div class="close-option close-option-1" id="closeoption' + newId + '">x</div><p class="option-text">Choose Option</p><div class="option-button" id="AddSurvey' + newId + '">Add Survey</div><div class="option-button" id="AddModule' + newId + '">Add Module</div><div class="option-button" id="AddBranch' + newId + '">Add Branch</div><div class="option-button" id="AddRule' + newId + '">Attach Rule</div><div class="option-button" id="AttachNode' + newId + '">Attach Node</div></div></div>';
              node += '<div class="study_node_id" id="study_node_box' + newId + '"> <p class="ptext" id="study_node_id' + newId + '"></p></div>';
              node += '<button id="deleteNode' + newId + '" class="nodedeletebutton displaynone"><img src="../assets/trash-primary.png"></button>';
              break;
            case "branch":
              node = '<div class="addbranches" id="branch' + newId + '"><p class="branch-head">Add Branch</p><input id="branchinput' + newId + '" class="globalinput" type="number"><div class="globalbutton" id="AddNumberOfBranch' + newId + '">Add Branch</div><div class="add-survey-head add-survey-head1" id="branchRule' + newId + '">Rule</div></div>';
              node += '<div class="study_node_id" id="study_node_box' + newId + '"></div>';
              node += '<button id="deleteNode' + newId + '" class="nodedeletebutton displaynone"><img src="../assets/trash-primary.png"></button>';
              break;
            case "button":
              node = '<div class="emptybranch"></div>';
              node += '<div class="action-component action-component1 action-component2" id="action_component' + newId + '"><div class="addbutton-1" id="AddButton' + newId + '"><p>+</p><button id="deleteNode' + newId + '" class="nodedeletebutton nodedeletebutton1 displaynone"><img src="../assets/trash-primary.png"></button></div>';
              node += '<div class="choose-option choose-option1 choose-option2 bounce" id="option-box' + newId + '"><div class="close-option close-option-1" id="closeoption' + newId + '">x</div><p class="option-text">Choose Option</p><div class="option-button" id="AddSurvey' + newId + '">Add Survey</div><div class="option-button" id="AddModule' + newId + '">Add Module</div><div class="option-button" id="AddBranch' + newId + '">Add Branch</div><div class="option-button" id="AddRule' + newId + '">Attach Rule</div><div class="option-button" id="AttachNode' + newId + '">Attach Node</div></div></div>';
              break;
            case "rule":
              node = '<div class="add-survey-div" id="add-rule' + newId + '"><div class="add-survey-head">Add Rule</div><div class="addsurvey-drag"><p class="drag-text" ondrop="allowDropModule(event,' + newId + ',6)" ondragover="dropModule(event,' + newId + ',6)" id="dragText' + newId + '"><small style="font-weight:normal">Drag Rule Here</small></p></div></div>';
              node += '<div class="action-component action-component1" id="action_component' + newId + '"><div class="addbutton-1" id="AddButton' + newId + '"><p>+</p></div>';
              node += '<div class="choose-option choose-option1 bounce" id="option-box' + newId + '"><div class="close-option close-option-1" id="closeoption' + newId + '">x</div><p class="option-text">Choose Option</p><div class="option-button" id="AddSurvey' + newId + '">Add Survey</div><div class="option-button" id="AddModule' + newId + '">Add Module</div><div class="option-button" id="AddBranch' + newId + '">Add Branch</div><div class="option-button" id="AddRule' + newId + '">Attach Rule</div><div class="option-button" id="AttachNode' + newId + '">Attach Node</div></div></div>';
              node += '<button id="deleteNode' + newId + '" class="nodedeletebutton displaynone"><img src="../assets/trash-primary.png"></button>';
              break;
            case "attachnode":
              node = '<div class="attachnodediv" id="attachnodeComponent' + newId + '"><p id="attachnodeComponentBanner' + newId + '" class="hd3" >Attached</p><p class="hd">Attach Node</p><p class="copy">Please assign node you want to attach, eg. A10 </p><input class="globalinput" id="attachnodeinput' + newId + '"><button id="attachnodebutton' + newId + '">Attach Node</button></div>';
              node += '<button id="deleteNode' + newId + '" class="nodedeletebutton displaynone"><img src="../assets/trash-primary.png"></button>';
              break;
            case "addNode":
              node = '<div class="action-component action-component1" id="action_component' + newId + '"><div class="addbutton-1" id="AddButton' + newId + '"><p>+</p></div>';
              node += '<div class="choose-option choose-option1 bounce" id="option-box' + newId + '"><div class="close-option close-option-1" id="closeoption' + newId + '">x</div><p class="option-text">Choose Option</p><div class="option-button" id="AddSurvey' + newId + '">Add Survey</div><div class="option-button" id="AddModule' + newId + '">Add Module</div><div class="option-button" id="AddBranch' + newId + '">Add Branch</div><div class="option-button" id="AddRule' + newId + '">Attach Rule</div><div class="option-button" id="AttachNode' + newId + '">Attach Node</div></div></div>';
          }
          var newIndex = this.nodes.length;
          var nextAlphabet = "";
          var nextAlphabetNumber = "";
          var newNodeAlphabet = "";
          var lastAlphabet = "";

          //Set alphabets
          if (id >= 0) {
            const getLastNode = this.nodes.filter(e => e.id == id);
            if (getLastNode[0]) {
              if (getLastNode[0].alphabet) {
                nextAlphabet = getLastNode[0].alphabet;
                lastAlphabet = getLastNode[0].lastAlphabet;
                nextAlphabetNumber = parseInt(getLastNode[0].alphabetNumber) + 10;
              }
              newNodeAlphabet = nextAlphabet + nextAlphabetNumber;
            }
          } else {
            nextAlphabet = "A";
            nextAlphabetNumber = "10";
            newNodeAlphabet = nextAlphabet + nextAlphabetNumber;
            lastAlphabet = "A";
          }
          var study_name = this.study_name;
          study_name = study_name.replace(/\s/g, '');
          let obj = {
            "id": newId,
            "nodeId": "",
            "parentId": idString,
            "index": newIndex,
            "lineIndex": newIndex,
            "type": type,
            "typeid": "",
            "rule1": "",
            "rule2": "",
            "totalBranches": 1,
            "div": [[{
              'v': newIdString,
              'f': node
            }, idString, '']],
            "alphabet": nextAlphabet,
            "alphabetNumber": nextAlphabetNumber,
            "nodeAlphabet": newNodeAlphabet,
            "lastAlphabet": lastAlphabet,
            "study_name": study_name,
            "continueNode": false,
            "totalSiblings": nodes,
            "attachedNodeId": "",
            "start_timestamp": "",
            "end_timestamp": ""
          };
          this.nodes.pushObject(obj);
        }

        // For branches more than 2
        else {
          var newIndex = this.nodes.length;
          for (let i = 0; i < nodes; i++) {
            var ind = newIndex + i;
            var newNodeStrings = newIdString + (i + 1);
            switch (type) {
              case "survey":
                node = '<div class="add-survey-div add-survey-div11" id="add-survey' + newNodeStrings + '"><div class="add-survey-head add-survey-head1" ondrop="allowDropModule(event,' + newNodeStrings + ',1)" ondragover="dropModule(event,' + newNodeStrings + ',1)" id="dragRule1' + newNodeStrings + '">Rule</div><div class="addsurvey-drag"><p class="drag-text" ondrop="allowDropModule(event,' + newNodeStrings + ',2)" ondragover="dropModule(event,' + newNodeStrings + ',2)" id="dragTextSurvey' + newNodeStrings + '">Drag Survey Here</p></div><<div class="add-survey-head add-survey-head1" ondrop="allowDropModule(event,' + newNodeStrings + ',3)" ondragover="dropModule(event,' + newNodeStrings + ',3)" id="dragRule2' + newNodeStrings + '">Rule</div><div class="continuenode"><p>Continue</p><input type="checkbox" id="continuewNode' + newNodeStrings + '"></div><div class="delaynode"><p>Delay:</p><input type="number" id="delay-days-' + newNodeStrings + '"></div></div>';
                node += '<div class="action-component action-component1" id="action_component' + newNodeStrings + '"><div class="addbutton-1" id="AddButton' + newNodeStrings + '"><p>+</p></div>';
                node += '<div class="choose-option1 bounce" id="option-box' + newNodeStrings + '"><div class="close-option close-option-1" id="closeoption' + newNodeStrings + '">x</div><p class="option-text">Choose Option</p><div class="option-button" id="AddSurvey' + newNodeStrings + '">Add Survey</div><div class="option-button" id="AddModule' + newNodeStrings + '">Add Module</div><div class="option-button" id="AddBranch' + newNodeStrings + '">Add Branch</div><div class="option-button" id="AddRule' + newNodeStrings + '">Attach Rule</div><div class="option-button" id="AttachNode' + newNodeStrings + '">Attach Node</div></div></div>';
                node += '<div class="study_node_id" id="study_node_box' + newNodeStrings + '"> <p class="ptext" id="study_node_id' + newNodeStrings + '">-</p></div>';
                node += '<button id="deleteNode' + newNodeStrings + '" class="nodedeletebutton displaynone"><img src="../assets/trash-primary.png"></button>';
                break;
              case "module":
                node = '<div class="add-survey-div add-survey-div12" id="add-module' + newNodeStrings + '"><div class="add-survey-head add-survey-head1" ondrop="allowDropModule(event,' + newNodeStrings + ',1)" ondragover="dropModule(event,' + newNodeStrings + ',1)" id="dragRule1' + newNodeStrings + '">Rule</div><div class="addsurvey-drag"><p class="drag-text" ondrop="allowDropModule(event,' + newNodeStrings + ',4)" ondragover="dropModule(event,' + newNodeStrings + ',4)" id="dragTextModule' + newNodeStrings + '"><small style="font-weight:normal">Drag Module Here</small></p></div><div class="add-survey-head add-survey-head1" ondrop="allowDropModule(event,' + newNodeStrings + ',3)" ondragover="dropModule(event,' + newNodeStrings + ',3)" id="dragRule2' + newNodeStrings + '">Rule</div><div class="continuenode"><p>Continue</p><input type="checkbox" id="continuewNode' + newNodeStrings + '"></div><div class="delaynode"><p>Delay:</p><input type="number" id="delay-days-' + newNodeStrings + '"></div></div>';
                node += '<div class="action-component action-component1" id="action_component' + newNodeStrings + '"><div class="addbutton-1" id="AddButton' + newNodeStrings + '"><p>+</p></div>';
                node += '<div class="choose-option1 bounce" id="option-box' + newNodeStrings + '"><div class="close-option close-option-1" id="closeoption' + newNodeStrings + '">x</div><p class="option-text">Choose Option</p><div class="option-button" id="AddSurvey' + newNodeStrings + '">Add Survey</div><div class="option-button" id="AddModule' + newNodeStrings + '">Add Module</div><div class="option-button" id="AddBranch' + newNodeStrings + '">Add Branch</div><div class="option-button" id="AddRule' + newNodeStrings + '">Attach Rule</div><div class="option-button" id="AttachNode' + newNodeStrings + '">Attach Node</div></div></div>';
                node += '<div class="study_node_id" id="study_node_box' + newNodeStrings + '"> <p class="ptext" id="study_node_id' + newNodeStrings + '">-</p></div>';
                node += '<button id="deleteNode' + newNodeStrings + '" class="nodedeletebutton displaynone"><img src="../assets/trash-primary.png"></button>';
                break;
              case "branch":
                node = '<div class="addbranches" id="branch' + newNodeStrings + '"><p class="branch-head">Add Branch</p><input id="branchinput' + newNodeStrings + '" class="globalinput" type="number"><div class="globalbutton" id="AddNumberOfBranch' + newNodeStrings + '">Add Branch</div><div class="add-survey-head add-survey-head1"  id="branchRule' + newNodeStrings + '">Rule</div></div>';
                node += '<button id="deleteNode' + newNodeStrings + '" class="nodedeletebutton displaynone"><img src="../assets/trash-primary.png"></button>';
                break;
              case "button":
                node = '<div class="emptybranch"></div>';
                node += '<div class="action-component action-component1 action-component2" id="action_component' + newNodeStrings + '"><div class="addbutton-1" id="AddButton' + newNodeStrings + '"><p>+</p><button id="deleteNode' + newNodeStrings + '" class="nodedeletebutton nodedeletebutton1 displaynone"><img src="../assets/trash-primary.png"></button></div>';
                node += '<div class="choose-option1 bounce" id="option-box' + newNodeStrings + '"><div class="close-option close-option-1" id="closeoption' + newNodeStrings + '">x</div><p class="option-text">Choose Option</p><div class="option-button" id="AddSurvey' + newNodeStrings + '">Add Survey</div><div class="option-button" id="AddModule' + newNodeStrings + '">Add Module</div><div class="option-button" id="AddBranch' + newNodeStrings + '">Add Branch</div><div class="option-button" id="AddRule' + newNodeStrings + '">Attach Rule</div><div class="option-button" id="AttachNode' + newNodeStrings + '">Attach Node</div></div></div>';
                break;
              case "rule":
                node = '<div class="add-survey-div" id="add-rule' + newNodeStrings + '"><div class="add-survey-head">Add Rule</div><div class="addsurvey-drag"><p class="drag-text" ondrop="allowDropModule(event,' + newNodeStrings + ',6)" ondragover="dropModule(event,' + newNodeStrings + ',6)" id="dragText' + newNodeStrings + '"><small style="font-weight:normal">Drag Rule Here</small></p></div></div>';
                node += '<div class="action-component action-component1" id="action_component' + newNodeStrings + '"><div class="addbutton-1" id="AddButton' + newNodeStrings + '"><p>+</p></div>';
                node += '<div class="choose-option1 bounce" id="option-box' + newNodeStrings + '"><div class="close-option close-option-1" id="closeoption' + newNodeStrings + '">x</div><p class="option-text">Choose Option</p><div class="option-button" id="AddSurvey' + newNodeStrings + '">Add Survey</div><div class="option-button" id="AddModule' + newNodeStrings + '">Add Module</div><div class="option-button" id="AddBranch' + newNodeStrings + '">Add Branch</div><div class="option-button" id="AddRule' + newNodeStrings + '">Attach Rule</div><div class="option-button" id="AttachNode' + newNodeStrings + '">Attach Node</div></div></div>';
                node += '<button id="deleteNode' + newNodeStrings + '" class="nodedeletebutton displaynone"><img src="../assets/trash-primary.png"></button>';
                break;
              case "attachnode":
                node = '<div class="attachnodediv" id="attachnodeComponent' + newNodeStrings + '"><p id="attachnodeComponentBanner' + newNodeStrings + '" class="hd3" >Attached</p><p class="hd">Attach Node</p><p class="copy">Please assign node you want to attach, eg. A10 </p><input class="globalinput"  id="attachnodeinput' + newNodeStrings + '"><button id="attachnodebutton' + newNodeStrings + '">Attach Node</button></div>';
                node += '<button id="deleteNode' + newNodeStrings + '" class="nodedeletebutton displaynone"><img src="../assets/trash-primary.png"></button>';
            }

            //Alphabetic nodes
            var nextAlphabet = "";
            var nextAlphabetNumber = "";
            var newNodeAlphabet = "";
            var lastAlphabet = "";
            const getLastNode = this.nodes.filter(e => e.id == idString);
            if (getLastNode[0]) {
              if (type == "button") {
                if (getLastNode[0].alphabet) {
                  var lstAlphabetIndex = this.alphabets.indexOf(getLastNode[0].alphabet);
                  if (this.alphabets[lstAlphabetIndex + 1 + i]) {
                    nextAlphabet = this.nextAlphabet();
                    lastAlphabet = this.alphabets[lstAlphabetIndex + parseInt(nodes)];
                  }
                  nextAlphabetNumber = 0;
                }
                newNodeAlphabet = nextAlphabet + nextAlphabetNumber;
              }
            }
            var study_name = this.study_name;
            study_name = study_name.replace(/\s/g, '');
            let obj = {
              "id": newNodeStrings,
              "nodeId": "",
              "parentId": idString,
              "index": ind,
              "type": type,
              "typeid": "",
              "rule1": "",
              "rule2": "",
              "lineIndex": newIndex,
              "totalBranches": nodes,
              "div": [[{
                'v': newNodeStrings,
                'f': node
              }, idString, '']],
              "alphabet": nextAlphabet,
              "alphabetNumber": nextAlphabetNumber,
              "nodeAlphabet": newNodeAlphabet,
              "lastAlphabet": lastAlphabet,
              "study_name": study_name,
              "continueNode": false,
              "totalSiblings": nodes,
              "attachedNodeId": "",
              "start_timestamp": "",
              "end_timestamp": ""
            };
            this.nodes.pushObject(obj);
          }
        }
        this.send('drawChart');
        // this.send('autoSave');
        console.log(this.nodes);
      },
      drawChart() {
        var that = this;
        google.charts.load('current', {
          packages: ["orgchart"]
        });
        google.charts.setOnLoadCallback(drawChart);
        function drawChart() {
          var data = new google.visualization.DataTable();
          var previous = 0;
          data.addColumn('string', 'Name');
          data.addColumn('string', 'Manager');
          data.addColumn('string', 'ToolTip');

          //Create chart divs
          that.nodes.forEach(function (element) {
            data.addRows(element.div);
          });
          if (that.nodes.length === 0) {
            that.send('addNode', "addNode", 1, -1, -1, 0);
            return;
          }
          // Create the chart.
          var chart = new google.visualization.OrgChart(document.getElementById('chart_div'));
          // Draw the chart, setting the allowHtml option to true for the tooltips.
          chart.draw(data, {
            'allowHtml': true,
            allowCollapse: true
          });
          var numberOfRows = data.getNumberOfRows();
          if (false) {
            for (var i = 0; i < data.getNumberOfRows(); i++) {
              chart.collapse(i, true);
            }
            google.visualization.events.addListener(chart, 'select', toggleDisplay);
            function toggleDisplay() {
              var selection = chart.getSelection();
              var row;
              if (selection.length == 0) {
                row = previous;
              } else {
                row = selection[0].row;
                previous = row;
              }
              var collapsed = chart.getCollapsedNodes();
              var collapse = collapsed.indexOf(row) == -1;
              chart.collapse(row, collapse);
              chart.setSelection([{
                row: row,
                column: null
              }]);
            }
          }
          that.nodes.forEach(function (element, index) {
            var id = element.id;
            var typeid = element.typeid;
            var rule1 = element.rule1;
            var rule2 = element.rule2;
            var index = element.index;
            var newId = that.uniqueid();
            // newId = newId.toString();
            var parentId = element.parentId;
            if (element.hasOwnProperty('nodeId')) {
              var nodeAlphabet = element.nodeAlphabet;
              $("#study_node_id" + id).text(nodeAlphabet);
            }
            var continueNode = element.continueNode;
            if (continueNode) {
              $('#continuewNode' + id).prop('checked', true);
            } else {
              $('#continuewNode' + id).prop('checked', false);
            }
            var delayInDays = element.delay_in_days;
            if (delayInDays) {
              $('#delay-days-' + id).val(delayInDays);
            }
            $('#action_component' + parentId).hide();
            $('.Add-action').hide();
            $('#option-box').hide();
            $('#option-box' + id).hide();
            var totalBranches = element.totalBranches;
            if (element.type == "attachnode") {
              if (element.attachedNodeId) {
                $('#attachnodeComponentBanner' + id).show();
                document.getElementById('attachnodeinput' + id).value = element.attachedNodeId;
              }
            }
            $("#study_node_id" + id).click(function () {
              var mainNodeId = element.nodeId;
              var nodeAlphabet = element.nodeAlphabet;
              if ($("#study_node_id" + id).hasClass("nodeActive")) {
                $("#study_node_id" + id).text(nodeAlphabet);
                $("#study_node_id" + id).removeClass('nodeActive');
              } else {
                $("#study_node_id" + id).addClass('nodeActive');
                $("#study_node_id" + id).text(mainNodeId);
              }
            });

            //Open Option
            $("#AddButton" + id).click(function () {
              $('#option-box' + id).show();
            });

            //Close Option
            $("#closeoption" + id).click(function () {
              $('#option-box' + id).hide();
            });

            //Add Survey
            $("#AddSurvey" + id).click(function () {
              that.send('addNode', "survey", 1, newId, id, index, totalBranches);
            });

            //Add Module
            $("#AddModule" + id).click(function () {
              that.send('addNode', "module", 1, newId, id, index, totalBranches);
            });

            //Add Rule
            $("#AddRule" + id).click(function () {
              that.send('addNode', "rule", 1, newId, id, index, totalBranches);
              // editChart(index, newId, "module", id, totalBranches)
            });

            //Add Branch
            $("#AddBranch" + id).click(function () {
              that.send('addNode', "branch", 1, newId, id, index, totalBranches);
            });

            //Attach Node
            $("#AttachNode" + id).click(function () {
              that.send('addNode', "attachnode", 1, newId, id, index, totalBranches);
            });

            //AttachNodeButton Clicked
            $("#attachnodebutton" + id).click(function () {
              var attachnodeinput = document.getElementById('attachnodeinput' + id).value;
              if (attachnodeinput) {
                if (attachnodeinput.length <= 5) {
                  attachnodeinput = attachnodeinput.toUpperCase();
                  const r = confirm("Are you sure you want to attach " + attachnodeinput + " node ?");
                  if (r) {
                    that.nodes[index].attachedNodeId = attachnodeinput;
                    $('#attachnodeComponentBanner' + id).show();
                  }
                } else {
                  alert("Only node's alphabetical id is required, eg. A10");
                }
              } else {
                alert("Node id is required");
              }
            });

            //Add Number of Branches Pressed
            $("#AddNumberOfBranch" + id).click(function () {
              var branchNumbers = document.getElementById('branchinput' + id).value;
              const checkNode = that.nodes.filter(e => e.parentId == id);
              if (checkNode[0]) {
                that.newAlert("You can't add branches if child nodes are already present.", "Branch", "Warning");
                document.getElementById('branchinput' + id).value = "";
              } else {
                const r = confirm("Are you sure you want to add " + branchNumbers + " child nodes ?");
                if (r) {
                  that.send('addNode', "button", branchNumbers, newId, id, index, totalBranches);
                  that.newAlert("Please don't forget to add branch rule.", "Branch", "Warning");
                }
              }
            });
            var ruleDetachButtonCode = '<div class="rule-detach-button"style="position: absolute;right:-10px;bottom:35px;"><div class="top-menu"><div class="top-menu-button bounce m2 detach" style="border:1px solid #fff;border-radius:50%;width:20px;height:20px;padding:0;background:#fff;box-shadow:1px 1px 10px #e4e3e3"><i class="fa fa-times" style="padding:3px"></i></div></div></div>';
            var rule1Id = '#dragRule1' + id;
            var rule2Id = '#dragRule2' + id;
            var branchRuleId = '#branchRule' + id;
            if (rule1) {
              $(rule1Id).append(ruleDetachButtonCode);
              $(rule1Id).find('.rule-detach-button').css('bottom', '140px');
            }
            if (element.type === 'branch' && element.typeid) {
              $(branchRuleId).append(ruleDetachButtonCode);
              $(branchRuleId).find('.rule-detach-button').css('bottom', '10px');
              $(branchRuleId).find('.top-menu-button.detach').click(function (e) {
                e.stopImmediatePropagation();
                that.send('deleteRuleFromJson', id, 'branch');
                localStorage.setItem('targetId', id);
                that.send('updateNode');
              });
            }
            if (rule2) {
              $(rule2Id).append(ruleDetachButtonCode);
            }
            //Action Add rule 1
            $(rule1Id).click(function () {
              that.set('newRule', false);
              that.set('createRuleForModuleSurvey', element.type);
              that.set('selectedNodeId', id);
              localStorage.setItem('selectedNodeId_rule1', id);
              that.set('selectedRuleType', "rule1");
              if (rule1) {
                that.store.query('rule', {
                  identifier: rule1
                }).then(function (rules) {
                  const rule = rules.get('firstObject');
                  if (rule) {
                    that.set('ruleChoiceSelected', "simple");
                    that.set('newRule', true);
                    that.set('rule', rule);
                    that.set('childNodeIds', []);
                  } else {
                    that.newAlert("This rule has been deleted and cannot be opened", "Rule Deleted", "Warning");
                  }
                });
              } else {
                that.send('createRule', 2, "simple");
              }
            });

            //Action Add rule 2
            $("#dragRule2" + id).click(function () {
              that.set('newRule', false);
              that.set('createRuleForModuleSurvey', element.type);
              that.set('selectedNodeId', id);
              localStorage.setItem('selectedNodeId_rule2', id);
              that.set('selectedRuleType', "rule2");
              if (rule2) {
                that.store.query('rule', {
                  identifier: rule2
                }).then(function (rules) {
                  const rule = rules.get('firstObject');
                  if (rule) {
                    that.set('ruleChoiceSelected', "simple");
                    that.set('newRule', true);
                    that.set('rule', rule);
                    that.set('childNodeIds', []);
                  } else {
                    that.newAlert("This rule has been deleted and cannot be opened", "Rule Deleted", "Warning");
                  }
                });
              } else {
                that.send('createRule', 2, "simple");
              }
            });
            $(rule2Id).find('.top-menu-button.detach').click(function (e) {
              e.stopImmediatePropagation();
              that.send('deleteRuleFromJson', id, 2);
              localStorage.setItem('targetId', id);
              that.send('updateNode');
            });
            $(rule1Id).find('.top-menu-button.detach').click(function (e) {
              e.stopImmediatePropagation();
              that.send('deleteRuleFromJson', id, 1);
              localStorage.setItem('targetId', id);
              that.send('updateNode');
            });

            //Branch Rule
            $("#branchRule" + id).click(function () {
              var childNodeId = [];
              that.nodes.forEach(function (el) {
                if (el.parentId == id) {
                  that.nodes.forEach(function (el1) {
                    if (el.id == el1.parentId) {
                      childNodeId.pushObject(el1.id);
                    }
                  });
                }
              });
              if (childNodeId.length > 0) {
                if (typeid) {
                  that.store.query('rule', {
                    identifier: typeid
                  }).then(function (rules) {
                    const rule = rules.get('firstObject');
                    that.set('ruleChoiceSelected', "simple");
                    that.set('newRule', true);
                    that.set('rule', rule);
                    that.set('createRuleForModuleSurvey', element.type);
                    that.set('childNodeIds', childNodeId);
                    that.set('selectedNodeId', id);
                    that.set('selectedRuleType', "branchRule");
                  });
                } else {
                  that.set('childNodeIds', childNodeId);
                  that.set('newRule', false);
                  that.set('createRuleForModuleSurvey', element.type);
                  that.set('selectedNodeId', id);
                  that.set('selectedRuleType', "branchRule");
                  that.send('createRule', 2, "simple");
                }
              } else {
                that.newAlert("Please add branches below to add a rule", "Branch", "Warning");
              }
              that.getNodeIds();
            });
            $('#continuewNode' + id).change(function () {
              var checked = $('#continuewNode' + id).is(':checked');
              if (checked) {
                that.nodes[index].continueNode = true;
              } else {
                that.nodes[index].continueNode = false;
              }
              // that.send('autoSave');
            });

            $('#delay-days-' + id).change(function () {
              var value = $(this).val();
              that.nodes[index].delay_in_days = value;
              // that.send('autoSave');
            });

            $("#deleteNode" + id).click(function () {
              const nodeArray = that.nodes.filter(e => e.id == id);
              if (nodeArray[0]) {
                const nodeAlphabet = nodeArray[0].nodeAlphabet;
                const r = confirm("Are you sure you want to delete node " + nodeAlphabet + "?");
                if (r) {
                  const currentIndex = that.nodes.findIndex(e => e.id === id);
                  that.nodes.splice(currentIndex, 1);
                  that.set('showalertonleaving', true);
                  setTimeout(function () {
                    that.send('drawChart');
                  }, 100);
                  // that.send('autoSave');
                }
              }
            });

            // addsurvey-drag

            that.showDeleteNodeButton();
          });
          $(".addsurvey-drag").click(function () {
            const children = $(this).children();
            const id = children.data().id;
            var $temp = $("<input>");
            $("body").append($temp);
            var t = id;
            t = t.trim();
            $temp.val(t).select();
            document.execCommand("copy");
            that.newAlert("Id copied to clipboard", "Copied", "Success");
            $temp.remove();
          });
        }
        // console.log(that.nodes)
      },

      saveAsDraft: function () {
        this.createResult();
        var partId = this.partId;
        var that = this;
        var accessToken = this.get('session.data.authenticated.access_token');
        var node = this.nodes;
        var resultArray = this.resultArray;
        var validateNodes = this.validateNodes();
        if (node.length > 0) {
          if (validateNodes) {
            $.ajax({
              url: _environment.default.APP.API_HOST + '/parts/' + partId,
              type: 'PATCH',
              headers: {
                "Authorization": "Bearer " + accessToken,
                "Content-Type": "application/json"
              },
              data: JSON.stringify({
                "part": {
                  "part_json": node,
                  "part_result_json": resultArray
                }
              }),
              success: function (res) {
                that.newAlert("Study saved successfully.", "Study", "Success");
                that.set('phaseId', "");
                that.set('isTreeEditing', false);
              },
              error: function (err, xH) {
                var errorMessage = err.responseText;
                that.newAlert(errorMessage, "Study", "Failure");
              }
            });
          } else {
            this.newAlert("All nodes must either have Module / Survey / Rule.", "Study Structure", "Warning");
          }
        } else {
          this.newAlert("Empty study structure can't be saved.", "Study Structure", "Warning");
        }
      },
      // ********************* Auto save ******************
      autoSave: function () {
        this.createResult();
        var partId = this.partId;
        var that = this;
        var accessToken = this.get('session.data.authenticated.access_token');
        var node = this.nodes;
        var resultArray = this.resultArray;
        if (node.length > 0) {
          $.ajax({
            url: _environment.default.APP.API_HOST + '/parts/' + partId,
            type: 'PATCH',
            headers: {
              "Authorization": "Bearer " + accessToken,
              "Content-Type": "application/json"
            },
            data: JSON.stringify({
              "part": {
                "part_json": node,
                "part_result_json": resultArray
              }
            }),
            success: function (res) {
              that.set('isTreeEditing', false);
            },
            error: function (err, xH) {
              var errorMessage = err.responseText;
              that.newAlert(errorMessage, "Study", "Failure");
            }
          });
        }
      },
      saveandgotnextphase: function () {
        this.createResult();
        var partId = this.partId;
        var that = this;
        var accessToken = this.get('session.data.authenticated.access_token');
        var node = this.nodes;
        var resultArray = this.resultArray;
        var validateNodes = this.validateNodes();
        var previousPhaseId = this.previousPhaseId;
        var selectedArmPhase = this.selectedArmPhase;
        if (node.length > 0) {
          if (validateNodes) {
            $.ajax({
              url: _environment.default.APP.API_HOST + '/parts/' + partId,
              type: 'PATCH',
              headers: {
                "Authorization": "Bearer " + accessToken,
                "Content-Type": "application/json"
              },
              data: JSON.stringify({
                "part": {
                  "part_json": node,
                  "part_result_json": resultArray
                }
              }),
              success: function (res) {
                that.newAlert("Study saved successfully.", "Study", "Success");
                that.set('phaseId', previousPhaseId);
                that.set('isTreeEditing', false);
                that.set('showalertonleaving', false);
                that.set('showalertonleaving', false);
                that.set('showTreeChangedAlert', false);
                that.set('alertmessagechage', false);
                that.set('nodeNotSaved', false);
                if (that.alertmessagechage) {
                  that.transitionToRoute(that.routingTo);
                } else {
                  that.send("selectArmPhase", selectedArmPhase.armIndex, selectedArmPhase.phaseIndex, selectedArmPhase.phase, selectedArmPhase.armId, selectedArmPhase.armArrayId, selectedArmPhase.phaseArrayId);
                }
                that.set('nodeNotSaved', false);
              },
              error: function (err, xH) {
                var errorMessage = err.responseText;
                that.newAlert(errorMessage, "Study", "Failure");
              }
            });
          } else {
            that.set('nodeNotSaved', false);
            that.set('showalertonleaving', false);
            that.set('showTreeChangedAlert', false);
            this.newAlert("All nodes must either have Module / Survey / Rule.", "Study Structure", "Warning");
          }
        } else {
          that.set('phaseId', previousPhaseId);
          that.set('nodeNotSaved', false);
          that.set('showalertonleaving', false);
          that.set('showTreeChangedAlert', false);
          this.newAlert("Empty study structure can't be saved.", "Study Structure", "Warning");
        }
      },
      gotonextphase: function () {
        var previousPhaseId = this.previousPhaseId;
        this.set('phaseId', previousPhaseId);
        this.set('isTreeEditing', false);
        this.set('nodeNotSaved', false);
        this.set('showalertonleaving', false);
        this.set('showTreeChangedAlert', false);
        var selectedArmPhase = this.selectedArmPhase;
        this.send("selectArmPhase", selectedArmPhase.armIndex, selectedArmPhase.phaseIndex, selectedArmPhase.phase, selectedArmPhase.armId, selectedArmPhase.armArrayId, selectedArmPhase.phaseArrayId);
      },
      publishStudy: function () {
        var study_name = this.model.study.study_name;
        const r = confirm("Are you sure you want to publish " + study_name);
        var that = this;
        if (r) {
          var accessToken = this.get('session.data.authenticated.access_token');
          var studyId = this.model.study.id;

          //Checks if randomisation is added or not
          if (that.model.study.structure_json[0]) {
            const armIdentifier = that.model.study.structure_json[0].id;
            that.store.query('arm', {
              identifier: armIdentifier
            }).then(function (arms) {
              const arm = arms.get('firstObject');
              const randomisation_probability = arm.get('randomisation_probability');
              if (randomisation_probability) {
                $.ajax({
                  url: _environment.default.APP.API_HOST + '/studies/' + studyId,
                  type: 'PATCH',
                  headers: {
                    "Authorization": "Bearer " + accessToken,
                    "Content-Type": "application/json"
                  },
                  data: JSON.stringify({
                    "study": {
                      "draft": false,
                      "ready_for_randomisation": true
                    }
                  }),
                  success: function (res) {
                    that.newAlert("Study published successfully.", "Study", "Success");
                    that.send('refreshCurrentRoute');
                  },
                  error: function (err, xH) {
                    var errorMessage = err.responseText;
                    that.newAlert(errorMessage, "Study", "Failure");
                  }
                });
              } else {
                that.newAlert("Please assign randomisation before publishing the study.", "Study", "Warning");
              }
            });
          }
        }
      },
      // ********************** Right side View *************************
      chooseRightComponentOption: function (choice) {
        if (choice === "rules") {
          $('.media-choices-1').css({
            "color": 'black'
          });
          $('.shadow1').animate({
            left: '0px'
          }, 300);
          $('.rules').css({
            "color": 'white'
          });
          this.set('ruleSelected', true);
          this.set('moduleSelected', false);
          this.set('surveySelected', false);
          // this.send('getRules')
        } else if (choice === "modules") {
          $('.media-choices-1').css({
            "color": 'black'
          });
          $('.shadow1').animate({
            left: '33%'
          }, 300);
          $('.modules-1').css({
            "color": 'white'
          });
          this.set('ruleSelected', false);
          this.set('moduleSelected', true);
          this.set('surveySelected', false);
          // this.send('getModules')
        } else {
          $('.media-choices-1').css({
            "color": 'black'
          });
          $('.shadow1').animate({
            left: '66%'
          }, 300);
          $('.surveys-1').css({
            "color": 'white'
          });
          this.set('ruleSelected', false);
          this.set('moduleSelected', false);
          this.set('surveySelected', true);
          // this.send('getSurveys');
        }
      },

      //********************** Get Modules *******************/

      getModules: function () {
        var page = this.modulePage;
        var that = this;
        var modulesearch = this.get('modulesearch');
        this.store.query('module', {
          page: page,
          id_or_name: modulesearch,
          page_size: that.numberOfRecords
        }).then(function (modules) {
          var next = modules.meta.next;
          var previous = modules.meta.previous;
          that.set('modules', modules);
          that.set('nextModule', next);
          that.set('previousModule', previous);
        });
      },
      searchModule: function () {
        var that = this;
        clearTimeout(this.delayTimer);
        that.delayTimer = setTimeout(function () {
          that.send('getModules');
        }, 1000);
      },
      leftModuleButton: function () {
        if (this.previousModule) {
          this.modulePage = this.modulePage - 1;
          this.send('getModules');
        }
      },
      rightModuleButton: function () {
        if (this.nextModule) {
          this.modulePage = this.modulePage + 1;
          this.send('getModules');
        }
      },
      editModule: function (moduleId) {
        let routerService = this.get('routerService');
        let url = routerService.urlFor('create-module', moduleId);
        window.open(url);
      },
      previewModule: function (moduleId) {
        let routerService = this.get('routerService');
        let url = routerService.urlFor('modulePreview', moduleId);
        window.open(url);
      },
      //********************** Get Surveys *******************/
      getSurveys: function () {
        var page = this.surveyPage;
        var that = this;
        var surveySearch = this.get('surveySearch');
        this.store.query('survey', {
          page: page,
          id_or_name: surveySearch,
          page_size: that.numberOfRecords,
          test: false
        }).then(function (surveys) {
          var next = surveys.meta.next;
          var previous = surveys.meta.previous;
          that.set('surveys', surveys);
          that.set('nextSurvey', next);
          that.set('previousSurvey', previous);
        });
      },
      searchSurvey: function () {
        var that = this;
        clearTimeout(this.delayTimer);
        that.delayTimer = setTimeout(function () {
          that.send('getSurveys');
        }, 1000);
      },
      leftSurveyButton: function () {
        if (this.previousSurvey) {
          this.surveyPage = this.surveyPage - 1;
          this.send('getSurveys');
        }
      },
      rightSurveyButton: function () {
        if (this.nextSurvey) {
          this.surveyPage = this.surveyPage + 1;
          this.send('getSurveys');
        }
      },
      editSurveys: function (surveyId, surveyText) {
        var localStorageName = "survey-" + surveyId;
        if (typeof surveyText === "string") {
          window.localStorage.setItem(localStorageName, surveyText);
        } else {
          window.localStorage.setItem(localStorageName, JSON.stringify(surveyText));
        }
        let routerService = this.get('routerService');
        let url = routerService.urlFor('create-survey', surveyId);
        window.open(url);
      },
      previewSurvey: function (surveyId, surveyText) {
        let routerService = this.get('routerService');
        let url = routerService.urlFor('survey-preview', surveyId);
        window.open(url);
      },
      //Get Rules
      getRules: function () {
        var page = this.rulePage;
        var that = this;
        var ruleSearch = this.get('ruleSearch');
        var query = {
          page: page,
          page_size: that.numberOfRecords,
          draft: false,
          is_time_based: false
        };
        if (isFinite(ruleSearch)) {
          query.id = ruleSearch;
        } else {
          query.search = ruleSearch;
        }
        if (!this.get('searchAllRules')) {
          query.is_branching_rule = false;
        }
        this.store.query('rule', query).then(function (rules) {
          var next = rules.meta.next;
          var previous = rules.meta.previous;
          that.set('rules', rules);
          that.set('nextRule', next);
          that.set('previousRule', previous);
        });
      },
      getTimeBasedRules: function () {
        var page = this.rulePage1;
        var that = this;
        var ruleSearch = this.get('timebasedruleSearch');
        this.store.query('rule', {
          page: page,
          search: ruleSearch,
          page_size: that.numberOfRecords,
          draft: false,
          study: that.model.study.id,
          is_time_based: true
        }).then(function (rules) {
          var next = rules.meta.next;
          var previous = rules.meta.previous;
          that.set('timebasedRules', rules);
          that.set('nextTimeBasedRule', next);
          that.set('previousTimeBasedRule', previous);
        });
      },
      searchRule: function () {
        var that = this;
        clearTimeout(this.delayTimer);
        that.delayTimer = setTimeout(function () {
          that.send('getRules');
        }, 1000);
      },
      searchTimeBasedRule: function () {
        var that = this;
        clearTimeout(this.delayTimer);
        that.delayTimer = setTimeout(function () {
          that.send('getTimeBasedRules');
        }, 1000);
      },
      leftRuleButton: function () {
        if (this.previousRule) {
          this.rulePage = this.rulePage - 1;
          this.send('getRules');
        }
      },
      rightRuleButton: function () {
        if (this.nextRule) {
          this.rulePage = this.rulePage + 1;
          this.send('getRules');
        }
      },
      leftRuleButton1: function () {
        if (this.previousTimeBasedRule) {
          this.rulePage1 = this.rulePage1 - 1;
          this.send('getTimeBasedRules');
        }
      },
      rightRuleButton1: function () {
        if (this.nextTimeBasedRule) {
          this.rulePage1 = this.rulePage1 + 1;
          this.send('getTimeBasedRules');
        }
      },
      ruleSaved: function (ruleChoiceSelected) {
        if (ruleChoiceSelected == "simple") {
          this.send('getRules');
        } else {
          this.send('getTimeBasedRules');
        }
        this.set('newRule', false);
        if (this.nodes.length > 0) {
          this.send('drawChart');
        }
      },
      updateRule: function (rule, choice) {
        this.set('newRule', true);
        this.set('rule', rule);
        this.set('createRuleForModuleSurvey', "");
        this.set('childNodeIds', []);
        this.set('selectedNodeId', "");
        this.set('selectedRuleType', "");
        this.set('ruleChoiceSelected', choice);
        //********* Node ids for blockely */
        this.getNodeIds();
      },
      //************************ Variables **************** */
      addVariable: async function (type) {
        var existedVariables = this.get('studyVariables');
        var that = this;
        var variable_name = this.get('variable_name');
        const r = confirm("Are you sure you want to add " + variable_name);
        // console.log('type', type)
        if (variable_name) {
          // console.log('variable_name', variable_name)
          // console.log('existedVariables', existedVariables)
          // variable_name = variable_name.replace(/\s/g, '');
          if (r) {
            var newVariables = await this.splitString(variable_name, existedVariables, type);
            var accessToken = this.get('session.data.authenticated.access_token');
            var studyId = this.model.study.id;
            $.ajax({
              url: _environment.default.APP.API_HOST + '/studies/' + studyId,
              type: 'PATCH',
              headers: {
                "Authorization": "Bearer " + accessToken,
                "Content-Type": "application/json"
              },
              data: JSON.stringify({
                "study": {
                  "variables_to_track": newVariables
                }
              }),
              success: function (res) {
                // that.send('refreshCurrentRoute');
                if (type == "study") {
                  that.set('treeSelected', false);
                  that.set('variableSelected', true);
                  that.set('studyvariablesSelected', true);
                  that.set('globalvariableSelected', false);
                  that.set('trackingvariablesSelected', false);
                } else if (type == "tracking") {
                  that.set('treeSelected', false);
                  that.set('variableSelected', true);
                  that.set('studyvariablesSelected', false);
                  that.set('globalvariableSelected', false);
                  that.set('trackingvariablesSelected', true);
                } else {
                  that.set('treeSelected', false);
                  that.set('variableSelected', true);
                  that.set('studyvariablesSelected', false);
                  that.set('globalvariableSelected', true);
                  that.set('trackingvariablesSelected', true);
                }
                that.send('saveGlobalVariables', newVariables);
                // that.setStudyVariables();
                that.set('studyVariables', newVariables);
              },
              error: function (err, xH) {
                var errorMessage = err.responseText;
                alert(errorMessage);
              }
            });
            this.set('variable_name', "");
          }
        }
      },
      getGlobalVariables: function () {
        const that = this;
        const accessToken = this.get('session.data.authenticated.access_token');
        const site = this.model.study.get('site');
        const siteId = site.get('id');

        //"GL_gender", "GL_age", "GL_last_login"
        $.ajax({
          url: _environment.default.APP.API_HOST + '/mommentumhubsiteglobalvars?site=' + siteId,
          type: 'GET',
          headers: {
            "Authorization": "Bearer " + accessToken,
            "Content-Type": "application/json"
          },
          success: function (res) {
            that.setStudyVariables();
          },
          error: function (err, xH) {
            var errorMessage = err.responseText;
          }
        });
      },
      // Saves variable for further use
      saveGlobalVariables: function (newVariables) {
        const that = this;
        const accessToken = this.get('session.data.authenticated.access_token');
        const site = this.model.study.get('site');
        const siteId = site.get('id');
        var globalVariables = [];
        var finalGlobalVariables = [];
        // 1. Only get global variables
        newVariables.forEach(function (variable) {
          if (variable.includes("GL_")) {
            globalVariables.push(variable);
          }
        });
        //"GL_gender", "GL_age", "GL_last_login"
        //2. Get globalvariables and check if they already exist
        $.ajax({
          url: _environment.default.APP.API_HOST + '/mommentumhubsiteglobalvars?site=' + siteId,
          type: 'GET',
          headers: {
            "Authorization": "Bearer " + accessToken,
            "Content-Type": "application/json"
          },
          success: function (res) {
            console.log('res', res);
            var mommentumhubsiteglobalvars = res.mommentumhubsiteglobalvar;
            if (mommentumhubsiteglobalvars[0]) {
              const global_vars = mommentumhubsiteglobalvars[0].global_vars;
              const id = mommentumhubsiteglobalvars[0].id;
              if (global_vars) {
                // if added global variables not present
                globalVariables.forEach(function (variable) {
                  if (!global_vars.includes(variable)) {
                    finalGlobalVariables.push(variable);
                  }
                });
                const t = finalGlobalVariables.concat(global_vars);
                patchGlobalVariables(t, id);
              } else {
                finalGlobalVariables = globalVariables;
                patchGlobalVariables(finalGlobalVariables, id);
              }
            } else {
              finalGlobalVariables = globalVariables;
              postGlobalVariables(finalGlobalVariables);
            }
          },
          error: function (err, xH) {
            var errorMessage = err.responseText;
          }
        });

        //3.1 Save "POST" globalVariables
        function postGlobalVariables(finalGlobalVariables) {
          $.ajax({
            url: _environment.default.APP.API_HOST + '/mommentumhubsiteglobalvars',
            type: 'POST',
            headers: {
              "Authorization": "Bearer " + accessToken,
              "Content-Type": "application/json"
            },
            data: JSON.stringify({
              "mommentumhubsiteglobalvar": {
                "site": siteId,
                "global_vars": finalGlobalVariables
              }
            }),
            success: function (res) {
              that.setStudyVariables();
            },
            error: function (err, xH) {
              var errorMessage = err.responseText;
              alert(errorMessage);
            }
          });
        }

        //3.2 Save "PATCH" globalVariables
        function patchGlobalVariables(finalGlobalVariables, id) {
          $.ajax({
            url: _environment.default.APP.API_HOST + '/mommentumhubsiteglobalvars/' + id,
            type: 'PATCH',
            headers: {
              "Authorization": "Bearer " + accessToken,
              "Content-Type": "application/json"
            },
            data: JSON.stringify({
              "mommentumhubsiteglobalvar": {
                "site": siteId,
                "global_vars": finalGlobalVariables
              }
            }),
            success: function (res) {
              that.setStudyVariables();
            },
            error: function (err, xH) {
              var errorMessage = err.responseText;
              alert(errorMessage);
            }
          });
        }
      },
      //********************** Rules ******************** */
      createRule: function (type, choice) {
        this.set('ruleChoiceSelected', choice);
        if (type == 1) {
          this.set('createRuleForModuleSurvey', "");
          this.set('childNodeIds', []);
          this.set('selectedNodeId', "");
          this.set('selectedRuleType', "");
        }
        if (this.newRule) {
          this.set('newRule', false);
          if (this.nodes.length > 0) {
            this.send('drawChart');
          }
        } else {
          this.set('ruleId', "");
          this.set('rule', []);
          var that = this;
          var accessToken = this.get('session.data.authenticated.access_token');
          $.ajax({
            url: _environment.default.APP.API_HOST + '/rules',
            type: 'POST',
            headers: {
              "Authorization": "Bearer " + accessToken,
              "Content-Type": "application/json"
            },
            success: function (res) {
              var ruleId = res.rule.id;
              that.set('newRule', true);
              that.set('ruleId', ruleId);
            },
            error: function (err, xH) {
              var errorMessage = err.responseText;
              that.newAlert(errorMessage, "Error", "Failure");
            }
          });
        }
        //********* Node ids for blockely */
        this.getNodeIds();
      },
      //********************* Test study or Part ***********************/
      testStudy: function () {
        var that = this;
        this.set('isTestingStudy', true);
        this.set('totalArms', []);
        this.model.study.structure_json.forEach(function (item) {
          const obj = {
            "id": item.arm,
            "value": item.id
          };
          that.totalArms.pushObject(obj);
        });
        $('.hover_bkgr_fricc-study-comp12').show();
      },
      testPart: function () {
        this.set('isTestingStudy', false);
        $('.hover_bkgr_fricc-study-comp12').show();
      },
      deleteRuleFromJson: function (id, type) {
        var index = 0;
        var elementId = '';
        this.nodes.forEach(function (element, ind) {
          if (element.id == id) {
            index = ind;
          }
        });
        if (type == 'branch') {
          this.nodes[index].typeid = "";
          elementId = 'branchRule' + id;
        }
        if (type == 1) {
          this.nodes[index].rule1 = "";
          elementId = 'dragRule1' + id;
        }
        if (type == 2) {
          this.nodes[index].rule2 = "";
          elementId = 'dragRule2' + id;
        }
        document.getElementById(elementId).dataset.id = '';
        setTimeout(function () {
          elementId = '#' + elementId;
          $(elementId).css({
            "background-color": 'rgba(52, 77, 144, 0.3)'
          });
          $(elementId).text('Rule');
          document.getElementById("updatetreeNode").click();
        }, 100);
      }
    },
    ifNotSaved: function (transition) {
      if (this.showalertonleaving) {
        transition.abort();
        this.set('alertmessagechage', true);
        this.set('nodeNotSaved', true);
      }
    },
    showAlertOnChange: function (transition) {
      if (this.showalertonleaving) {
        this.set('showTreeChangedAlert', true);
        return true;
      }
      return false;
    },
    ifAlphabetExist: function (alphabet) {
      var isAvailable = false;
      this.nodes.forEach(function (element) {
        if (element.alphabet == alphabet) {
          isAvailable = true;
        }
      });
      return isAvailable;
    },
    nextAlphabet: function () {
      var alphabets = [];
      this.nodes.forEach(function (element) {
        alphabets.push(element.alphabet);
      });
      var temp1 = [];
      var temp2 = [];
      var temp3 = [];
      var temp4 = [];
      alphabets.forEach(function (alphabet) {
        if (alphabet.length == 1) {
          temp1.push(alphabet);
        }
        if (alphabet.length == 2) {
          if (alphabet[0] == "A") {
            temp2.push(alphabet);
          }
          if (alphabet[0] == "B") {
            temp3.push(alphabet);
          }
          if (alphabet[0] == "C") {
            temp4.push(alphabet);
          }
        }
      });
      temp1 = temp1.sort();
      temp2 = temp2.sort();
      temp3 = temp3.sort();
      temp4 = temp4.sort();
      temp1 = temp1.concat(temp2);
      temp1 = temp1.concat(temp3);
      alphabets = temp1.concat(temp4);
      if (alphabets[alphabets.length - 1]) {
        var lastAlphabet = alphabets[alphabets.length - 1];
        var lastAlphabetIndex = this.alphabets.indexOf(lastAlphabet);
        return this.alphabets[lastAlphabetIndex + 1];
      } else {
        return "";
      }
    },
    getSiblingNodes: function (currentAlphabet, currentAlphabetNumber) {
      const that = this;
      var temp = [];
      const node = this.nodes.filter(function (e) {
        return e.alphabetNumber == 0 && e.alphabet == currentAlphabet;
      });
      if (node[0]) {
        const totalSiblings = node[0].totalSiblings;
        const currentAlphabetIndex = this.alphabets.indexOf(currentAlphabet);
        for (let i = 0; i < totalSiblings; i++) {
          const newAlphabet = that.alphabets[currentAlphabetIndex + i];
          const obj = newAlphabet + currentAlphabetNumber;
          temp.pushObject(obj);
        }
      }
      return temp;
    },
    //******************** Create arms phases parts on study creation ********* */
    createArmsAndPhases: function () {
      var initial_part = this.model.study.get('initial_study_part');
      if (!initial_part) {
        var that = this;
        var accessToken = this.get('session.data.authenticated.access_token');
        $.ajax({
          url: _environment.default.APP.API_HOST + '/parts',
          type: 'POST',
          headers: {
            "Authorization": "Bearer " + accessToken,
            "Content-Type": "application/json"
          },
          success: function (res) {
            const initial_study_part = res.part.id;
            $('.hover_bkgr_fricccc_spinner1').show();
            that.createInitialArms(0, initial_study_part);
          },
          error: function (err, xH) {
            var errorMessage = err.responseText;
            $('.hover_bkgr_fricccc_spinner1').hide();
            alert(errorMessage);
          }
        });
      }
    },
    createInitialArms: function (index, initial_study_part) {
      var that = this;
      var accessToken = this.get('session.data.authenticated.access_token');
      var studyId = this.model.study.get('id');
      var number_of_phases = this.model.study.get('number_of_phases');
      var structure_json = this.model.study.get('structure_json');
      if (!index) index = 0;
      if (structure_json) {
        if (structure_json[index]) {
          $.ajax({
            url: _environment.default.APP.API_HOST + '/arms',
            type: 'POST',
            headers: {
              "Authorization": "Bearer " + accessToken,
              "Content-Type": "application/json"
            },
            data: JSON.stringify({
              "arm": {
                "study": studyId,
                "number_of_phases": number_of_phases
              }
            }),
            success: function (res) {
              var armId = res.arm.id;
              var armIdentifiear = res.arm.identifier;
              that.createInitialPhases(0, armId, structure_json[index].phase, index, initial_study_part, armIdentifiear);
            },
            error: function (err, xH) {
              $('.hover_bkgr_fricccc_spinner1').hide();
              var errorMessage = err.responseText;
              alert(errorMessage);
            }
          });
        } else {
          that.store.findRecord('study', studyId);
          $('.hover_bkgr_fricccc_spinner1').hide();
        }
      }
    },
    showDeleteNodeButton: function () {
      const that = this;
      that.nodes.forEach(function (element) {
        const nodeId = element.id;
        const nodeArray = that.nodes.filter(e => e.parentId == nodeId);
        if (nodeArray[0]) {
          setTimeout(function () {
            $('#deleteNode' + nodeId).addClass('displaynone');
            $('#deleteNode' + nodeId).hide();
          }, 10);
          if (nodeArray[0].type == 'addNode') {
            $('#action_component' + nodeId).show();
          }
        } else {
          setTimeout(function () {
            $('#deleteNode' + nodeId).removeClass('displaynone');
            $('#deleteNode' + nodeId).show();
            $('#action_component' + nodeId).show();
          }, 10);
        }
      });
    },
    createInitialPhases: function (phaseIndex, armId, structure_json_phase, armIndex, initial_study_part, armIdentifiear) {
      var that = this;
      var accessToken = this.get('session.data.authenticated.access_token');
      if (!phaseIndex) phaseIndex = 0;
      if (structure_json_phase) {
        if (structure_json_phase[phaseIndex]) {
          var structure_json_phase_count = structure_json_phase.length;
          $.ajax({
            url: _environment.default.APP.API_HOST + '/phases',
            type: 'POST',
            headers: {
              "Authorization": "Bearer " + accessToken,
              "Content-Type": "application/json"
            },
            data: JSON.stringify({
              "phase": {
                "arm": armId
              }
            }),
            success: function (res) {
              var phaseId = res.phase.id;
              var phaseidentifier = res.phase.identifier;
              that.createInitialParts(phaseId, armId, armIndex, phaseIndex, structure_json_phase_count, structure_json_phase, initial_study_part, armIdentifiear, phaseidentifier);
            },
            error: function (err, xH) {
              var errorMessage = err.responseText;
              $('.hover_bkgr_fricccc_spinner1').hide();
              alert(errorMessage);
            }
          });
        }
      }
    },
    createInitialParts: function (phaseId, armId, selectedArmIndex, selectedPhaseIndex, structure_json_phase_count, structure_json_phase, initial_study_part, armIdentifiear, phaseidentifier) {
      var that = this;
      var accessToken = this.get('session.data.authenticated.access_token');
      $.ajax({
        url: _environment.default.APP.API_HOST + '/parts',
        type: 'POST',
        headers: {
          "Authorization": "Bearer " + accessToken,
          "Content-Type": "application/json"
        },
        data: JSON.stringify({
          "part": {
            "phase": phaseId
          }
        }),
        success: function (res) {
          var partId = res.part.id;
          var partIdentifier = res.part.identifier;
          that.updateInitialStudy(armId, phaseId, partId, selectedArmIndex, selectedPhaseIndex, structure_json_phase_count, structure_json_phase, initial_study_part, armIdentifiear, phaseidentifier, partIdentifier);
        },
        error: function (err, xH) {
          var errorMessage = err.responseText;
          $('.hover_bkgr_fricccc_spinner1').hide();
          alert(errorMessage);
        }
      });
    },
    updateInitialStudy: function (armId, phaseId, partId, selectedArmIndex, selectedPhaseIndex, structure_json_phase_count, structure_json_phase, initial_study_part, armIdentifiear, phaseidentifier, partIdentifier) {
      var that = this;
      var studyId = this.model.study.id;
      var structure_json = this.model.study.structure_json;
      structure_json[selectedArmIndex].id = armIdentifiear;
      structure_json[selectedArmIndex].phase[selectedPhaseIndex].created = true;
      structure_json[selectedArmIndex].phase[selectedPhaseIndex].id = phaseidentifier;
      structure_json[selectedArmIndex].phase[selectedPhaseIndex].partId = partIdentifier;
      var accessToken = this.get('session.data.authenticated.access_token');
      $.ajax({
        url: _environment.default.APP.API_HOST + '/studies/' + studyId,
        type: 'PATCH',
        headers: {
          "Authorization": "Bearer " + accessToken,
          "Content-Type": "application/json"
        },
        data: JSON.stringify({
          "study": {
            "structure_json": structure_json,
            "initial_study_part": initial_study_part
          }
        }),
        success: function (res) {
          if (structure_json_phase_count == selectedPhaseIndex + 1) {
            that.createInitialArms(selectedArmIndex + 1, initial_study_part);
          } else {
            that.createInitialPhases(selectedPhaseIndex + 1, armId, structure_json_phase, selectedArmIndex, initial_study_part, armIdentifiear);
          }
        },
        error: function (err, xH) {
          var errorMessage = err.responseText;
          $('.hover_bkgr_fricccc_spinner1').hide();
          alert(errorMessage);
        }
      });
    },
    createResult: function () {
      var nodes = this.nodes;
      var that = this;
      var ind = 0;
      var resultArray = [];
      nodes.forEach(function (element, index) {
        var childNodes = [];

        //Get Child Ids
        nodes.forEach(function (el) {
          if (element.id == el.parentId) {
            //Ignore button ids
            if (el.type == "button") {
              nodes.forEach(function (el1) {
                if (el.id == el1.parentId) {
                  childNodes.pushObject(parseInt(el1.id));
                }
              });
            } else {
              childNodes.pushObject(parseInt(el.id));
            }
          }
        });
        var parentId = null;
        var typeid = null;
        var rule2 = null;
        var rule1 = null;
        var branch = 0;
        var next = null;
        var nodeId = null;
        var study_name = null;
        if (element.parentId) {
          parentId = parseInt(element.parentId);
        }
        if (element.rule1) {
          rule1 = element.rule1;
        }
        if (element.rule2) {
          rule2 = element.rule2;
        }
        if (element.typeid) {
          typeid = element.typeid;
        }
        if (element.type == "branch") {
          typeid = null;
          rule2 = element.typeid;
        }
        if (nodes[index + 1]) {
          if (nodes[index + 1].type == "branch") {
            branch = 1;
          }
        }
        if (element.nodeId) {
          nodeId = element.nodeId;
        }
        if (element.study_name) {
          study_name = element.study_name;
        }
        if (childNodes.length == 1) {
          next = childNodes[0];
        }

        // For attach nodes
        if (element.type == "attachnode") {
          const attachedNodeId = element.attachedNodeId;
          if (attachedNodeId) {
            const attachedNode = that.nodes.filter(e => e.nodeAlphabet == attachedNodeId);
            if (attachedNode[0]) {
              const attachedNodeId = attachedNode[0].id;
              const parentId = element.parentId;
              const previousNode = resultArray.filter(e => e.id == parseInt(parentId));
              if (previousNode[0]) {
                const previousNodeIndex = resultArray.indexOf(previousNode[0]);
                resultArray[previousNodeIndex].next = attachedNodeId;
                console.log(resultArray);
              }
            }
          }
        }
        if (element.type != "button") {
          if (element.type != "attachnode") {
            const obj = {
              "index": index,
              "id": parseInt(element.id),
              "parentId": parentId,
              "childNodes": childNodes,
              "type": element.type,
              "typeid": typeid,
              "start_rule": rule1,
              "end_rule": rule2,
              "totalBranches": childNodes.length,
              "status": "PND",
              "processed": 0,
              "branch": branch,
              "next": next,
              "nodeId": nodeId,
              "study_name": study_name,
              "continueNode": element.continueNode,
              "delay_in_days": element.delay_in_days,
              "start_timestamp": "",
              "end_timestamp": ""
            };
            resultArray.push(obj);
            ind = ind + 1;
          }
        }
      });
      this.set('resultArray', resultArray);
      // console.log(that.resultArray)
    },

    validateNodes: function () {
      var that = this;
      var isValid = true;
      that.nodes.forEach(function (element, index) {
        if (element.type == 'addNode') return;
        if (element.type != "button") {
          if (element.type != "attachnode") {
            if (!element.typeid) {
              isValid = false;
            }
          }
        }
      });
      return isValid;
    },
    //Positions the left side tree on addition of node
    divPositioning: function () {
      var myDiv = $(".study-arms-phases");
      var scrollto = myDiv[0].scrollWidth / 2 - myDiv.width() / 2;
      myDiv.animate({
        scrollLeft: scrollto
      });
    },
    createstudyDiagram: function () {
      var parentDiv = document.getElementById('creative-box');
      var div = document.createElement('div');
      div.setAttribute('class', 'module-survey-container');
      div.style.top = '100px';
      parentDiv.appendChild(div);
    },
    //For variables
    splitString: async function (variable, allVariables, type) {
      var variablesArray = variable.split(",");
      for (var i = 0; i < variablesArray.length; i++) {
        var element = variablesArray[i];
        var updatedVariables = allVariables.map(variable => {
          return variable.toLowerCase();
        });
        element = element.trim();
        element = element.replace(/\s/g, '');
        if (type == "tracking") {
          if (element.toLowerCase().startsWith("tr_")) {
            element = element.slice(3);
          }
          element = "TR_" + element;
        } else if (type == "global") {
          if (element.toLowerCase().startsWith("gl_")) {
            element = element.slice(3);
          }
          element = "GL_" + element;
        } else {
          var existingEvent = await $.ajax({
            url: _environment.default.APP.API_HOST + '/events?name__iexact=' + element
          });
          if (existingEvent && existingEvent.event && existingEvent.event.length > 0) {
            alert('Event with name "' + element + '" already exists. Variable names cannot collide with event names.');
            return false;
          }
        }
        if (updatedVariables.includes(element.toLowerCase())) {
          alert("You can't add the same variable twice i.e " + element);
        } else {
          allVariables.pushObject(element);
        }
      }
      return allVariables;
    },
    setStudyVariables: function (model) {
      var that = this;
      var accessToken = this.get('session.data.authenticated.access_token');
      that.set('simpleVariables', []);
      that.set('globalVariables', []);
      $.ajax({
        url: _environment.default.APP.API_HOST + '/studies/' + this.model.study.get('id'),
        type: 'GET',
        headers: {
          "Authorization": "Bearer " + accessToken,
          "Content-Type": "application/json"
        },
        success: function (res) {
          const study = res.study;
          const variables_to_track = study.variables_to_track;
          var golbalVariables = [];
          var studyVariables = [];
          var trackingVariables = [];
          // console.log('setStudyVariables variables_to_track', variables_to_track)
          variables_to_track.forEach(function (variables) {
            const strFirstThree = variables.substring(0, 3);
            // console.log('strFirstThree', strFirstThree)
            if (strFirstThree == "GL_" || strFirstThree == "gl_") {
              golbalVariables.push(variables);
            } else if (strFirstThree == "TR_" || strFirstThree == "tr_") {
              // console.log('tracking variables', variables)
              trackingVariables.push(variables);
            } else {
              studyVariables.push(variables);
            }
          });
          that.set('simpleVariables', studyVariables);
          that.set('trackingVariables', trackingVariables);
          // console.log('trackingVariables',trackingVariables)
          that.getGlobalVariables(golbalVariables, studyVariables, trackingVariables);
        },
        error: function (err, xH) {
          var errorMessage = err.responseText;
          alert(errorMessage);
        }
      });
    },
    // Get global variables saved as per site
    getGlobalVariables: function (golbalVariables, studyVariables, trackingVariables) {
      // console.log('golbalVariables', golbalVariables)
      // console.log('studyVariables', studyVariables)
      // console.log('trackingVariables', trackingVariables)
      const that = this;
      const accessToken = this.get('session.data.authenticated.access_token');
      const site = this.model.study.get('site');
      const siteId = site.get('id');
      var finalGlobalVariables = [];
      $.ajax({
        url: _environment.default.APP.API_HOST + '/mommentumhubsiteglobalvars?site=' + siteId,
        type: 'GET',
        headers: {
          "Authorization": "Bearer " + accessToken,
          "Content-Type": "application/json"
        },
        success: function (res) {
          var mommentumhubsiteglobalvars = res.mommentumhubsiteglobalvar;
          if (mommentumhubsiteglobalvars[0]) {
            const global_vars = mommentumhubsiteglobalvars[0].global_vars;
            const trVariables = trackingVariables;
            console.log('global_vars', global_vars);
            if (global_vars) {
              // if added global variables not present
              global_vars.forEach(function (variable) {
                if (!golbalVariables.includes(variable)) {
                  finalGlobalVariables.push(variable);
                }
              });
              const t = finalGlobalVariables.concat(golbalVariables);
              that.set('globalVariables', t);
              const i = t.concat(studyVariables);

              // var defaultTr = that.get('trackingVariables')
              // console.log('trackingVariables',trackingVariables)
              // const trVariables = defaultTr.concat(trackingVariables)
              // console.log('trVariables', trVariables)
              that.set('trackingVariables', trackingVariables);
              const all = i.concat(trVariables);
              that.set('studyVariables', all);
            } else {
              that.set('globalVariables', golbalVariables);
              const t = golbalVariables;
              const i = t.concat(studyVariables);
              const all = i.concat(trVariables);
              that.set('studyVariables', all);
            }
          } else {
            that.set('globalVariables', golbalVariables);
            const t = golbalVariables;
            const i = t.concat(studyVariables);
            const all = i.concat(trackingVariables);
            that.set('studyVariables', all);
          }
          var trvars = that.get('trackingVariables');
          console.log('trakingVariables', trvars);
          var defaultChecked = "TR_defaul_check";
          var _isDefaultCheckedIdx = trvars.findIndex(element => {
            // console.log('element', element)
            var newEl = element.split(':');
            // console.log('defaultChecked', defaultChecked)
            if (newEl[0] == defaultChecked) {
              return newEl[1];
            }
          });
          that.set('isDefaultChecked', _isDefaultCheckedIdx < 0);
          that.saveAllVariablesInStudy(that.studyVariables);
        },
        error: function (err, xH) {
          var errorMessage = err.responseText;
        }
      });
    },
    // Save global variables in study if not available
    saveAllVariablesInStudy: function (variables) {
      const variables_to_track = this.model.study.variables_to_track;
      if (JSON.stringify(variables_to_track) != JSON.stringify(variables)) {
        const that = this;
        const accessToken = this.get('session.data.authenticated.access_token');
        var studyId = this.model.study.id;
        $.ajax({
          url: _environment.default.APP.API_HOST + '/studies/' + studyId,
          type: 'PATCH',
          headers: {
            "Authorization": "Bearer " + accessToken,
            "Content-Type": "application/json"
          },
          data: JSON.stringify({
            "study": {
              "variables_to_track": variables
            }
          }),
          success: function (res) {},
          error: function (err, xH) {
            var errorMessage = err.responseText;
            alert(errorMessage);
          }
        });
      }
    },
    //Create Arm
    createArm: function () {
      var that = this;
      var studyId = this.model.study.id;
      var number_of_phases = this.model.study.number_of_phases;
      var accessToken = this.get('session.data.authenticated.access_token');
      $.ajax({
        url: _environment.default.APP.API_HOST + '/arms',
        type: 'POST',
        headers: {
          "Authorization": "Bearer " + accessToken,
          "Content-Type": "application/json"
        },
        data: JSON.stringify({
          "arm": {
            "study": studyId,
            "number_of_phases": number_of_phases
          }
        }),
        success: function (res) {
          var armId = res.arm.id;
          that.createPhase(armId);
        },
        error: function (err, xH) {
          var errorMessage = err.responseText;
          alert(errorMessage);
        }
      });
    },
    //Create Arm
    createPhase: function (armId) {
      var that = this;
      var accessToken = this.get('session.data.authenticated.access_token');
      $.ajax({
        url: _environment.default.APP.API_HOST + '/phases',
        type: 'POST',
        headers: {
          "Authorization": "Bearer " + accessToken,
          "Content-Type": "application/json"
        },
        data: JSON.stringify({
          "phase": {
            "arm": armId
          }
        }),
        success: function (res) {
          var phaseId = res.phase.id;
          that.set('phaseId', phaseId);
          that.createPart(phaseId, armId);
        },
        error: function (err, xH) {
          var errorMessage = err.responseText;
          alert(errorMessage);
        }
      });
    },
    createPart: function (phaseId, armId) {
      var that = this;
      var accessToken = this.get('session.data.authenticated.access_token');
      $.ajax({
        url: _environment.default.APP.API_HOST + '/parts',
        type: 'POST',
        headers: {
          "Authorization": "Bearer " + accessToken,
          "Content-Type": "application/json"
        },
        data: JSON.stringify({
          "part": {
            "phase": phaseId
          }
        }),
        success: function (res) {
          var partId = res.part.id;
          that.set('nodes', []);
          $('.Add-action').show();
          that.send('drawChart');
          that.set('partId', partId);
          that.updateStudy(armId, phaseId, partId);
        },
        error: function (err, xH) {
          var errorMessage = err.responseText;
          alert(errorMessage);
        }
      });
    },
    updateStudy: function (armId, phaseId, partId) {
      var that = this;
      var studyId = this.model.study.id;
      var structure_json = this.model.study.structure_json;
      var selectedArmIndex = this.selectedArmIndex;
      var selectedPhaseIndex = this.selectedPhaseIndex;
      structure_json[selectedArmIndex].id = armId;
      structure_json[selectedArmIndex].phase[selectedPhaseIndex].created = true;
      structure_json[selectedArmIndex].phase[selectedPhaseIndex].id = phaseId;
      structure_json[selectedArmIndex].phase[selectedPhaseIndex].partId = partId;
      var accessToken = this.get('session.data.authenticated.access_token');
      $.ajax({
        url: _environment.default.APP.API_HOST + '/studies/' + studyId,
        type: 'PATCH',
        headers: {
          "Authorization": "Bearer " + accessToken,
          "Content-Type": "application/json"
        },
        data: JSON.stringify({
          "study": {
            "structure_json": structure_json
          }
        }),
        success: function (res) {
          that.send('refreshCurrentRoute');
          that.set('nodeNotSaved', false);
          that.set('showalertonleaving', false);
          setTimeout(function () {
            $('.selectedbox').hide();
            $('#selectedbox' + structure_json[selectedArmIndex].arm + structure_json[selectedArmIndex].phase[selectedPhaseIndex].phase).show();
          }, 1000);
        },
        error: function (err, xH) {
          var errorMessage = err.responseText;
          alert(errorMessage);
        }
      });
    },
    getNodeIds: function () {
      var nodeIds = [];
      this.nodes.forEach(function (element) {
        var mainNodeId = element.nodeId;
        var nodeAlphabet = element.nodeAlphabet;
        if (mainNodeId && nodeAlphabet) {
          const obj = {
            "alphabet": nodeAlphabet,
            "value": mainNodeId
          };
          nodeIds.pushObject(obj);
        }
      });
      this.set("study_node_ids", nodeIds);
    },
    patchRandomisationProbability: function (index) {
      var that = this;
      var accessToken = this.get('session.data.authenticated.access_token');
      if (!index) index = 0;
      var a = index + 1;
      const randomizationArmsAllIds = this.randomizationArmsAllIds;
      const randomizationArmsIds = this.randomizationArmsIds;
      if (randomizationArmsAllIds) {
        if (randomizationArmsAllIds[index]) {
          var inputId = randomizationArmsIds[index];
          var randomisation_probability = document.getElementById(inputId).value;
          var armId = randomizationArmsAllIds[index];
          var data_to_api = {
            "randomisation_probability": "1/" + randomisation_probability
          };
          $.ajax({
            url: _environment.default.APP.API_HOST + '/arms/' + armId,
            type: 'PATCH',
            data: data_to_api,
            headers: {
              "Authorization": "Bearer " + accessToken
            },
            success: function (res) {
              that.patchRandomisationProbability(index + 1);
              if (randomizationArmsAllIds.length == a) {
                that.newAlert("Randomisation has added successfully.", "Randomisation Strategy", "Success");
                $('.randomisationStrategyComponent').hide();
              }
            },
            error: function (err, xH) {
              var str = err.responseText;
              str = str.split(':["').pop();
              str = str.split('"]')[0];
              that.newAlert(str, "Randomisation Strategy", "Failure");
            }
          });
        }
      }
    },
    saveBlockSize: function () {
      var that = this;
      var studyId = this.model.study.id;
      var accessToken = this.get('session.data.authenticated.access_token');
      var blocksize = this.get('blocksize');
      var e = document.getElementById("blockoptions");
      var blockoption = e.options[e.selectedIndex].value;
      $.ajax({
        url: _environment.default.APP.API_HOST + '/studies/' + studyId,
        type: 'PATCH',
        headers: {
          "Authorization": "Bearer " + accessToken,
          "Content-Type": "application/json"
        },
        data: JSON.stringify({
          "study": {
            "randomisation_block_size": blocksize,
            "randomisation_stratification_vars": blockoption
          }
        }),
        success: function (res) {
          that.send('refreshCurrentRoute');
        },
        error: function (err, xH) {
          var errorMessage = err.responseText;
          str = str.split(':["').pop();
          str = str.split('"]')[0];
          that.newAlert(str, "Randomisation Strategy", "Failure");
        }
      });
    },
    uniqueid() {
      return Math.floor(Math.random() * 1000000000);
    },
    /*  Alert to show success/ failure or warning   */
    newAlert: function (message, title, type) {
      if (type === "Success") {
        this.toast.success(message, title, {
          "closeButton": false,
          "debug": false,
          "newestOnTop": false,
          "progressBar": false,
          "positionClass": "toast-top-right",
          "preventDuplicates": false,
          "onclick": null,
          "showDuration": "300",
          "hideDuration": "1000",
          "timeOut": "5000",
          "extendedTimeOut": "1000",
          "showEasing": "swing",
          "hideEasing": "linear",
          "showMethod": "fadeIn",
          "hideMethod": "fadeOut"
        });
      } else if (type === "Failure") {
        this.toast.error(message, title, {
          "closeButton": false,
          "debug": false,
          "newestOnTop": false,
          "progressBar": false,
          "positionClass": "toast-top-right",
          "preventDuplicates": false,
          "onclick": null,
          "showDuration": "300",
          "hideDuration": "1000",
          "timeOut": "5000",
          "extendedTimeOut": "1000",
          "showEasing": "swing",
          "hideEasing": "linear",
          "showMethod": "fadeIn",
          "hideMethod": "fadeOut"
        });
      } else {
        this.toast.warning(message, title, {
          "closeButton": false,
          "debug": false,
          "newestOnTop": false,
          "progressBar": false,
          "positionClass": "toast-top-right",
          "preventDuplicates": false,
          "onclick": null,
          "showDuration": "300",
          "hideDuration": "1000",
          "timeOut": "5000",
          "extendedTimeOut": "1000",
          "showEasing": "swing",
          "hideEasing": "linear",
          "showMethod": "fadeIn",
          "hideMethod": "fadeOut"
        });
      }
    }
  });
  _exports.default = _default;
});