define("momentumhub/routes/onboarding-confirmation", ["exports", "momentumhub/routes/registration"], function (_exports, _registration) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _registration.default.extend({
    model(param) {
      this.controllerFor("onboarding-confirmation").set('studyId', param.studyId);
      this.controllerFor("onboarding-confirmation").set('groupId', param.groupId);
      this.checkIfUserAuthenticated();
    },
    actions: {
      refreshCurrentRoute() {
        this.refresh();
      }
    },
    activate: function () {
      this._super();
      window.scrollTo(0, 0);
    },
    setupController(controller, model) {
      this._super(controller, model);
    },
    // Check if user Authenticated and then get user id and age
    checkIfUserAuthenticated: function () {
      const isAuthenticated = this.session.isAuthenticated;
      const that = this;
      if (isAuthenticated) {
        const user_email = this.session.get('data.user_email');
        this.store.query('user', {
          email__iexact: user_email
        }).then(function (users) {
          if (users.length > 0) {
            const user = users.get('firstObject');
            const userId = user.get('id');
            const age = user.get('age');
            const has_completed_onboarding = user.get('has_completed_onboarding');
            that.controllerFor("onboarding-confirmation").createGroupInstance(userId, age, has_completed_onboarding);
          }
        });
      }
    }
  });
  _exports.default = _default;
});