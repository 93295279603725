define("momentumhub/controllers/module-preview", ["exports", "momentumhub/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    hedgeDocUrl: "",
    session: Ember.inject.service(),
    isLastPage: false,
    hasNext: false,
    showNext: false,
    module: null,
    next_node: "",
    current_node: "",
    studyPartJson: [],
    actions: {
      nextButton: function () {
        const nextNodeID = this.getNextNode(this.current_node, this.studyPartJson);
        var continuetoNextNode = this.studyPartJson.filter(e => e.id == nextNodeID);
        if (continuetoNextNode.length > 0) {
          this.transitionToRoute('modulePreview', continuetoNextNode[0].typeid, {
            queryParams: {
              showNext: this.get('showNext'),
              'nodeId': nextNodeID
            }
          });
        }
      },
      endOfModule: function () {
        if (!this.get('module.hide_save_buttons')) {
          this.set('isLastPage', true);
        }
      },
      nextSurvey: function () {
        const surveyId = localStorage.getItem("hedgedocsurveyresult");
        let result = localStorage.getItem("hedgedocsurveyresult1");
        localStorage.setItem("hedgedocsurveyresult", "");
        localStorage.setItem("hedgedocsurveyresult1", "");
        const that = this;
        this.store.findRecord('survey', surveyId).then(function (survey) {
          // const json = survey.get('survey_body_viewable');
          var survey_body = survey.get('survey_body');
          var surveyObj = new Survey.Model(survey.get('survey_body_viewable'));
          result = JSON.parse(result);
          for (const [key, value] of Object.entries(result)) {
            surveyObj.setValue(key, value);
          }
          that.saveSurveyResult(surveyObj, survey_body, surveyId, "InModule", survey_body);
        });
      },
      exitModule: function () {
        this.set('isLastPage', false);
        let lastUrl = localStorage.getItem('previousUrl');
        if (lastUrl) {
          this.transitionToRoute(lastUrl);
        } else {
          window.history.back();
        }
        // this.transitionToRoute('modules');
      }
    },

    getNextNode: function (currentNode, part) {
      //1. Get current node's object
      var currentNodeObjectArray = part.filter(e => e.id == currentNode);
      if (currentNodeObjectArray.length > 0) {
        const currentNodeObject = currentNodeObjectArray[0];
        const isnextBranch = currentNodeObject.branch;
        const next = currentNodeObject.next;
        const totalBranches = currentNodeObject.totalBranches;

        //2. If there is a next node
        if (totalBranches > 0) {
          // 2.1. if next node is a linear node then return next node id
          if (isnextBranch == 0) {
            return next;
          }

          //2.2. Else next node is a branch then find a node with status NTT
          else {
            var nextNodeObjectArray = part.filter(e => e.status === "NTT");
            if (nextNodeObjectArray.length > 0) {
              var nextNodeObject = nextNodeObjectArray[0];
              var nextId = nextNodeObject.id;
              return nextId;
            }
          }
        }
        //3. No next node
        else {
          return "PART COMPLETE";
        }
      }
    },
    // Save Survey results
    saveSurveyResult: function (survey, json, surveyId, type, survey_body_json) {
      var result = survey.data;
      var that = this;
      var finalResultArray = [];
      var displayValues = [];
      for (var key in survey.data) {
        var question = survey.getQuestionByValueName(key);
        var mappings = {};
        if (!question) continue;
        if (question.classMetaData.name == 'matrix') question.rows.map(function (row, index) {
          mappings[row.id] = row.title;
        });
        var questionResult = {
          name: key,
          value: survey.data[key],
          title: question.title,
          displayValue: question.displayValue,
          questionLabel: mappings
        };
        displayValues.push(questionResult);
      }
      json.pages.forEach(function (page) {
        for (const [key, value] of Object.entries(result)) {
          const a = page.elements.filter(function (e) {
            return e.name == key;
          });
          if (a[0]) {
            const b = a[0];

            // 1.Checkbox
            if (b.type == "checkbox") {
              var choicesArray = [];
              value.forEach(function (val) {
                const c = b.choices.filter(function (e) {
                  return e.value == val;
                });
                if (c[0]) {
                  var text = c[0].text;
                  text = that.checkString(text);
                  choicesArray.pushObject(text);
                }
              });
              const obj = {
                "question": key,
                "answer": choicesArray
              };
              finalResultArray.pushObject(obj);
            }

            // 2.radiogroup
            if (b.type == "radiogroup" || b.type == 'randomselect' || b.type == 'quiz') {
              const c = b.choices.filter(function (e) {
                return e.value == value;
              });
              if (c[0]) {
                var text = c[0].text;
                text = that.checkString(text);
                const obj = {
                  "question": key,
                  "answer": text
                };
                finalResultArray.pushObject(obj);
              }
            }

            //3. Dropdown
            if (b.type == "dropdown") {
              const c = b.choices.filter(function (e) {
                return e.value == value;
              });
              if (c[0]) {
                var text = c[0].text;
                text = that.checkString(text);
                const obj = {
                  "question": key,
                  "answer": text
                };
                finalResultArray.pushObject(obj);
              }
            }

            //4. Boolean
            if (b.type == "boolean") {
              if (value == true) {
                var text = b.labelTrue;
                text = that.checkString(text);
                const obj = {
                  "question": key,
                  "answer": text
                };
                finalResultArray.pushObject(obj);
              } else {
                var text = b.labelFalse;
                text = that.checkString(text);
                const obj = {
                  "question": key,
                  "answer": text
                };
                finalResultArray.pushObject(obj);
              }
            }

            //5. Ratings
            if (b.type == "rating") {
              const c = b.rateValues.filter(function (e) {
                return e.value == value;
              });
              if (c[0]) {
                var text = c[0].text;
                text = that.checkString(text);
                const obj = {
                  "question": key,
                  "answer": text
                };
                finalResultArray.pushObject(obj);
              }
            }

            //6. Ranking
            if (b.type == "ranking") {
              const obj = {
                "question": key,
                "answer": value
              };
              finalResultArray.pushObject(obj);
            }

            //7. Simple Text
            if (b.type == "text") {
              const obj = {
                "question": key,
                "answer": value
              };
              finalResultArray.pushObject(obj);
            }

            //8. matrix
            if (b.type == "matrix") {
              var matrixArray = [];
              for (const [k, v] of Object.entries(value)) {
                var columnText = "";
                var rowText = "";
                var cv = b.columns.filter(function (e) {
                  return e.value == v;
                });
                if (cv[0]) {
                  columnText = cv[0].text;
                }
                var rv = b.rows.filter(function (e) {
                  return e.value == k;
                });
                if (rv[0]) {
                  rowText = rv[0].text;
                }
                if (columnText.includes("~")) {
                  var fields = columnText.split('~ val:');
                  columnText = fields[1];
                }
                if (rowText.includes("~")) {
                  var fields = rowText.split('~ val:');
                  rowText = fields[1];
                }
                if (!isNaN(parseInt(columnText))) {
                  matrixArray.pushObject(rowText + ":" + columnText);
                }
              }
              const obj = {
                "question": key,
                "answer": matrixArray
              };
              finalResultArray.pushObject(obj);
            }

            //9. ImagePicker
            if (b.type == "imagepicker") {
              const c = b.choices.filter(function (e) {
                return e.value == value;
              });
              if (c[0]) {
                var text = c[0].text;
                text = that.checkString(text);
                const obj = {
                  "question": key,
                  "answer": text
                };
                finalResultArray.pushObject(obj);
              }
            }

            //10. matrixdropdown
            if (b.type == "matrixdropdown") {
              var matrixArray = [];
              for (const [k, v] of Object.entries(value)) {
                var rowIndex = k[4];
                var rowText = b.rows[parseInt(rowIndex) - 1].text;
                var rowColumn = "";
                if (rowText.includes("~")) {
                  var fields = rowText.split('~ val:');
                  rowText = fields[1];
                }
                //Columns
                for (const [k1, v1] of Object.entries(v)) {
                  var columnIndex = k1[7];
                  var columnText = b.columns[parseInt(columnIndex) - 1].title;
                  if (columnText.includes("~")) {
                    var fields = columnText.split('~ val:');
                    columnText = fields[1];
                    rowColumn = rowText + "_" + columnText;
                    matrixArray.pushObject(rowColumn + ":" + v1);
                  } else {
                    if (b.choices[v1 - 1]) {
                      const choice = b.choices[v1 - 1].text;
                      if (choice.includes("~")) {
                        var fields = choice.split('~ val:');
                        rowColumn = rowText + "_" + columnText;
                        matrixArray.pushObject(rowColumn + ":" + fields[1]);
                      }
                    }
                  }
                }
              }
              const obj = {
                "question": key,
                "answer": matrixArray
              };
              finalResultArray.pushObject(obj);
            }
            if (b.type == "expression") {
              var title = b.title;
              title = title.split('~')[1];
              const answer = title + ":" + value;
              const obj = {
                "question": key,
                "answer": answer
              };
              finalResultArray.pushObject(obj);
            }
            if (b.type == "ratinggauge") {
              var title = b.title;
              title = title.split('~')[1];
              const answer = title + ":" + value;
              const obj = {
                "question": key,
                "answer": answer
              };
              finalResultArray.pushObject(obj);
            }
          }
        }
        console.log(finalResultArray);
        displayValues.reverse();
        finalResultArray.reverse();
        that.saveSurveyActivity(finalResultArray, surveyId, type, result, displayValues);
      });
    },
    checkString(text) {
      if (text) {
        if (text.includes("~")) {
          var fields = text.split('~');
          return fields[1];
        } else {
          return text;
        }
      } else {
        return "";
      }
    },
    saveSurveyActivity: function (result_array, survey, type, result_json, displayValues) {
      const that = this;
      const accessToken = this.get('session.data.authenticated.access_token');
      const user = this.get('session.data.userId');
      var data_to_api = {
        "user": user,
        "survey": survey,
        "result_array": JSON.stringify(result_array),
        "result_json": JSON.stringify(result_json),
        "display_values": JSON.stringify(displayValues)
      };
      $.ajax({
        url: _environment.default.APP.API_HOST + '/activities',
        type: 'POST',
        data: data_to_api,
        headers: {
          "Authorization": "Bearer " + accessToken
        },
        success: function (res) {
          console.log("successfull");
        },
        error: function (err, xH) {
          var str = err.responseText;
          console.log(str);
        }
      });
    }
  });
  _exports.default = _default;
});