define("momentumhub/templates/login", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "wwWOpwx1",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,0],[\"Login\"],null]],[2,\"\\n\"],[10,\"div\"],[14,0,\"login_page\"],[12],[2,\"\\n    \"],[1,[34,1]],[2,\"\\n\"],[13],[2,\"\\n\"],[1,[30,[36,3],[[30,[36,2],null,null]],null]]],\"hasEval\":false,\"upvars\":[\"page-title\",\"login-component\",\"-outlet\",\"component\"]}",
    "moduleName": "momentumhub/templates/login.hbs"
  });
  _exports.default = _default;
});