define("momentumhub/controllers/clue-calmers", ["exports", "momentumhub/config/environment", "survey-knockout"], function (_exports, _environment, Survey) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    //variable declaration
    isTeenager: false,
    session: Ember.inject.service(),
    videos: null,
    foldersToSearch: null,
    init: function () {
      this._super(...arguments);
      var that = this;
      var participantAge = localStorage.getItem('participantAge');
      if (participantAge <= 12) {
        this.set('isTeenager', false);
      } else {
        this.set('isTeenager', true);
      }
      this.getVideos(_environment.default.APP.VIMEO_ACCESS_TOKEN);
      this.set('videos', {});
      this.set('foldersToSearch', ['cc_breathing_child', 'cc_mind_child', 'cc_muscle_child', 'cc_breathing_teen', 'cc_mind_teen', 'cc_muscle_teen']);
      var accessToken = localStorage.getItem('access_token_hedgedoc');
      this.get('store').findAll('userrewardvar', {}).then(rewards => {
        var rewards = rewards.firstObject.reward_vars || {};
        console.log('rewards', rewards);
        var token = that.get('session.data.authenticated.access_token');
        console.log('token', token);
        var userId = this.session.get('data.userId');
        console.log('userId', userId);
        rewards.TR_ClueCalmers_completed = rewards.TR_ClueCalmers_completed + 1;
        var data_to_api = {
          "user": userId,
          "reward_vars": rewards
        };
        // $.ajax({
        //     url: ENV.APP.API_HOST + '/userrewardvars',
        //     headers: {
        //         Authorization: 'Bearer ' + token
        //     },
        //     type: 'POST',
        //     data: data_to_api,
        //     success: function (res) {
        //         console.log('success', res);
        //     },
        //     error: function (err, xH) {
        //         console.log('err', err.responseText);
        //     }
        // });
      });
    },

    getLatestVideo: function (folder, access_token) {
      var that = this;
      $.ajax({
        url: 'https://api.vimeo.com' + folder.uri + '/videos',
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + access_token
        },
        success: function (res) {
          if (res.data && res.data.length) {
            that.set('videos.' + folder.name, res.data[0]);
            console.log(that.get('videos'));
          }
        }
      });
    },
    getVideos: function (access_token) {
      var that = this;
      $.ajax({
        "url": "https://api.vimeo.com/me/projects",
        "method": "GET",
        "headers": {
          Authorization: 'Bearer ' + access_token
        },
        success: function (res) {
          console.log(res);
          var data = res.data;
          if (data && data.length) {
            data.map(function (row) {
              if (that.get('foldersToSearch').indexOf(row.name) > -1) {
                that.getLatestVideo(row, access_token);
              }
            });
          }
        }
      });
    },
    classNames: ['css-framework-fancy-class'],
    targetAttachment: "none",
    isShowingModal: true,
    participantId: 0,
    header: {
      isHeader: true
    },
    footer: {
      isFooter: true
    },
    body: {
      isBody: true
    },
    form1: {
      isForm1: true
    },
    form2: {
      isForm2: true
    },
    actions: {
      previewModule: function (moduleId) {
        this.transitionToRoute('modulePreview', moduleId);
      }
    }
  });
  _exports.default = _default;
});