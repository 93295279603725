define("momentumhub/controllers/participant-details", ["exports", "momentumhub/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    reports: null,
    userId: null,
    userMessage: null,
    toast: Ember.inject.service(),
    session: Ember.inject.service(),
    sentMessages: null,
    getReports: function () {
      var that = this;
      this.store.query('user-report', {
        user_id: this.get('userId')
      }).then(reports => {
        that.set('reports', reports);
      });
    },
    getMessages: function () {
      var userId = this.get('userId');
      var currentUser = this.get('session.data.userId');
      var that = this;
      this.store.query('notification', {
        participant_lookup: userId,
        actor_object_id: currentUser
      }).then(function (notifications) {
        that.set('sentMessages', notifications);
      });
    },
    newAlert: function (message, title, type) {
      if (type === "Success") {
        this.toast.success(message, title, {
          "closeButton": false,
          "debug": false,
          "newestOnTop": false,
          "progressBar": false,
          "positionClass": "toast-top-right",
          "preventDuplicates": false,
          "onclick": null,
          "showDuration": "300",
          "hideDuration": "1000",
          "timeOut": "5000",
          "extendedTimeOut": "1000",
          "showEasing": "swing",
          "hideEasing": "linear",
          "showMethod": "fadeIn",
          "hideMethod": "fadeOut"
        });
      } else if (type === "Failure") {
        this.toast.error(message, title, {
          "closeButton": false,
          "debug": false,
          "newestOnTop": false,
          "progressBar": false,
          "positionClass": "toast-top-right",
          "preventDuplicates": false,
          "onclick": null,
          "showDuration": "300",
          "hideDuration": "1000",
          "timeOut": "5000",
          "extendedTimeOut": "1000",
          "showEasing": "swing",
          "hideEasing": "linear",
          "showMethod": "fadeIn",
          "hideMethod": "fadeOut"
        });
      } else {
        this.toast.warning(message, title, {
          "closeButton": false,
          "debug": false,
          "newestOnTop": false,
          "progressBar": false,
          "positionClass": "toast-top-right",
          "preventDuplicates": false,
          "onclick": null,
          "showDuration": "300",
          "hideDuration": "1000",
          "timeOut": "5000",
          "extendedTimeOut": "1000",
          "showEasing": "swing",
          "hideEasing": "linear",
          "showMethod": "fadeIn",
          "hideMethod": "fadeOut"
        });
      }
    },
    actions: {
      downloadReport: function (report) {
        var reportUrl = '/reports/user' + report.user + '/' + report.report_type_display.toLowerCase() + '/' + report.id + '/' + 'Article.pdf';
        window.open(reportUrl);
      },
      viewReport: function (report) {
        var reportUrl = '/reports/user' + report.user + '/' + report.report_type_display.toLowerCase() + '/' + report.id + '/' + 'index.html';
        window.open(reportUrl);
      },
      sendMessage: function () {
        var message = this.get('userMessage');
        var receiver = this.get('model.user.id');
        var that = this;
        var data = {
          'message': message,
          'send_to_all': false,
          'users': [receiver],
          'message_types': 'email',
          'notification_settings': 'post_and_notify'
        };
        var accessToken = this.get('session.data.authenticated.access_token');
        $.ajax({
          url: _environment.default.APP.API_HOST + '/messages',
          type: 'POST',
          data: JSON.stringify({
            'message': data
          }),
          headers: {
            "Authorization": "Bearer " + accessToken,
            "Content-Type": "application/json"
          },
          success: function (res) {
            that.newAlert("Message sent successfully.", "Message", "Success");
            that.set('userMessage', null);
            setTimeout(function () {
              that.getMessages();
            }, 2000);
          },
          error: function (err, xH) {
            var str = err.responseText;
            str = str.split(':["').pop();
            str = str.split('"]')[0];
            that.newAlert(str, "Message", "Failure");
          }
        });
      }
    }
  });
  _exports.default = _default;
});