define("momentumhub/models/studyuser", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberData.default.Model.extend({
    study: _emberData.default.belongsTo('study'),
    user: _emberData.default.belongsTo('user'),
    user_study_part_json: _emberData.default.attr(),
    current_phase: _emberData.default.attr(),
    current_node: _emberData.default.attr(),
    current_module_page: _emberData.default.attr(),
    get_activity_results_overall: _emberData.default.attr(),
    get_activity_results_first_survey_results_only: _emberData.default.attr(),
    get_activity_results_last_survey_results_only: _emberData.default.attr(),
    get_activity_results_avg_survey_results_only: _emberData.default.attr(),
    completed: _emberData.default.attr(),
    current_study: _emberData.default.attr('boolean'),
    current_progress: _emberData.default.attr(),
    current_progress_error: _emberData.default.attr(),
    required_module_id: _emberData.default.attr(),
    is_onboarding: _emberData.default.attr('boolean'),
    study_start_time: _emberData.default.attr(),
    study_end_time: _emberData.default.attr(),
    enrollement_time: _emberData.default.attr(),
    part_timestamp_json: _emberData.default.attr()
  });
  _exports.default = _default;
});