define("momentumhub/models/rule", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberData.default.Model.extend({
    name: _emberData.default.attr(),
    trigger: _emberData.default.attr(),
    trigger_frequency: _emberData.default.attr(),
    logic: _emberData.default.attr(),
    trigger_blockly_xml: _emberData.default.attr(),
    events: _emberData.default.attr(),
    repetition: _emberData.default.attr(),
    rule_trigger_timestamp: _emberData.default.attr(),
    is_time_based: _emberData.default.attr('boolean'),
    identifier: _emberData.default.attr(),
    max_number_of_triggers: _emberData.default.attr(),
    tp1: _emberData.default.attr(),
    tp2: _emberData.default.attr(),
    interval_days: _emberData.default.attr(),
    interval_hours: _emberData.default.attr(),
    always_execute: _emberData.default.attr('boolean'),
    is_branching_rule: _emberData.default.attr('boolean')
  });
  _exports.default = _default;
});