define("momentumhub/constants/notification-settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    label: "Post and Notify",
    value: "post_and_notify"
  }, {
    label: "Full Message Text",
    value: "full_message_text"
  }, {
    label: "Notify Only",
    value: "notify_only"
  }, {
    label: "Full Message to SMS/Email Only",
    value: "notify_full_message"
  }];
  _exports.default = _default;
});