define("momentumhub/templates/usermanagement", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "AVrxLMzi",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,0],[\"Usermanagement\"],null]],[2,\"\\n\"],[10,\"div\"],[14,0,\"container  user_management_component_container\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"user_management_component\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"left\"],[12],[2,\"\\n\"],[6,[37,2],[[35,1]],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[8,\"link-to\",[],[[\"@route\"],[\"usermanagement.users\"]],[[\"default\"],[{\"statements\":[[2,\"Users\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,2],[[35,3]],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[8,\"link-to\",[],[[\"@route\"],[\"usermanagement.groups\"]],[[\"default\"],[{\"statements\":[[2,\"Groups\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,2],[[35,4]],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[8,\"link-to\",[],[[\"@route\"],[\"usermanagement.sites\"]],[[\"default\"],[{\"statements\":[[2,\"Sites\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,2],[[35,5]],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[8,\"link-to\",[],[[\"@route\"],[\"usermanagement.studies\"]],[[\"default\"],[{\"statements\":[[2,\"Studies\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,2],[[35,6]],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[8,\"link-to\",[],[[\"@route\"],[\"usermanagement.links\"]],[[\"default\"],[{\"statements\":[[2,\"Links\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"        \"],[13],[2,\"\\n        \"],[10,\"div\"],[14,0,\"right\"],[12],[2,\"\\n            \"],[1,[30,[36,8],[[30,[36,7],null,null]],null]],[2,\"\\n        \"],[13],[2,\"\\n\\n    \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"page-title\",\"showUserRoute\",\"if\",\"showGroupRoute\",\"showSiteRoute\",\"showStudyRoute\",\"showLinksRoute\",\"-outlet\",\"component\"]}",
    "moduleName": "momentumhub/templates/usermanagement.hbs"
  });
  _exports.default = _default;
});