define("momentumhub/routes/login", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    session: Ember.inject.service(),
    router: Ember.inject.service(),
    activate: function () {
      this._super();
      window.scrollTo(0, 0);
    },
    actions: {
      refreshCurrentRoute() {
        this.refresh();
      }
    },
    setupController(controller, model) {
      setTimeout(function () {
        $('.hover_bkgr_fricc').show();
        $(".hover_bkgr_fricc").css({
          "backgroundColor": "white"
        });
        $('.close').hide();
      }, 0);
    }
  });
  _exports.default = _default;
});