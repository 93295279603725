define("momentumhub/controllers/usermanagement", ["exports", "momentumhub/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    ajax: Ember.inject.service(),
    toast: Ember.inject.service(),
    session: Ember.inject.service(),
    users: [],
    //Permissions
    showUserRoute: false,
    showGroupRoute: false,
    showSiteRoute: false,
    showStudyRoute: false,
    showLinksRoute: false,
    actions: {
      createUser: function () {
        $('.hover_bkgr_fricc-user-comp').show();
      }
    },
    //********* Get users **********/
    getUsers: function () {
      var that = this;
      this.store.query('user', {
        page: 1
      }).then(function (users) {
        that.set('users', users);
      });
    }
  });
  _exports.default = _default;
});