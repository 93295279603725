define("momentumhub/components/rule-assets", ["exports", "momentumhub/config/environment", "jquery", "blockly", "momentumhub/constants/notification-settings"], function (_exports, _environment, _jquery, _blockly, _notificationSettings) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    toast: Ember.inject.service(),
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    inhours: 0,
    notificationSettings: _notificationSettings.default,
    selectedNotifcationSetting: 'post_and_notify',
    triggerConditions: [{
      "id": "1",
      "trigger_type": "part : start",
      "value": "PST"
    }, {
      "id": "2",
      "trigger_type": "part : end",
      "value": "PEN"
    }, {
      "id": "3",
      "trigger_type": "module : start",
      "value": "MST"
    }, {
      "id": "4",
      "trigger_type": "module : end",
      "value": "MED"
    }, {
      "id": "5",
      "trigger_type": "survey : start",
      "value": "SST"
    }, {
      "id": "6",
      "trigger_type": "survey : end",
      "value": "SEN"
    }, {
      "id": "7",
      "trigger_type": "login",
      "value": "LI"
    }, {
      "id": "8",
      "trigger_type": "logout",
      "value": "LO"
    }],
    moduleTriggerConditions: [{
      "id": "3",
      "trigger_type": "module : start",
      "value": "MST"
    }, {
      "id": "4",
      "trigger_type": "module : end",
      "value": "MED"
    }],
    surveyTriggerConditions: [{
      "id": "5",
      "trigger_type": "survey : start",
      "value": "SST"
    }, {
      "id": "6",
      "trigger_type": "survey : end",
      "value": "SEN"
    }],
    ssenderReciever: [{
      "id": "1",
      "type": "Guardian",
      "value": "PR"
    }, {
      "id": "2",
      "type": "Researcher",
      "value": "RS"
    }, {
      "id": "2",
      "type": "Creator",
      "value": "CR"
    }, {
      "id": "2",
      "type": "Clinician",
      "value": "CL"
    }, {
      "id": "2",
      "type": "Admin",
      "value": "AD"
    }, {
      "id": "2",
      "type": "Participant",
      "value": "PT"
    }],
    ssenderSender: [{
      "id": "1",
      "type": "Researcher",
      "value": "RS"
    }, {
      "id": "2",
      "type": "Momentum Hub",
      "value": "MH"
    }],
    triggerFrequency: [{
      "id": "1",
      "trigger_type": "hourly",
      "value": "HR"
    }, {
      "id": "2",
      "trigger_type": "daily",
      "value": "DL"
    }, {
      "id": "3",
      "trigger_type": "weekely",
      "value": "WK"
    }],
    BranchStart: [{
      "id": "4",
      "trigger_type": "module : end",
      "value": "MED"
    }, {
      "id": "6",
      "trigger_type": "survey : end",
      "value": "SEN"
    }],
    timePoint: [{
      "id": 1,
      "value": "EN",
      "name": "Enrollment"
    }, {
      "id": 2,
      "value": "SS",
      "name": "Study Start"
    }, {
      "id": 3,
      "value": "SE",
      "name": "Study End"
    }, {
      "id": 4,
      "value": "PS",
      "name": "Part Start"
    }, {
      "id": 5,
      "value": "PE",
      "name": "Part End"
    }, {
      "id": 6,
      "value": "NS",
      "name": "Node Start"
    }, {
      "id": 7,
      "value": "NE",
      "name": "Node End"
    }],
    selectedTimePoint: "",
    selectedTimePoint_2: "",
    delivery_type: [{
      "id": "EM",
      "name": "Email"
    }, {
      "id": "SM",
      "name": "Sms"
    }],
    ruleChoiceSelected: "",
    rewardOperation: 'selin',
    createEvent: false,
    events: [{
      "id": "1",
      "event_type": "Message"
    }, {
      "id": "2",
      "event_type": "Alert"
    }, {
      "id": "3",
      "event_type": "Tracking var"
    }, {
      "id": "4",
      "event_type": "Optional Module"
    }, {
      "id": "6",
      "event_type": "Reports"
    }],
    eventType: 0,
    showBlockly: false,
    workspace: null,
    studyVariables: [],
    modules: [],
    attributeBindings: ['draggable'],
    draggable: 'true',
    eventAction: "",
    currentEvents: [],
    rule: "",
    xmlText: "",
    logicCode: "",
    existingRule: [],
    //On rule node click
    createRuleForModuleSurvey: "",
    selectedNodeId: "",
    selectedRuleType: "",
    childNodeIds: [],
    istimebasedevent: false,
    isTimeBaseRule: false,
    studyId: "",
    study_node_ids: [],
    study_name: "",
    updateEvents: false,
    selectedEvent: [],
    max_number_of_triggers: 1,
    delay_in_hours: 0,
    study_strucure: [],
    firstTimePointPhase: [],
    secondTimePointPhase: [],
    firstTimePointNodes: [],
    secondTimePointNodes: [],
    timeBasedVariable_tp1: "",
    timeBasedVariable_tp2: "",
    tp1Json: {},
    tp2Json: {},
    interval_hours: 0,
    interval_days: 0,
    alwaysExecute: false,
    didReceiveAttrs() {
      this._super(...arguments);
      var that = this;
      setTimeout(function () {
        that.resetOptions();
        that.set('currentEvents', []);
        that.set('xmlText', "");
        if (that.ruleChoiceSelected == "time") {
          that.set('isTimeBaseRule', true);
        }
        if (that.ruleChoiceSelected == "simple") {
          that.set('isTimeBaseRule', false);
        }
        if (that.existingRule.length != 0) {
          that.setExistingRule();
        } else {
          that.getEvents(that.rule);
          that.setTriggerPoint();
          that.set('isBranchingRule', that.get('selectedRuleType') === 'branchRule');
        }
        that.createchildNodeIdEvents();
      }, 100);
      this.set('timeBasedVariable_tp1', "");
      this.set('timeBasedVariable_tp2', "");
      this.set('tp1Json', {});
      this.set('tp2Json', {});
    },
    resetOptions: function () {
      this.set('rule_delievery_date', "");
      this.set('rule_delievery_time', "");
      this.set('istimebasedevent', false);
      this.set('isTimeBaseRule', false);
      (0, _jquery.default)("#timebaseEvent").prop('selectedIndex', 0);
      (0, _jquery.default)('select option:contains("Select Trigger Point")').prop('selected', true);
      this.send('closeupdateEvent');
    },
    setTriggerPoint: function () {
      var createRuleForModuleSurvey = this.createRuleForModuleSurvey;
      var selectedRuleType = this.selectedRuleType;
      if (createRuleForModuleSurvey == "module") {
        if (selectedRuleType == "rule1") {
          (0, _jquery.default)('select option:contains("module : start")').prop('selected', true);
        }
        if (selectedRuleType == "rule2") {
          (0, _jquery.default)('select option:contains("module : end")').prop('selected', true);
        }
      }
      if (createRuleForModuleSurvey == "survey") {
        if (selectedRuleType == "rule1") {
          (0, _jquery.default)('select option:contains("survey : start")').prop('selected', true);
        }
        if (selectedRuleType == "rule2") {
          (0, _jquery.default)('select option:contains("survey : end")').prop('selected', true);
        }
      }
    },
    validateEvent: async function (eventName, eventAction) {
      if (eventName && !eventName.startsWith('event_')) {
        alert('Event name should start with prefix "event_"');
        return false;
      }
      if (eventAction == 'RP') {
        var existingEvent = await _jquery.default.ajax({
          url: _environment.default.APP.API_HOST + '/events?name__iexact=' + eventName
        });
        if (existingEvent && existingEvent.event && existingEvent.event.length > 0) {
          alert('Report event with name "' + eventName + '" already exists');
          return false;
        }
      }
      return true;
    },
    //Action
    actions: {
      setAlwaysExecute: function (e) {
        console.log(e);
      },
      //Close assets component
      close: function () {
        (0, _jquery.default)('.close').hide();
        (0, _jquery.default)('.inner-component1').hide();
        (0, _jquery.default)('.rule-assets').css({
          padding: '0px'
        }, 300);
        (0, _jquery.default)('.rule-assets').animate({
          width: '0px'
        }, 300);
        this.set('createRuleNotSelected', true);
        this.set('dateSelected', false);
        this.set('participantSelected', false);
        this.set('studySelected', false);
        this.set('moduleSelected', false);
        this.set('engagementSelected', false);
        this.set('selectedInputs', []);
        this.set('childNodeIds', []);
      },
      addCloseEvent: function () {
        if (this.createEvent) {
          this.set('createEvent', false);
          this.set('eventType', 0);
        } else {
          this.set('createEvent', true);
        }
      },
      eventSelected: function () {
        var e = document.getElementById("event-selector");
        var eventId = e.options[e.selectedIndex].value;
        if (eventId == 1) {
          this.set('eventType', 1);
          this.set('eventAction', "MG");
        } else if (eventId == 2) {
          this.set('eventType', 1);
          this.set('eventAction', "AL");
        } else if (eventId == 3) {
          this.set('eventType', 2);
          this.set('eventAction', "RW");
        } else if (eventId == 4) {
          this.set('eventType', 5);
          this.set('eventAction', "OM");
        } else if (eventId == 5) {
          this.set('eventType', 5);
          this.set('eventAction', "RM");
        } else if (eventId == 6) {
          this.set('eventType', 6);
          this.set('eventAction', 'RP');
        }
      },
      setTimePoint: function (type) {
        const that = this;
        if (type == "one") {
          const e = document.getElementById("time_point");
          const time_point = e.options[e.selectedIndex].value;
          this.set('selectedTimePoint', time_point);
          if (time_point == "1") {
            that.set('timeBasedVariable_tp1', "enrollment_time");
          }
          if (time_point == "2") {
            that.set('timeBasedVariable_tp1', "study_start_time");
          }
          if (time_point == "3") {
            that.set('timeBasedVariable_tp1', "study_end_time");
          }
          if (time_point == "1" || time_point == "2" || time_point == "3" || time_point == "6" || time_point == "4") {
            this.tp1Json["time_point"] = time_point;
            this.tp1Json["selectedTimePoint"] = time_point;
          }
          if (time_point == "1" || time_point == "2" || time_point == "3") {
            this.tp1Json["arm"] = "";
            this.tp1Json["node"] = "";
            this.tp1Json["phase"] = "";
            this.tp1Json["start_end"] = "";
          }
          if (time_point == "7") {
            that.set('selectedTimePoint', "6");
            this.tp1Json["time_point"] = time_point;
            this.tp1Json["selectedTimePoint"] = 6;
          }
          if (time_point == "5") {
            that.set('selectedTimePoint', "4");
            this.tp1Json["time_point"] = time_point;
            this.tp1Json["selectedTimePoint"] = 4;
          }
          if (time_point == "4" || time_point == "6") {
            this.tp1Json["start_end"] = "start";
          }
          if (time_point == "5" || time_point == "7") {
            this.tp1Json["start_end"] = "end";
          }
          setTimeout(function () {
            if (time_point == "6") {
              (0, _jquery.default)('#first_time_point_study_start_end').prop('selectedIndex', 1);
            }
            if (time_point == "7") {
              (0, _jquery.default)('#first_time_point_study_start_end').prop('selectedIndex', 2);
            }
            if (time_point == "4") {
              (0, _jquery.default)('#first_time_point_study_start_end').prop('selectedIndex', 1);
            }
            if (time_point == "5") {
              (0, _jquery.default)('#first_time_point_study_start_end').prop('selectedIndex', 2);
            }
          }, 100);
        } else {
          const e = document.getElementById("time_point_2");
          const time_point = e.options[e.selectedIndex].value;
          this.set('selectedTimePoint_2', time_point);
          if (time_point == "1") {
            that.set('timeBasedVariable_tp2', "enrollment_time");
          }
          if (time_point == "2") {
            that.set('timeBasedVariable_tp2', "study_start_time");
          }
          if (time_point == "3") {
            that.set('timeBasedVariable_tp2', "study_end_time");
          }
          if (time_point == "7") {
            that.set('selectedTimePoint_2', "6");
            this.tp2Json["time_point"] = time_point;
            this.tp2Json["selectedTimePoint"] = 6;
          }
          if (time_point == "5") {
            that.set('selectedTimePoint_2', "4");
            this.tp2Json["time_point"] = time_point;
            this.tp2Json["selectedTimePoint"] = 4;
          }
          if (time_point == "1" || time_point == "2" || time_point == "3" || time_point == "6" || time_point == "4") {
            this.tp2Json["time_point"] = time_point;
            this.tp2Json["selectedTimePoint"] = time_point;
          }
          if (time_point == "1" || time_point == "2" || time_point == "3") {
            this.tp2Json["arm"] = "";
            this.tp2Json["node"] = "";
            this.tp2Json["phase"] = "";
            this.tp2Json["start_end"] = "";
          }
          if (time_point == "4" || time_point == "6") {
            this.tp2Json["start_end"] = "start";
          }
          if (time_point == "5" || time_point == "7") {
            this.tp2Json["start_end"] = "end";
          }
          setTimeout(function () {
            if (time_point == "6") {
              (0, _jquery.default)('#second_time_point_study_start_end').prop('selectedIndex', 1);
            }
            if (time_point == "7") {
              (0, _jquery.default)('#second_time_point_study_start_end').prop('selectedIndex', 2);
            }
            if (time_point == "4") {
              (0, _jquery.default)('#second_time_point_study_start_end').prop('selectedIndex', 1);
            }
            if (time_point == "5") {
              (0, _jquery.default)('#second_time_point_study_start_end').prop('selectedIndex', 2);
            }
          }, 100);
        }
      },
      set_to_last_login_change: function () {
        const set_to_last_login_change = (0, _jquery.default)('#set_to_last_login').is(':checked');
        if (set_to_last_login_change) {
          (0, _jquery.default)("#first_time_point_study_arm").prop("disabled", true);
          (0, _jquery.default)("#first_time_point_study_phase").prop("disabled", true);
          (0, _jquery.default)("#first_time_point_study_nodes").prop("disabled", true);
          (0, _jquery.default)('#first_time_point_study_start_end').prop("disabled", true);
          (0, _jquery.default)('#time_point').prop("disabled", true);
          (0, _jquery.default)('#first_time_point_study_arm').prop('selectedIndex', 0);
          (0, _jquery.default)('#first_time_point_study_phase').prop('selectedIndex', 0);
          (0, _jquery.default)('#first_time_point_study_nodes').prop('selectedIndex', 0);
          this.tp1Json["last_login"] = true;
          this.set('timeBasedVariable_tp1', "GL_last_login");
        } else {
          (0, _jquery.default)('#time_point').prop("disabled", false);
          (0, _jquery.default)("#first_time_point_study_arm").prop("disabled", false);
          this.tp1Json["last_login"] = false;
        }
      },
      set_to_current_time_change: function () {
        const set_to_current_time_change = (0, _jquery.default)('#set_to_current_time').is(':checked');
        if (set_to_current_time_change) {
          (0, _jquery.default)("#second_time_point_study_arm").prop("disabled", true);
          (0, _jquery.default)("#second_time_point_study_phase").prop("disabled", true);
          (0, _jquery.default)("#second_time_point_study_nodes").prop("disabled", true);
          (0, _jquery.default)('#second_time_point_study_start_end').prop("disabled", true);
          (0, _jquery.default)('#time_point_2').prop("disabled", true);
          (0, _jquery.default)('#second_time_point_study_arm').prop('selectedIndex', 0);
          (0, _jquery.default)('#second_time_point_study_phase').prop('selectedIndex', 0);
          (0, _jquery.default)('#second_time_point_study_nodes').prop('selectedIndex', 0);
          this.tp2Json["current_time_change"] = true;
          this.set('timeBasedVariable_tp2', "currentTime");
        } else {
          (0, _jquery.default)('#time_point_2').prop("disabled", false);
          (0, _jquery.default)("#second_time_point_study_arm").prop("disabled", false);
          this.tp2Json["current_time_change"] = false;
        }
      },
      armSelected: function (type) {
        const that = this;
        if (type == "first") {
          const e = document.getElementById("first_time_point_study_arm");
          const first_time_point_study_arm = e.options[e.selectedIndex].value;
          if (first_time_point_study_arm == "Initial") {
            const initial_part_id = this.initial_study_part;
            this.store.findRecord('part', initial_part_id).then(function (part) {
              const identifier = part.get('identifier');
              const phase = {
                "id": identifier,
                "phase": 1,
                "partId": identifier,
                "created": true
              };
              that.set('firstTimePointPhase', [phase]);
            });
          } else {
            const phase = this.study_strucure[first_time_point_study_arm - 1].phase;
            this.set('firstTimePointPhase', phase);
          }
          (0, _jquery.default)('#first_time_point_study_phase').prop('selectedIndex', 0);
          (0, _jquery.default)('#first_time_point_study_nodes').prop('selectedIndex', 0);
          // $('#first_time_point_study_start_end').prop('selectedIndex', 0);
          (0, _jquery.default)("#first_time_point_study_phase").prop("disabled", false);
          (0, _jquery.default)("#first_time_point_study_nodes").prop("disabled", true);
          this.tp1Json["arm"] = first_time_point_study_arm;
        } else {
          const e = document.getElementById("second_time_point_study_arm");
          const second_time_point_study_arm = e.options[e.selectedIndex].value;
          if (second_time_point_study_arm == "Initial") {
            const initial_part_id = this.initial_study_part;
            this.store.findRecord('part', initial_part_id).then(function (part) {
              const identifier = part.get('identifier');
              const phase = {
                "id": identifier,
                "phase": 1,
                "partId": identifier,
                "created": true
              };
              that.set('secondTimePointPhase', [phase]);
            });
          } else {
            const phase = this.study_strucure[second_time_point_study_arm - 1].phase;
            this.set('secondTimePointPhase', phase);
          }
          (0, _jquery.default)('#second_time_point_study_phase').prop('selectedIndex', 0);
          (0, _jquery.default)('#second_time_point_study_nodes').prop('selectedIndex', 0);
          // $('#second_time_point_study_start_end').prop('selectedIndex', 0);
          (0, _jquery.default)("#second_time_point_study_phase").prop("disabled", false);
          (0, _jquery.default)("#second_time_point_study_nodes").prop("disabled", true);
          this.tp2Json["arm"] = second_time_point_study_arm;
        }
      },
      phaseSelected: function (type) {
        const selectedTimePoint = this.selectedTimePoint;
        const selectedTimePoint_2 = this.selectedTimePoint_2;
        if (type == "first") {
          if (selectedTimePoint == "6") {
            const a = document.getElementById("first_time_point_study_arm");
            const first_time_point_study_arm = a.options[a.selectedIndex].value;
            const e = document.getElementById("first_time_point_study_phase");
            const first_time_point_study_phase = e.options[e.selectedIndex].value;
            if (first_time_point_study_arm == "Initial") {
              const partId = e.options[e.selectedIndex].id;
              this.send('getNodes', partId, "first");
              this.tp1Json["phase"] = 1;
            } else {
              const partId = this.study_strucure[first_time_point_study_arm - 1].phase[first_time_point_study_phase - 1].partId;
              this.send('getNodes', partId, "first");
              this.tp1Json["phase"] = this.study_strucure[first_time_point_study_arm - 1].phase[first_time_point_study_phase - 1].phase;
            }
            (0, _jquery.default)('#first_time_point_study_nodes').prop('selectedIndex', 0);
            // $('#first_time_point_study_start_end').prop('selectedIndex', 0);
            (0, _jquery.default)("#first_time_point_study_nodes").prop("disabled", false);
          }
          if (selectedTimePoint == "4") {
            const a = document.getElementById("first_time_point_study_phase");
            const c = document.getElementById("first_time_point_study_start_end");
            const first_time_point_study_phase = a.options[a.selectedIndex].id;
            const first_time_point_study_phase_id = a.options[a.selectedIndex].value;
            const first_time_point_study_start_end = c.options[c.selectedIndex].value;
            const variable = first_time_point_study_phase + "_" + first_time_point_study_start_end + "_timestamp";
            this.set('timeBasedVariable_tp1', variable);
            this.tp1Json["phase"] = first_time_point_study_phase_id;
          }
        } else {
          if (selectedTimePoint_2 == "6") {
            const a = document.getElementById("second_time_point_study_arm");
            const second_time_point_study_arm = a.options[a.selectedIndex].value;
            const e = document.getElementById("second_time_point_study_phase");
            const second_time_point_study_phase = e.options[e.selectedIndex].value;
            if (second_time_point_study_arm == "Initial") {
              const partId = e.options[e.selectedIndex].id;
              this.send('getNodes', partId, "second");
            } else {
              const partId = this.study_strucure[second_time_point_study_arm - 1].phase[second_time_point_study_phase - 1].partId;
              this.send('getNodes', partId, "second");
            }
            (0, _jquery.default)('#second_time_point_study_nodes').prop('selectedIndex', 0);
            // $('#second_time_point_study_start_end').prop('selectedIndex', 0);
            (0, _jquery.default)("#second_time_point_study_nodes").prop("disabled", false);
            this.tp2Json["phase"] = second_time_point_study_phase;
          }
          if (selectedTimePoint_2 == "4") {
            const a = document.getElementById("second_time_point_study_phase");
            const c = document.getElementById("second_time_point_study_start_end");
            const second_time_point_study_phase = a.options[a.selectedIndex].id;
            const second_time_point_study_phase_id = a.options[a.selectedIndex].value;
            const second_time_point_study_start_end = c.options[c.selectedIndex].value;
            const variable = second_time_point_study_phase + "_" + second_time_point_study_start_end + "_timestamp";
            this.set('timeBasedVariable_tp2', variable);
            this.tp2Json["phase"] = second_time_point_study_phase_id;
          }
        }
        // this.savetime_based_rule_vars_track()
      },

      nodeSelected: function (type) {
        if (type == "first") {
          // $('#first_time_point_study_start_end').prop('selectedIndex', 0);
          // $('#first_time_point_study_start_end').prop("disabled", false);
          const a = document.getElementById("first_time_point_study_nodes");
          const c = document.getElementById("first_time_point_study_start_end");
          const first_time_point_study_nodes = a.options[a.selectedIndex].value;
          const first_time_point_study_nodes_name = a.options[a.selectedIndex].id;
          const first_time_point_study_start_end = c.options[c.selectedIndex].value;
          const variable = first_time_point_study_nodes + "_" + first_time_point_study_start_end;
          this.set('timeBasedVariable_tp1', variable);
          var s1 = first_time_point_study_nodes_name;
          var s2 = s1.substring(1);
          s2 = parseInt(s2) + 1;
          this.tp1Json["node"] = s2;
          this.tp1Json["start_end"] = first_time_point_study_start_end;
        } else {
          // $('#second_time_point_study_start_end').prop('selectedIndex', 0);
          // $('#second_time_point_study_start_end').prop("disabled", false);
          const a = document.getElementById("second_time_point_study_nodes");
          const c = document.getElementById("second_time_point_study_start_end");
          const second_time_point_study_nodes = a.options[a.selectedIndex].value;
          const second_time_point_study_nodes_name = a.options[a.selectedIndex].id;
          const second_time_point_study_start_end = c.options[c.selectedIndex].value;
          const variable = second_time_point_study_nodes + "_" + second_time_point_study_start_end;
          this.set('timeBasedVariable_tp2', variable);
          var s11 = second_time_point_study_nodes_name;
          var s22 = s11.substring(1);
          s22 = parseInt(s22) + 1;
          this.tp2Json["node"] = s22;
          this.tp2Json["start_end"] = second_time_point_study_start_end;
        }
        this.savetime_based_rule_vars_track();
      },
      firstEndSelected: function (type) {
        if (type == "first") {
          const a = document.getElementById("first_time_point_study_nodes");
          const c = document.getElementById("first_time_point_study_start_end");
          const first_time_point_study_nodes = a.options[a.selectedIndex].value;
          const first_time_point_study_start_end = c.options[c.selectedIndex].value;
          const variable = first_time_point_study_nodes + "_" + first_time_point_study_start_end;
        } else {
          const a = document.getElementById("second_time_point_study_nodes");
          const c = document.getElementById("second_time_point_study_start_end");
          const second_time_point_study_nodes = a.options[a.selectedIndex].value;
          const second_time_point_study_start_end = c.options[c.selectedIndex].value;
          const variable = second_time_point_study_nodes + "_" + second_time_point_study_start_end;
        }
      },
      getNodes: function (identifier, type) {
        const accessToken = this.get('session.data.authenticated.access_token');
        const that = this;
        if (type == "first") {
          that.set('firstTimePointNodes', []);
        } else {
          that.set('secondTimePointNodes', []);
        }
        _jquery.default.ajax({
          url: _environment.default.APP.API_HOST + '/parts?identifier=' + identifier,
          type: 'GET',
          headers: {
            "Authorization": "Bearer " + accessToken,
            "Content-Type": "application/json"
          },
          success: function (res) {
            var parts = res.part;
            if (parts[0]) {
              const part = parts[0];
              const part_json = part.part_json;
              var nodeIds = [];
              if (part_json) {
                part_json.forEach(function (element) {
                  var mainNodeId = element.nodeAlphabet;
                  var nodeid = element.nodeId;
                  const obj = {
                    "nodeAlphabet": mainNodeId,
                    "nodeid": nodeid
                  };
                  nodeIds.pushObject(obj);
                });
                if (type == "first") {
                  that.set("firstTimePointNodes", nodeIds);
                } else {
                  that.set("secondTimePointNodes", nodeIds);
                }
              }
            }
          },
          error: function (err, xH) {
            var errorMessage = err.responseText;
            that.newAlert(errorMessage, "Error", "Failure");
          }
        });
      },
      showBlockly: function () {
        (0, _jquery.default)('.hover_bkgr_fricc-study-blockly').show();
        this.set('showBlockly', true);
      },
      closeBlokly: function (xmlText, logicCode, option) {
        if (option == "close") {
          (0, _jquery.default)('.hover_bkgr_fricc-study-blockly').hide();
        }
        this.set('xmlText', xmlText);
        if (logicCode) {
          this.set('logicCode', logicCode);
        }
        this.set('showBlockly', false);
        var that = this;
        setTimeout(function () {
          that.addBlockely(xmlText);
        }, 100);
      },
      setEvent: function (event) {
        const that = this;
        this.set('updateEvents', true);
        this.set('selectedEvent', event);
        this.set('eventName', event.name);
        this.set('rewardOperation', event.reward_operation);
        if (event.delay_in_hours) {
          this.set('delay_in_hours', event.delay_in_hours);
        }
        this.set('eventAction', event.action);
        if (event.action == "MG") {
          (0, _jquery.default)("#event-selector").prop('selectedIndex', 1);
          this.set('eventType', 1);
          this.set("message", event.message);
          setTimeout(function () {
            console.log(event.notification_settings);
            that.set('selectedNotificationSetting', event.notification_settings);
            if (event.delivery_type == "EM") {
              (0, _jquery.default)("#delivery_type").prop('selectedIndex', 1);
            }
            if (event.delivery_type == "SM") {
              (0, _jquery.default)("#delivery_type").prop('selectedIndex', 2);
            }
            if (event.receiver == "PR") {
              (0, _jquery.default)('#receiver').prop('selectedIndex', 1);
            }
            if (event.receiver == "RS") {
              (0, _jquery.default)('#receiver').prop('selectedIndex', 2);
            }
            if (event.receiver == "CR") {
              (0, _jquery.default)('#receiver').prop('selectedIndex', 3);
            }
            if (event.receiver == "CL") {
              (0, _jquery.default)('#receiver').prop('selectedIndex', 4);
            }
            if (event.receiver == "AD") {
              (0, _jquery.default)('#receiver').prop('selectedIndex', 5);
            }
            if (event.receiver == "PT") {
              (0, _jquery.default)('#receiver').prop('selectedIndex', 6);
            }
            if (event.sender == "PR") {
              (0, _jquery.default)('#sender').prop('selectedIndex', 1);
            }
            if (event.sender == "RS") {
              (0, _jquery.default)('#sender').prop('selectedIndex', 2);
            }
            if (event.sender == "CR") {
              (0, _jquery.default)('#sender').prop('selectedIndex', 3);
            }
            if (event.sender == "CL") {
              (0, _jquery.default)('#sender').prop('selectedIndex', 4);
            }
            if (event.sender == "AD") {
              (0, _jquery.default)('#sender').prop('selectedIndex', 5);
            }
            if (event.sender == "PT") {
              (0, _jquery.default)('#sender').prop('selectedIndex', 6);
            }
          }, 0);
        }
        if (event.action == "AL") {
          (0, _jquery.default)("#event-selector").prop('selectedIndex', 2);
          this.set('eventType', 1);
          this.set("message", event.message);
        }
        if (event.action == "RW") {
          this.set('eventType', 2);
          setTimeout(function () {
            (0, _jquery.default)("#event-selector").prop('selectedIndex', 3);
            if (event.reward_operation == "PL") {
              (0, _jquery.default)('#rewardoperation').prop('selectedIndex', 1);
            }
            if (event.reward_operation == "MN") {
              (0, _jquery.default)('#rewardoperation').prop('selectedIndex', 2);
            }
          }, 0);
          this.set('reward_variable', event.reward_variable);
          this.set('reward_given', event.reward_change);
        }
        if (event.action == "OM") {
          (0, _jquery.default)("#event-selector").prop('selectedIndex', 4);
          this.set('eventType', 5);
          const module_id = event.module_id;
          const module_availability = event.module_availability;
          const is_resource = event.is_resource;
          setTimeout(function () {
            that.store.query('module', {
              identifier: module_id
            }).then(function (modules) {
              if (modules.length > 0) {
                const mod = modules.get('firstObject');
                const moduleTitle = mod.get('name');
                var doc = document.getElementById("optionalModuleText");
                var outerDoc = document.getElementById("dragoptionalmodule");
                doc.innerText = moduleTitle;
                outerDoc.style.backgroundColor = "rgba(250, 206, 3, 0.1)";
                outerDoc.setAttribute('data-id', module_id);
              }
            });
            (0, _jquery.default)('#isModuleRequired').attr('checked', false);
            if (is_resource) {
              (0, _jquery.default)('#isResource').attr('checked', true);
            }
            if (module_availability) {
              (0, _jquery.default)('#isModuleAvailable').attr('checked', true);
            } else {
              (0, _jquery.default)('#isModuleAvailable').attr('checked', false);
            }
          }, 100);
        }
        if (event.action == 'RP') {
          this.set('eventType', 6);
          (0, _jquery.default)("#event-selector").prop('selectedIndex', 5);
          const surveyId = event.survey_id;
          const receiver = event.receiver;
          setTimeout(function () {
            (0, _jquery.default)('#receiver').val(receiver);
            (0, _jquery.default)('#report_type').val(event.report_type);
            (0, _jquery.default)('#dragsurvey').attr('data-id', surveyId);
            that.store.query('survey', {
              identifier: surveyId
            }).then(function (surveys) {
              if (surveys.length > 0) {
                const survey = surveys.get('firstObject');
                const surveyTitle = survey.get('name');
                var doc = document.getElementById("surveyText");
                var outerDoc = document.getElementById("dragsurvey");
                outerDoc.style.backgroundColor = "rgba(250, 206, 3, 0.1)";
                doc.innerText = surveyTitle;
              }
            });
          });
        }
        if (event.action == "RM") {
          (0, _jquery.default)("#event-selector").prop('selectedIndex', 4);
          this.set('eventType', 5);
          this.set('eventAction', "OM");
          const module_id = event.module_id;
          const that = this;
          const module_availability = event.module_availability;
          const is_resource = event.is_resource;
          setTimeout(function () {
            that.store.query('module', {
              identifier: module_id
            }).then(function (modules) {
              if (modules.length > 0) {
                const mod = modules.get('firstObject');
                const moduleTitle = mod.get('name');
                var doc = document.getElementById("optionalModuleText");
                var outerDoc = document.getElementById("dragoptionalmodule");
                doc.innerText = moduleTitle;
                outerDoc.style.backgroundColor = "rgba(250, 206, 3, 0.1)";
                outerDoc.setAttribute('data-id', module_id);
              }
            });
            (0, _jquery.default)('#isModuleRequired').attr('checked', true);
            if (is_resource) {
              (0, _jquery.default)('#isResource').attr('checked', true);
            }
            if (module_availability) {
              (0, _jquery.default)('#isModuleAvailable').attr('checked', true);
            } else {
              (0, _jquery.default)('#isModuleAvailable').attr('checked', false);
            }
          }, 100);
        }
        if (event.delivery_time_after_creation) {
          setTimeout(function () {
            (0, _jquery.default)("#timebaseEvent").prop('selectedIndex', 1);
          }, 0);
          this.set('istimebasedevent', true);
          this.set('numberoftimesent', event.event_fire_frequency);
          this.set('delay', event.days_between_fire_frequency);
          this.set('delievery_time', event.delivery_time_after_creation);
        } else {
          setTimeout(function () {
            (0, _jquery.default)("#timebaseEvent").prop('selectedIndex', 0);
          }, 0);
          this.set('istimebasedevent', false);
          this.set('numberoftimesent', "");
          this.set('delay', "");
          this.set('delievery_time', "");
        }
      },
      closeupdateEvent: function () {
        this.set('updateEvents', false);
        this.set('selectedEvent', []);
        this.set('eventName', "");
        (0, _jquery.default)("#event-selector").prop('selectedIndex', 0);
        this.set('eventAction', "");
        this.set('eventType', 0);
        this.set("message", "");
        (0, _jquery.default)("#delivery_type").prop('selectedIndex', 0);
        (0, _jquery.default)('#receiver').prop('selectedIndex', 0);
        setTimeout(function () {
          (0, _jquery.default)("#timebaseEvent").prop('selectedIndex', 0);
        }, 0);
        this.set('istimebasedevent', false);
        this.set('numberoftimesent', "");
        this.set('delay', "");
        this.set('delievery_time', "");
        (0, _jquery.default)('#rewardoperation').prop('selectedIndex', 0);
        this.set('reward_variable', "");
        this.set('reward_given', "");
      },
      /******************** Create Event ********************* */
      createEvent: async function () {
        var that = this;
        var accessToken = this.get('session.data.authenticated.access_token');
        var eventName = this.get('eventName');
        var eventAction = this.eventAction;
        var e = document.getElementById("receiver");
        var p = document.getElementById("sender");
        var f = document.getElementById('branchNode');
        var l = document.getElementById("delivery_type");
        var b = document.getElementById("rewardoperation");
        var rule = this.rule;
        var message = this.get("message");
        var valid = await this.validateEvent(eventName, eventAction);
        if (!valid) {
          return;
        }
        var receiver = "";
        var sender = "";
        var delivery_type = "";
        var branchNode = "";
        var node_ref = null;
        var optionalModule = null;
        var available = true;
        var isResourceType = false;
        var survey = null;
        var reportType = null;
        var notificationSetting = null;

        // Rewards
        var reward_variable = null;
        var rewardoperation = null;
        var reward_given = null;

        // message Validation
        if (this.eventAction == "MG") {
          delivery_type = l.options[l.selectedIndex].value;
          receiver = e.options[e.selectedIndex].value;
          sender = p.options[p.selectedIndex].value;
          notificationSetting = document.getElementById('notificationSetting').value;
          if (receiver != "selin" && delivery_type != "selin" && sender != "selin") {
            if (message) {
              valid = true;
            }
          }
          if (notificationSetting != 'notify_only' && message.length === 0) {
            valid = false;
          }
        }
        if (this.eventAction == 'RP') {
          survey = (0, _jquery.default)('#dragsurvey').attr('data-id');
          reportType = (0, _jquery.default)('#report_type').val();
          receiver = (0, _jquery.default)('#receiver').val();
          if (receiver && reportType && survey) {
            valid = true;
          }
        }

        // Reward validation

        if (this.eventAction == "RW") {
          reward_variable = this.get('reward_variable');
          if (reward_variable) {
            // AS PER COMMENT WITH FRANK, THIS LOGIC IS NOT REQUIRED
            if (!reward_variable.includes('RW_')) {
              reward_variable = reward_variable;
            }
            rewardoperation = b.options[b.selectedIndex].value;
            reward_given = this.get('reward_given');
            if (reward_variable && rewardoperation != "selin" && reward_given) {
              valid = true;
            }
          }
        }

        // Optional Module
        if (this.eventAction == "OM") {
          optionalModule = (0, _jquery.default)('#dragoptionalmodule').attr("data-id");
          const isModuleRequired = (0, _jquery.default)('#isModuleRequired').is(':checked');
          const isResource = (0, _jquery.default)('#isResource').is(':checked');
          const isModuleAvailable = (0, _jquery.default)('#isModuleAvailable').is(':checked');
          // make is tequired if checkbox = true
          if (isModuleRequired) {
            eventAction = "RM";
          }
          if (isModuleAvailable) {
            available = true;
          }
          if (isResource) {
            isResourceType = true;
          } else {
            available = false;
          }
          if (optionalModule) {
            valid = true;
          }
        }

        // Required Module
        // if (this.eventAction == "RM") {
        //     optionalModule = $('#dragoptionalmodule').attr("data-id");
        //     if (optionalModule) { valid = true }
        // }

        // Alert Validation
        if (that.eventType == 1) {
          if (this.eventAction == "AL") {
            valid = true;
          }
        }
        if (that.eventType == 3) {
          branchNode = f.options[f.selectedIndex].value;
          node_ref = branchNode;
          if (branchNode != "selin123") {
            valid = true;
          }
        }

        // delievery base event
        var istimebasedevent = this.istimebasedevent;
        var numberoftimesent = 1;
        var delay = 0;
        var delivery_time = "IM";
        var delivery_timestamp = null;
        var deliveryTimeStamptimepvalid = true;
        var delay_in_hours = this.get('delay_in_hours');
        var delay_in_hours_valid = true;
        var numberoftimesentValid = true;
        var delayValid = true;
        if (istimebasedevent) {
          delivery_time = "DL";
          numberoftimesent = this.get('numberoftimesent');
          delay = this.get('delay');
          delivery_timestamp = this.get('delievery_time');
          if (!numberoftimesent) {
            numberoftimesentValid = false;
          }
          ;
          if (!delay) {
            delayValid = false;
          }
          ;
          if (!delivery_timestamp) {
            deliveryTimeStamptimepvalid = false;
          }
          ;
          if (delay_in_hours == "") {
            delay_in_hours = 0;
          }
          ;
        }
        if (deliveryTimeStamptimepvalid) {
          if (numberoftimesentValid) {
            if (delayValid) {
              if (eventName) {
                eventName = eventName.replace(/ /g, "_");
                if (eventName.match(/^(?=.*[^\W_])[\w ]*$/)) {
                  if (eventAction) {
                    if (valid) {
                      _jquery.default.ajax({
                        url: _environment.default.APP.API_HOST + '/events',
                        type: 'POST',
                        headers: {
                          "Authorization": "Bearer " + accessToken,
                          "Content-Type": "application/json"
                        },
                        data: JSON.stringify({
                          "event": {
                            "name": eventName,
                            "module_availability": available,
                            "is_resource": isResourceType,
                            "module_id": optionalModule,
                            "receiver": receiver,
                            "sender": sender,
                            "action": eventAction,
                            "rule": rule,
                            "node_ref": node_ref,
                            "delivery_time": delivery_time,
                            "delivery_time_after_creation": delivery_timestamp,
                            "event_fire_frequency": numberoftimesent,
                            "days_between_fire_frequency": delay,
                            "message": message,
                            "delivery_type": delivery_type,
                            "reward_variable": reward_variable,
                            "reward_operation": rewardoperation,
                            "reward_change": reward_given,
                            "delay_in_hours": delay_in_hours,
                            survey_id: survey,
                            report_type: reportType,
                            notification_settings: notificationSetting
                          }
                        }),
                        success: function (res) {
                          var eventId = res.event.id;
                          that.getEvents(rule);
                          that.set('eventName', "");
                          that.set('eventAction', "");
                          that.set('receiver', "");
                          that.set('message', "");
                          that.set('eventType', 0);
                          that.set('istimebasedevent', false);
                          that.set('delievery_date', "");
                          that.set('delievery_time', "");
                          that.set('reward_variable', "");
                          that.set('reward_given', "");
                          (0, _jquery.default)("#event-selector").prop('selectedIndex', 0);
                          (0, _jquery.default)("#receiver").prop('selectedIndex', 0);
                          (0, _jquery.default)("#sender").prop('selectedIndex', 0);
                          (0, _jquery.default)("#selector").prop('selectedIndex', 0);
                          (0, _jquery.default)("#branchNode").prop('selectedIndex', 0);
                          (0, _jquery.default)("#delivery_type").prop('selectedIndex', 0);
                          (0, _jquery.default)("#timebaseEvent").prop('selectedIndex', 0);
                          (0, _jquery.default)("#rewardoperation").prop('selectedIndex', 0);
                          (0, _jquery.default)("#report_type").prop('selectedIndex', 0);
                          (0, _jquery.default)('#isModuleRequired').attr('checked', false);
                          (0, _jquery.default)('#isResource').attr('checked', false);
                          (0, _jquery.default)('#isModuleAvailable').attr('checked', false);
                        },
                        error: function (err, xH) {
                          var errorMessage = err.responseText;
                          that.newAlert(errorMessage, "Error", "Failure");
                        }
                      });
                    } else {
                      if (that.eventType == 1) {
                        if (receiver == "selin") {
                          this.showError('receiver');
                        }
                        if (!message || message.length === 0) {
                          this.showError('message');
                        }
                      }
                      if (that.eventType == 2) {
                        if (!reward_variable) {
                          this.showError('reward_variable');
                        }
                        if (rewardoperation == "selin") {
                          this.showError('rewardoperation');
                        }
                        if (!reward_given) {
                          this.showError('reward_given');
                        }
                      }
                      if (this.eventAction == "OM") {
                        this.showError('dragoptionalmodule');
                      }
                    }
                  } else {
                    this.showError('event-selector');
                  }
                } else {
                  alert('Event name should contain only alpha numeric characters and spaces');
                  this.showError('eventName');
                }
              } else {
                this.showError('eventName');
              }
            } else {
              this.showError('delay');
            }
          } else {
            this.showError('numberoftimesent');
          }
        } else {
          this.showError('delievery_time');
        }
      },
      updateEvent: async function () {
        var that = this;
        var accessToken = this.get('session.data.authenticated.access_token');
        const r = confirm("Are you sure you want to update this event ? If event name is changed, you will need to open blockly, re-attach event and save rule");
        if (r) {
          var eventName = this.get('eventName');
          var eventAction = this.eventAction;
          var e = document.getElementById("receiver");
          var p = document.getElementById("sender");
          var f = document.getElementById('branchNode');
          var l = document.getElementById("delivery_type");
          var b = document.getElementById("rewardoperation");
          var rule = this.rule;
          var message = this.get("message");
          var valid = await this.validateEvent(eventName, eventAction);
          if (!valid) {
            return;
          }
          var receiver = "";
          var sender = "";
          var delivery_type = "";
          var branchNode = "";
          var node_ref = null;
          var selectedEvent = this.selectedEvent;
          var available = true;
          var isResourceType = false;

          // Rewards
          var reward_variable = null;
          var rewardoperation = null;
          var reward_given = null;
          var optionalModule = null;
          var notificationSetting = null;
          var survey = null;
          var reportType = null;

          // Message validation
          if (this.eventAction == "MG") {
            delivery_type = l.options[l.selectedIndex].value;
            receiver = e.options[e.selectedIndex].value;
            sender = p.options[p.selectedIndex].value;
            notificationSetting = document.getElementById('notificationSetting').value;
            if (receiver != "selin" && delivery_type != "selin" && sender != "selin") {
              if (message) {
                valid = true;
              }
            }
          }

          // Reward validation
          if (this.eventAction == "RW") {
            reward_variable = this.get('reward_variable');
            if (reward_variable) {
              if (!reward_variable.includes('RW_')) {
                reward_variable = "RW_" + reward_variable;
              }
              rewardoperation = b.options[b.selectedIndex].value;
              reward_given = this.get('reward_given');
              if (reward_variable && rewardoperation != "selin" && reward_given) {
                valid = true;
              }
            }
          }
          if (this.eventAction == 'RP') {
            survey = (0, _jquery.default)('#dragsurvey').attr('data-id');
            reportType = (0, _jquery.default)('#report_type').val();
            receiver = (0, _jquery.default)('#receiver').val();
            if (receiver && reportType && survey) {
              valid = true;
            }
          }

          // Optional Module
          if (this.eventAction == "OM") {
            optionalModule = (0, _jquery.default)('#dragoptionalmodule').attr("data-id");
            const isModuleRequired = (0, _jquery.default)('#isModuleRequired').is(':checked');
            const isModuleAvailable = (0, _jquery.default)('#isModuleAvailable').is(':checked');
            const isResource = (0, _jquery.default)('#isResource').is(':checked');

            // make is tequired if checkbox = true
            if (isModuleRequired) {
              eventAction = "RM";
            }
            if (isResource) {
              isResourceType = true;
            }
            if (isModuleAvailable) {
              available = true;
            } else {
              available = false;
            }
            if (optionalModule) {
              valid = true;
            }
          }

          // // Required Module
          // if (this.eventAction == "RM") {
          //     optionalModule = $('#dragoptionalmodule').attr("data-id");
          //     if (optionalModule) { valid = true }
          // }

          // Alert validation
          if (that.eventType == 1) {
            if (this.eventAction == "AL") {
              valid = true;
            }
          }
          if (that.eventType == 3) {
            branchNode = f.options[f.selectedIndex].value;
            node_ref = branchNode;
            if (branchNode != "selin123") {
              valid = true;
            }
          }

          // delievery base event
          var istimebasedevent = this.istimebasedevent;
          var numberoftimesent = 1;
          var delay = 0;
          var delivery_time = "IM";
          var delivery_timestamp = null;
          var deliveryTimeStamptimepvalid = true;
          var delay_in_hours = this.get('delay_in_hours');
          var delay_in_hours_valid = true;
          var numberoftimesentValid = true;
          var delayValid = true;
          if (istimebasedevent) {
            delivery_time = "DL";
            numberoftimesent = this.get('numberoftimesent');
            delay = this.get('delay');
            delivery_timestamp = this.get('delievery_time');
            if (!numberoftimesent) {
              numberoftimesentValid = false;
            }
            ;
            if (!delay) {
              delayValid = false;
            }
            ;
            if (!delivery_timestamp) {
              deliveryTimeStamptimepvalid = false;
            }
            ;
            if (delay_in_hours == "" || delay_in_hours != 0) {
              delay_in_hours_valid = false;
            }
            ;
          }
          if (deliveryTimeStamptimepvalid) {
            if (numberoftimesentValid) {
              if (delayValid) {
                if (eventName) {
                  eventName = eventName.replace(/ /g, "_");
                  if (eventName.match(/^(?=.*[^\W_])[\w ]*$/)) {
                    if (eventAction) {
                      if (valid) {
                        _jquery.default.ajax({
                          url: _environment.default.APP.API_HOST + '/events/' + selectedEvent.id,
                          type: 'PATCH',
                          headers: {
                            "Authorization": "Bearer " + accessToken,
                            "Content-Type": "application/json"
                          },
                          data: JSON.stringify({
                            "event": {
                              "name": eventName,
                              "module_availability": available,
                              "is_resource": isResourceType,
                              "receiver": receiver,
                              "module_id": optionalModule,
                              "sender": sender,
                              "action": eventAction,
                              "rule": rule,
                              "node_ref": node_ref,
                              "delivery_time": delivery_time,
                              "delivery_time_after_creation": delivery_timestamp,
                              "event_fire_frequency": numberoftimesent,
                              "days_between_fire_frequency": delay,
                              "message": message,
                              "delivery_type": delivery_type,
                              "delay_in_hours": delay_in_hours,
                              survey_id: survey,
                              report_type: reportType,
                              notification_settings: notificationSetting
                            }
                          }),
                          success: function (res) {
                            var eventId = res.event.id;
                            that.getEvents(rule);
                            that.set('eventName', "");
                            that.set('eventAction', "");
                            that.set('receiver', "");
                            that.set('message', "");
                            that.set('eventType', 0);
                            that.set('istimebasedevent', false);
                            that.set('delievery_date', "");
                            that.set('delievery_time', "");
                            (0, _jquery.default)("#event-selector").prop('selectedIndex', 0);
                            (0, _jquery.default)("#receiver").prop('selectedIndex', 0);
                            (0, _jquery.default)("#sender").prop('selectedIndex', 0);
                            (0, _jquery.default)("#selector").prop('selectedIndex', 0);
                            (0, _jquery.default)("#branchNode").prop('selectedIndex', 0);
                            (0, _jquery.default)("#delivery_type").prop('selectedIndex', 0);
                            (0, _jquery.default)("#report_type").prop('selectedIndex', 0);
                            (0, _jquery.default)("#timebaseEvent").prop('selectedIndex', 0);
                            (0, _jquery.default)('#isModuleRequired').attr('checked', false);
                            (0, _jquery.default)('#isModuleAvailable').attr('checked', false);
                          },
                          error: function (err, xH) {
                            var errorMessage = err.responseText;
                            that.newAlert(errorMessage, "Error", "Failure");
                          }
                        });
                      } else {
                        if (that.eventType == 1) {
                          if (receiver == "selin") {
                            this.showError('receiver');
                          }
                          if (!message) {
                            this.showError('message');
                          }
                        }
                        if (that.eventType == 2) {
                          if (!reward_variable) {
                            this.showError('reward_variable');
                          }
                          if (rewardoperation == "selin") {
                            this.showError('rewardoperation');
                          }
                          if (!reward_given) {
                            this.showError('reward_given');
                          }
                        }
                        if (this.eventAction == "OM") {
                          this.showError('dragoptionalmodule');
                        }
                      }
                    } else {
                      this.showError('event-selector');
                    }
                  } else {
                    alert('Event name should contain only alpha numeric characters and spaces');
                    this.showError('eventName');
                  }
                } else {
                  this.showError('eventName');
                }
              } else {
                this.showError('delay');
              }
            } else {
              this.showError('numberoftimesent');
            }
          } else {
            this.showError('delievery_time');
          }
        }
      },
      //DeleteEvent
      deleteEvent: function (id) {
        var that = this;
        var accessToken = this.get('session.data.authenticated.access_token');
        var rule = this.rule;
        const r = confirm("Are you sure you want to delete this event?");
        if (r) {
          _jquery.default.ajax({
            url: _environment.default.APP.API_HOST + '/events/' + id,
            type: 'DELETE',
            headers: {
              "Authorization": "Bearer " + accessToken,
              "Content-Type": "application/json"
            },
            success: function (res) {
              that.getEvents(rule);
            },
            error: function (err, xH) {
              var errorMessage = err.responseText;
              that.newAlert(errorMessage, "Error", "Failure");
            }
          });
        }
      },
      SelecttriggerPoint: function () {
        var e = document.getElementById("triggerpoints");
        var triggerPoint = e.options[e.selectedIndex].value;
        if (triggerPoint == "TB") {
          this.set('isTimeBaseRule', true);
        } else {
          this.set('isTimeBaseRule', false);
        }
      },
      triggerrepetition: function () {
        var u = document.getElementById("ruletriggerrepetition");
        var ruletriggerrepetition = null;
        if (u) ruletriggerrepetition = u.options[u.selectedIndex].value;
        if (ruletriggerrepetition == "ON") {
          (0, _jquery.default)('#ruletriggerfrequency').hide();
          (0, _jquery.default)('#ruletriggerfrequency_head').hide();
          (0, _jquery.default)('#trigger_head').hide();
          (0, _jquery.default)('#max_number_of_triggers').hide();
          (0, _jquery.default)('#trigger_copy').hide();
          (0, _jquery.default)('#inhours').hide();
          (0, _jquery.default)('#rule_delievery_time').show();
          this.set('max_number_of_triggers', 1);
        } else {
          (0, _jquery.default)('#ruletriggerfrequency').show();
          (0, _jquery.default)('#ruletriggerfrequency_head').show();
          (0, _jquery.default)('#trigger_head').show();
          (0, _jquery.default)('#max_number_of_triggers').show();
          (0, _jquery.default)('#trigger_copy').show();
        }
      },
      triggerFrequncy: function () {
        var k = document.getElementById("ruletriggerfrequency");
        var ruletriggerfrequency = k.options[k.selectedIndex].value;
        if (ruletriggerfrequency == "HR") {
          (0, _jquery.default)('#inhours').show();
          (0, _jquery.default)('.rule_delivery_time').hide();
        } else {
          (0, _jquery.default)('#inhours').hide();
          (0, _jquery.default)('.rule_delivery_time').show();
        }
      },
      // Save Rule
      saveRule: function () {
        var that = this;
        var accessToken = this.get('session.data.authenticated.access_token');
        var ruleId = this.rule;
        var logicCode = this.logicCode;
        var rule_name = this.get('rule_name');
        var e = document.getElementById("triggerpoints");
        var k = document.getElementById("ruletriggerfrequency");
        var u = document.getElementById("ruletriggerrepetition");
        var triggerPoint = e.options[e.selectedIndex].value;
        var xmlText = this.xmlText;
        var isTimeBaseRule = this.isTimeBaseRule;
        var isBranchingRule = this.isBranchingRule;
        var study = null;
        var ruletriggerfrequency = null;
        var ruletriggerrepetition = null;
        var rule_trigger_timestamp = null;
        var ruletriggerfrequencyValid = true;
        var ruletriggerrepetitionValid = true;
        var deliveryTimeStamptimepvalid = true;
        var deliveryTimeStamdatepvalid = true;
        var ruleChoiceSelected = this.ruleChoiceSelected;
        var is_time_based = false;
        var max_number_of_triggers_valid = true;
        var max_number_of_triggers = this.get('max_number_of_triggers');
        if (ruleChoiceSelected == "simple") {
          is_time_based = false;
        } else {
          is_time_based = true;
        }
        var tp1 = null;
        var tp2 = null;
        var interval_hours = this.get('interval_hours');
        var interval_days = this.get('interval_days');
        var alwaysExecute = this.get('alwaysExecute');
        if (interval_hours == "") {
          interval_hours = 0;
        }
        if (interval_days == "") {
          interval_days = 0;
        }
        if (isTimeBaseRule) {
          study = this.studyId;
          if (u) ruletriggerrepetition = u.options[u.selectedIndex].value;
          if (ruletriggerrepetition == "ON") {
            ruletriggerfrequency = null;
          } else {
            if (k) ruletriggerfrequency = k.options[k.selectedIndex].value;
            if (ruletriggerfrequency != "selin234") {
              ruletriggerfrequencyValid = true;
            } else {
              ruletriggerfrequencyValid = true;
            }
          }
          if (ruletriggerrepetition != "selin999") {
            ruletriggerrepetitionValid = true;
          } else {
            ruletriggerrepetitionValid = false;
          }
          if (max_number_of_triggers) {
            max_number_of_triggers_valid = true;
          } else {
            max_number_of_triggers_valid = false;
          }
          var todaysDate = new Date();
          var dDate = todaysDate.getDate();
          if (dDate < 10) {
            dDate = "0" + dDate;
          }
          var dMonth = todaysDate.getMonth() + 1;
          if (dMonth < 10) {
            dMonth = "0" + dMonth;
          }
          var dHour = todaysDate.getHours();
          if (dHour < 10) {
            dHour = "0" + dMonth;
          }
          var dYear = todaysDate.getFullYear();
          var newdDate = dYear + "-" + dMonth + "-" + dDate;
          var rule_delievery_date = newdDate;
          var gmt = this.getGmt();
          // var rule_delievery_date = this.get('rule_delievery_date');

          if (rule_delievery_date) {
            deliveryTimeStamdatepvalid = true;
            if (ruletriggerfrequency == "HR") {
              var inhours = document.getElementById('inhours').value;
              if (inhours) {
                deliveryTimeStamptimepvalid = true;
                inhours = dHour + ":" + inhours;
                // rule_trigger_timestamp = rule_delievery_date + "T" + inhours + ":00" + gmt;
                rule_trigger_timestamp = rule_delievery_date + "T" + inhours + ":00+10:00";
              } else {
                deliveryTimeStamptimepvalid = false;
              }
            } else {
              var rule_delievery_time = this.get('rule_delievery_time');
              if (rule_delievery_time || alwaysExecute) {
                if (rule_delievery_time == '') rule_delievery_time = '00:00';
                deliveryTimeStamptimepvalid = true;
                // rule_trigger_timestamp = rule_delievery_date + "T" + rule_delievery_time + ":00" + gmt;
                rule_trigger_timestamp = rule_delievery_date + "T" + rule_delievery_time + ":00+10:00";
              } else {
                deliveryTimeStamptimepvalid = false;
              }
            }
          } else {
            deliveryTimeStamdatepvalid = false;
          }
          if (this.timeBasedVariable_tp1) {
            this.tp1Json["tp1"] = this.timeBasedVariable_tp1;
          } else {
            this.tp1Json["tp1"] = "";
          }
          if (this.timeBasedVariable_tp2) {
            this.tp2Json["tp2"] = this.timeBasedVariable_tp2;
          } else {
            this.tp2Json["tp2"] = "";
          }
          tp1 = this.tp1Json;
          tp2 = this.tp2Json;
        }
        if (rule_name) {
          if (triggerPoint != "selin") {
            if (ruletriggerfrequencyValid) {
              if (ruletriggerrepetitionValid) {
                if (deliveryTimeStamdatepvalid) {
                  if (deliveryTimeStamptimepvalid) {
                    if (max_number_of_triggers_valid) {
                      if (logicCode) {
                        _jquery.default.ajax({
                          url: _environment.default.APP.API_HOST + '/rules/' + ruleId,
                          type: 'PATCH',
                          headers: {
                            "Authorization": "Bearer " + accessToken,
                            "Content-Type": "application/json"
                          },
                          data: JSON.stringify({
                            "rule": {
                              "name": rule_name,
                              "trigger": triggerPoint,
                              "logic": logicCode,
                              "trigger_blockly_xml": xmlText,
                              "draft": false,
                              "rule_trigger_timestamp": rule_trigger_timestamp,
                              "repetition": ruletriggerrepetition,
                              "trigger_frequency": ruletriggerfrequency,
                              "study": study,
                              "is_time_based": is_time_based,
                              "max_number_of_triggers": max_number_of_triggers,
                              "tp1": tp1,
                              "tp2": tp2,
                              "interval_days": interval_days,
                              "interval_hours": interval_hours,
                              "always_execute": alwaysExecute,
                              "is_branching_rule": isBranchingRule
                            }
                          }),
                          success: function (res) {
                            that.newAlert("Rule added successfully.", "Rule", "Success");
                            that.updateTreeNode(rule_name, res.rule.identifier);
                            that.set('rule_name', "");
                            that.set('logicCode', "");
                            (0, _jquery.default)('select option:contains("Select Trigger Point")').prop('selected', true);
                            that.sendAction('ruleSaved', ruleChoiceSelected);
                            that.set('isTimeBaseRule', false);
                            // that.set('rule_delievery_date', "");
                            that.set('rule_delievery_time', "");
                          },
                          error: function (err, xH) {
                            var errorMessage = err.responseText;
                            that.newAlert(errorMessage, "Error", "Failure");
                          }
                        });
                      } else {
                        that.newAlert("Please attach logic in this rule.", "Logic", "Warning");
                      }
                    } else {
                      this.showError('max_number_of_triggers');
                    }
                  } else {
                    if (ruletriggerfrequency == "HR") {
                      this.showError('inhours');
                    } else {
                      this.showError('rule_delievery_time');
                    }
                  }
                } else {
                  this.showError('rule_delievery_date');
                }
              } else {
                this.showError('ruletriggerrepetition');
              }
            } else {
              this.showError('ruletriggerfrequency');
            }
          } else {
            this.showError('triggerpoints');
          }
        } else {
          this.showError('rule_name');
        }
      },
      deleteRule: function () {
        var that = this;
        var accessToken = this.get('session.data.authenticated.access_token');
        const r = confirm("Are you sure you want to delete this rule?");
        const selectedNodeId_rule2 = localStorage.getItem('selectedNodeId_rule2');
        const selectedNodeId_rule1 = localStorage.getItem('selectedNodeId_rule1');
        if (r) {
          var ruleId = this.rule;
          _jquery.default.ajax({
            url: _environment.default.APP.API_HOST + '/rules/' + ruleId + '/is-in-study',
            type: 'GET',
            headers: {
              'Authorization': 'Bearer ' + accessToken
            },
            success: function (res) {
              console.log(res);
              if (res.rule.is_in_study) {
                that.newAlert('Rule is assigned to a study and hence cannot be deleted', "Unable to delete rule", "Failure");
              } else {
                _jquery.default.ajax({
                  url: _environment.default.APP.API_HOST + '/rules/' + ruleId,
                  type: 'DELETE',
                  headers: {
                    "Authorization": "Bearer " + accessToken,
                    "Content-Type": "application/json"
                  },
                  success: function (res) {
                    that.sendAction('ruleSaved');
                    if (selectedNodeId_rule1) {
                      that.sendAction('deleteRuleFromJson', selectedNodeId_rule1, 1);
                      localStorage.removeItem('selectedNodeId_rule1');
                    }
                    if (selectedNodeId_rule2) {
                      that.sendAction('deleteRuleFromJson', selectedNodeId_rule2, 2);
                      localStorage.removeItem('selectedNodeId_rule2');
                    }
                  },
                  error: function (err, xH) {
                    var errorMessage = err.responseText;
                    that.newAlert(errorMessage, "Error", "Failure");
                  }
                });
              }
            }
          });
        }
      },
      closeRule: function () {
        this.set('childNodeIds', []);
        this.sendAction('ruleSaved');
      },
      timebasedevent: function () {
        var e = document.getElementById("timebaseEvent");
        var istimebaseevent = e.options[e.selectedIndex].value;
        var boolValue = false;
        if (istimebaseevent == "true") {
          boolValue = true;
        }
        this.set('istimebasedevent', boolValue);
      }
    },
    setExistingRule: function () {
      var that = this;
      var existingRule = this.existingRule;
      this.set('rule', existingRule.id);
      this.set('rule_name', existingRule.name);
      this.set('logicCode', existingRule.logic);
      that.set('isBranchingRule', existingRule.is_branching_rule);
      this.getEvents(existingRule.id);
      var trigger = existingRule.trigger;
      var triggerPoints = this.triggerConditions.filter(function (e) {
        return e.value == trigger;
      });
      var ruleChoiceSelected = this.ruleChoiceSelected;
      if (ruleChoiceSelected == "simple") {
        if (triggerPoints.length > 0) {
          var triggerPoint = triggerPoints[0].trigger_type;
          setTimeout(function () {
            (0, _jquery.default)('select option:contains(' + triggerPoint + ')').prop('selected', true);
          }, 0);
        }
      } else {
        that.set('isTimeBaseRule', true);
        setTimeout(function () {
          (0, _jquery.default)("#triggerpoints").prop('selectedIndex', "TB");
        }, 0);
        that.set('interval_days', existingRule.interval_days);
        that.set('interval_hours', existingRule.interval_hours);
        that.set('tp1Json', existingRule.tp1);
        that.set('tp2Json', existingRule.tp2);
        that.set('alwaysExecute', existingRule.always_execute);
        // tp1
        if (existingRule.tp1.last_login) {
          setTimeout(function () {
            (0, _jquery.default)('#set_to_last_login').prop('checked', true);
            that.send('set_to_last_login_change');
          }, 0);
        } else {
          that.set('selectedTimePoint', existingRule.tp1.selectedTimePoint);
          if (existingRule.tp1.selectedTimePoint == "1") {
            that.set('timeBasedVariable_tp1', "enrollment_time");
          }
          if (existingRule.tp1.selectedTimePoint == "2") {
            that.set('timeBasedVariable_tp1', "study_start_time");
          }
          if (existingRule.tp1.selectedTimePoint == "3") {
            that.set('timeBasedVariable_tp1', "study_end_time");
          }
          setTimeout(function () {
            (0, _jquery.default)('#set_to_last_login').prop('checked', false);
            that.send('set_to_last_login_change');
          }, 0);
          setTimeout(function () {
            if (existingRule.tp1.time_point) {
              (0, _jquery.default)('#time_point').prop('selectedIndex', existingRule.tp1.time_point);
            }
          }, 20);
          setTimeout(function () {
            if (existingRule.tp1.time_point) {
              that.send('setTimePoint', "one");
            }
          }, 25);
          setTimeout(function () {
            if (existingRule.tp1.arm) {
              if (existingRule.tp1.arm == "Initial") {
                (0, _jquery.default)('#first_time_point_study_arm').prop('selectedIndex', 1);
              } else {
                (0, _jquery.default)('#first_time_point_study_arm').prop('selectedIndex', parseInt(existingRule.tp1.arm) + 1);
              }
              that.send('armSelected', "first");
            }
          }, 40);
          setTimeout(function () {
            if (existingRule.tp1.start_end == "start") {
              (0, _jquery.default)('#first_time_point_study_start_end').prop('selectedIndex', 1);
            }
          }, 60);
          setTimeout(function () {
            if (existingRule.tp1.start_end == "end") {
              (0, _jquery.default)('#first_time_point_study_start_end').prop('selectedIndex', 2);
            }
          }, 80);
          setTimeout(function () {
            if (existingRule.tp1.phase) {
              (0, _jquery.default)('#first_time_point_study_phase').prop('selectedIndex', existingRule.tp1.phase);
            }
          }, 100);
          setTimeout(function () {
            if (existingRule.tp1.phase) {
              that.send('phaseSelected', "first");
            }
          }, 120);
          setTimeout(function () {
            if (existingRule.tp1.node) {
              (0, _jquery.default)('#first_time_point_study_nodes').prop('selectedIndex', existingRule.tp1.node);
            }
          }, 1000);
          setTimeout(function () {
            if (existingRule.tp1.node) {
              that.send('nodeSelected', "first");
            }
          }, 1200);
        }

        //tp2
        if (existingRule.tp2.current_time_change) {
          setTimeout(function () {
            (0, _jquery.default)('#set_to_current_time').prop('checked', true);
            that.send('set_to_current_time_change');
          }, 0);
        } else {
          that.set('selectedTimePoint_2', existingRule.tp2.selectedTimePoint);
          setTimeout(function () {
            (0, _jquery.default)('#set_to_current_time').prop('checked', false);
            that.send('set_to_current_time_change');
          }, 10);
          setTimeout(function () {
            if (existingRule.tp2.time_point) {
              (0, _jquery.default)('#time_point_2').prop('selectedIndex', existingRule.tp2.time_point);
            }
          }, 30);
          setTimeout(function () {
            if (existingRule.tp1.time_point) {
              that.send('setTimePoint', "two");
            }
          }, 35);
          setTimeout(function () {
            if (existingRule.tp2.arm) {
              if (existingRule.tp2.arm == "Initial") {
                (0, _jquery.default)('#second_time_point_study_arm').prop('selectedIndex', 1);
              } else {
                (0, _jquery.default)('#second_time_point_study_arm').prop('selectedIndex', parseInt(existingRule.tp2.arm) + 1);
              }
              that.send('armSelected', "second");
            }
          }, 50);
          setTimeout(function () {
            if (existingRule.tp2.start_end == "start") {
              (0, _jquery.default)('#second_time_point_study_start_end').prop('selectedIndex', 1);
            }
          }, 70);
          setTimeout(function () {
            if (existingRule.tp2.start_end == "end") {
              (0, _jquery.default)('#second_time_point_study_start_end').prop('selectedIndex', 2);
            }
          }, 90);
          setTimeout(function () {
            if (existingRule.tp2.phase) {
              (0, _jquery.default)('#second_time_point_study_phase').prop('selectedIndex', existingRule.tp2.phase);
            }
          }, 110);
          setTimeout(function () {
            if (existingRule.tp2.phase) {
              that.send('phaseSelected', "second");
            }
          }, 500);
          setTimeout(function () {
            if (existingRule.tp2.node) {
              (0, _jquery.default)('#second_time_point_study_nodes').prop('selectedIndex', existingRule.tp2.node);
            }
          }, 1000);
          setTimeout(function () {
            if (existingRule.tp2.node) {
              that.send('nodeSelected', "second");
            }
          }, 1200);
        }
      }
      var triggerFrequency = existingRule.trigger_frequency;
      var repetition = existingRule.repetition;
      var rule_trigger_timestamp = existingRule.rule_trigger_timestamp;
      var max_number_of_triggers = existingRule.max_number_of_triggers;
      if (max_number_of_triggers) {
        that.set('max_number_of_triggers', max_number_of_triggers);
      }
      if (repetition == "ON") {
        setTimeout(function () {
          (0, _jquery.default)("#ruletriggerrepetition").prop('selectedIndex', 1);
          (0, _jquery.default)('#ruletriggerfrequency').hide();
          (0, _jquery.default)('#trigger_head').hide();
          (0, _jquery.default)('#max_number_of_triggers').hide();
          (0, _jquery.default)('#trigger_copy').hide();
          (0, _jquery.default)('#ruletriggerfrequency_head').hide();
        }, 0);
      }
      if (repetition == "OF") {
        setTimeout(function () {
          (0, _jquery.default)("#ruletriggerrepetition").prop('selectedIndex', 2);
          (0, _jquery.default)('#ruletriggerfrequency').show();
          (0, _jquery.default)('#trigger_head').show();
          (0, _jquery.default)('#max_number_of_triggers').show();
          (0, _jquery.default)('#trigger_copy').show();
          (0, _jquery.default)('#ruletriggerfrequency_head').show();
          if (triggerFrequency == "HR") {
            setTimeout(function () {
              (0, _jquery.default)("#ruletriggerfrequency").prop('selectedIndex', 3);
              (0, _jquery.default)('#inhours').show();
              (0, _jquery.default)('#rule_delievery_time').hide();
            }, 0);
          }
          if (triggerFrequency == "DL") {
            setTimeout(function () {
              (0, _jquery.default)("#ruletriggerfrequency").prop('selectedIndex', 2);
              (0, _jquery.default)('#inhours').hide();
              (0, _jquery.default)('#rule_delievery_time').show();
            }, 0);
          }
          if (triggerFrequency == "WK") {
            setTimeout(function () {
              (0, _jquery.default)("#ruletriggerfrequency").prop('selectedIndex', 1);
              (0, _jquery.default)('#inhours').hide();
              (0, _jquery.default)('#rule_delievery_time').show();
            }, 0);
          }
        }, 0);
      }
      if (rule_trigger_timestamp) {
        var triggerDateTime = new Date(rule_trigger_timestamp);
        var year = triggerDateTime.getFullYear();
        var month = triggerDateTime.getMonth();
        if (month < 10) {
          month = "0" + month;
        }
        var date = triggerDateTime.getDate();
        if (date < 10) {
          date = "0" + date;
        }
        var fullDate = year + "-" + month + "-" + date;
        that.set('rule_delievery_date', fullDate);
        var hour = triggerDateTime.getHours();
        if (hour < 10) {
          hour = "0" + hour;
        }
        var min = triggerDateTime.getMinutes();
        if (min < 10) {
          min = "0" + min;
        }
        var time = hour + ":" + min;
        if (triggerFrequency == "HR") {
          (0, _jquery.default)('#inhours').show();
          (0, _jquery.default)('#rule_delievery_time').hide();
          this.set('inhours', min);
        } else {
          (0, _jquery.default)('#inhours').hide();
          (0, _jquery.default)('#rule_delievery_time').show();
          this.set('rule_delievery_time', time);
        }
      }
      var trigger_blockly_xml = existingRule.trigger_blockly_xml;
      if (trigger_blockly_xml) {
        this.set('xmlText', trigger_blockly_xml);
        var that = this;
        setTimeout(function () {
          that.addBlockely(trigger_blockly_xml);
        }, 100);
      }
    },
    updateTreeNode: function (ruleName, ruleId) {
      var selectedNodeId = this.selectedNodeId;
      var selectedRuleType = this.selectedRuleType;
      this.sendAction('createRule');
      setTimeout(function () {
        if (selectedRuleType == "rule1") {
          var divId = "dragRule1" + selectedNodeId;
          var doc = document.getElementById(divId);
          doc.innerText = ruleName;
          doc.style.fontSize = "16px";
          doc.style.backgroundColor = "#344D90";
          doc.setAttribute('data-id', ruleId);
        }
        if (selectedRuleType == "rule2") {
          var divId = "dragRule2" + selectedNodeId;
          var doc = document.getElementById(divId);
          doc.innerText = ruleName;
          doc.style.backgroundColor = "#344D90";
          doc.style.fontSize = "16px";
          doc.setAttribute('data-id', ruleId);
        }
        if (selectedRuleType == "branchRule") {
          var divId = "branchRule" + selectedNodeId;
          var doc = document.getElementById(divId);
          doc.innerText = ruleName;
          doc.style.backgroundColor = "#344D90";
          doc.style.fontSize = "16px";
          doc.setAttribute('data-id', ruleId);
        }
        localStorage.setItem('targetId', selectedNodeId);
        document.getElementById("updatetreeNode").click();
      }, 100);
    },
    addBlockely: function (xml) {
      if (document.getElementById('inputsandlogics')) {
        document.getElementById("inputsandlogics").innerHTML = "";
      }
      // var options = {
      //     "readOnly": true
      // }
      var workspace = _blockly.default.inject('inputsandlogics');
      this.set('workspace', workspace);
      if (typeof xml != "string" || xml.length < 5) {
        return false;
      }
      try {
        var dom = _blockly.default.Xml.textToDom(xml);
        _blockly.default.mainWorkspace.clear();
        _blockly.default.Xml.domToWorkspace(_blockly.default.mainWorkspace, dom);
        return true;
      } catch (e) {
        console.error(e);
        return false;
      }
    },
    //get events
    getEvents: function (rule) {
      var that = this;
      this.store.query('event', {
        rule: rule
      }).then(function (events) {
        that.set('currentEvents', events);
      });
    },
    createchildNodeIdEvents: function () {
      var that = this;
      var childNodeIds = this.childNodeIds;
      if (childNodeIds.length > 0) {
        var accessToken = this.get('session.data.authenticated.access_token');
        var rule = this.rule;
        _jquery.default.ajax({
          url: _environment.default.APP.API_HOST + '/events?rule=' + rule,
          type: 'GET',
          headers: {
            "Authorization": "Bearer " + accessToken,
            "Content-Type": "application/json"
          },
          success: function (res) {
            var res = res.event;
            if (res.length == 0) {
              that.createNodeEvents(0, rule);
            }
          },
          error: function (err, xH) {
            var errorMessage = err.responseText;
            that.newAlert(errorMessage, "Error", "Failure");
          }
        });
      }
    },
    createNodeEvents: function (index, rule) {
      var that = this;
      var accessToken = this.get('session.data.authenticated.access_token');
      var childNodeIds = this.childNodeIds;
      if (!index) index = 0;
      if (childNodeIds) {
        if (childNodeIds[index]) {
          var childNodeId = index + 1;
          var eventName = "node" + childNodeId;
          var node_ref = childNodeIds[index];
          var eventAction = "MD";
          _jquery.default.ajax({
            url: _environment.default.APP.API_HOST + '/events',
            type: 'POST',
            headers: {
              "Authorization": "Bearer " + accessToken,
              "Content-Type": "application/json"
            },
            data: JSON.stringify({
              "event": {
                "name": eventName,
                "action": eventAction,
                "rule": rule,
                "node_ref": node_ref,
                "is_node_event": true
              }
            }),
            success: function (res) {
              that.createNodeEvents(index + 1, rule);
              that.getEvents(rule);
            },
            error: function (err, xH) {
              var errorMessage = err.responseText;
              that.newAlert(errorMessage, "Error", "Failure");
            }
          });
        }
      }
    },
    savetime_based_rule_vars_track: function () {
      const studyId = this.studyId;
      const accessToken = this.get('session.data.authenticated.access_token');
      const that = this;
      var timeBasedVariable_tp1 = this.timeBasedVariable_tp1;
      var timeBasedVariable_tp2 = this.timeBasedVariable_tp2;
      if (timeBasedVariable_tp1.includes('_start')) {
        timeBasedVariable_tp1 = timeBasedVariable_tp1.replace('_start', '');
      }
      if (timeBasedVariable_tp1.includes('_end')) {
        timeBasedVariable_tp1 = timeBasedVariable_tp1.replace('_end', '');
      }
      if (timeBasedVariable_tp2.includes('_start')) {
        timeBasedVariable_tp2 = timeBasedVariable_tp2.replace('_start', '');
      }
      if (timeBasedVariable_tp2.includes('_end')) {
        timeBasedVariable_tp2 = timeBasedVariable_tp2.replace('_end', '');
      }
      if (timeBasedVariable_tp2.includes('_end')) {
        timeBasedVariable_tp2 = timeBasedVariable_tp2.replace('_end', '');
      }

      //Empty unnecessary variables
      if (timeBasedVariable_tp1.includes('GL_last_login')) {
        timeBasedVariable_tp1 = "";
      }
      if (timeBasedVariable_tp2.includes('currentTime')) {
        timeBasedVariable_tp2 = "";
      }
      if (timeBasedVariable_tp1.includes('_timestamp')) {
        timeBasedVariable_tp1 = "";
      }
      if (timeBasedVariable_tp2.includes('_timestamp')) {
        timeBasedVariable_tp2 = "";
      }
      if (timeBasedVariable_tp1.includes('selin234')) {
        timeBasedVariable_tp1 = "";
      }
      if (timeBasedVariable_tp2.includes('selin234')) {
        timeBasedVariable_tp2 = "";
      }
      if (timeBasedVariable_tp1.includes('enrollment_time')) {
        timeBasedVariable_tp1 = "";
      }
      if (timeBasedVariable_tp2.includes('enrollment_time')) {
        timeBasedVariable_tp2 = "";
      }
      if (timeBasedVariable_tp1.includes('study_end_time')) {
        timeBasedVariable_tp1 = "";
      }
      if (timeBasedVariable_tp2.includes('study_end_time')) {
        timeBasedVariable_tp2 = "";
      }
      if (timeBasedVariable_tp1.includes('study_start_time')) {
        timeBasedVariable_tp1 = "";
      }
      if (timeBasedVariable_tp2.includes('study_start_time')) {
        timeBasedVariable_tp2 = "";
      }
      if (timeBasedVariable_tp1.includes('study_time')) {
        timeBasedVariable_tp1 = "";
      }
      if (timeBasedVariable_tp2.includes('study_time')) {
        timeBasedVariable_tp2 = "";
      }
      var variables = [];
      _jquery.default.ajax({
        url: _environment.default.APP.API_HOST + '/studies/' + studyId,
        type: 'GET',
        headers: {
          "Authorization": "Bearer " + accessToken,
          "Content-Type": "application/json"
        },
        success: function (res) {
          var study = res.study;
          var time_based_rule_vars_track = study.time_based_rule_vars_track;
          if (time_based_rule_vars_track) {
            variables = time_based_rule_vars_track;
            if (variables.includes(timeBasedVariable_tp1)) {} else {
              if (timeBasedVariable_tp1) {
                variables.push(timeBasedVariable_tp1);
              }
            }
            if (variables.includes(timeBasedVariable_tp2)) {} else {
              if (timeBasedVariable_tp2) {
                variables.push(timeBasedVariable_tp2);
              }
            }
          } else {
            if (timeBasedVariable_tp1) {
              variables.push(timeBasedVariable_tp1);
            }
            if (timeBasedVariable_tp2) {
              variables.push(timeBasedVariable_tp2);
            }
          }
          _jquery.default.ajax({
            url: _environment.default.APP.API_HOST + '/studies/' + studyId,
            type: 'PATCH',
            headers: {
              "Authorization": "Bearer " + accessToken,
              "Content-Type": "application/json"
            },
            data: JSON.stringify({
              "study": {
                "time_based_rule_vars_track": variables
              }
            }),
            success: function (res) {},
            error: function (err, xH) {
              var errorMessage = err.responseText;
              that.newAlert(errorMessage, "Error", "Failure");
            }
          });
        },
        error: function (err, xH) {
          var errorMessage = err.responseText;
          that.newAlert(errorMessage, "Error", "Failure");
        }
      });
    },
    getGmt: function () {
      var a = new Date();
      a = a.toString();
      var b = a.split('GMT')[1];
      return b.substr(0, b.indexOf(' (Australian Eastern Daylight Time)'));
    },
    /*  Alert to show success/ failure or warning   */
    newAlert: function (message, title, type) {
      if (type === "Success") {
        this.toast.success(message, title, {
          "closeButton": false,
          "debug": false,
          "newestOnTop": false,
          "progressBar": false,
          "positionClass": "toast-top-right",
          "preventDuplicates": false,
          "onclick": null,
          "showDuration": "300",
          "hideDuration": "1000",
          "timeOut": "5000",
          "extendedTimeOut": "1000",
          "showEasing": "swing",
          "hideEasing": "linear",
          "showMethod": "fadeIn",
          "hideMethod": "fadeOut"
        });
      } else if (type === "Failure") {
        this.toast.error(message, title, {
          "closeButton": false,
          "debug": false,
          "newestOnTop": false,
          "progressBar": false,
          "positionClass": "toast-top-right",
          "preventDuplicates": false,
          "onclick": null,
          "showDuration": "300",
          "hideDuration": "1000",
          "timeOut": "5000",
          "extendedTimeOut": "1000",
          "showEasing": "swing",
          "hideEasing": "linear",
          "showMethod": "fadeIn",
          "hideMethod": "fadeOut"
        });
      } else {
        this.toast.warning(message, title, {
          "closeButton": false,
          "debug": false,
          "newestOnTop": false,
          "progressBar": false,
          "positionClass": "toast-top-right",
          "preventDuplicates": false,
          "onclick": null,
          "showDuration": "300",
          "hideDuration": "1000",
          "timeOut": "5000",
          "extendedTimeOut": "1000",
          "showEasing": "swing",
          "hideEasing": "linear",
          "showMethod": "fadeIn",
          "hideMethod": "fadeOut"
        });
      }
    },
    showError: function (id) {
      (0, _jquery.default)("#" + id).addClass("error");
      setTimeout(function () {
        (0, _jquery.default)("#" + id).removeClass("error");
      }, 2000);
    }
  });
  _exports.default = _default;
});