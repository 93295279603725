define("momentumhub/helpers/getvimeolink", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Helper.helper(function getvimeolink(options) {
    var src = options[0];
    if (src) {
      if (src.includes('iframe')) {
        src = src.split('src="')[1];
        src = src.substr(0, src.indexOf('" width'));
        return src;
      }
    }
  });
  _exports.default = _default;
});