define("momentumhub/models/parentparticipant", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberData.default.Model.extend({
    parent: _emberData.default.belongsTo('user'),
    participant: _emberData.default.belongsTo('user'),
    get_participant_age: _emberData.default.attr(),
    get_participant_dark_theme: _emberData.default.attr('boolean'),
    selected_theme: _emberData.default.attr()
  });
  _exports.default = _default;
});