define("momentumhub/components/admin-dashboard", ["exports", "jquery", "momentumhub/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    router: Ember.inject.service(),
    delayTimer: "",
    tagstype: "",
    selectedId: "",
    selectedTags: [],
    selectedName: "",
    selectedDescription: "",
    moduleThumbnail: "",
    /* Modules */
    modules: [],
    modulePage: 1,
    nextModule: "",
    previousModule: "",
    modulesearch: "",
    totalModules: "",
    totalViewableModules_start: "",
    totalViewableModules_end: "",
    /* Surveys */
    surveys: [],
    surveyPage: 1,
    nextSurvey: "",
    previousSurvey: "",
    deleteComponentName: "",
    deleteComponentTitle: "",
    deleteComponentId: "",
    description: "",
    searchSurvey: "",
    totalSurveys: "",
    totalViewableSurveys_start: "",
    totalViewableSurveys_end: "",
    /* Studies */
    studyPage: 1,
    studies: [],
    nextStudy: "",
    previousStudy: "",
    allowPrint: false,
    hideSaveButtons: false,
    init() {
      this._super(...arguments);
      this.set("modulesearch", "");
      this.set("searchSurvey", "");
      this.getModules();
      this.getSurveys();
      this.getStudies();
    },
    actions: {
      refreshRouter() {
        this.getModules();
        this.getSurveys();
      },
      /*************************** Modules ***************************/
      searchModule: function () {
        var that = this;
        clearTimeout(this.delayTimer);
        that.delayTimer = setTimeout(function () {
          that.getModules();
        }, 1000);
      },
      createModule: function () {
        (0, _jquery.default)('.hover_bkgr_fricc-study-module').show();
      },
      editModule: function (moduleId) {
        this.get('router').transitionTo('create-module', moduleId);
      },
      editModuleTags: function (moduleId, moduleTags, moduleName, desc, moduleThumbnail, module) {
        this.set('selectedId', moduleId);
        this.set('selectedTags', moduleTags);
        this.set('selectedName', moduleName);
        this.set('selectedDescription', desc);
        this.set('moduleThumbnail', moduleThumbnail);
        this.set('tagstype', "Module");
        this.set('hideSaveButtons', module.get('hide_save_buttons'));
        (0, _jquery.default)('.hover_bkgr_fricc-module-tags').show();
      },
      previewModule: function (moduleId) {
        this.get('router').transitionTo('modulePreview', moduleId);
      },
      leftModuleButton: function () {
        if (this.previousModule) {
          this.modulePage = this.modulePage - 1;
          this.getModules();
        }
      },
      rightModuleButton: function () {
        if (this.nextModule) {
          this.modulePage = this.modulePage + 1;
          this.getModules();
        }
      },
      /*************************** Surveys ***************************/

      searchSurvey: function () {
        var that = this;
        clearTimeout(this.delayTimer);
        that.delayTimer = setTimeout(function () {
          that.getSurveys();
        }, 1000);
      },
      createSurvey: function () {
        (0, _jquery.default)('.hover_bkgr_fricc-study-survey').show();
      },
      editSurveyTags: function (surveyId, surveyTags, surveyName, description, allowPrint) {
        this.set('selectedId', surveyId);
        this.set('selectedTags', surveyTags);
        this.set('selectedName', surveyName);
        this.set('tagstype', "Survey");
        this.set('selectedDescription', description);
        this.set('allowPrint', allowPrint);
        (0, _jquery.default)('.hover_bkgr_fricc-module-tags').show();
      },
      editSurveys: function (surveyId, surveyText) {
        var localStorageName = "survey-" + surveyId;
        if (typeof surveyText === "string") {
          window.localStorage.setItem(localStorageName, surveyText);
        } else {
          window.localStorage.setItem(localStorageName, JSON.stringify(surveyText));
        }
        this.get('router').transitionTo('create-survey', surveyId);
      },
      previewSurvey: function (surveyId, surveyText) {
        this.get('router').transitionTo('survey-preview', surveyId);
      },
      leftSurveyButton: function () {
        if (this.previousSurvey) {
          this.surveyPage = this.surveyPage - 1;
          this.getSurveys();
        }
      },
      rightSurveyButton: function () {
        if (this.nextSurvey) {
          this.surveyPage = this.surveyPage + 1;
          this.getSurveys();
        }
      },
      /* ******************  Study  ********************/
      leftStudyButton: function () {
        if (this.previousStudy) {
          this.studyPage = this.studyPage - 1;
          this.getStudies();
        }
      },
      rightStudyButton: function () {
        if (this.nextStudy) {
          this.studyPage = this.studyPage + 1;
          this.getStudies();
        }
      },
      searchStudy: function () {
        var that = this;
        clearTimeout(this.delayTimer);
        that.delayTimer = setTimeout(function () {
          that.getStudies();
        }, 1000);
      },
      /* Delete Component */
      closeDeleteComponent: function () {
        (0, _jquery.default)('.hover_bkgr_fricc-delete-comp').hide();
        this.set('deleteComponentId', "");
        this.set('deleteComponentName', "");
        this.set('deleteComponentTitle', "");
      },
      openDeleteComponent: function (text, id, name) {
        this.set('deleteComponentName', text);
        this.set('deleteComponentTitle', name);
        this.set('deleteComponentId', id);
        (0, _jquery.default)('.hover_bkgr_fricc-delete-comp').show();
      },
      deleteComponentFromApi: function () {
        var that = this;
        var token = that.get('session.data.authenticated.access_token');
        var deleteComponentId = this.deleteComponentId;
        var deleteComponentName = this.deleteComponentName;
        // delete Module
        if (deleteComponentName == "module") {
          _jquery.default.ajax({
            url: _environment.default.APP.API_HOST + '/modules/' + deleteComponentId,
            headers: {
              Authorization: 'Bearer ' + token
            },
            type: 'DELETE',
            success: function (res) {
              that.getModules();
              (0, _jquery.default)('.hover_bkgr_fricc-delete-comp').hide();
              that.set('deleteComponentId', "");
              that.set('deleteComponentName', "");
              that.set('deleteComponentTitle', "");
            },
            error: function (err, xH) {
              alert(err.responseText);
            }
          });
        }
        //Delete surveys
        if (deleteComponentName == "survey") {
          _jquery.default.ajax({
            url: _environment.default.APP.API_HOST + '/surveys/' + deleteComponentId,
            headers: {
              Authorization: 'Bearer ' + token
            },
            type: 'DELETE',
            success: function (res) {
              that.getSurveys();
              (0, _jquery.default)('.hover_bkgr_fricc-delete-comp').hide();
              that.set('deleteComponentId', "");
              that.set('deleteComponentName', "");
              that.set('deleteComponentTitle', "");
            },
            error: function (err, xH) {
              alert(err.responseText);
            }
          });
        }
      },
      /* Route to study */
      routetoStudy: function (id) {
        this.get('router').transitionTo('study-builder', id);
      },
      /* Create New study */
      createNewStudy: function () {
        (0, _jquery.default)('.hover_bkgr_fricc-study-comp').show();
      }
    },
    /* Get Modules For Researchers Dashboard */
    getModules: function () {
      var page = this.modulePage;
      var that = this;
      var modulesearch = this.get('modulesearch');
      var numberOfRecords = this.getNumberOfBoxesToDisplay();
      this.store.query('module', {
        page: page,
        id_or_name: modulesearch,
        page_size: numberOfRecords
      }).then(function (modules) {
        var next = modules.meta.next;
        var previous = modules.meta.previous;
        that.set('modules', modules);
        that.set('nextModule', next);
        that.set('previousModule', previous);
        that.setDivs("Module", modules.meta.count);
        that.hideShowButtons("Module");
        that.set('totalModules', modules.meta.count);
        that.set('totalViewableModules_start', page * numberOfRecords - numberOfRecords + 1);
        if (modules.meta.count < numberOfRecords) {
          that.set('totalViewableModules_end', modules.meta.count);
        } else {
          that.set('totalViewableModules_end', page * numberOfRecords);
        }
      });
    },
    /* Get Surveys For Researchers Dashboard */
    getSurveys: function () {
      var page = this.surveyPage;
      var that = this;
      var searchSurvey = this.get('searchSurvey');
      var numberOfRecords = this.getNumberOfBoxesToDisplay();
      this.store.query('survey', {
        page: page,
        page_size: numberOfRecords,
        id_or_name: searchSurvey,
        test: false
      }).then(function (surveys) {
        var next = surveys.meta.next;
        var previous = surveys.meta.previous;
        that.set('surveys', surveys);
        that.set('nextSurvey', next);
        that.set('previousSurvey', previous);
        that.setDivs("Survey", surveys.meta.count);
        that.hideShowButtons("Survey");
        that.set('totalSurveys', surveys.meta.count);
        that.set('totalViewableSurveys_start', page * numberOfRecords - numberOfRecords + 1);
        if (surveys.meta.count < numberOfRecords) {
          that.set('totalViewableSurveys_end', surveys.meta.count);
        } else {
          that.set('totalViewableSurveys_end', page * numberOfRecords);
        }
      });
    },
    /* Get Studies For Researchers Dashboard */
    getStudies: function () {
      var page = this.studyPage;
      var that = this;
      var searchStudy = this.get('searchStudy');
      var numberOfRecords = this.getNumberOfBoxesToDisplay();
      this.store.query('study', {
        page: page,
        page_size: numberOfRecords - 1,
        name__icontains: searchStudy
      }).then(function (studies) {
        var next = studies.meta.next;
        var previous = studies.meta.previous;
        that.set('studies', studies);
        that.set('nextStudy', next);
        that.set('previousStudy', previous);
        that.setDivs("Study", studies.meta.count);
        that.hideShowButtons("Study");
      });
    },
    /* Get exact number of boxes to display as per the screen width. */
    getNumberOfBoxesToDisplay: function () {
      var screenWidth = screen.width;
      if (screenWidth) {
        var eachComponentWidth = screenWidth - 300;
        var eachComponent = Math.round(eachComponentWidth / 300);
        if (eachComponent === 0) {
          return 1;
        } else {
          return eachComponent;
        }
      } else {
        return 5;
      }
    },
    setDivs: function (type, records) {
      var fitnumber = this.getNumberOfBoxesToDisplay();
      if (type === "Module") {
        if (records >= fitnumber) {
          (0, _jquery.default)('#modulebar').css({
            "justify-content": 'space-evenly'
          });
        } else {
          (0, _jquery.default)('#modulebar').css({
            "justify-content": 'start'
          });
        }
      }
      if (type === "Survey") {
        if (records >= fitnumber) {
          (0, _jquery.default)('#surveybar').css({
            "justify-content": 'space-evenly'
          });
        } else {
          (0, _jquery.default)('#surveybar').css({
            "justify-content": 'start'
          });
        }
      }
      if (type === "Study") {
        if (records >= fitnumber - 1) {
          (0, _jquery.default)('#studybar').css({
            "justify-content": 'space-evenly'
          });
        } else {
          (0, _jquery.default)('#studybar').css({
            "justify-content": 'start'
          });
        }
      }
    },
    hideShowButtons: function (type) {
      if (type === "Module") {
        if (this.previousModule) {
          (0, _jquery.default)('#previousModule').show();
        } else {
          (0, _jquery.default)('#previousModule').hide();
        }
        if (this.nextModule) {
          (0, _jquery.default)('#nextModule').show();
        } else {
          (0, _jquery.default)('#nextModule').hide();
        }
      }
      if (type === "Survey") {
        if (this.previousSurvey) {
          (0, _jquery.default)('#previousSurvey').show();
        } else {
          (0, _jquery.default)('#previousSurvey').hide();
        }
        if (this.nextSurvey) {
          (0, _jquery.default)('#nextSurvey').show();
        } else {
          (0, _jquery.default)('#nextSurvey').hide();
        }
      }
      if (type === "Study") {
        if (this.previousStudy) {
          (0, _jquery.default)('#previousStudy').show();
        } else {
          (0, _jquery.default)('#previousStudy').hide();
        }
        if (this.nextStudy) {
          (0, _jquery.default)('#nextStudy').show();
        } else {
          (0, _jquery.default)('#nextStudy').hide();
        }
      }
    }
  });
  _exports.default = _default;
});