define("momentumhub/models/part", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberData.default.Model.extend({
    part_json: _emberData.default.attr(),
    identifier: _emberData.default.attr(),
    phase: _emberData.default.belongsTo('phase'),
    part_result_json: _emberData.default.attr()
  });
  _exports.default = _default;
});