define("momentumhub/routes/survey-preview", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "momentumhub/config/environment", "survey-knockout", "momentumhub/helpers/custom-widgets", "survey-pdf", "blockly/msg/en", "momentumhub/utils"], function (_exports, _authenticatedRouteMixin, _environment, Survey, _customWidgets, SurveyPDF, _en, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    session: Ember.inject.service(),
    activate: function () {
      this._super();
      window.scrollTo(0, 0);
    },
    model(param) {
      if (param.id) {
        return Ember.RSVP.hash({
          survey: this.store.query('survey', {
            identifier: param.id
          })
        });
      }
    },
    actions: {
      refreshCurrentRoute() {
        this.refresh();
      }
    },
    setupController(controller, model) {
      this._super(controller, model);
      this.loadSurveyBuilder(model);
    },
    /* Loads the survey Builder */
    loadSurveyBuilder: function (model) {
      var that = this;
      var surveyModel = model.survey.get('firstObject');
      if (surveyModel) {
        var json = surveyModel.get('survey_body_viewable');
        var survey_body = surveyModel.get('survey_body');
        // #todo, eliminate Survey from Global
        window.Survey = window.Survey || Survey;
        (0, _customWidgets.default)();
        setTimeout(function () {
          Survey.StylesManager.applyTheme("modern");
          window.survey = new Survey.Model(json);
          if (surveyModel.get('allow_print')) (0, _utils.setupSurveyPrint)(window.survey, json);
          survey.onComplete.add(function (result) {
            that.saveSurveyResult(result, survey_body, survey_body);
            document.querySelector('#surveyResult').textContent = "Result JSON:\n" + JSON.stringify(result.data, null, 3);
          });
          survey.render("surveyElement");
          // survey.completedHtml = "<p class='survey_response_copy'>Thank you for the response.</p>"
          // survey.showCompletedPage = false
        }, 100);
      }
    },
    saveSurveyResult: function (survey, json, survey_body) {
      var result = survey.data;
      console.log(result);
      var that = this;
      var isForParentDetails = that.checkParentDetilsSurvey(survey_body);
      var displayValues = [];
      for (var key in survey.data) {
        var question = survey.getQuestionByValueName(key);
        var mappings = {};
        if (!question) continue;
        if (question.classMetaData.name == 'matrix') question.rows.map(function (row, index) {
          mappings[row.id] = row.title;
        });
        var questionResult = {
          name: key,
          value: survey.data[key],
          title: question.title,
          displayValue: question.displayValue,
          questionLabel: mappings
        };
        displayValues.push(questionResult);
      }
      json.pages.forEach(function (page) {
        var finalResult = [];
        var finalResultArray = [];
        for (const [key, value] of Object.entries(result)) {
          var question = survey.getQuestionByValueName(key);
          console.log(key);
          console.log(question.displayValue);
          const a = page.elements.filter(function (e) {
            return e.name == key;
          });
          if (a[0]) {
            const b = a[0];

            // 1.Checkbox
            if (b.type == "checkbox") {
              var choicesArray = [];
              value.forEach(function (val) {
                const c = b.choices.filter(function (e) {
                  return e.value == val;
                });
                if (c[0]) {
                  var text = c[0].text;
                  text = that.checkString(text);
                  choicesArray.pushObject(text);
                }
              });
              const obj = {
                "question": key,
                "answer": choicesArray
              };
              finalResultArray.pushObject(obj);
            }

            // 2.radiogroup
            if (b.type == "radiogroup") {
              const c = b.choices.filter(function (e) {
                return e.value == value;
              });
              if (c[0]) {
                var text = c[0].text;
                text = that.checkString(text);
                const obj = {
                  "question": key,
                  "answer": text
                };
                finalResultArray.pushObject(obj);
              }
            }

            //3. Dropdown
            if (b.type == "dropdown") {
              const c = b.choices.filter(function (e) {
                return e.value == value;
              });
              if (c[0]) {
                var text = c[0].text;
                text = that.checkString(text);
                const obj = {
                  "question": key,
                  "answer": text
                };
                finalResultArray.pushObject(obj);
              }
            }

            //4. Boolean
            if (b.type == "boolean") {
              if (value == true) {
                var text = b.labelTrue;
                text = that.checkString(text);
                const obj = {
                  "question": key,
                  "answer": text
                };
                finalResultArray.pushObject(obj);
              } else {
                var text = b.labelFalse;
                text = that.checkString(text);
                const obj = {
                  "question": key,
                  "answer": text
                };
                finalResultArray.pushObject(obj);
              }
            }

            //5. Ratings
            if (b.type == "rating") {
              const c = b.rateValues.filter(function (e) {
                return e.value == value;
              });
              if (c[0]) {
                var text = c[0].text;
                text = that.checkString(text);
                const obj = {
                  "question": key,
                  "answer": text
                };
                finalResultArray.pushObject(obj);
              }
            }

            //6. Ranking
            if (b.type == "ranking") {
              const obj = {
                "question": key,
                "answer": value
              };
              finalResultArray.pushObject(obj);
            }

            //7. Simple Text
            if (b.type == "text") {
              const obj = {
                "question": key,
                "answer": value
              };
              finalResultArray.pushObject(obj);
            }

            //8. matrix
            if (b.type == "matrix") {
              var matrixArray = [];
              for (const [k, v] of Object.entries(value)) {
                var columnText = "";
                var rowText = "";
                var cv = b.columns.filter(function (e) {
                  return e.value == v;
                });
                if (cv[0]) {
                  columnText = cv[0].text;
                }
                var rv = b.rows.filter(function (e) {
                  return e.value == k;
                });
                if (rv[0]) {
                  rowText = rv[0].text;
                }
                if (columnText.includes("~")) {
                  var fields = columnText.split('~ val:');
                  columnText = fields[1];
                }
                if (rowText.includes("~")) {
                  var fields = rowText.split('~ val:');
                  rowText = fields[1];
                }
                if (!isNaN(parseInt(columnText))) {
                  matrixArray.pushObject(rowText + ":" + columnText);
                }
              }
              const obj = {
                "question": key,
                "answer": matrixArray
              };
              finalResultArray.pushObject(obj);
            }

            //9. ImagePicker
            if (b.type == "imagepicker") {
              const c = b.choices.filter(function (e) {
                return e.value == value;
              });
              if (c[0]) {
                var text = c[0].text;
                text = that.checkString(text);
                const obj = {
                  "question": key,
                  "answer": text
                };
                finalResultArray.pushObject(obj);
              }
            }

            //10. matrixdropdown
            if (b.type == "matrixdropdown") {
              var matrixArray = [];
              for (const [k, v] of Object.entries(value)) {
                var rowIndex = k[4];
                var rowText = b.rows[parseInt(rowIndex) - 1].text;
                var rowColumn = "";
                if (rowText.includes("~")) {
                  var fields = rowText.split('~ val:');
                  rowText = fields[1];
                }
                //Columns
                for (const [k1, v1] of Object.entries(v)) {
                  var columnIndex = k1[7];
                  var columnText = b.columns[parseInt(columnIndex) - 1].title;
                  if (columnText.includes("~")) {
                    var fields = columnText.split('~ val:');
                    columnText = fields[1];
                    rowColumn = rowText + "_" + columnText;
                    matrixArray.pushObject(rowColumn + ":" + v1);
                  } else {
                    if (b.choices[v1 - 1]) {
                      const choice = b.choices[v1 - 1].text;
                      if (choice.includes("~")) {
                        var fields = choice.split('~ val:');
                        rowColumn = rowText + "_" + columnText;
                        matrixArray.pushObject(rowColumn + ":" + fields[1]);
                      }
                    }
                  }
                }
              }
              const obj = {
                "question": key,
                "answer": matrixArray
              };
              finalResultArray.pushObject(obj);
            }
            if (b.type == "expression") {
              var title = b.title;
              title = title.split('~')[1];
              const answer = title + ":" + value;
              const obj = {
                "question": key,
                "answer": answer
              };
              finalResultArray.pushObject(obj);
            }
            if (b.type == "ratinggauge") {
              var title = b.title;
              title = title.split('~')[1];
              const answer = title + ":" + value;
              const obj = {
                "question": key,
                "answer": answer
              };
              finalResultArray.pushObject(obj);
            }
          }
        }
        console.log(finalResultArray);
      });
      console.log(displayValues);
    },
    checkParentDetilsSurvey: function (json) {
      var isParentSurvey = false;
      json.pages.forEach(function (page, pageIndex) {
        page.elements.forEach(function (question, questionIndex) {
          if (question.type == "text") {
            if (question.title) {
              if (question.title.includes("$parent_first_name") || question.title.includes("$parent_last_name") || question.title.includes("$parent_email") || question.title.includes("$parent_password")) {
                isParentSurvey = true;
              }
            }
          }
        });
      });
      return isParentSurvey;
    },
    checkString(text) {
      if (text) {
        if (text.includes("~")) {
          var fields = text.split('~');
          return fields[1];
        } else {
          return text;
        }
      } else {
        return "";
      }
    }
  });
  _exports.default = _default;
});