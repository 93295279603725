define("momentumhub/routes/onboarding-new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class OnboardingNewRoute extends Ember.Route {
    model(params) {
      this.set('params', params);
      this.controllerFor("onboarding-new").set('studyId', params.studyId);
      this.controllerFor("onboarding-new").set('groupId', params.groupId);
      localStorage.setItem('onboardingDetails', JSON.stringify(params));
    }
    setupController(controller, model) {
      if (this.get('params') && this.get('params').showConsentForm) {
        this.controllerFor('onboarding-new').set('showConsentForm', true);
      }
      this.controllerFor('onboarding-new').checkIfStudyPublished();
    }
  }
  _exports.default = OnboardingNewRoute;
});