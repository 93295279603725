define("momentumhub/components/guardian-dashboard", ["exports", "jquery", "momentumhub/config/environment", "animejs/lib/anime.es.js"], function (_exports, _jquery, _environment, _animeEs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    router: Ember.inject.service(),
    showFeelingsTracker: false,
    showClueCalmers: false,
    showPowerThinking: false,
    showClimbingPlans: false,
    showMoodBoost: false,
    init() {
      this._super(...arguments);
      if (this.session.data.viewingName) {
        this.set("showFeelingsTracker", this.session.data.viewingShow_feelings_tracker);
        this.getParticipantRewards();
      } else {
        this.set('showClueCalmers', false);
        this.set('showPowerThinking', false);
        this.set('showClimbingPlans', false);
        this.set('showMoodBoost', false);
        this.set("showFeelingsTracker", false);
      }
    },
    getParticipantRewards: function () {
      const that = this;
      const accessToken = this.get('session.data.authenticated.access_token');
      var child_email = this.session.get('data.viewingEmail');
      _jquery.default.ajax({
        url: _environment.default.APP.API_HOST + '/parentparticipantrewardvars?child_email=' + child_email,
        type: 'GET',
        headers: {
          "Authorization": "Bearer " + accessToken
        },
        success: function (res) {
          console.log('res', res);
          var rewards = res.userrewardvar[0].reward_vars;
          var userType = that.session.get('data.user_type');
          if (rewards && rewards.TR_ClueCalmers_available == 1) {
            that.set('showClueCalmers', true);
          }
          if (rewards && rewards.TR_PowerThinking_available == 1) {
            that.set('showPowerThinking', true);
          }
          if (rewards && rewards.TR_ClimbingPlans_available == 1) {
            console.log('inside climbing plans');
            that.set('showClimbingPlans', true);
          }
          if (rewards && rewards.TR_MoodBoost_available == 1) {
            that.set('showMoodBoost', true);
          }
          if (rewards && rewards.TR_ChangeFocus_available == 1) {
            that.set('showChangeFocus', true);
          }
          if (userType === 'CL' && rewards && rewards.TR_FeelingsTracker_available == 1) {
            // Only when the user is clinician, we should show feelings tracker based on TR vars
            // For guardians, the decision is based on the "Show Guardian feelings tracker" boolean of the user
            that.set('showFeelingsTracker', true);
          }
        },
        error: function (err, xH) {
          var str = err.responseText;
          str = str.split(':["').pop();
          str = str.split('"]')[0];
          console.log(str);
        }
      });
    }
  });
  _exports.default = _default;
});