define("momentumhub/components/mount-momentum-step", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    step: null,
    showTooltips: null,
    index: null,
    showTooltip: Ember.computed('showTooltips.[]', 'step', 'index', function () {
      return this.get('showTooltips').indexOf(this.get('index')) > -1;
    }),
    actions: {
      removeStep: function () {
        const step = this.get('step');
        this.get('removeStep')(step);
      },
      toggleComplete: function () {
        const step = this.get('step');
        step.set('is_complete', !step.is_complete);
      }
    }
  });
  _exports.default = _default;
});