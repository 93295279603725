define("momentumhub/components/mount-momentum-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    model: null,
    showExamples: false,
    session: Ember.inject.service(),
    showExampleCategories: true,
    exampleCategory: null,
    examples: [],
    store: Ember.inject.service('store'),
    toast: Ember.inject.service('toast'),
    currentUserType: null,
    shouldDisableSaveButton: false,
    init() {
      this._super(...arguments);
      this.set('currentUserType', this.session.get('data.user_type'));
      if (this.get('currentUserType') != 'PT') {
        this.set('shouldDisableSaveButton', true);
      }
      this.set('steps', []);
      this.get('store').query('mountain-example', {}).then(response => {
        this.set('examples', response);
      });
      if (this.get('model.steps') && this.get('model.steps').length === 0) this.addNewStep();
    },
    showTooltips: Ember.computed('model.steps.[]', 'model.steps.@each.worry_level', function () {
      let worryLevel = 0;
      let steps = this.get('model.steps');
      let show = steps.map(function (step, index) {
        const diff = step.worry_level - worryLevel;
        worryLevel = step.worry_level;
        if (diff > 2) {
          return index;
        }
      });
      return show;
    }),
    addNewStep() {
      this.get('model.steps').pushObject(this.get('store').createRecord('mountain-step', {
        step: '',
        reward: '',
        worry_level: 0,
        isComplete: false
      }));
      this.notifyPropertyChange('model.steps');
    },
    actions: {
      addStep: function () {
        const steps = this.get('model.steps');
        if (!this.model.title || this.model.title.length === 0) {
          alert('Please add a "Goal" before adding a step.');
          return;
        }
        this.addNewStep();
      },
      removeStep: function (step) {
        this.get('model.steps').removeObject(step);
      },
      reorderItems: function (items, draggedItem) {
        this.set('model.steps', items);
      },
      saveChanges: function () {
        if (this.get('shouldDisableSaveButton')) {
          return;
        }
        if (!this.get('model.title')) {
          this.toast.error('Please fill a goal before saving', 'Save Failure');
          return;
        }
        this.get('model').save().then(model => {
          this.get('model.steps').filterBy('isNew', true).forEach(item => {
            if (item.step.length > 0) this.store.deleteRecord(item);
          });
        });
        this.toast.success('Your data has been saved successfully.', 'Save Success');
      },
      showExamples: function () {
        this.set('showExamples', true);
      },
      hideExamples: function () {
        this.set('showExamples', false);
      },
      showExampleCategory: function (category) {
        this.set('exampleCategory', category);
        this.set('showExampleCategories', false);
      },
      hideExampleCategory: function () {
        this.set('showExampleCategories', true);
      },
      targetDropped: function (param) {
        this.get('model.steps').pushObject(this.get('store').createRecord('mountain-step', {
          step: param,
          reward: '',
          worry_level: 0,
          isComplete: false
        }));
      }
    }
  });
  _exports.default = _default;
});