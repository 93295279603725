define("momentumhub/components/full-calendar", ["exports", "ember-fullcalendar/components/full-calendar"], function (_exports, _fullCalendar) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _fullCalendar.default;
    }
  });
});