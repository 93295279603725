define("momentumhub/components/landing-page", ["exports", "jquery", "momentumhub/config/environment", "blockly/msg/en"], function (_exports, _jquery, _environment, _en) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    router: Ember.inject.service(),
    user_type: "",
    actions: {}
  });
  _exports.default = _default;
});