define("momentumhub/router", ["exports", "momentumhub/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  class Router extends Ember.Router {
    constructor() {
      super(...arguments);
      _defineProperty(this, "location", _environment.default.locationType);
      _defineProperty(this, "rootURL", _environment.default.rootURL);
    }
  }
  _exports.default = Router;
  Router.map(function () {
    this.route('dashboard');
    this.route('studyPartBuilder');
    this.route('modules');
    this.route('create-module', {
      path: '/create-module/:id'
    });
    this.route('studies');
    this.route('user-profile', function () {
      this.route('rewards');
    });
    this.route('inbox');
    this.route('surveys');
    this.route('create-survey', {
      path: '/create-survey/:id'
    });
    this.route('study-analytics', {
      path: '/study-analytics/:id'
    });
    this.route('study-builder', {
      path: '/study-builder/:id'
    });
    this.route('modulePreview', {
      path: '/modulePreview/:id'
    });
    this.route('survey-preview', {
      path: '/survey-preview/:id'
    });
    this.route('admin');
    this.route('studyview', {
      path: '/view/:studyUserId/:UserId'
    });
    this.route('reset-password', {
      path: '/reset-password/:id/:token'
    });
    this.route('researcher');
    this.route('registration', {
      path: '/registration/:studyId/:groupId'
    });
    this.route('not-found', {
      path: '/*path'
    });
    this.route('usermanagement', function () {
      this.route('users');
      this.route('groups');
      this.route('studies');
      this.route('sites');
      this.route('links');
    });
    this.route('sites');
    this.route('login');
    this.route('climbing-plans', function () {
      this.route('create');
      this.route('edit', {
        path: ':id/edit'
      });
    });
    this.route('clue-calmers');
    this.route('feelings-tracker');
    this.route('loading');
    this.route('feelingstracker');
    this.route('assesmentResults');
    this.route('power-thinking');
    this.route('change-focus');
    this.route('mood-boosters');
    this.route('onboarding-new', {
      path: '/onboarding-new/:studyId/:groupId'
    });
    this.route('onboarding-redirect');
    this.route('onboarding-confirmation', {
      path: '/onboarding-confirmation/:studyId/:groupId'
    });
    this.route('participant-details', {
      path: '/participant-details/:userId'
    });
  });
});