define("momentumhub/components/worry-level", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    worryLevel: 0,
    worryHoverLevel: 0,
    allLevels: Array(),
    init() {
      this._super(...arguments);
      let startValue = 10;
      let endValue = 0;
      let finalAngle = 120;
      let levels = Array();
      let count = startValue - endValue + 1;
      const angleDivider = count;
      for (let i = startValue; i >= endValue; i--) {
        levels.push([count - 1, finalAngle, finalAngle * -1, i]);
        finalAngle -= 270 / angleDivider;
        count--;
      }
      this.set('allLevels', levels);
      this.setNeedleRotate(levels[startValue - this.get('worryLevel')]);
    },
    setNeedleRotate(level) {
      const angle = level[1] + 88;
      this.set('needleRotateAngle', angle);
    },
    actions: {
      setRating: function (level) {
        this.set('worryLevel', level[0]);
        this.setNeedleRotate(level);
      }
    }
  });
  _exports.default = _default;
});