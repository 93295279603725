define("momentumhub/models/coordinator", ["exports", "momentumhub/models/obj-hash", "ember-drag-drop/utils/proxy-unproxy-objects"], function (_exports, _objHash, _proxyUnproxyObjects) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Object.extend(Ember.Evented, {
    objectMap: Ember.computed(function () {
      return _objHash.default.create();
    }),
    getObject: function (id, ops) {
      ops = ops || {};
      var payload = this.get('objectMap').getObj(id);
      if (payload.ops.source && !payload.ops.source.isDestroying && !payload.ops.source.isDestroyed) {
        const action = payload.ops.source['action'];
        // Support when action is a function
        if (typeof action === 'function') {
          action(payload.obj);
        }

        // Support when action is a string
        if (typeof action === 'string' && typeof payload.ops.source.target[action] === 'function') {
          payload.ops.source.target[action](payload.obj);
        }
      }
      if (payload.ops.target && !payload.ops.target.isDestroying && !payload.ops.target.isDestroyed) {
        const action = payload.ops.target['action'];
        // Support when action is a function
        if (typeof action === 'function') {
          action(payload.obj);
        }

        // Support when action is a string
        if (typeof action === 'string' && typeof payload.ops.target.source[action] === 'function') {
          payload.ops.target.source[action](payload.obj);
        }
      }
      this.trigger("objectMoved", {
        obj: (0, _proxyUnproxyObjects.unwrapper)(payload.obj),
        source: payload.ops.source,
        target: ops.target
      });
      return (0, _proxyUnproxyObjects.unwrapper)(payload.obj);
    },
    setObject: function (obj, ops) {
      ops = ops || {};
      return this.get('objectMap').add({
        obj: obj,
        ops: ops
      });
    }
  });
  _exports.default = _default;
});