define("momentumhub/templates/components/worry-level", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "tzW6PQXK",
    "block": "{\"symbols\":[\"level\"],\"statements\":[[3,\" <div class=\\\"worry-level-container\\\">\\n    <div class=\\\"worry-level-bars\\\">\\n{{#each allLevels as |level|}}\\n            {{#if (gte level 0) }}\\n                <div data-level=\\\"{{level}}\\\" class=\\\"worry-level level-{{level}} {{if (gte worryLevel level) 'active' ''}} {{if (gte worryHoverLevel level) 'hover' ''}}\\\" {{action \\\"setRating\\\" level}}></div>\\n            {{/if}}\\n        {{/each}}    </div>\\n</div> \"],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"widget-wrapper cw-gauge--wrapper\"],[14,1,\"cw-gauge--wrapper\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"cw-gauge--reading-container\"],[12],[2,\"\\n\"],[6,[37,4],[[30,[36,3],[[30,[36,3],[[35,2]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[11,\"div\"],[16,0,[31,[\"cw-reading--\",[30,[36,0],[[32,1],\"0\"],null]]]],[16,\"data-rating\",[31,[[30,[36,0],[[32,1],\"0\"],null]]]],[16,\"data-theta\",[31,[[30,[36,0],[[32,1],\"1\"],null]]]],[16,5,[31,[\"transform:rotate(\",[30,[36,0],[[32,1],\"1\"],null],\"Deg)\"]]],[4,[38,1],[[32,0],\"setRating\",[32,1]],null],[12],[2,\"\\n                \"],[10,\"div\"],[15,\"stlyle\",[31,[\"transform:rotate(\",[30,[36,0],[[32,1],\"2\"],null],\"Deg)\"]]],[12],[1,[30,[36,0],[[32,1],\"3\"],null]],[13],[2,\"\\n            \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"cw-gauge--needle\"],[15,5,[31,[\"transform: rotate(\",[34,5],\"deg)\"]]],[12],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"get\",\"action\",\"allLevels\",\"-track-array\",\"each\",\"needleRotateAngle\"]}",
    "moduleName": "momentumhub/templates/components/worry-level.hbs"
  });
  _exports.default = _default;
});