define("momentumhub/models/study", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberData.default.Model.extend({
    study_name: _emberData.default.attr(),
    number_of_arms: _emberData.default.attr(),
    number_of_phases: _emberData.default.attr(),
    start_date: _emberData.default.attr(),
    variables_to_track: _emberData.default.attr(),
    active: _emberData.default.attr('boolean'),
    archived: _emberData.default.attr('boolean'),
    draft: _emberData.default.attr('boolean'),
    creator: _emberData.default.belongsTo('user'),
    researchers: _emberData.default.hasMany('user'),
    participants: _emberData.default.hasMany('user'),
    professionals: _emberData.default.attr(),
    structure_json: _emberData.default.attr(),
    initial_study_part: _emberData.default.attr(),
    groups: _emberData.default.hasMany('researchergroup'),
    participant_groups: _emberData.default.hasMany('participantgroup'),
    participants_in_study: _emberData.default.hasMany('user'),
    site: _emberData.default.belongsTo('mommentumhubsite'),
    default_magic_link: _emberData.default.attr(),
    randomisation_block_size: _emberData.default.attr(),
    randomisation_stratification_vars: _emberData.default.attr(),
    time_based_rule_vars_track: _emberData.default.attr(),
    keep_variables_from_previous_study: _emberData.default.attr(),
    total_users: _emberData.default.attr('number'),
    avg_progress: _emberData.default.attr('number')
  });
  _exports.default = _default;
});